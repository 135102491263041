import { useState } from 'react'
import { CardContainer } from '../../../shared/containers/CardContainer'
import { Flex, Spinner } from '@chakra-ui/react'
import LastProfile from './lastProfile'
import DailyProfiles from './dailyProfiles'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { IProfileBatch } from '../../../../provider/Profile/profileProvider'
import { fetchApi } from '../../../../utils/fetcher'

export const LatestProfiles = () => {
  const [data, setData] = useState<IProfileBatch>([])
  const [isLoading, setIsLoading] = useState(true)

  useAsyncEffect(async () => {
    void loaded(async () => {
      const data: IProfileBatch = (await fetchApi(`profile/page/1`)).profiles
      data.forEach(datum => (datum.address = datum.address_?.[0]))
      setData(data)
    }, setIsLoading)
  }, [])

  return (
    <CardContainer>
      <Flex
        w="100%"
        justifyContent="space-between"
        direction={{ base: 'column', lg: 'row' }}
        gap="24px"
        //flexWrap="wrap"
      >
        {isLoading ? <Spinner /> : <LastProfile data={data} />}
        <DailyProfiles />
      </Flex>
    </CardContainer>
  )
}

export default LatestProfiles
