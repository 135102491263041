import { Box, Flex, Image, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { FormEvent, useLayoutEffect, useRef, useState } from 'react'
import { RoleBadge } from './RoleBadge'
import UserFollowingComponent from '../../../../../User/UserContent/UserWall/UserFollowing/userFollowingComponent'

export const Members = () => {
  const [value, setValue] = useState('')
  const [valuesArr, setValuesArr] = useState<string[]>([])

  const [width, setWidth] = useState<number>(0)
  const ref = useRef<HTMLDivElement>(null)

  const [wrapperWidth, setWrapperWidth] = useState<number>(0)
  const [wrapperHeight, setWrapperHeight] = useState<number>(0)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (value) {
      setValuesArr([...valuesArr, value])
      setValue('')
    }
  }

  const removeEl = (index: number) => {
    const newArr = [...valuesArr]
    newArr.splice(index, 1)
    setValuesArr(newArr)
  }

  useLayoutEffect(() => {
    if (ref.current && wrapperRef.current) {
      if (valuesArr.length === 1) {
        setWidth(ref.current.offsetWidth + 14)
      } else setWidth(ref.current.offsetWidth)

      setWrapperWidth(wrapperRef.current.offsetWidth)
      setWrapperHeight(ref.current.offsetHeight)
    }
  }, [valuesArr])

  // todo

  // Czy placeholder ma sie ruszac xD ?

  return (
    <Flex
      flexDir="column"
      gap="16px"
      mt="-8px"
      ref={wrapperRef}
    >
      <form onSubmit={onSubmit}>
        <InputGroup
          flexWrap="wrap"
          maxWidth={wrapperWidth}
        >
          <Flex
            position="absolute"
            top="7px"
            pl="10px"
            gap="10px"
            pr="10px"
            alignItems="center"
            zIndex="1"
            flexWrap="wrap"
            maxWidth={wrapperWidth - 40}
            ref={ref}
          >
            {valuesArr.map((value, index) => (
              <RoleBadge
                value={value}
                key={index}
                removeEl={() => removeEl(index)}
              ></RoleBadge>
            ))}
          </Flex>
          <Input
            height={wrapperHeight ? wrapperHeight + 14 + 'px' : '40px'}
            pl={width == 20 ? '12px' : width}
            placeholder={!valuesArr.length ? 'Filter by roles' : ''}
            _focus={{
              boxShadow: 'none',
              border: '1px solid',
              borderColor: 'borderPrimary !important',
              outline: 'none !important',
            }}
            onChange={e => setValue(e.target.value)}
            value={value}
          />
          <InputRightElement>
            <Image src="/assets/icons/search.svg" />
          </InputRightElement>
        </InputGroup>
      </form>
      <Flex
        borderRadius="16px"
        border="1px solid"
        borderColor="borderPrimary"
        w="100%"
        flexDirection="column"
      >
        <UserFollowingComponent
          name="Landon Reynolds"
          address="0x00"
          nickname="landonRe"
          roles={['Author', 'Developer', 'Blockchain expert', 'sth']}
          description="Short description orem ipsum dolor sit #amet, consectetur adipiscing ut tortor hendrerit #cursus congue velit quis magna."
        />
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        <UserFollowingComponent
          name="Landon Reynolds"
          address="0x00"
          nickname="landonRe"
          roles={['Author', 'Developer', 'Blockchain expert']}
          description="Short description orem ipsum dolor sit #amet, consectetur adipiscing ut tortor hendrerit #cursus congue velit quis magna."
        />
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        <UserFollowingComponent
          name="Landon Reynolds"
          address="0x00"
          nickname="landonRe"
          roles={['Author', 'Developer', 'Blockchain expert']}
          description="Short description orem ipsum dolor sit #amet, consectetur adipiscing ut tortor hendrerit #cursus congue velit quis magna."
        />
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        <UserFollowingComponent
          name="Landon Reynolds"
          address="0x00"
          nickname="landonRe"
          roles={['Author', 'Developer', 'Blockchain expert']}
          description="Short description orem ipsum dolor sit #amet, consectetur adipiscing ut tortor hendrerit #cursus congue velit quis magna."
        />
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        <UserFollowingComponent
          name="Landon Reynolds"
          address="0x00"
          nickname="landonRe"
          roles={['Author', 'Developer', 'Blockchain expert']}
          description="Short description orem ipsum dolor sit #amet, consectetur adipiscing ut tortor hendrerit #cursus congue velit quis magna."
        />
      </Flex>
    </Flex>
  )
}
