import { createContext, useContext, useEffect, useReducer, useState } from 'react'
import { CHAT_FILTER_TYPE, emptyState, IState, Reducer } from './reducer'
import { io } from 'socket.io-client'
import { useEthereumProvider } from '../Ethereum/ethereumProvider'
import { getSocialxplorerJwtAuthKey } from '../../utils/auth'
import { Message, Thread, User, emptyUser } from './types'
import { createMessageFromData } from './helpers'
import first from 'lodash/first'
import { _log } from '../../logger'


const api = process.env.REACT_APP_API as string;

export const socket = io(api.slice(0, -3), {
  transports: ['websocket'],
  autoConnect: false,
})

interface IContext extends IState {
  setFilter: (filter: CHAT_FILTER_TYPE) => void
  openThread: (threadId: any) => void
  openOverlayThread: (threadId: any) => void
  toggleDetails: () => void
  toggleOverlayChat: (open: boolean) => void
  getChatUser: (threadId: string) => void
  archiveThread: (threadId: any) => void
  getThreadById: (threadId: string) => Thread | null
  loadThread: (threadId: string) => void
}

const emptyContext: IContext = {
  ...emptyState,
  archiveThread: (threadId: any) => null,
  getChatUser: (threadId: string) => null,
  openOverlayThread: (threadId: any) => null,
  setFilter: (filter: CHAT_FILTER_TYPE) => null,
  openThread: (threadId: any) => null,
  toggleDetails: () => null,
  toggleOverlayChat: (open: boolean) => null,
  getThreadById: (threadId: string) => null,
  loadThread: (threadId: string) => null,
}

const Context = createContext(emptyContext)

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, emptyContext)
  const [threadsUnfiltered, setThreadsUnfiltered] = useState<any[]>([])
  const wallet = useEthereumProvider()

  const setFilter = (filter: CHAT_FILTER_TYPE) => {
    dispatch({
      filter: filter,
    })
  }

  const openThread = (threadId: any) => {
    dispatch({
      selectedThreadId: threadId,
    })
  }

  const openOverlayThread = (threadId: any) => {
    if (getThreadById(threadId)?.messages.length == 0) {
      const key = getSocialxplorerJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)
      socket.emit('openedChat', { jwt: secret, thread: threadId, address: wallet.account })
      socket.emit('messages', { jwt: secret, thread: threadId, address: wallet.account })
    }
    dispatch({
      selectedOverlayThreadIds: state.selectedOverlayThreadIds.includes(threadId)
        ? state.selectedOverlayThreadIds.filter(item => item != threadId)
        : state.selectedOverlayThreadIds.concat([threadId]),
    })
  }

  useEffect(() => {
    if (state.filter == CHAT_FILTER_TYPE.All) {
      dispatch({
        threadsFiltered: state.threads.filter(thread => !state.archivedThreads.includes(thread.id)),
      })
    } else if (state.filter == CHAT_FILTER_TYPE.Archived) {
      dispatch({
        threadsFiltered: state.threads.filter(thread => state.archivedThreads.includes(thread.id)),
      })
    } else {
      dispatch({
        threadsFiltered: state.threads,
      })
    }
  }, [state.threads, state.filter, state.archivedThreads])

  useEffect(() => {
    const key = getSocialxplorerJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)
    socket.emit('openedChat', { jwt: secret, thread: state.selectedThreadId, address: wallet.account })
    socket.emit('messages', { jwt: secret, thread: state.selectedThreadId, address: wallet.account })
  }, [state.selectedThreadId])

  const toggleDetails = () => {
    dispatch({ detailsOpened: !state.detailsOpened })
  }

  const toggleOverlayChat = (open: boolean) => {
    dispatch({ overlayChatOpened: open })
  }

  useEffect(() => {
    if (!wallet.isAuthed) return
    socket.connect()
  }, [wallet.isAuthed])

  useEffect(() => {
    dispatch({
      threads: [],
      selectedThreadId: null,
      selectedOverlayThreadIds: [],
    })
  }, [wallet.account])

  const getThreads = () => {
    const key = getSocialxplorerJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)
    socket.emit('threads', { jwt: secret, address: wallet.account as address })
  }

  const getThreadById = (threadId: string) => {
    return state.threads.find(thread => thread.id == threadId) ?? null
  }

  const archiveThread = (threadId: any) => {
    const key = getSocialxplorerJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)

    if (state.archivedThreads.includes(threadId)) {
      socket.emit('archive', { jwt: secret, thread: state.selectedThreadId, address: wallet.account, unarchive: true })
      dispatch({ archivedThreads: state.archivedThreads.filter(thread => thread != threadId) })
    } else {
      socket.emit('archive', { jwt: secret, thread: state.selectedThreadId, address: wallet.account })
    }
  }

  const getChatUser = (threadId: string) => {
    return state.users[threadId] ?? emptyUser
  }

  const loadThread = (threadId: string) => {
    const threadPage = getThreadById(threadId)?.page
    const threadLoading = getThreadById(threadId)?.loading

    const firstMessage = getThreadById(threadId)?.messages[0]

    if (threadLoading) return

    if (firstMessage) {
      if (firstMessage.text == '_') return
    }

    if (threadPage != undefined) {
      const key = getSocialxplorerJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)

      socket.emit('messages', {
        jwt: secret,
        thread: state.selectedThreadId,
        address: wallet.account,
        page: threadPage + 1,
      })

      let newThread = getThreadById(threadId) as Thread

      if (newThread) {
        newThread.page = newThread.page + 1
        newThread.loading = true
        dispatch({
          threads: state.threads.map(thread => (thread.id == threadId ? newThread : thread)),
        })
      }
    }
  }

  const getSettings = () => {
    const key = getSocialxplorerJwtAuthKey(wallet.account as address)
    const secret = localStorage.getItem(key)
    socket.emit('account', { jwt: secret, address: wallet.account as address })
  }

  useEffect(() => {
    if (socket.connected && state.isConnected == false) {
      getThreads()
      getSettings()
    }
  }, [socket.connected, wallet.account])

  useEffect(() => {
    function onConnect() {
      dispatch({ isConnected: true })
      getThreads()
      const key = getSocialxplorerJwtAuthKey(wallet.account as address)
      const secret = localStorage.getItem(key)

      socket.emit('establishConnection', { jwt: secret, address: wallet.account as address })
      getSettings()
    }

    function onThreads(payload: any) {
      let data = payload

      try {
        let usedIds: any = []

        let newThreads: any = []

        for (const thread of data) {
          if (usedIds.includes(thread.id)) {
          } else {
            if (thread.id != null) {
              if (thread.users[0].length > 1) {
                usedIds = usedIds.concat([thread.id])
                newThreads = newThreads.concat([thread])
              }
            }
          }
        }

        dispatch({
          threads: data
            .filter(item => item.id != null && item.users[0].length > 1)
            .map((d: any) => {
              let users: User[] = []

              for (let u of d.users[0]) {
                users.push({
                  mainAddress: u.address,
                  avatarUrl: u.avatar,
                  name: u.name,
                  description: u.description,
                  nickname: u.nickname,
                  lastActive: new Date(u.chatLastActive ?? u._created_at),
                })
              }

              return {
                messages: [],
                id: d.id,
                lastMessage: createMessageFromData(d.message),
                users: users.filter(user => user.mainAddress != (wallet.account as address)),
                page: 0,
                loading: false,
              }
            }),
          loading: false,
        })
      } catch (e) {}
    }

    function onThreadMessages(payload: any) {
      let data = payload.data

      let messages: Message[] = []

      for (let m of data) {
        messages.push(createMessageFromData(m))
      }

      if (messages.length > 0) {
        const thread = state.threads.find(thread => thread.id == messages[0].thread)

        if (thread) {
          /* Filter out duplicate messages that were received upon superflouous 'messages' request */
          const messagesFiltered = messages.filter(
            message => !thread.messages.map(({ uuid }) => uuid).includes(message.uuid)
          )

          thread.messages = messagesFiltered.reverse().concat(thread.messages)
          thread.loading = false
          dispatch({ threads: state.threads.map(t => (t.id == thread.id ? thread : t)), loading: false })
        }
      } else {
        const thread = state.threads.find(thread => thread.loading == true)
        if (thread) {
          thread.loading = false
          dispatch({
            threads: state.threads.map(t => (t.id == thread.id ? thread : t)),
            loading: false,
          })
        }
      }
    }

    function onData(data: any) {
      _log('data:', data)

      const thread = getThreadById(data.thread)

      if (thread) {
        thread.messages = thread.messages.concat([createMessageFromData(data)])
        thread.lastMessage = createMessageFromData(data)
        thread.scrollTrigger = !thread.scrollTrigger

        const newThreads = state.threads.filter(item => item.id != thread.id)

        newThreads.unshift(thread)

        dispatch({
          threads: newThreads,
          loading: false,
        })
      }
    }

    function onAccount(data: any) {
      dispatch({
        archivedThreads: data[0]?.archivedThreads ?? [],
      })
    }

    function onArchive(data: any) {
      _log('archive:', data)

      if (data.isArchived) {
        dispatch({
          archivedThreads: state.archivedThreads.concat([data.thread]),
        })
      } else {
        dispatch({
          archivedThreads: state.archivedThreads.filter(thread => {
            thread != data.thread
          }),
        })
      }
    }

    socket.on('connect', onConnect)
    socket.on('threads', onThreads)
    socket.on('messages', onThreadMessages)
    socket.on('data', onData)
    socket.on('account', onAccount)
    socket.on('archive', onArchive)

    return () => {
      socket.off('connect', onConnect)
      socket.off('threads', onThreads)
      socket.off('messages', onThreadMessages)
      socket.off('data', onData)
      socket.off('account', onAccount)
      socket.off('archive', onArchive)
    }
  }, [wallet.account, state.messages, state.threads])

  return (
    <Context.Provider
      value={{
        ...state,
        setFilter,
        loadThread,
        openThread,
        getThreadById,
        openOverlayThread,
        archiveThread,
        getChatUser,
        toggleOverlayChat,
        toggleDetails,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useChatProvider = () => useContext(Context)
