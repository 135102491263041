// todo get rid of async reflection
const loaded = async <T>(
  reflected: (...p: any) => Promise<T>,
  setIsLoading: (_: boolean) => void = () => null,
  onError?: (error: any) => void
): Promise<T | undefined> => {
  setIsLoading(true)

  let result: T | undefined
  try {
    result = await reflected()
  } catch (error) {
    console.warn(error)

    onError?.(error)
  } finally {
    setIsLoading(false)
  }

  return result
}

export { loaded }
