import { Box, Flex, Grid, Image, MenuDivider } from '@chakra-ui/react'
import { useChatProvider } from '../../../../../provider/Chat/chatProvider'
import { Avatar } from '../../../../shared/avatars/Avatar'
import { time_ago, trimAddress } from '../../../../../utils/parser'
import { useNavigate } from 'react-router'
import { Thread } from '../../../../../provider/Chat/types'
import { useMemo } from 'react'
import { ArrowLeft } from '../../../../icons/ArrowLeft'

const MenuItem = ({
  children,
  action,
  icon,
}: {
  children: React.ReactNode
  action: () => void
  icon: React.ReactNode
}) => {
  return (
    <Flex
      cursor="pointer"
      _hover={{ bg: 'textSecondary10' }}
      align="center"
      gap="10px"
      color="textSecondary"
      paddingY="8px"
      fontSize="12px"
      px="8px"
      onClick={action}
    >
      {icon}
      <Box>{children}</Box>
    </Flex>
  )
}

export const DetailsTab = () => {
  const { currentUser, archiveThread, selectedThreadId, archivedThreads, openThread, getThreadById, toggleDetails } =
    useChatProvider()

  const navigate = useNavigate()

  const thread: Thread | null = useMemo(() => {
    return selectedThreadId ? getThreadById(selectedThreadId) : null
  }, [selectedThreadId])

  return (
    <Flex
      flexDir="column"
      pos="relative"
      padding="24px"
      alignSelf="flex-start"
      w={{ base: 'auto', md: '300px' }}
      border="1px solid"
      borderColor="rgba(230, 232, 240, 1)"
      borderRadius="16px"
    >
      <Flex
        pos="absolute"
        left="20px"
        h="20px"
      >
        {' '}
        <Flex
          boxSize="30px"
          align="center"
          justifyContent="center"
          onClick={toggleDetails}
          _hover={{ bg: 'rgba(0,0,0,0.05)' }}
          cursor="pointer"
          display={{ base: 'flex', md: 'none' }}
          padding="7px"
          borderRadius="50%"
        >
          <ArrowLeft />
        </Flex>
      </Flex>
      <Flex
        align="center"
        flexDir="column"
      >
        <Avatar
          bgColor="#eee"
          size="100px"
          image={thread ? thread.users[0].avatarUrl ?? undefined : undefined}
          name={thread ? thread.users[0].name ?? thread.users[0].mainAddress : 'X'}
          textSize={{ short: '14px', long: '14px' }}
        />

        <Flex
          mt="20px"
          color="rgba(71, 77, 102, 1)"
          fontWeight="600"
        >
          {thread ? thread.users[0].name ?? trimAddress(thread.users[0].mainAddress, 5) : 'Loading'}
        </Flex>
        <Flex
          color="rgba(143, 149, 178, 1)"
          fontSize="12px"
        >
          Active {(thread && thread.users[0] && time_ago(thread.users[0].lastActive)) ?? 'more than month ago'}
        </Flex>

        <Flex
          my="15px"
          fontSize="14px"
          fontWeight="400"
          textAlign="center"
          maxW="100%"
        >
          {thread
            ? thread.users[0].description == ''
              ? 'This user has no description'
              : thread.users[0].description
            : ''}
        </Flex>
        <Flex
          flexDir="column"
          w="100%"
          mt="40px"
        >
          <MenuItem
            action={() => navigate(`/profile/${thread ? thread.users[0].mainAddress : null}`)}
            icon={<Image src="/assets/icons/user.svg" />}
          >
            View user profile
          </MenuItem>
          {/* <MenuItem
            action={() => {}}
            icon={<Image src="/assets/icons/paperclip.svg" />}
          >
            {' '}
            Show attachements
          </MenuItem> */}

          {/* <MenuItem
            action={() => {}}
            icon={<Image src="/assets/icons/message-icon.svg" />}
          >
            Mark as unread
          </MenuItem> */}
          {/* <MenuItem
            action={() => {}}
            icon={<Image src="/assets/icons/bell.svg" />}
          >
            Disable notifications
          </MenuItem> */}
          {/* <Box
            w="100%"
            h="1px"
            my="8px"
            bg="rgba(0,0,0,0.05)"
          ></Box> */}

          <MenuItem
            action={() => {
              if (!archivedThreads.includes(selectedThreadId)) openThread(null)
              archiveThread(selectedThreadId)
            }}
            icon={<Image src="/assets/icons/archive.svg" />}
          >
            {archivedThreads.includes(selectedThreadId) ? 'Unarchive' : 'Archive'}
          </MenuItem>
          {/* <MenuItem
            action={() => {}}
            icon={<Image src="/assets/icons/trash.svg" />}
          >
            Clear message history
          </MenuItem> */}
          {/* <MenuItem
            action={() => {}}
            icon={<Image src="/assets/icons/block2.svg" />}
          >
            Block messages
          </MenuItem> */}
        </Flex>
      </Flex>
    </Flex>
  )
}
