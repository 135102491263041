import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { NotificationItem } from './NotificationItem'
import { useState } from 'react'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { useAccountProfileProvider } from '../../../../../provider/AccountProfile/context'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { loaded } from '../../../../../utils/process'
import { fetchApi } from '../../../../../utils/fetcher'

export enum ENotificationType {
  UPVOTE = 'upvote',
  DOWNVOTE = 'downvote',
}

export interface INotification {
  type: ENotificationType

  // User ot group address
  address: address

  // Text for notification eg. 'Your post is upvoted by Adam'
  data: string

  // Link to notification eg. link to upvoted post
  link: string

  date: Date

  isRead: boolean

  sender?: address
}
export const NotificationModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const account = useAccountProfileProvider()
  const wallet = useEthereumProvider()

  /* Get notifications */
  useAsyncEffect(async () => {
    // if (!wallet.isAuthed || wallet.isAuthing || account.isLoading) {
    //   setNotifications([])
    //   _log('esa')
    //   return
    // }

    await loaded(async () => {
      const data = await fetchApi(`notification/user/${wallet.account}`)

      data.reverse()

      setNotifications(data)
    })
  }, [wallet.isAuthed, wallet.isAuthing, account.isLoading, wallet.account])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay sx={{ backdropFilter: 'blur(3px)', background: '#00000010' }} />
      <ModalContent
        marginBottom={{ base: '10px', md: '64px' }}
        bgColor="backgroundMain"
        w="100%"
        p="0px"
        mx="10px"
        borderRadius="20px"
        marginTop="128px"
      >
        <ModalHeader
          p="0px"
          userSelect="none"
        >
          <Flex
            alignItems="center"
            p="16px 24px"
          >
            Notifications
          </Flex>
          <ModalCloseButton
            onClick={onClose}
            mt="8px"
          />
        </ModalHeader>
        <Divider />
        <ModalBody p="24px">
          <Flex
            flexDir="column"
            gap="17px"
          >
            <Flex
              justify="space-between"
              fontSize="14px"
              fontWeight="500"
              color="backgroundAccent"
            >
              <Text>New notifications</Text>
            </Flex>
            {notifications.length ? (
              notifications.map(notification => (
                <NotificationItem
                  key={notification.date.toString()}
                  data={notification}
                  handlers={{ closeModal: onClose }}
                />
              ))
            ) : (
              <Text sx={{ textAlign: 'center', fontSize: '12px', color: 'textSecondary' }}>No new notifications.</Text>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
