import useWindowFocus from 'use-window-focus'
import { useEffect, useState } from 'react'
import { delay } from '../../utils/os'
import useAsyncEffect from '../effects/async'
import { useAccount, useSignMessage } from 'wagmi'
import { _log } from '../../logger'

const useSignMessageSafe = () => {
  const isWindowFocused = useWindowFocus()
  const [hasFocusedSince, setHasFocusedSince] = useState<Promise<any> | null>(null)

  const signMessage = useSignMessage()
  const account = useAccount()

  const sign = async (message: string) => {
    // TODO: Fix
    // await hasFocusedSince

    return await signMessage.signMessageAsync({ message })
  }

  useEffect(() => {
    if (!isWindowFocused) {
      /* Update promise */
      setHasFocusedSince(new Promise(resolve => resolve(null)))
    } else {
      setHasFocusedSince(null) // Reset to null when window focus is regained
    }
  }, [isWindowFocused])

  return { signMessageSafe: sign }
}

export { useSignMessageSafe }
