import React, { useEffect, useState } from 'react'

interface IProps {
  children: React.ReactNode
  delay?: number
}

const Delayed = ({ children, delay = 266 }: IProps) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  return <>{isShown ? children : null}</>
}

export default Delayed
