import { Button, Flex } from '@chakra-ui/react'
import { ITotalCounts } from '../searchBar'
import React, { Dispatch, SetStateAction } from 'react'
import { TResult } from '../searchResults/SearchResults'

export default function ResultsCounts({
  totalCounts,
  resultType,
  setResultType,
  search,
}: {
  totalCounts: ITotalCounts
  resultType: TResult
  setResultType: Dispatch<SetStateAction<TResult>>
  search: string
}) {
  return (
    <Flex
      gap="6px"
      marginTop="6px"
    >
      <Button
        isDisabled={totalCounts.profile == 0 || !search}
        height="32px"
        onClick={() => setResultType(resultType == 'profile' ? 'none' : 'profile')}
        color="textQuaternary"
        bg={resultType == 'profile' ? 'backgroundAccentLight' : 'backgroundMain'}
        border="1px solid"
        borderColor={resultType == 'profile' ? 'bluePlaceholder' : '#D8DAE5'}
      >
        Profiles: {search ? totalCounts.profile : 0}
      </Button>
      <Button
        isDisabled={totalCounts.txs == 0 || !search}
        height="32px"
        onClick={() => setResultType(resultType == 'tx' ? 'none' : 'tx')}
        color="textQuaternary"
        bg={resultType == 'tx' ? 'backgroundAccentLight' : 'backgroundMain'}
        border="1px solid"
        borderColor={resultType == 'profile' ? 'bluePlaceholder' : '#D8DAE5'}
      >
        Transactions: {search ? totalCounts.txs : 0}
      </Button>
      <Button
        isDisabled={totalCounts.group == 0 || !search}
        height="32px"
        onClick={() => setResultType(resultType == 'group' ? 'none' : 'group')}
        color="textQuaternary"
        bg={resultType == 'group' ? 'backgroundAccentLight' : 'backgroundMain'}
        border="1px solid"
        borderColor={resultType == 'profile' ? 'bluePlaceholder' : '#D8DAE5'}
      >
        Groups: {search ? totalCounts.group : 0}
      </Button>
    </Flex>
  )
}
