import { Button, Flex, Image, Text, useBoolean } from '@chakra-ui/react'
import React, { useState } from 'react'
import { QuickPost } from '../../../../../../shared/actions/QuickPost/QuickPost'
import { Post } from './Post/Post'
import { PostType } from '../../../deafult/Default'

export enum Tab {
  ALL,
  TEXT,
  IMAGES,
  VIDEOS,
  AUDIO,
  PAGES,
}

export const tabs: Array<{ id: Tab; iconId: string }> = [
  { id: Tab.ALL, iconId: 'circle-icon' },
  { id: Tab.TEXT, iconId: 'text-icon' },
  { id: Tab.IMAGES, iconId: 'photo-icon' },
  { id: Tab.VIDEOS, iconId: 'movie-icon' },
]

export const Groups = () => {
  const [state, setState] = useState(Tab.ALL)
  const [isNewestFirst, setIsNewestFirst] = useBoolean()

  return (
    <Flex
      flexDir="column"
      gap="16px"
    >
      <QuickPost />
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        gap="14px"
        flexWrap={{ base: 'wrap', xl: 'unset' }}
      >
        <Flex
          align="center"
          gap="8px"
        >
          <Button
            borderRadius="8px"
            border="1px solid"
            borderColor="#D8DAE5"
            gridGap="8px"
            p="8px 16px"
            alignItems="center"
          >
            <Image src="/assets/icons/filter-icon.svg" />
            <Text
              fontSize="12px"
              color="textSecondary"
            >
              Filter
            </Text>
          </Button>
          <Button
            borderRadius="8px"
            border="1px solid"
            borderColor="#D8DAE5"
            gridGap="8px"
            p="8px 16px"
            alignItems="center"
            bg="none"
          >
            <Image src="/assets/icons/arrows-sort.svg" />
            <Text
              fontSize="12px"
              color="textSecondary"
            >
              Newest first
            </Text>
          </Button>
        </Flex>
        <Flex flexWrap="wrap">
          {tabs.map(tab => (
            <Button
              variant="slave"
              key={tab.id}
              borderRadius="8px"
              p="10px"
              alignItems="center"
              justifyContent="center"
              _hover={{
                '*': {
                  filter: 'unset',
                },
              }}
              sx={{
                '*': {
                  filter: tab.id === state ? 'unset' : 'saturate(.15) brightness(1.7)',
                },
              }}
              bg={tab.id === state ? 'backgroundAccentLight' : 'unset'}
              cursor="pointer"
              onClick={() => setState(tab.id)}
            >
              <Image
                src={`/assets/icons/${tab.iconId}.svg`}
                boxSize="16px"
              />
            </Button>
          ))}
        </Flex>
      </Flex>
      <Post
        data={{
          ipfs: 'ipfs://QmVoMSwwJw2v6A1eKqtD8Ua22zhejrdBVUTxpMnsNGC2Sf/post-5a960d05-9c54-403a-804b-6ce1d2df7d34.json',
          type: PostType.Post,
          _created_at: '2023-06-01',
        }}
      />
    </Flex>
  )
}
