import {
  Button,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { SelectSection } from '../../../../shared/actions/QuickPost/modal/SelectSection'

interface IPostsFilter {
  isOpen: boolean
  onClose: () => void
  postsAmount: number
  submit: () => void
}

export default function PostsFilter({ isOpen, onClose, postsAmount, submit }: IPostsFilter) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            fontWeight="600"
            fontSize="16px"
          >
            Filter
          </Text>
        </ModalHeader>
        <ModalCloseButton marginTop="6px">
          <Image
            src="/assets/icons/close-dark.svg"
            boxSize="16px"
          />
        </ModalCloseButton>
        <Divider orientation="horizontal" />

        <ModalBody>
          <Flex
            flexDir="column"
            gap="24px"
            fontSize="14px"
            fontWeight="500"
            color="backgroundAccent"
            p="24px 0px"
          >
            <SelectSection
              text="Applications"
              subheading="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              placeholder="Select"
            />
            <SelectSection
              text="Roles"
              subheading="Vivamus in facilisis  justo eget ornare."
              placeholder="Select roles"
            />
            <SelectSection
              text="Intrests"
              subheading="Aliquam tincidunt ex condimentum molestie."
              placeholder="Select interests"
            />
          </Flex>
        </ModalBody>

        <Divider orientation="horizontal" />
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onClose}
            p="8px 16px"
          >
            <Text>Cancel</Text>
          </Button>
          <Button
            p="8px 16px"
            bg="backgroundAccent"
            onClick={submit}
          >
            <Text color="#FAFBFF">
              Show {Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 1 }).format(postsAmount)}{' '}
              {postsAmount == 1 ? 'Post' : 'Posts'}
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
