import { Dispatch, SetStateAction } from 'react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import { Badge, Flex, Image, Text } from '@chakra-ui/react'

interface IWelcome {
  setStage: Dispatch<SetStateAction<Stage>>
}

function CandaoInfoCard() {
  return (
    <>
      <Flex
        direction="column"
        alignItems={{ base: 'flex-start', md: 'flex-end' }}
      >
        <Text
          color="#3366FF"
          fontWeight="500"
          fontSize="14px"
          whiteSpace="nowrap"
        >
          $0
        </Text>
        <Text
          color="#8F95B2"
          fontWeight="400"
          fontSize="12px"
          whiteSpace="nowrap"
        >
          Per lead
        </Text>
      </Flex>
      <Flex
        direction="column"
        alignItems={{ base: 'flex-start', md: 'flex-end' }}
      >
        <Text
          color="#3366FF"
          fontWeight="500"
          fontSize="14px"
          whiteSpace="nowrap"
        >
          ∞
        </Text>
        <Text
          color="#8F95B2"
          fontWeight="400"
          fontSize="12px"
          whiteSpace="nowrap"
        >
          Connections
        </Text>
      </Flex>
    </>
  )
}

function SocialMediaInfoCard() {
  return (
    <>
      <Text
        color="#3366FF"
        fontWeight="500"
        fontSize="14px"
        whiteSpace="nowrap"
      >
        $20 - $80
      </Text>
      <Text
        color="#8F95B2"
        fontWeight="400"
        fontSize="12px"
      >
        Per lead
      </Text>
    </>
  )
}

function PremiumAccounts() {
  return (
    <>
      <Text
        color="#3366FF"
        fontWeight="500"
        fontSize="14px"
        whiteSpace="nowrap"
      >
        $15 - $30
      </Text>
      <Text
        color="#8F95B2"
        fontWeight="400"
        fontSize="12px"
      >
        Per month
      </Text>
    </>
  )
}

export default function Comparison({ setStage }: IWelcome) {
  return (
    <>
      <Heading
        step={1}
        heading="Quick Comparison"
        subheading="Let's compare the Candao Protocol to other prevalent models in the market."
      />

      <Flex
        px="24px"
        mt="24px"
        gap="8px"
        flexDirection="column"
      >
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          borderWidth="1px"
          borderColor="borderPrimary"
          borderRadius="16px"
          padding="16px"
          width="full"
        >
          <Image src="/assets/icons/compare.svg" />
          <Flex
            direction="column"
            w="full"
            px="16px"
          >
            <Text
              color="textSecondary"
              fontWeight="400"
              fontSize="14px"
              alignSelf="stretch"
            >
              Premium accounts for dating-only and matchmaking apps cost between
            </Text>
            <Flex
              mt="12px"
              direction="column"
              display={{ base: 'flex', md: 'none' }}
            >
              <PremiumAccounts />
            </Flex>
          </Flex>
          <Flex
            direction="column"
            px="16px"
            alignItems="flex-end"
            display={{ base: 'none', md: 'flex' }}
          >
            <PremiumAccounts />
          </Flex>
        </Flex>
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          borderWidth="1px"
          borderColor="borderPrimary"
          borderRadius="16px"
          padding="16px"
          width="full"
        >
          <Image src="/assets/icons/speaker.svg" />
          <Flex
            direction="column"
            w="full"
            px="16px"
          >
            <Text
              color="textSecondary"
              fontWeight="400"
              fontSize="15px"
              alignSelf="stretch"
            >
              Social media apps advertising fees
            </Text>
            <Flex
              mt="12px"
              direction="column"
              display={{ base: 'flex', md: 'none' }}
            >
              <SocialMediaInfoCard />
            </Flex>
          </Flex>
          <Flex
            direction="column"
            px="16px"
            alignItems="flex-end"
            display={{ base: 'none', md: 'flex' }}
          >
            <SocialMediaInfoCard />
          </Flex>
        </Flex>
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          borderWidth="1px"
          borderColor="borderPrimary"
          borderRadius="16px"
          padding="16px"
          width="full"
          position="relative"
        >
          <Badge
            color="#2952CC"
            background="bluePlaceholder"
            fontSize="10px"
            fontWeight="500"
            padding="4px 16px"
            style={{ borderRadius: '0px 15px 0px 8px', position: 'absolute', top: 0, right: 0 }}
          >
            FREE
          </Badge>
          <Image src="/assets/icons/explorer-logo.svg" />
          <Flex
            direction="column"
            w="full"
            px="16px"
          >
            <Text
              color="#3366FF"
              fontWeight="500"
              fontSize="14px"
            >
              Candao Protocol
            </Text>
            <Text
              color="textSecondary"
              fontWeight="400"
              fontSize="13px"
              lineHeight="16px"
            >
              Avg. $0.4 (blockchain gas fees) to complete your profile.
            </Text>
            <Flex
              gap="16px"
              mt="12px"
              display={{ base: 'flex', md: 'none' }}
            >
              <CandaoInfoCard />
            </Flex>
          </Flex>
          <Flex
            direction="row"
            px="16px"
            alignItems="flex-end"
            gap="16px"
            display={{ base: 'none', md: 'flex' }}
          >
            <CandaoInfoCard />
          </Flex>
        </Flex>
      </Flex>

      <Footer
        action={() => setStage(Stage.PHOTOS)}
        goBack={() => setStage(Stage.WELCOME)}
      />
    </>
  )
}
