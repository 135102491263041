import { Flex, Spinner } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import useLoadedEffect from '../hooks/effects/loaded'
import { _log } from '../logger'
import { loaded } from '../utils/process'
import { fetchApi } from '../utils/fetcher'

const LinkForwarder = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  useLoadedEffect(async () => {
    if (!id) {
      return
    }

    await loaded(async () => {
      const res = await fetchApi(`link/${id}`)
      navigate(res.link)
    }, undefined)
  }, [id])

  return (
    <Flex
      sx={{
        width: '100%',
        minH: '33vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </Flex>
  )
}

export default LinkForwarder
