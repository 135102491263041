import { useState } from 'react'
import {
  Box,
  ButtonProps,
  Flex,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { SearchBar } from '../components/pages/Dashboard/SearchBar/searchBar'
import { CardContainer } from '../components/shared/containers/CardContainer'
import ProfilesInfo from '../components/pages/Pages/profilesInfo'
import useAsyncEffect from '../hooks/effects/async'
import moment from 'moment/moment'
import { buildIpfsGateway, getInitials, trimAddress } from '../utils/parser'
import { Link } from 'react-router-dom'
import { IProfileBatch } from '../provider/Profile/profileProvider'
import { fetchApi } from '../utils/fetcher'
import { IRate } from '../components/pages/Dashboard/LatestProfiles/dailyProfiles'
import { loaded } from '../utils/process'
import { copyToClipboard } from '../utils/os'
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from '@ajna/pagination'
import { UserTag } from '../components/shared/tags/UserTag'

export const Profiles = () => {
  const [data, setData] = useState<IProfileBatch>([])
  const [cdoHolders, setCdoHolders] = useState<number>()
  const [isLoading, setIsLoading] = useState(true)

  const [numOfPages, setNumOfPages] = useState(1)
  const outerLimit = 2
  const innerLimit = 2
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 10,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })
  const [profileCount, setProfileCount] = useState<number>()
  const [activeProfilesCount, setActiveProfilesCount] = useState<number>()

  const [state, setState] = useState<{
    [key in 'active' | 'total']: { total: number; dayChange: number }
  }>()

  const toast = useToast()

  const infoProfiles = [
    {
      title: 'Number of Profiles',
      number: profileCount,
      percent: 0,
      subTitle: 'Total profiles',
      subInfo: 'Last 24h',
    },
    { title: 'CDO Holders', subTitle: 'Holders', number: cdoHolders, percent: 0, subInfo: 'Percentage' },
    {
      title: 'Active Accounts',
      number: activeProfilesCount,
      percent: 0,
      subTitle: 'Daily Active Accounts',
      subInfo: '24h Change',
    },
  ] as any

  const baseStyles: ButtonProps = {
    w: '32px',
    h: '32px',
    fontSize: 'sm',
    border: 'none',
  }

  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: 'backgroundAccentLight',
    },
  }

  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: 'backgroundAccentLight',
    },
    bg: 'backgroundAccentLight',
    color: '#3366FF',
  }

  useAsyncEffect(
    () =>
      loaded(async () => {
        const accounts: IRate = await fetchApi('rate/profile', {})
        const dayAccounts: IRate = await fetchApi('rate/profile', { rate: 24 })
        /* FIXME: TODO: api.candao.io deprecation */
        const cdoHolders = await fetchApi(`ico/holders`)
        const profileCount = await fetchApi('profile/counts/total', {})
        const activeAccounts = await fetchApi('rate', {})

        setState({
          active: { dayChange: dayAccounts.change, total: accounts.rate },
          total: { dayChange: dayAccounts.change, total: accounts.rate },
        })
        setActiveProfilesCount(activeAccounts.onboardedProfiles.length)
        setProfileCount(profileCount)
        setCdoHolders(cdoHolders?.length)
      }),
    []
  )

  useAsyncEffect(
    () =>
      loaded(async () => {
        const data = await fetchApi(`profile/page/1`)

        data.profiles.forEach((datum: any) => (datum.address = datum.address_?.[0]))
        setData(data.profiles)
        setNumOfPages(data.totalPages)
      }),
    []
  )

  const handlePageChange = async (page: number) =>
    loaded(async () => {
      setCurrentPage(page)
      const data = await fetchApi(`profile/page/${page}`)

      data.profiles.forEach((datum: any) => (datum.address = datum.address_?.[0]))
      setData(data.profiles)
      setNumOfPages(data.totalPages)
    })

  const [showUserPopup, setShowUserPopup] = useState<`0x${string}`[] | undefined>(undefined)

  const handleOnHover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(address)
    }, 100)
  }

  const handleOnUnhover = (address: `0x${string}`[]) => {
    setTimeout(() => {
      setShowUserPopup(undefined)
    }, 100)
  }

  const handleCopyLink = async (address: address) => {
    await copyToClipboard(`${document.location.host}/profile/${address}`)
    toast({ title: `Copied!` })
  }

  return (
    <Flex
      justify="center"
      w="100%"
      direction="column"
      bg="backgroundSecondary"
    >
      <Flex
        w="100%"
        h="180px"
        borderRadius="12px"
        bgColor="backgroundDark"
        justifyContent="center"
        alignItems="center"
      >
        <SearchBar />
      </Flex>
      <Text
        pt="32px"
        pb="24px"
        fontSize="24px"
        color="textPrimary"
        fontWeight="600"
        mx={{ base: '16px', candao: 'unset' }}
      >
        Profiles
      </Text>
      <Flex
        w="100%"
        justifyContent="space-between"
        pb="32px"
        gridGap="24px"
        direction={{ base: 'column', lg: 'row' }}
      >
        {infoProfiles.map((item: any) => (
          <ProfilesInfo
            key={item.title}
            title={item.title}
            numbers={item.number}
            percent={item.percent}
            subTitle={item.subTitle}
            subInfo={item.subInfo}
          />
        ))}
      </Flex>
      <CardContainer props={{ overflow: 'scroll' }}>
        {/* <TableContainer w="100%"  maxW="100vw"> */}
        <Table variant="simple">
          <Thead
            bgColor="backgroundMain"
            borderRadius="20px"
          >
            <Tr
              bgColor="backgroundMain"
              borderRadius="8px"
            >
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Profile
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Username
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Followers
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Roles
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Join&nbsp;date
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              >
                Address
              </Th>
              <Th
                border="0px"
                fontSize="10px"
                color="textSecondary"
              />
            </Tr>
          </Thead>
          <Tbody alignItems="center">
            {data.map(datum => (
              <Tr
                key={datum.address}
                fontSize="14px"
                maxW="100%"
              >
                <Td
                  color="#3366FF"
                  border="0px"
                  display="flex"
                  position="relative"
                  minW="220px"
                >
                  <Link
                    to={`/profile/${datum.address}`}
                    style={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'center',
                    }}
                    onMouseEnter={() => handleOnHover(datum.address_)}
                    onMouseLeave={() => handleOnUnhover(datum.address_)}
                  >
                    <Box
                      alignItems="center"
                      justifyContent="center"
                      display="flex"
                      w="40px"
                      h="40px"
                      bgColor="bluePlaceholder"
                      color="#2952CC"
                      borderRadius="50%"
                      backgroundImage={buildIpfsGateway(datum.avatar)}
                      backgroundPosition="center center"
                      backgroundRepeat="no-repeat"
                      backgroundSize="cover"
                      userSelect="none"
                      position="relative"
                    >
                      {(!datum.avatar || datum.avatar.length < 35) && (
                        <Text textTransform="uppercase">{getInitials(datum.name)}</Text>
                      )}
                    </Box>
                    <Text color={datum.name ? 'textPrimary' : 'textTertiary'}>{datum.name || 'No name'}</Text>
                  </Link>
                </Td>
                <Td
                  color="backgroundAccent"
                  border="0px"
                >
                  {/* TODO: Make this use `datum.isOnboarded` */}
                  {datum.nickname ? <Text>@{datum.nickname}</Text> : <Text color="textTertiary">Inactive</Text>}
                </Td>
                <Td
                  color="backgroundAccent"
                  border="0px"
                >
                  {!!datum.followers?.length && <Text>{datum.followers.length}</Text>}
                  {!!datum.followers?.length || <Text opacity=".33">-</Text>}
                </Td>
                <Td
                  display="flex"
                  gridGap="4px"
                  alignItems="center"
                  border="0px"
                  minW="250px"
                  flexWrap="nowrap"
                >
                  {datum.roles?.length ? (
                    <>
                      {datum.roles?.slice(0, 2).map(datuum => (
                        <UserTag
                          text={datuum}
                          key={datuum}
                          compact
                        />
                      ))}
                      {!!datum.roles?.slice(2)?.length && (
                        <UserTag
                          compact
                          text={`+${datum.roles?.slice(2)?.length}`}
                        />
                      )}
                    </>
                  ) : (
                    <Box opacity="0.7">
                      <UserTag
                        text="None"
                        compact
                      />
                    </Box>
                  )}
                </Td>
                <Td
                  color="textSecondary"
                  border="0px"
                  minW="150px"
                >
                  {moment(datum._created_at).fromNow()}
                </Td>
                <Td
                  color="#3366FF"
                  display="flex"
                  border="0px"
                  alignItems="center"
                >
                  <Tooltip
                    hasArrow
                    label="Copy profile link"
                  >
                    <Flex
                      onClick={() => handleCopyLink(datum.address!)}
                      cursor="pointer"
                      w="auto"
                      borderRadius="8px"
                      border="1px solid"
                      borderColor="borderPrimary"
                      p="6px 8px"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text
                        color="#3366FF"
                        mx="4px"
                      >
                        {trimAddress(datum.address)}
                      </Text>
                      <Box
                        bgColor="borderPrimary"
                        borderRadius="4px"
                        w="20px"
                        h="20px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Image src="/assets/icons/link-icon.svg" />
                      </Box>
                    </Flex>
                  </Tooltip>
                </Td>
                <Td
                  color="textSecondary"
                  border="0px"
                  p="0px"
                  m="0px"
                  justifyContent="flex-end"
                  w="32px"
                  h="32px"
                  alignItems="center"
                >
                  <Link to={`/profile/${datum.address}`}>
                    <Box
                      border="1px solid"
                      borderColor="#D8DAE5"
                      borderRadius="8px"
                      w="32px"
                      h="32px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Image
                        src="/assets/icons/eye-icon2.svg"
                        boxSize="16px"
                      />
                    </Box>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex
          w="100%"
          justify="center"
          gap="8px"
        >
          {/* @ts-ignore */}
          <Pagination
            pagesCount={numOfPages}
            currentPage={currentPage}
            // normalStyles={normalStyles}
            // activeStyles={activeStyles}
            // separatorStyles={baseStyles}
            onPageChange={(p: number) => handlePageChange(p)}
          >
            <PaginationContainer
              align="center"
              justify="space-between"
              w="auto"
              p={4}
              gap="4px"
            >
              <PaginationPrevious
                border="none"
                maxH="32px"
                maxW="32px"
                minH="32px"
                minW="32px"
                p="0"
              >
                <Image
                  src="/assets/icons/chevron-right.svg"
                  transform="rotate(180deg)"
                />
              </PaginationPrevious>
              <PaginationPageGroup
                align="center"
                separator={
                  <PaginationSeparator
                    isDisabled
                    bg="backgroundAccentLight"
                    border="none"
                    fontSize="sm"
                    w="32px"
                    h="32px"
                    jumpSize={11}
                  />
                }
              >
                {pages.map((page: number) => (
                  <PaginationPage
                    w="32px"
                    h="32px"
                    border="none"
                    bg="none"
                    key={`pagination_page_${page}`}
                    page={page}
                    fontSize="sm"
                    _hover={{
                      bg: 'backgroundAccentLight',
                    }}
                    _current={{
                      bg: 'backgroundAccentLight',
                      fontSize: 'sm',
                      color: '#3366FF',
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                border="none"
                maxH="32px"
                maxW="32px"
                minH="32px"
                minW="32px"
                p="0"
              >
                <Image src="/assets/icons/chevron-right.svg" />
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Flex>
        {/* </TableContainer> */}
      </CardContainer>
    </Flex>
  )
}

export default Profiles
