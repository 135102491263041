import { Flex } from '@chakra-ui/react'

export const CardContainer = ({ children, width, props }: { children?: any; width?: string; props?: any }) => {
  return (
    <Flex
      flexWrap="wrap"
      padding="16px"
      w={width}
      borderRadius="16px"
      border="1px solid"
      borderColor="borderPrimary"
      bg="backgroundMain"
      flexDir="column"
      maxW="100%"
      {...props}
    >
      {children}
    </Flex>
  )
}
