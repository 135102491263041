import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import SpinnerText from '../../../../../../../shared/tags/SpinnerText'

export const StatDetail = ({ label, data }: { label: string; data?: number }) => {
  return (
    <Flex sx={{ bg: 'borderPrimary', borderRadius: '4px', p: '6px 10px', flexDir: 'column', gap: '4px' }}>
      <Text sx={{ fontSize: '12px', color: '#8F95B2' }}>{label}</Text>
      {data !== undefined ? (
        <Text sx={{ fontSize: '14px', fontWeight: '500', color: '#36F' }}>{data}</Text>
      ) : (
        <SpinnerText />
      )}
    </Flex>
  )
}
