enum EAuthType {
  Local,
}

const getSocialxplorerJwtAuthKey = (address: address) =>
  `jwt-socialxplorer-${address ? address.toLowerCase() : 'invalid'}`

const getCandaoJwtAuthKey = (address: address) => `jwt-candao-access-${address ? address.toLowerCase() : 'invalid'}`
const getCandaoJwtRefreshKey = (address: address) => `jwt-candao-refresh-${address ? address.toLowerCase() : 'invalid'}`

const getAuthHeaders = (address: address, type = EAuthType.Local) => {
  switch (type) {
    case EAuthType.Local:
      return {
        authorization: JSON.stringify({
          address,
          token: process.env.REACT_APP_CANDAO_ADMIN_KEY || localStorage.getItem(getSocialxplorerJwtAuthKey(address)),
        }),
      }
  }
}

const getBlockedHeaders = (addresses: address[]) => ({
  block: JSON.stringify(addresses),
})

const parseNonce = (nonce: string) => `Sign to prove you are you to Candao

${nonce}`

export {
  EAuthType,
  getAuthHeaders,
  getBlockedHeaders,
  parseNonce,
  getSocialxplorerJwtAuthKey,
  getCandaoJwtAuthKey,
  getCandaoJwtRefreshKey,
}
