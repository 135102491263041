import { Avatar, Badge, Box, Flex, IconButton, Image, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { trimAddress } from '../../../../../utils/parser'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { useContractsProvider } from '../../../../../provider/Contracts/contractsProvider'
import { etherUnits, formatUnits } from 'viem'

interface IPayment {
  setStage: Dispatch<SetStateAction<Stage>>

  handleSubmit: () => void
}

export default function Payment({ setStage, handleSubmit }: IPayment) {
  const { roles, interests, totalEsimtatedGas } = useUserOnboardingProvider()
  const [rolesEstimation, setRolesEstimation] = useState(-1)
  const [interestsEstimation, setInterestsEstimation] = useState(-1)

  const wallet = useEthereumProvider()

  const contracts = useContractsProvider()

  useAsyncEffect(async () => {
    const rolesEstimation = (await contracts.role?.estimateGas.setRole([wallet.account, roles])) ?? 0
    const interestsEstimation = (await contracts.interest?.estimateGas.setInterest([wallet.account, interests])) ?? 0

    setRolesEstimation(rolesEstimation.toString())
    setInterestsEstimation(interestsEstimation.toString())
  }, [roles, interests])

  return (
    <>
      <Heading
        step={7}
        heading="Payment method"
        subheading="Short summary of your costs (gas on blockchain)."
      />
      <Flex
        px="24px"
        direction="column"
        gap="24px"
        width="100%"
      >
        <Box w="full">
          <Box
            borderColor="borderPrimary"
            borderWidth="1px"
            style={{ borderTopRightRadius: '16px', borderTopLeftRadius: '16px' }}
            p="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="full"
          >
            <Text color="textSecondary">{roles?.length ?? 0} Roles</Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="16px"
            >
              <Text color="textQuaternary">
                {rolesEstimation !== -1 ? formatUnits(BigInt(rolesEstimation), 8).toString() : <SpinnerText />} BNB
              </Text>
              <Image
                src="/assets/icons/edit.svg"
                cursor="pointer"
                onClick={() => setStage(Stage.ROLES)}
              />
            </Box>
          </Box>
          <Box
            borderColor="borderPrimary"
            borderWidth="1px"
            borderTopWidth="0px"
            p="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="full"
          >
            <Text color="textSecondary">{interests.length ?? 0} Interests</Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="16px"
            >
              <Text color="textQuaternary">
                <Text color="textQuaternary">
                  {interestsEstimation !== -1 ? (
                    formatUnits(BigInt(interestsEstimation), 8).toString()
                  ) : (
                    <SpinnerText />
                  )}{' '}
                  BNB
                </Text>
              </Text>
              <Image
                src="/assets/icons/edit.svg"
                cursor="pointer"
                onClick={() => setStage(Stage.INTERESTS)}
              />
            </Box>
          </Box>
          <Box
            borderColor="borderPrimary"
            borderWidth="1px"
            borderTopWidth="0px"
            p="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="full"
          >
            <Text color="textSecondary">OnChain Profile</Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="16px"
            >
              <Text color="textQuaternary">
                {interestsEstimation !== -1 && rolesEstimation !== -1 ? (
                  (
                    Number(totalEsimtatedGas) -
                    Number(formatUnits(BigInt(interestsEstimation), 8)) -
                    Number(formatUnits(BigInt(rolesEstimation), 8))
                  )
                    .toString()
                    .slice(0, 10)
                ) : (
                  <SpinnerText />
                )}{' '}
                BNB
              </Text>
              <Image
                src="/assets/icons/edit.svg"
                cursor="pointer"
                onClick={() => setStage(Stage.INFO)}
              />
            </Box>
          </Box>
          <Box
            borderColor="borderPrimary"
            borderWidth="1px"
            background="backgroundTertiary"
            style={{ borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}
            p="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderTopWidth="0px"
            w="full"
          >
            <Text color="textSecondary">Total</Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap="16px"
            >
              <Text
                color="#3366FF"
                fontWeight="500"
              >
                {totalEsimtatedGas || <SpinnerText />} BNB
              </Text>
            </Box>
          </Box>
        </Box>
        <Box w="full">
          <Box
            borderColor="borderPrimary"
            borderWidth="1px"
            p="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="full"
            gap="16px"
            style={{ borderRadius: '16px' }}
          >
            <Avatar
              name="Wallet"
              bg="bluePlaceholder"
              color="#2952CC"
              fontWeight="400"
              width="40px"
              height="40px"
            />
            <Box w="full">
              <Text
                fontWeight="500"
                color="textQuaternary"
              >
                Wallet
              </Text>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                fontSize="12"
                color="textSecondary"
                gap="8px"
              >
                <Text fontWeight="500">
                  <SpinnerText /> BNB{' '}
                </Text>
                <Text>·</Text>
                <Text>{trimAddress(wallet.account)}</Text>
              </Box>
            </Box>
            <Box>
              <Menu
                placement="bottom-end"
                offset={[20, 10]}
              >
                <MenuButton
                  as={IconButton}
                  icon={
                    <Image
                      src="/assets/icons/chevron-down-small.svg"
                      width="16px"
                      height="16px"
                    />
                  }
                />
                <MenuList
                  w="full"
                  borderWidth="0px"
                  style={{ filter: 'drop-shadow(0px 4px 8px rgba(16, 24, 64, 0.16))' }}
                >
                  <Wallet
                    name="Wallet Name"
                    balance={<SpinnerText />}
                    address={wallet.account as address}
                    selected
                  />
                </MenuList>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Footer
        action={() => {
          handleSubmit()
        }}
        goBack={() => setStage(Stage.SUMMARY)}
      />
    </>
  )
}

function Wallet({
  name,
  balance,
  address,
  selected,
}: {
  name: string
  balance: string | React.ReactNode
  address: string
  selected?: boolean
}) {
  return (
    <Box
      borderColor="borderPrimary"
      borderWidth="1px"
      style={{ borderLeft: '0px', borderRight: '0px' }}
      borderTopWidth="0px"
      p="16px"
      display="flex"
      alignItems="center"
      justifyContent={selected ? 'space-between' : 'flex-start'}
      w="507px"
      gap="16px"
      cursor="pointer"
      background={selected ? 'borderPrimary' : ''}
    >
      <Avatar
        name="Wallet"
        bg="bluePlaceholder"
        color="#2952CC"
        fontWeight="400"
        width="40px"
        height="40px"
      />
      <Box width={selected ? '100%' : 'auto'}>
        <Text
          fontWeight="500"
          color="textQuaternary"
        >
          {name}
        </Text>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          fontSize="12"
          color="textSecondary"
          gap="8px"
        >
          <Text fontWeight="500">{balance} BNB </Text>
          <Text>·</Text>
          <Text>{trimAddress(address)}</Text>
        </Box>
      </Box>
      {selected && (
        <Badge
          fontWeight="500"
          fontSize="10px"
          color="#2952CC"
          bg="bluePlaceholder"
        >
          Selected
        </Badge>
      )}
    </Box>
  )
}
