import { Flex, Grid, Image, Text } from '@chakra-ui/react'
import React from 'react'

interface ICategoryItem {
  name: string
  onClick: () => void
  imageUrl?: string
}

export const CategoryItem = ({ name, imageUrl, onClick }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      p="10px 12px"
      borderRadius="8px"
      h="60px"
      fontSize="12px"
      fontWeight="500"
      textShadow="0px 2px 4px rgba(0, 0, 0, 0.30)"
      color="backgroundMain"
      textAlign="center"
      bgImage={imageUrl}
      backgroundPosition="center"
      backgroundSize="cover"
      position="relative"
      cursor="pointer"
      onClick={onClick}
    >
      <Flex
        w="100%"
        h="100%"
        borderRadius="8px"
        zIndex="0"
        bgGradient="linear(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%)"
        position="absolute"
      />
      <Text
        zIndex="1"
        maxW="100px"
        overflowWrap="anywhere"
      >
        {name}
      </Text>
    </Flex>
  )
}
