import { Box, Flex, Grid, Text, Image } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { UserTag } from '../../../../shared/tags/UserTag'
import { ResultCategory } from './ResultCategory'
import { IResults, ITotalCounts } from '../searchBar'
import LastProfile from '../../LatestProfiles/lastProfile'
import { Link, useNavigate } from 'react-router-dom'
import { buildIpfsGateway, trimAddress } from '../../../../../utils/parser'
import moment from 'moment'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import ResultsCounts from '../resultsCounts/resultsCounts'
import { IGroup } from '../../../../../hooks/useGroup'
import { ShortText } from '../../../../shared/typography/ShortText'
import { text } from 'stream/consumers'

interface IProps {
  closeHandle: () => void
  ref_: any
  data: IResults
  isLoading: boolean
  totalCounts: ITotalCounts
  search: string
  hasSuggestions?: boolean
}

export enum EVariant {
  Fetching,
  Fetched,
}

export type TResult = 'none' | 'profile' | 'tx' | 'contract' | 'group'

export const SearchResults = ({ hasSuggestions, closeHandle, ref_, data, isLoading, totalCounts, search }: IProps) => {
  const navigate = useNavigate()
  const [resultType, setResultType] = useState<TResult>('none')

  const keyboardHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeHandle()
      }
    },
    [hasSuggestions]
  )

  const handleNavigateTransaction = data => {
    navigate(`/tx/${data.hash}`, { state: { artificial: data.artificial } })
  }

  useEffect(() => {
    document.addEventListener('keydown', keyboardHandler, false)

    return () => {
      document.removeEventListener('keydown', keyboardHandler, false)
    }
  }, [keyboardHandler])

  const variant = {
    [EVariant.Fetching]: () => (
      <Flex
        alignSelf="center"
        mb="24px"
        color="#98A2B3"
        fontSize="56px"
      >
        <SpinnerText />
      </Flex>
    ),
    [EVariant.Fetched]: () => (
      <>
        <ResultsCounts
          totalCounts={totalCounts}
          resultType={resultType}
          setResultType={setResultType}
          search={search}
        />
        {(resultType === 'profile' || resultType === 'none') && !!data.profile.length && (
          <ResultCategory categoryText="Profiles">
            {!!data.profile.length && (
              <Box pt="10px">
                <LastProfile data={data.profile as any} />
              </Box>
            )}
          </ResultCategory>
        )}

        {(resultType === 'tx' || resultType === 'none') && !!data.tx.length && (
          <ResultCategory categoryText="Transactions">
            {data.tx.map((tx, index) => {
              return (
                <React.Fragment key={tx.hash}>
                  <Grid
                    templateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr' }}
                    alignItems="center"
                    p="8px 0"
                    sx={{
                      '&:not(:last-of-type)': {
                        borderBottom: '1px solid borderPrimary',
                      },
                    }}
                  >
                    <Box
                      onClick={() => handleNavigateTransaction(tx)}
                      tabIndex={0}
                      sx={{ cursor: 'pointer !important' }}
                    >
                      <Flex
                        align="center"
                        gap="8px"
                      >
                        <Flex
                          height="40px"
                          width="40px"
                          borderRadius="8px"
                          bgColor="#EDEFF5"
                          fontSize="16px"
                          color="#C1C4D6"
                          align="center"
                          justify="center"
                        >
                          O
                        </Flex>
                        <Flex flexDir="column">
                          <Text
                            fontSize="14px"
                            color="#3366FF"
                          >
                            {trimAddress(tx.hash)}
                          </Text>
                          <Flex
                            align="center"
                            gap="4px"
                            fontSize="12px"
                            color="textSecondary"
                          >
                            {moment(tx._created_at).fromNow()}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Box>
                    <Flex
                      flexDir="column"
                      fontSize="14px"
                      color="textSecondary"
                      align={{ base: 'end', md: 'start' }}
                    >
                      {tx.sender && (
                        <Text>
                          From&nbsp;
                          <Link
                            to={`/profile/${tx.sender}`}
                            color="#3366FF"
                          >
                            {trimAddress(tx.sender)}
                          </Link>
                        </Text>
                      )}
                      {tx.target && (
                        <Text>
                          To&nbsp;
                          <Link
                            to={`/profile/${tx.target}`}
                            color="#3366FF"
                          >
                            {trimAddress(tx.target)}
                          </Link>
                        </Text>
                      )}
                    </Flex>
                    <Flex
                      align="end"
                      flexDir="column"
                      gap="4px"
                      display={{ md: 'flex', base: 'none' }}
                    >
                      <Text
                        fontSize="12px"
                        color="textSecondary"
                      >
                        Time
                      </Text>
                      <UserTag text="Label" />
                    </Flex>
                  </Grid>
                </React.Fragment>
              )
            })}
          </ResultCategory>
        )}

        {(resultType === 'contract' || resultType === 'none') && !!data.contract.length && (
          <ResultCategory categoryText="Contracts">
            {!!data.contract.length &&
              data.contract.map((contract, index) => {
                return (
                  <Flex
                    key={`${contract.address}-${index}`}
                    py="6px"
                  >
                    <Flex>
                      <Link
                        to={`https://bscscan.com/address/${contract?.address}`}
                        target="_blank"
                      >
                        <Flex
                          align="center"
                          gap="8px"
                        >
                          <Flex
                            height="40px"
                            width="40px"
                            borderRadius="8px"
                            bgColor="#EDEFF5"
                            fontSize="16px"
                            color="#C1C4D6"
                            align="center"
                            justify="center"
                          >
                            O
                          </Flex>
                          <Flex flexDir="column">
                            <Text
                              fontSize="14px"
                              color="#3366FF"
                            >
                              {trimAddress(contract.address)}
                            </Text>
                          </Flex>
                        </Flex>
                      </Link>
                      <Flex
                        flexDir="column"
                        fontSize="14px"
                        color="textSecondary"
                        align="center"
                        justifyContent="space-around"
                        px="30px"
                      >
                        <Text>{contract?.name}</Text>
                      </Flex>
                    </Flex>
                  </Flex>
                )
              })}
          </ResultCategory>
        )}

        {(resultType === 'group' || resultType === 'none') && !!data.group.length && (
          <ResultCategory categoryText="Groups">
            {data.group.map((group: IGroup) => (
              <Link
                to={`/group/${group.address}`}
                key={group.address}
              >
                <Flex
                  key={`${group.address}-${group.symbol}`}
                  my="8px"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap="4px"
                >
                  <Image
                    src={buildIpfsGateway(group.metadata.image)}
                    width="40px"
                    height="40px"
                    fallback={
                      <Image
                        src="/assets/icons/bnbIcon.svg"
                        border="1px solid"
                        borderColor="borderPrimary"
                        borderRadius="13px"
                        sx={{ boxSize: '40px', p: '4px' }}
                      />
                    }
                    sx={{ borderRadius: '13px', boxSize: '40px' }}
                  />

                  <Flex
                    ml="12px"
                    flexDir="column"
                  >
                    <ShortText>
                      <Text
                        color={group.metadata.name ? 'textPrimary' : 'textTertiary'}
                        fontSize="12px"
                        fontWeight="500"
                      >
                        {group.metadata.name || 'No name'}
                      </Text>
                    </ShortText>
                    <Text
                      whiteSpace="nowrap"
                      fontSize="10px"
                      color="textSecondary"
                      pt="3px"
                    >
                      {group.symbol}
                    </Text>
                  </Flex>
                  <Flex
                    ml="24px"
                    gap="8px"
                  >
                    <Text
                      color="textBlue"
                      fontSize="14px"
                    >
                      {trimAddress(group.owner)}
                    </Text>
                  </Flex>
                </Flex>
              </Link>
            ))}
          </ResultCategory>
        )}
      </>
    ),
  }

  return (
    <>
      <Flex
        ref={ref_}
        color="textPrimary"
        boxShadow="0px 8px 8px rgba(16, 24, 64, 0.16)"
        borderRadius="0 0 16px 16px"
        flexDir="column"
        position="absolute"
        top="48px"
        w="calc(100% + 2px)"
        bg="backgroundSecondary"
        p="8px"
        zIndex="dropdown"
      >
        {variant[isLoading ? EVariant.Fetching : EVariant.Fetched]()}
      </Flex>
    </>
  )
}
