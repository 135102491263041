import { Button, Flex, Image, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import SpinnerText from '../../../../../shared/tags/SpinnerText'

interface IRoleFeedProps {
  roleCategory: string
  rolesNumber: number
  people: number
}

export const RoleFeed: FC<IRoleFeedProps> = ({ roleCategory, rolesNumber, people }) => {
  return (
    <Flex
      p="8px 13px"
      borderRadius="8px"
      bg="backgroundTertiary"
      justify="space-between"
      w="100%"
      align="center"
    >
      <Flex flexDir="column">
        <Text
          fontSize="14px"
          color="backgroundAccent"
        >
          {roleCategory}
        </Text>
        <Flex
          align="center"
          gap="9px"
        >
          <Flex
            align="center"
            gap="4px"
            as="span"
            fontSize="12px"
          >
            <Text color="textSecondary">Roles:</Text>
            <Text
              color="backgroundAccent"
              fontWeight="500"
            >
              {rolesNumber ?? <SpinnerText />}
            </Text>
          </Flex>
          <Flex
            align="center"
            gap="4px"
            as="span"
            fontSize="12px"
          >
            <Image
              src="/assets/icons/data-users.svg"
              boxSize="16px"
            />
            <Text
              color="backgroundAccent"
              fontWeight="500"
            >
              {people ?? <SpinnerText />}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Button
        variant="slave"
        isDisabled
      >
        <Image src="/assets/icons/plus-circle.svg" />
      </Button>
    </Flex>
  )
}
