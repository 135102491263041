import { Button, Flex, Input, Text } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserAvatar } from '../../../shared/avatars/UserAvatar'
import { Stage } from '../Onboarding'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'

interface ICreateProfileProps {
  setStage: Dispatch<SetStateAction<Stage>>
}

export const CreateProfile: FC<ICreateProfileProps> = ({ setStage }) => {
  const { address_ } = useAccountProfileProvider()
  const navigate = useNavigate()

  return (
    <Flex
      align="center"
      flexDir="column"
      gap="32px"
      w="100%"
      justify="center"
    >
      <Flex
        flexDir="column"
        align="center"
        textAlign="center"
      >
        <Text
          color="backgroundAccent"
          fontSize="20px"
          fontWeight="600"
        >
          Create a new profile
        </Text>
        <Text
          color="textQuaternary"
          fontSize="14px"
        >
          Choose your own OnChain name and avatar so your friends can recognize you.
        </Text>
      </Flex>
      <UserAvatar
        size="64px"
        bgColor="bluePlaceholder"
      >
        <Text
          fontSize="16px"
          color="#2952CC"
        >
          SN
        </Text>
      </UserAvatar>
      <Input
        placeholder="OnChain name"
        borderColor="#D8DAE5"
        _hover={{ borderColor: '#D8DAE5' }}
        _placeholder={{ color: '#8F95B2' }}
        color="textQuaternary"
      />
      <Flex
        w="100%"
        align="center"
        justify="center"
        gap="8px"
      >
        <Button
          variant="ghost-disabled"
          borderColor="#D8DAE5"
          fontSize="12px"
          onClick={() => navigate('/')}
        >
          Skip
        </Button>
        <Button
          variant="dark"
          bgColor="textBlue"
          fontSize="12px"
          onClick={() =>
            setTimeout(() => {
              navigate(`/profile/${address_}`)
            }, 1000)
          }
          color="backgroundMain"
        >
          Next
        </Button>
      </Flex>
    </Flex>
  )
}
