import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import UserRolesComponent from './userRolesComponent'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { UserWallLayout } from '../UserWallLayout'

export const UserRoles = () => {
  const profile = useProfileProvider()

  return (
    <UserWallLayout
      heading="Roles"
      headingDetails={`${profile.isLoading ? <SpinnerText /> : profile.roles?.length || 0} roles`}
    >
      {profile.roles?.map(role => (
        <UserRolesComponent
          role={role}
          key={role}
        />
      ))}
    </UserWallLayout>
  )
}

export default UserRoles
