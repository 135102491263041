import { Flex } from '@chakra-ui/react'
import React, { FC } from 'react'

interface IResultCategoryProps {
  categoryText: string
  children: React.ReactNode
  isFirstElement?: boolean
}

export const ResultCategory: FC<IResultCategoryProps> = ({ categoryText, children, isFirstElement }) => {
  return (
    <Flex
      flexDir="column"
      marginTop={!isFirstElement ? '24px' : '6px'}
    >
      <Flex
        bgColor="#EDEFF5"
        textTransform="uppercase"
        align="center"
        p="4px 8px"
        bg="borderPrimary"
        color="textSecondary"
        fontSize="10px"
        fontWeight="600"
        borderRadius="4px"
      >
        {categoryText}
      </Flex>
      {children}
    </Flex>
  )
}
