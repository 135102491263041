import { Link } from 'react-router-dom'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { ButtonFollow } from '../../../../../shared/actions/ButtonFollow'
import { getInitials } from '../../../../../../utils/parser'
import { UserTag } from '../../../../../shared/tags/UserTag'

export const UserFollowingComponent = ({
  address,
  name,
  nickname,
  roles,
  description,
}: {
  address: address
  name: string
  nickname: string
  roles: string[]
  description: string
}) => {
  return (
    <Flex
      flexDirection="column"
      sx={{
        '&:not(:last-of-type)': {
          borderBottom: '1px solid borderPrimary',
        },
      }}
    >
      <Flex
        p="12px 16px"
        justifyContent="space-between"
        w="100%"
      >
        <Link to={`/profile/${address}`}>
          <Flex gridGap="8px">
            <Box
              alignItems="center"
              justifyContent="center"
              w="40px"
              h="40px"
              display="flex"
              borderRadius="50%"
              bgColor="bluePlaceholder"
              color="#2952CC"
              textTransform="uppercase"
            >
              {getInitials(name)}
            </Box>
            <Flex direction="column">
              <Text
                fontSize="14px"
                color="backgroundAccent"
              >
                {name}
              </Text>
              <Text
                fontSize="12px"
                color="textSecondary"
              >
                @{nickname}
              </Text>
            </Flex>
          </Flex>
        </Link>
        <Flex
          gridGap="8px"
          height="32px"
        >
          {/* <Button
            maxH="32px"
            minH="32px"
            maxW="32px"
            minW="32px"
            p="0"
          >
            <Image
              src="/assets/icons/dots.svg"
              boxSize="16px"
            />
          </Button> */}
          <Button
            display="flex"
            maxH="32px"
            minH="32px"
            maxW="32px"
            minW="32px"
            p="0"
          >
            <Image
              src="/assets/icons/message-icon.svg"
              boxSize="16px"
            />
          </Button>
          <ButtonFollow target={address} />
        </Flex>
      </Flex>
      <Flex
        p="8px 64px"
        flexDirection="column"
      >
        <Flex gridGap="4px">
          {roles.slice(0, 3).map((role: string, index: number) => (
            <UserTag
              compact
              key={index}
              colorBg="#EDEFF5"
              colorFg="textQuaternary"
              text={role}
            />
          ))}
          {roles?.length > 3 && (
            <UserTag
              compact
              colorBg="#EDEFF5"
              colorFg="textQuaternary"
              text={(roles?.length - 3).toString()}
            />
          )}
        </Flex>
        <Text
          pt="10px"
          fontSize="14px"
          color="textQuaternary"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}

export default UserFollowingComponent
