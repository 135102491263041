import React from 'react'
import { IGroup } from '../../../hooks/useGroup'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const NavigationBar = ({
  group,
  activeTab,
  navigate,
}: {
  group: IGroup
  activeTab?: string
  navigate: () => void
}) => {
  return (
    <Breadcrumb
      separator={<ChevronRightIcon color="textBlue" />}
      sx={{ fontSize: '10px' }}
    >
      <BreadcrumbItem>
        <BreadcrumbLink
          sx={{ color: 'textBlue' }}
          as={Link}
          to="/"
        >
          <Flex sx={{ alignItems: 'center', gap: '8px' }}>
            <Image src="/assets/icons/home.svg" />
            Home
          </Flex>
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <BreadcrumbLink
          sx={{ color: 'textBlue' }}
          as={Link}
          to="/group"
        >
          Group
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage={!!activeTab}>
        <BreadcrumbLink
          href="#"
          sx={{ color: activeTab ? 'textBlue' : '#474D66', cursor: 'pointer' }}
          onClick={navigate}
        >
          {group?.metadata?.name}
        </BreadcrumbLink>
      </BreadcrumbItem>

      {activeTab && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href="#"
            sx={{ color: '#474D66' }}
          >
            {activeTab}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  )
}
