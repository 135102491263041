import { Icon } from '@chakra-ui/react'

export const VoteIcon = ({ color, down }: { color: string; down?: boolean }) => {
  return (
    <Icon
      transform={down ? 'rotate(180deg)' : ''}
      boxSize="24px"
      fill="none"
    >
      <g clipPath="url(#clip0_1645_25022)">
        <path
          d="M6 12C6 12.7879 6.15519 13.5681 6.45672 14.2961C6.75825 15.0241 7.20021 15.6855 7.75736 16.2426C8.31451 16.7998 8.97595 17.2417 9.7039 17.5433C10.4319 17.8448 11.2121 18 12 18C12.7879 18 13.5681 17.8448 14.2961 17.5433C15.0241 17.2417 15.6855 16.7998 16.2426 16.2426C16.7998 15.6855 17.2417 15.0241 17.5433 14.2961C17.8448 13.5681 18 12.7879 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9.3335L9.33337 12.0002"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9.3335V14.6668"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.6667 12.0002L12 9.3335"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1645_25022">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
