import { Dispatch, SetStateAction } from 'react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import { Box, Image, Text } from '@chakra-ui/react'
import { fetcher } from '../../../../../utils/fetcher'
import { IInterest, useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'
import { Select, Type } from '../../../../shared/select/Select'
import { fetchInterests } from '../../../../shared/select/utils'
import { RolesInterestsPicker } from '../../../../shared/roles&interestsPicker/rolesInterestsPicker'

interface IInterests {
  setStage: Dispatch<SetStateAction<Stage>>
  estimateGas: () => Promise<string>
}

export default function Interests({ setStage, estimateGas }: IInterests) {
  const { interests, setInterests, totalEsimtatedGas, setTotalEstimatedGas } = useUserOnboardingProvider()

  return (
    <>
      <Heading
        step={5}
        heading="Choose your interests"
        subheading="Interests make us feel connected. Share what you love to do."
      />
      <Box
        px="24px"
        width="100%"
      >
        <RolesInterestsPicker
          items={interests}
          setItems={setInterests}
          fetch={fetchInterests}
          type={Type.INTERESTS}
          showIcon
          showWarning
        />
      </Box>
      <Footer
        estimatedCost={`${totalEsimtatedGas} BNB`}
        isLoading={false}
        goBack={() => setStage(Stage.ROLES)}
        action={() => setStage(Stage.SUMMARY)}
      />
    </>
  )
}
