import { Box, Button, Divider, Flex, Grid, Image, Input, useDisclosure } from '@chakra-ui/react'
import { FC, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAccountProfileProvider } from '../../../../../../../../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../../../../../../../../provider/Ethereum/ethereumProvider'
import { emptyPostContent, usePost } from '../../../../../../../../../shared/actions/QuickPost/usePost'
import { buildIpfsGateway, getInitials } from '../../../../../../../../../../utils/parser'
import { IPostSchema } from '../../../../../../deafult/Default'

interface IProps {
  thread: IPostSchema
  updateComments: () => void
}

export const CommentInput: FC<IProps> = ({ thread, updateComments }) => {
  const { account } = useEthereumProvider()
  const accountProfile = useAccountProfileProvider()
  const post = usePost()
  const dropzoneRef = useRef(null!)
  const input = useRef<HTMLInputElement>(null!)

  const handlePost = async () => {
    const result = await post.post({ ...post.content, thread: thread.ipfs })
    if (!result) {
      return
    }

    post.setContent(emptyPostContent)

    await updateComments()
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop: post.uploadAssets })

  const handlePicker = () => {
    if (dropzoneRef.current) {
      // @ts-ignore
      dropzoneRef.current.click()
    }
  }

  const handleContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    post.setContent(prev => ({ ...prev, text: e.target.value }))
  }

  const removeFile = (idx: number) => {
    const newImages = post.content.images.filter((_, index: number) => index != idx)
    post.setContent(prev => ({ ...prev, images: newImages }))
  }

  if (!account) {
    return null
  }

  return (
    <Flex
      {...getRootProps({ onClick: event => event.stopPropagation() })}
      w="100%"
      p="16px"
      justifyContent="space-between"
      gridGap="8px"
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems={{ base: 'flex-start', md: 'center' }}
      position="relative"
      _before={{
        content: '""',
        width: '100%',
        height: '1px',
        position: 'absolute',
        top: '8px',
        left: '0',
        background: 'borderPrimary',
      }}
    >
      <Flex
        alignItems="center"
        gridGap="16px"
        w="100%"
        mt="9px"
      >
        <Flex
          align="top"
          justify="space-between"
          width="full"
          cursor="auto"
          onClick={() => input.current.focus()}
        >
          <Box mr="10px">
            <Box
              textTransform="uppercase"
              fontSize="12px"
              color="#2952CC"
              display="flex"
              borderRadius="50%"
              bgColor="bluePlaceholder"
              h="32px"
              w="32px"
              alignItems="center"
              justifyContent="center"
              backgroundImage={buildIpfsGateway(accountProfile.avatar)}
              backgroundPosition="center center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            >
              {accountProfile.avatar ? '' : getInitials(accountProfile.name)}
            </Box>
          </Box>
          <Flex
            w="full"
            px="12px"
            py="10px"
            alignItems="ceter"
            direction="column"
            justify="space-between"
            border="1px"
            borderColor="#D8DAE5"
            borderRadius="8px"
            gap="12px"
            cursor="text"
          >
            <Input
              ref={input}
              disabled={post.isPosting}
              value={post.content.text}
              onChange={handleContent}
              onKeyDown={e => {
                if (e.code === 'Enter') handlePost()
              }}
              variant="unstyled"
              placeholder="Write a comment"
              sx={{
                _placeholder: {
                  color: 'textTertiary',
                },
              }}
              fontSize="14px"
              color="textSecondary"
              outline="none !important"
              style={{ outline: 'none', padding: 0, margin: 0 }}
            />
            <Flex sx={{ flexDirection: 'column', gap: '16px', w: '100%' }}>
              <Grid
                templateColumns={post.content.images.length > 1 ? '1fr 1fr' : '1fr'}
                gridGap="8px"
                maxHeight="45vh"
                overflowY="auto"
              >
                {post.content.images.map((image, index: number) => (
                  <Flex
                    key={`${image}_${index}`}
                    bgColor="borderPrimary"
                    h={post.content.images.length > 1 ? '250px' : '350px'}
                    borderRadius="8px"
                    position="relative"
                    bgImage={buildIpfsGateway(image)}
                    backgroundSize="cover"
                    align="center"
                    justify="center"
                  >
                    <Image
                      src="/assets/icons/delete-attachment.svg"
                      position="absolute"
                      top="8px"
                      left="8px"
                      _hover={{ cursor: 'pointer' }}
                      onClick={removeFile !== undefined ? () => removeFile(index) : () => {}}
                    />
                  </Flex>
                ))}
              </Grid>
              <Flex
                justify="space-between"
                align="center"
              >
                <Button
                  variant="slave"
                  onClick={handlePicker}
                  borderRadius="3px"
                >
                  <input
                    /* @ts-ignore */
                    {...getInputProps({ ref: dropzoneRef })}
                    onClick={event => event.stopPropagation()}
                  />
                  <Image src="/assets/icons/camera-gray.svg" />
                </Button>
                <Button
                  variant="slave"
                  onClick={handlePost}
                  borderRadius="3px"
                >
                  <Image src="/assets/icons/send-blue.svg" />
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
