import { Box } from '@chakra-ui/react'
import { UserTag } from '../tags/UserTag'
import { useIconProvider } from '../../../provider/Icons/iconsProvider'

export const RolesWithIndicator = ({
  roles,
  isProfile,
  compact = true,
}: {
  roles: string[]
  isProfile?: boolean
  compact?: boolean
}) => {
  const icons = useIconProvider()
  const indicatorIndex = compact ? 2 : 5
  return (
    <>
      {!roles?.length && (
        <Box opacity="0.7">
          <UserTag
            text="None"
            compact
          />
        </Box>
      )}
      {roles?.map((role, index) => {
        if (index < indicatorIndex) {
          if (!isProfile) {
            return (
              <UserTag
                //iconHeight="12px"
                //iconWidth="12px"
                compact
                key={index}
                imageUri={icons.roles.get(role)}
                text={role}
              />
            )
          } else {
            return (
              <UserTag
                compact
                text={role}
                key={role}
              />
            )
          }
        }
      })}
      {roles?.length > indicatorIndex && <UserTag text={`+${roles?.length - indicatorIndex}`} />}
    </>
  )
}
