import { IPostSchema, PostType } from '../../pages/Home/MiddlePanel/deafult/Default'
import { Post } from '../../pages/Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import { ICO } from '../candao/Ico'

export const icoPostData = {
  _created_at: '2023-07-01T12:12:38.236Z',
  ipfs: 'root',
  text: `Get your CDO tokens!

CDO is the essential utility token for the Candao Protocol, serving as the critical “fuel” within the expansive marketplace of everything, including its use in SocialXplorer, one of the integral applications developed for the protocol.

Every transaction, regardless of its nature, incurs a CDO fee, ensuring seamless operations and continuous improvements within this interconnected ecosystem.`,
  author: '0x847afbc786fad8dc817aa74d1086e5e04ce39965',
  images: [],
  type: 'Post',
  power: {
    voters: [
      '0x847afbc786fad8dc817aa74d1086e5e04ce39965',
      '0x83f668DD7137aE3ABE8f5d5f5bd6534Be9Bf657d',
      '0xe45800e2201ebef5685720cdf54afadeeaaabdd3',
    ],
    value: '318610321.1411249669832957408296765582915841048659517119',
  },
  reports: [],
  sharing: '',
  target: {
    roles: [],
    interests: [],
  },
  thread: '',
} as IPostSchema

export const IcoPost = () => {
  return (
    <Post
      key="root"
      data={icoPostData}
      customContent={<ICO />}
    />
  )
}
