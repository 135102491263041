import { Flex } from '@chakra-ui/react'
import { CandaoTokenInfo } from '../components/pages/Dashboard/CandaoTokenInfo/candaoTokenInfo'
import { LastBlocks } from '../components/pages/Dashboard/LastBlocks/lastBlocks'
import { LatestTransactions } from '../components/pages/Dashboard/LatestTransactions/latestTransactions'
import { MainInfo } from '../components/pages/Dashboard/MainInfo/mainInfo'
import { SearchBar } from '../components/pages/Dashboard/SearchBar/searchBar'
import { CardHeader } from '../components/shared/headers/cardHeader'
import LatestProfiles from '../components/pages/Dashboard/LatestProfiles/latestProfiles'

export const Dashboard = () => {
  return (
    <Flex
      flexWrap="wrap"
      flexDir="column"
      gap="48px"
      bg="backgroundSecondary"
      w="100%"
    >
      <Flex
        w="100%"
        h="180px"
        borderRadius="12px"
        bgColor="backgroundDark"
        justifyContent="center"
        alignItems="center"
      >
        <SearchBar />
      </Flex>
      <Flex
        gap="24px"
        w="100%"
        direction={{ base: 'column', lg: 'row' }}
      >
        <Flex
          w="100%"
          justifyContent="space-between"
        >
          <MainInfo />
        </Flex>
        <CandaoTokenInfo />
      </Flex>
      <Flex
        flexWrap="wrap"
        flexDir="column"
      >
        <CardHeader
          title="Latest profiles"
          addon="View all profiles"
          addonLink="/profiles"
        />
        <LatestProfiles />
      </Flex>
      <Flex
        justifyContent="100%"
        gridGap="24px"
        flexDir={{ base: 'column', lg: 'row', xl: 'row' }}
      >
        <Flex
          w={{ base: '100%', lg: '50%', xl: '50%' }}
          flexDir="column"
        >
          <CardHeader
            title="Latest Transactions"
            addon="View all transactions"
            addonLink="/txs"
          />
          <LatestTransactions />
        </Flex>
        <Flex
          w={{ base: '100%', lg: '50%', xl: '50%' }}
          flexDir="column"
        >
          <CardHeader
            title="Latest Blocks"
            addon="View all blocks"
            addonLink="/txs"
          />
          <LastBlocks />
        </Flex>
      </Flex>
    </Flex>
  )
}
