import { Box, Flex } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { CHAT_FILTER_TYPE } from '../../../../provider/Chat/reducer'

const FilterItem = ({
  active,
  label,
  onClick,
  amount,
}: {
  active: boolean
  label: string
  onClick: () => any
  amount: number
}) => {
  return (
    <Flex
      cursor="pointer"
      whiteSpace="nowrap"
      justifyContent="space-between"
      _hover={{ bg: 'rgba(235, 240, 255, 1)' }}
      minW="250px"
      borderRadius="8px"
      onClick={onClick}
      padding="6px 16px"
      color={active ? 'rgba(51, 102, 255, 1)' : 'textSecondary'}
      bg={active ? 'rgba(235, 240, 255, 1)' : 'none'}
    >
      <Box>{label}</Box>
      <Box>{amount}</Box>
    </Flex>
  )
}

export const RightFilter = () => {
  const { filter, setFilter, threads, archivedThreads } = useChatProvider()

  const archivedThreadsLength = useMemo(() => {
    return threads.filter(thread => archivedThreads.includes(thread.id)).length
  }, [archivedThreads, threads])

  return (
    <Flex
      display={{ base: 'none', md: 'flex' }}
      flexDir="column"
      minW="300px"
    >
      <Flex
        flexDir="column"
        gap="6px"
        padding="16px"
        borderRadius="16px"
        border="1px solid"
        bgColor="backgroundMain"
        borderColor="rgba(230, 232, 240, 1)"
      >
        <FilterItem
          label="All conversations"
          amount={threads.length - archivedThreadsLength}
          active={filter == CHAT_FILTER_TYPE.All}
          onClick={() => setFilter(CHAT_FILTER_TYPE.All)}
        />
        {/* <FilterItem
          label="Sent"
          amount={0}
          active={filter == CHAT_FILTER_TYPE.Sent}
          onClick={() => setFilter(CHAT_FILTER_TYPE.Sent)}
        /> */}

        <Box borderTop="1px solid borderPrimary" />

        <FilterItem
          label="Archived"
          amount={archivedThreadsLength}
          active={filter == CHAT_FILTER_TYPE.Archived}
          onClick={() => setFilter(CHAT_FILTER_TYPE.Archived)}
        />
      </Flex>
    </Flex>
  )
}
