import { Box, Flex, Image } from '@chakra-ui/react'

export const NoThreads = () => {
  return (
    <Flex
      my="60px"
      gap="6px"
      align="center"
      flexDir="column"
    >
      <Image
        w="30px"
        src="/assets/icons/no-threads.svg"
      />
      <Box
        color="rgba(71, 77, 102, 1)"
        fontSize="20px"
      >
        You have no messages
      </Box>
      <Box
        fontSize="14px"
        color="textSecondary"
        textAlign="center"
        maxW="400px"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis leo in metus pellentesque pharetra.
      </Box>
    </Flex>
  )
}
