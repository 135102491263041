import { Badge, Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { Stage } from '../UserOnboarding'

interface IWelcome {
  setStage: Dispatch<SetStateAction<Stage>>
}

export default function Welcome({ setStage }: IWelcome) {
  return (
    <>
      <Flex
        padding={{base: "24px", md: "50px"}}
        // mt={{base: "32px", md: "0"}}
        direction="column"
      >
        <Box
          textAlign="center"
          width="full"
        >
          <Text
            fontSize="18px"
            fontWeight="600"
            color="textQuaternary"
          >
            Your data belongs to you
          </Text>
          <Text
            fontWeight="400"
            fontSize="14px"
            color="textSecondary"
            align="center"
            mt="8px"
            lineHeight="20px"
          >
            In Candao Protocol you own your data and relationships with others, for the first time. All the information
            about your interests and your followers now belongs to you; it's in your wallet.
          </Text>
        </Box>
        <Flex
          mt="24px"
          gap="8px"
          direction="column"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            borderWidth="1px"
            borderColor="borderPrimary"
            borderRadius="16px"
            padding="16px"
            width="full"
          >
            <Image src="/assets/icons/interview.svg" />
            <Flex
              direction="column"
              w="full"
              px="16px"
            >
              <Text
                color="textQuaternary"
                fontWeight="600"
                fontSize="16px"
              >
                Meet new people
              </Text>
              <Text
                color="textSecondary"
                fontWeight="400"
                fontSize="14px"
              >
                Enjoy meeting new people online
              </Text>
            </Flex>
            <Badge
              color="#2952CC"
              background="bluePlaceholder"
              height="min"
              fontWeight="500"
            >
              FREE
            </Badge>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            borderWidth="1px"
            borderColor="borderPrimary"
            borderRadius="16px"
            padding="16px"
            width="full"
          >
            <Image src="/assets/icons/handshake.svg" />
            <Flex
              direction="column"
              w="full"
              px="16px"
            >
              <Text
                color="textQuaternary"
                fontWeight="600"
                fontSize="16px"
              >
                Grow your business
              </Text>
              <Text
                color="textSecondary"
                fontWeight="400"
                fontSize="14px"
              >
                Find new business partners and clients
              </Text>
            </Flex>
            <Badge
              color="#2952CC"
              background="bluePlaceholder"
              height="min"
              fontWeight="500"
            >
              FREE
            </Badge>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            borderWidth="1px"
            borderColor="borderPrimary"
            borderRadius="16px"
            padding="16px"
            width="full"
          >
            <Image src="/assets/icons/archery-target.svg" />
            <Flex
              direction="column"
              w="full"
              px="16px"
            >
              <Text
                color="textQuaternary"
                fontWeight="600"
                fontSize="16px"
              >
                Target your audience
              </Text>
              <Text
                color="textSecondary"
                fontWeight="400"
                fontSize="14px"
              >
                Reach audience of your choice.
              </Text>
            </Flex>
            <Badge
              color="#2952CC"
              background="bluePlaceholder"
              height="min"
              fontWeight="500"
            >
              FREE
            </Badge>
          </Flex>
        </Flex>
        <Text
          py="24px"
          fontWeight="400"
          fontSize="14px"
          color="textSecondary"
          textAlign="center"
        >
          Cover the blockchain gas fees for completing your profile, and enjoy endless benefits.
        </Text>
        <Button
          background="#3366FF"
          color="#FAFBFF"
          mx={{base: "40px", md: "100px"}}
          onClick={() => setStage(prev => prev + 1)}
          variant="dark"
        >
          Continue
        </Button>
      </Flex>
    </>
  )
}
