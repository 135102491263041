import * as ReactDOM from 'react-dom/client'
import { App } from './App'
import axios from 'axios'
import { ipfsGateway } from './utils/config'

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

export const adminAddress = '0x847afBc786fAd8DC817aa74D1086E5E04cE39965'

// TODO: invert this logic to be more permissive, fall-through like
axios.interceptors.request.use(function (config) {
  /** Which requests to clear the auth for */
  if (config.url?.match(`https://(${ipfsGateway}|api.binance.com).*`)) {
    config.headers.Authorization = undefined
  }

  /** Which requests to use custom auth for [api.candao.io has been deprecated; it was the only use here] */
  if (config.url?.match('https://(example.com).*')) {
    return config
  }

  return config
})

root.render(<App />)
