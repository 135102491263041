import { useState } from 'react'
import { fetcher } from '../../utils/fetcher'
import { loaded } from '../../utils/process'
import useAsyncEffect from '../effects/async'

const CANDAO_PRICE = 0.2

export const useCandaoPrice = () => {
  const [percentage, setPercentage] = useState<string>()
  const [oldPrice, setOldPrice] = useState<number>()
  const [price, setPrice] = useState<number>(CANDAO_PRICE)

  useAsyncEffect(async () => {
    // TODO: Makadu backend deprecation fix
    setOldPrice(CANDAO_PRICE)
    // const data = await loaded(async () => await fetcher('https://api.candao.io/api/token/cdo/price'))
    // // setPrice(data?.new_cdo_price)
    // setOldPrice(data?.old_cdo_price)
    setPercentage('0')
  }, [])

  return { percentage, oldPrice, price }
}
