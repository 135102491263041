import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

export interface IInterest {
  icon: string
  value: string
  label: string
  color: string
  interestedAmount: string
  remove: (name: string) => void
}

export interface IUserOnboarding {
  name: string
  username: string
  description: string
  roles: string[]
  interests: string[]
  images: string[]
  totalEsimtatedGas: string

  setName: Dispatch<SetStateAction<string>>
  setUsername: Dispatch<SetStateAction<string>>
  setDescription: Dispatch<SetStateAction<string>>
  setRoles: Dispatch<SetStateAction<string[]>>
  setInterests: Dispatch<SetStateAction<string[]>>
  setImages: Dispatch<SetStateAction<string[]>>
  setTotalEstimatedGas: Dispatch<SetStateAction<string>>
}

export const emptyUserOnboarding: IUserOnboarding = {
  name: '',
  setName: () => null,
  username: '',
  setUsername: () => null,
  description: '',
  setDescription: () => null,
  roles: [],
  setRoles: () => null,
  interests: [],
  setInterests: () => null,
  images: [],
  setImages: () => null,
  totalEsimtatedGas: '',
  setTotalEstimatedGas: () => null,
}

export const UserOnboardingProvider = ({ children }: any) => {
  const [name, setName] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [roles, setRoles] = useState<string[]>([])
  const [interests, setInterests] = useState<string[]>([])
  const [images, setImages] = useState<string[]>([])
  const [totalEsimtatedGas, setTotalEstimatedGas] = useState<string>('')

  return (
    <UserOnboardingContext.Provider
      value={{
        name,
        setName,
        username,
        setUsername,
        description,
        setDescription,
        roles,
        setRoles,
        interests,
        setInterests,
        images,
        setImages,
        totalEsimtatedGas,
        setTotalEstimatedGas,
      }}
    >
      {children}
    </UserOnboardingContext.Provider>
  )
}

const UserOnboardingContext = createContext(emptyUserOnboarding)

export const useUserOnboardingProvider = () => {
  const context = useContext(UserOnboardingContext)

  return context
}
