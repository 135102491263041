import { Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

export const PostVisibilityWarning = ({ onClose }: { onClose: () => void }) => {
  return (
    <Flex
      fontSize="14px"
      p="12px 16px"
      bg="#FFFAF1"
      border="1px solid"
      borderColor="#FFB020"
      borderRadius="8px"
      align="center"
      justify="space-between"
      w="100%"
    >
      <Flex
        align="center"
        gap="16px"
      >
        <Image
          src="/assets/icons/alert.svg"
          onClick={onClose}
        />
        <Flex as="span">
          <Text
            color="textQuaternary"
            fontWeight="500"
            as="span"
          >
            Visibility for one of your posts has been limited.&nbsp;
          </Text>
          <Text
            color="#996A13"
            textDecoration="underline"
            cursor="pointer"
          >
            Check now
          </Text>
        </Flex>
      </Flex>
      <Image
        src="/assets/icons/close-black.svg"
        cursor="pointer"
        onClick={onClose}
      />
    </Flex>
  )
}
