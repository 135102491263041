import { Box, Flex, Image } from '@chakra-ui/react'
import React from 'react'
import SpinnerText from '../../../../shared/tags/SpinnerText'

export const TokenInfoItem = ({
  icon,
  label,
  value,
  top,
  bottom,
}: {
  icon: string
  label: string
  value?: string | number
  top?: string | number
  bottom?: string | number | React.ReactNode
}) => {
  return (
    <Flex
      flexWrap="wrap"
      gap="16px"
      w="100%"
      justifyContent="space-between"
    >
      <Flex gridGap="16px">
        <Flex
          flexWrap="wrap"
          boxSize="32px"
          border="1px solid"
          borderColor="borderPrimary"
          bg="grayPrimary"
          borderRadius="8px"
          align="center"
          justify="center"
        >
          <Image src={icon} />
        </Flex>
        <Flex
          flexDirection="column"
          gridGap="2px"
        >
          <Box
            fontSize="10px"
            color="textSecondary"
          >
            {label}
          </Box>
          <Box
            fontSize="12px"
            color="textPrimary"
          >
            {value ?? <SpinnerText />}
          </Box>
        </Flex>
      </Flex>
      {top && (
        <Flex
          flexWrap="wrap"
          align="flex-end"
          justifyContent="center"
          gap="2px"
          flexDir="column"
        >
          <Box
            fontSize="10px"
            color="textSecondary"
          >
            {top}
          </Box>
          <Box
            fontSize="10px"
            color="textPrimary"
          >
            {bottom ?? <SpinnerText />}
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
