import React, { FC, useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Image, Select, Text, Tooltip } from '@chakra-ui/react'
import { TimeAndTarget } from '../filterReducer'

enum SwitchTabs {
  FOR_YOU,
  FOLLOWING,
  TRENDING,
  HOURS,
  DAYS,
}

interface ITabSwitcherProps {
  filterByTimeAndTarget: (h: TimeAndTarget) => void
}

export const TabSwitcher: FC<ITabSwitcherProps> = ({ filterByTimeAndTarget }) => {
  const [active, setActive] = useState(SwitchTabs.FOR_YOU)

  const tabSwitcherInfo = [
    {
      id: SwitchTabs.FOR_YOU,
      label: 'For you',
      icon: 'mood-smile',
      action: () => {
        filterByTimeAndTarget(TimeAndTarget.FOR_YOU)
      },
    },
    {
      id: SwitchTabs.FOLLOWING,
      label: 'Following',
      icon: 'user-check-icon',
      action: () => {
        filterByTimeAndTarget(TimeAndTarget.FOLLOWING)
      },
    },
    {
      id: SwitchTabs.TRENDING,
      label: 'Recent',
      icon: 'clock-icon',
      action: () => {
        filterByTimeAndTarget(TimeAndTarget.ALL)
      },
    },
    // {
    //   id: SwitchTabs.HOURS,
    //   label: '24h',
    //   icon: 'clock-icon',
    //   action: () => {
    //     filterByTimeAndTarget(TimeAndTarget['24H'])
    //   },
    // },
    // {
    //   id: SwitchTabs.DAYS,
    //   label: '7d',
    //   icon: 'calendar-icon',
    //   action: () => {
    //     filterByTimeAndTarget(TimeAndTarget['7D'])
    //   },
    // },
  ]

  const handleSelect = (e: any) => {
    const value = Number(e.target.value) as SwitchTabs
    setActive(value)
  }

  const selectRef = useRef<any>(null!)

  useEffect(() => {
    tabSwitcherInfo.filter(v => v.id == active)[0].action()
    selectRef.current?.click()
  }, [active])

  return (
    <Flex
      alignItems="center"
      userSelect="none"
      justifyContent={{ base: 'space-between', sm: 'flex-start' }}
      gap="2px"
      flexWrap={{ base: 'wrap', sm: 'unset' }}
      flex="1"
    >
      <Tooltip label="Change filter" hasArrow>
        <Box
          p="8px"
          py="0"
          background="backgroundMain"
          border="1px solid"
          borderColor="borderPrimary"
          rounded="8px"
          cursor="pointer"
          position="relative"
        >
          <select
            style={{
              opacity: 0,
              position: 'absolute',
              background: 'redPrimary',
              padding: '8px 12px',
              cursor: 'pointer',
              width: `100%`,
              height: '100%',
              marginLeft: '-8px',
            }}
            onChange={handleSelect}
          >
            {tabSwitcherInfo.map(tab => (
              <option
                key={tab.id}
                value={tab.id}
              >
                {tab.label}
              </option>
            ))}
          </select>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="50px"
            h="36px"
          >
            <Box
              pl="8px"
              py="4px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Image
                src={`/assets/icons/${tabSwitcherInfo.filter(v => v.id == active)[0].icon}.svg`}
                w="16px"
                h="16px"
              />
              <Text
                color="textSecondary"
                fontSize="14px"
              >
                {tabSwitcherInfo.filter(v => v.id == active)[0].label}
              </Text>
            </Box>
            <Box
              background="borderPrimary"
              borderRadius="4px"
              display="grid"
              placeItems="center"
              width="20px"
              height="20px"
            >
              <Image
                src="/assets/icons/chevron-down-small.svg"
                w="12px"
                h="12px"
                alt="Dropdown handle"
              />
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Flex>
  )
}

export default TabSwitcher
