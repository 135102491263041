import { useLocation, useNavigate } from 'react-router-dom'
import useAsyncEffect from '../../../hooks/effects/async'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { IParent } from '../../../types/Component'
import { fetcher } from '../../../utils/fetcher'
import { useToast } from '@chakra-ui/react'

export const RouteWrapper = ({ children }: IParent) => {
  const wallet = useEthereumProvider()
  const navigate = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  /* FIXME: TODO: api.candao.io deprecation; make a new onboarding scheme, matching this interface. */
  // /** Check whether to launch onboarding */
  // useAsyncEffect(async () => {
  //   if (!wallet.account) {
  //     return
  //   }

  //   const isRegistered = await fetcher(`${environment.CANDAO_API}/api/auth/is-wallet-invited/`, {
  //     address: wallet.account,
  //   })

  //   if (!isRegistered?.access_code?.code || isRegistered?.access_code?.code === 'null') {
  //     navigate('/onboarding')

  //     if (toast.isActive('error_not_registered')) {
  //       return
  //     }

  //     toast({ title: 'You have to onboard your address first to be able to navigate to other pages.', id: 'error_not_registered' })
  //   } else {
  //     if (pathname !== '/onboarding') {
  //       return
  //     }

  //     navigate('/')

  //     if (toast.isActive('error_onboarded')) {
  //       return
  //     }

  //     toast({ title: "You have already been onboarded; now you can explore our platform.", id: 'error_onboarded' })
  //   }
  // }, [wallet.account])

  return <>{children}</>
}
