import { Flex, Spinner, Text, useBoolean } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { emptyGroup, IGroup } from '../../../../../hooks/useGroup'
import { EState } from '../../../../../pages/Group'
import { fetchApi } from '../../../../../utils/fetcher'
import { loaded } from '../../../../../utils/process'
import { QuickPost } from '../../../../shared/actions/QuickPost/QuickPost'
import { IcoPost } from '../../../../shared/icoPost/IcoPost'
import { IPostSchema } from '../../../Home/MiddlePanel/deafult/Default'
import { Post } from '../../../Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import { UserWallLayout } from '../../../User/UserContent/UserWall/UserWallLayout'

export const General = () => {
  const [data, setData] = useState<IGroup>(emptyGroup)
  const [state, setState] = useState(EState.LOADING)
  const isLoading = state === EState.LOADING

  const insertPost = (post: IPostSchema) => setPosts(prev => [post, ...prev])

  const [isNewestFirst, setIsNewestFirst] = useBoolean(true)
  const [posts, setPosts] = useState<IPostSchema[]>([])

  const navigate = useNavigate()
  const { id } = useParams()

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        if (!id) {
          return
        }
        const res = await fetchApi('post/getGroups', { groups: [id] })
        setPosts(res)
      }),
    [id]
  )

  /* Sync group data */
  useAsyncEffect(async () => {
    if (!id) {
      setData(emptyGroup)
      return
    }

    setState(EState.LOADING)

    await loaded(
      async () => setData(await fetchApi(`group/${id}`)),
      undefined,
      () => setState(EState.ERROR)
    )
    setState(EState.LOADED)
  }, [id])

  /* Sync group-emitted posts. */
  useAsyncEffect(async () => {
    if (!id) {
      setData(emptyGroup)
      return
    }

    setState(EState.LOADING)

    await loaded(
      async () => setData(await fetchApi(`group/${id}`)),
      undefined,
      () => setState(EState.ERROR)
    )
    setState(EState.LOADED)
  }, [id])

  if (!id) {
    navigate('/groups')
  }
  return (
    <Flex
      flexDirection="column"
      gridGap="16px"
      flexGrow={1}
    >
      <UserWallLayout
        heading="Posts"
        headingDetails=""
      >
        <Flex
          flexDir="column"
          gap="16px"
          padding={{ base: '0', md: '16px' }}
          px={{ base: '0', md: '16px' }}
        >
          <QuickPost
            resultHandle={insertPost}
            group={data.address}
          />
          {isLoading && (
            <Flex
              flexGrow="1"
              justifyContent="center"
            >
              <Spinner color="#8F95B2" />
            </Flex>
          )}
          <IcoPost />
          {posts?.length ? (
            posts.map(post => (
              <Post
                key={post.ipfs}
                data={post}
              />
            ))
          ) : (
            <Text
              pt="12px"
              fontSize="14px"
              color="textQuaternary"
              textAlign="center"
            >
              This group does not have any posts yet.
            </Text>
          )}
        </Flex>
      </UserWallLayout>
    </Flex>
  )
}
