import { Button, Flex, Image, Popover, PopoverTrigger, Text, useDisclosure, useToast, Tooltip } from '@chakra-ui/react'

import { AddressZero } from '../../../../utils/ethereum'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useEffect, useMemo } from 'react'
import { trimAddress } from '../../../../utils/parser'
import { useProfileProvider } from '../../../../provider/Profile/profileProvider'
import { ProfilePopup } from './ProfilePopup/ProfilePopup'
import { Avatar } from '../../../shared/avatars/Avatar'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { useNavigate } from 'react-router-dom'
import useWindowFocus from 'use-window-focus'
import { useAccount } from 'wagmi'
import { getNetwork } from '@wagmi/core'
import { ENetwork } from '../../../../enum/network.enum'
import { NotificationModal } from './notifications/NotificationModal'

export const WalletDetails = () => {
  const menu = useDisclosure()
  const notification = useDisclosure()

  const navigate = useNavigate()
  const wallet = useEthereumProvider()
  const toast = useToast()
  const profile = useAccountProfileProvider()
  const isWindowFocused = useWindowFocus()

  const data = useProfileProvider()

  const { chain } = getNetwork()
  const account = useAccount()

  // /** Render wallet pending action warning */
  // useEffect(() => {
  //   if (!wallet.isConnecting || !isWindowFocused || toast.isActive('error_pending')) {
  //     return
  //   }

  //   const timer = setTimeout(() => {
  //     if (toast.isActive('error_pending')) {
  //       return
  //     }

  //     toast({ title: 'Open your wallet to take action', id: 'error_pending', duration: null })
  //   }, 333)

  //   return () => {
  //     clearTimeout(timer)
  //     toast.close('error_pending')
  //   }
  // }, [wallet.isConnecting, isWindowFocused])

  /** Hide network alert if correctly rendered */
  useEffect(() => {
    /* TODO: Move to wallet provider */
    const isWrongNetwork = ENetwork.FALLBACK !== chain?.id

    if (account.isConnected && (!chain || chain.unsupported || isWrongNetwork)) {
      return
    }

    toast.close('error_network')
  }, [chain?.id, account.isConnected])

  const addressMemoized = useMemo(() => profile.address, [profile.isLoading])

  return (
    <Flex position="relative">
      {!wallet.account ? null : (
        <Flex
          gap="8px"
          align="center"
        >
          <Button
            p="0"
            minW="32px"
            minH="32px"
            maxW="32px"
            maxH="32px"
            onClick={notification.onOpen}
            display={{ base: 'none', lg: 'flex' }}
            sx={{
              borderColor: 'borderPrimary',
            }}
          >
            <Image
              src="/assets/icons/bell.svg"
              boxSize="16px"
            />
          </Button>
          <NotificationModal
            isOpen={notification.isOpen}
            onClose={notification.onClose}
          />
          <Popover
            isOpen={menu.isOpen}
            onClose={menu.onClose}
          >
            <PopoverTrigger>
              <Button
                gap="8px"
                minH="32px"
                maxH="32px"
                onClick={menu.onOpen}
                p="0"
                pr="7px"
                sx={{
                  borderColor: 'borderPrimary',
                }}
              >
                <Avatar
                  name={profile.name}
                  bgColor="bluePlaceholder"
                  image={profile.avatar}
                  isLoading={profile.isLoading}
                  size="30px"
                  borderRadius="8px"
                  textSize={{ short: '12px', long: '12px' }}
                />
                <Text color="textSecondary">{trimAddress(addressMemoized ?? AddressZero)}</Text>
                {!profile.isOnboarded && (
                  <Tooltip
                    label="See profile to complete onboarding"
                    hasArrow
                  >
                    <Image
                      src="/assets/icons/info-big.svg"
                      sx={{ boxSize: '16px' }}
                    />
                  </Tooltip>
                )}
                <Flex
                  align="center"
                  justify="center"
                  bgColor="borderPrimary"
                  height="16px"
                  width="16px"
                  borderRadius="4px"
                >
                  <Image
                    src="/assets/icons/chevron-down-small.svg"
                    boxSize="12px"
                  />
                </Flex>
              </Button>
            </PopoverTrigger>
            <ProfilePopup controller={menu} />
          </Popover>
        </Flex>
      )}
    </Flex>
  )
}
