import { Dispatch, SetStateAction, createContext, useContext } from 'react'

export enum EGroupTab {
  Feed = 'Feed',
  Offers = 'Offers',
  Members = 'Members',
  Channels = 'Channels',
  Voting = 'Voting',
  About = 'About',
}

export enum EFeed {
  General = 'General',
}

type TabContext = {
  currentTab: EGroupTab
  setCurrentTab: Dispatch<SetStateAction<EGroupTab>>
  feed: EFeed
  setFeed: Dispatch<SetStateAction<EFeed | string>>
}

const defaultContext: TabContext = {
  currentTab: EGroupTab.Feed,
  setCurrentTab: () => null,
  feed: EFeed.General,
  setFeed: () => null,
}

export const TabsProvider = ({
  children,
  currentTab,
  setCurrentTab,
  feed,
  setFeed,
}: {
  children: React.ReactNode
  currentTab
  setCurrentTab
  feed
  setFeed
}) => {
  return <TabContext.Provider value={{ currentTab, setCurrentTab, feed, setFeed }}>{children}</TabContext.Provider>
}

const TabContext = createContext<TabContext>(defaultContext)

export const useTabProvider = () => {
  return useContext(TabContext)
}
