import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
} from '@ajna/pagination'
import { Button, Flex, Image, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { IGroup } from '../../../hooks/useGroup'
import { buildIpfsGateway, formatUsd } from '../../../utils/parser'
import SpinnerText from '../../shared/tags/SpinnerText'

export const GroupsMarketCap = ({
  data,
  numOfPages,
  currentPage,
  handlePageChange,
  pages,
  isLoading,
}: {
  data: IGroup[] | undefined
  numOfPages: number
  currentPage: number
  handlePageChange: (p: number) => void
  pages: number[]
  isLoading: boolean
}) => {
  const navigate = useNavigate()

  return (
    <Flex
      sx={{
        border: '1px solid',
        borderColor: 'borderPrimary',
        w: '100%',
        overflow: 'auto',
        p: '8px',
        borderRadius: '16px',
        flexDirection: 'column',
        gap: '32px',
      }}
    >
      <Table variant="unstyled">
        <Thead>
          <Tr>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>#</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>GROUP NAME</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>MEMBERS</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>SUPPLY</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>PRICE</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>MARKET CAP</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>VOLUME (24H)</Text>
            </Th>
            <Th>
              <Text sx={{ color: '#696F8C', fontSize: '10px' }}>TVL</Text>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody justifyContent="center">
          {!isLoading ? (
            data?.map((datum, index) => (
              <Tr
                key={datum.address}
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/group/${datum.address}`)}
              >
                <Td>
                  <Text sx={{ color: '#696F8C', fontSize: '14px' }}>{index + 1}</Text>
                </Td>
                <Td>
                  <Flex sx={{ gap: '8px', alignItems: 'center' }}>
                    <Image
                      src={buildIpfsGateway(datum.metadata.image)}
                      fallback={
                        <Image
                          src="/assets/icons/bnbIcon.svg"
                          borderRadius="50%"
                          sx={{ boxSize: '30px', p: '8px' }}
                          bg="backgroundMain"
                        />
                      }
                      borderRadius="50%"
                      width="30px"
                      height="30px"
                      objectFit="cover"
                      bg="backgroundMain"
                    />
                    <Flex sx={{ flexDirection: 'column' }}>
                      <Text sx={{ fontSize: '14px', fontWeight: '500' }}>{datum.metadata.name ?? 'No name'}</Text>
                      <Text sx={{ fontSize: '12px', color: '#696F8C' }}>{datum.symbol ?? 'NN'}</Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  <Text sx={{ color: '#696F8C', fontSize: '14px' }}>{datum.members ? datum?.members.length : '0'}</Text>
                </Td>
                <Td>
                  <Text color="textQuaternary">
                    {datum?.details?.supply.toLocaleString('en-US') ?? <SpinnerText />}
                  </Text>
                </Td>
                <Td>
                  <Text color="textQuaternary">
                    ${datum?.details?.price ? formatUsd(datum?.details?.price) : <SpinnerText />}
                  </Text>
                </Td>
                <Td>
                  <Text color="textQuaternary">
                    $
                    {datum?.details?.marketCap || datum?.details?.marketCap === 0 ? (
                      formatUsd(datum?.details?.marketCap)
                    ) : (
                      <SpinnerText />
                    )}
                  </Text>
                </Td>
                <Td>
                  $
                  {datum?.details?.volume || datum?.details?.volume === 0 ? (
                    formatUsd(datum?.details?.volume)
                  ) : (
                    <SpinnerText />
                  )}
                </Td>
                <Td>
                  <Text color="textQuaternary">
                    $
                    {datum?.details?.tvl || datum?.details?.tvl === 0 ? (
                      formatUsd(datum?.details?.tvl)
                    ) : (
                      <SpinnerText />
                    )}
                  </Text>
                </Td>
                <Td>
                  <Button
                    sx={{ p: '0', border: 'none' }}
                    variant="ghost"
                  >
                    <Image src="/assets/icons/dots.svg" />
                  </Button>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td sx={{ boxSize: '72px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner />
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Flex
        w="100%"
        justify="center"
        gap="8px"
      >
        {/* @ts-ignore */}
        <Pagination
          pagesCount={numOfPages}
          currentPage={currentPage}
          onPageChange={(p: number) => handlePageChange(p)}
        >
          <PaginationContainer
            align="center"
            justify="space-between"
            w="auto"
            p={4}
            gap="4px"
          >
            <PaginationPrevious
              border="none"
              maxH="32px"
              maxW="32px"
              minH="32px"
              minW="32px"
              p="0"
            >
              <Image
                src="/assets/icons/chevron-right.svg"
                transform="rotate(180deg)"
              />
            </PaginationPrevious>
            <PaginationPageGroup
              align="center"
              separator={
                <PaginationSeparator
                  isDisabled
                  bg="backgroundAccentLight"
                  border="none"
                  fontSize="sm"
                  w="32px"
                  h="32px"
                  jumpSize={11}
                />
              }
            >
              {pages.map((page: number) => (
                <PaginationPage
                  w="32px"
                  h="32px"
                  border="none"
                  bg="none"
                  key={`pagination_page_${page}`}
                  page={page}
                  fontSize="sm"
                  _hover={{
                    bg: 'backgroundAccentLight',
                  }}
                  _current={{
                    bg: 'backgroundAccentLight',
                    fontSize: 'sm',
                    color: '#3366FF',
                  }}
                />
              ))}
            </PaginationPageGroup>
            <PaginationNext
              border="none"
              maxH="32px"
              maxW="32px"
              minH="32px"
              minW="32px"
              p="0"
            >
              <Image src="/assets/icons/chevron-right.svg" />
            </PaginationNext>
          </PaginationContainer>
        </Pagination>
      </Flex>
    </Flex>
  )
}
