import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpoint,
  useDisclosure,
  useToast,
  Text,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { CreateProfile } from './stages/CreateProfile'
import { InvitationCode } from './stages/InvitationCode'
import { InvitationSuccess } from './stages/InvitationSuccess'
import { PhoneVerification } from './stages/PhoneVerification'
import { SmsCodeVerification } from './stages/SmsCodeVerification'
import { fetcher } from '../../../utils/fetcher'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { loaded } from '../../../utils/process'
import { useNavigate } from 'react-router-dom'
import { hashMessage, recoverPublicKey } from 'viem'
import { useSignMessageSafe } from '../../../hooks/utils/useSignMessageSafe'
import { useContractsProvider } from '../../../provider/Contracts/contractsProvider'
import { trimAddress } from '../../../utils/parser'
import { RegexUtils } from '../../../utils/regex'

export enum Stage {
  INVITATION_CODE,
  INVITATION_SUCCESS,
  PHONE_VERIFICATION,
  SMS_CODE_VERIFICATION,
  CREATE_PROFILE,
}

export interface IInvitator {
  name: string
  avatar: string
}

export const Onboarding = () => {
  const [stage, setStage] = useState<Stage>(Stage.INVITATION_CODE)
  const [invitator, setInvitator] = useState<IInvitator>({ name: '', avatar: '' })
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [accessCode, setAccessCode] = useState<string>('')
  const [isConnecting, setIsConnecting] = useState<{
    status: boolean
    address: address
  }>()

  const accountProfile = useAccountProfileProvider()
  const wallet = useEthereumProvider()
  const toast = useToast()
  const navigate = useNavigate()
  const safeSigner = useSignMessageSafe()
  const breakpoint = useBreakpoint()
  const accountConnectionModalControl = useDisclosure()
  const contracts = useContractsProvider()

  const candaoApi = process.env.REACT_APP_CANDAO_API;


  const checkAccessCode = async (code: string) => {
    let res = ''
    try {
      /* FIXME: TODO: api.candao.io deprecation */
      res = await fetcher(`${candaoApi}/api/access-code/${code}/public-info`)
    } catch (e: any) {
      toast({ title: e.response.data.message, status: 'error', isClosable: false })
      return 'error'
    }

    return res
  }

  const verifyPhoneNumber = async (code: string): Promise<any> => {
    if (!wallet.account) {
      return 'Please, check your wallet app'
    }

    const { raw_message_to_sign } = await loaded(
      async () =>
      /* FIXME: TODO: api.candao.io deprecation */
        await fetcher(`${candaoApi}/api/wallet/message-to-sign-by-address/${accountProfile.address_}`)
    )

    const signature = await loaded(async () => await safeSigner.signMessageSafe(raw_message_to_sign))
    const messageHash = hashMessage(raw_message_to_sign)
    const pubKey = await recoverPublicKey({ hash: messageHash, signature: signature as signature })

    const reqData = {
      public_key: pubKey.slice(4),
      signature: signature?.slice(2),
      phone_number: phoneNumber,
      phone_verification_code: code,
      access_code: accessCode,
    }

    let res = null
    try {
      /* FIXME: TODO: api.candao.io deprecation */
      res = await fetcher(`${candaoApi}/api/auth/verify-phone-number`, reqData)
    } catch (e: any) {
      if (e.response.status === 422) {
        const body = e.response.data.fields.body
        const keys = Object.keys(body)
        const objMap = new Map<any, any>(Object.entries(body))
        const res: string[] = []
        const errors = keys.map(key => objMap.get(key)._errors ?? [])
        errors.forEach((errArr: any[]) => errArr.forEach(err => res.push(err)))
        return {
          status: 422,
          errors: [...res],
        }
      } else if (e.response.status == 406) {
        return e.response.data.message
      } else if (e.response.status === 500) {
        return 'Assertion error'
      }

      return 'Error'
    }
    return res
  }

  const stageContent = useMemo(() => {
    if (stage === Stage.INVITATION_CODE)
      return (
        <InvitationCode
          setInvitator={setInvitator}
          setStage={setStage}
          accessCode={accessCode}
          setAccessCode={setAccessCode}
          checkAccessCode={checkAccessCode}
        />
      )
    else if (stage === Stage.INVITATION_SUCCESS)
      return (
        <InvitationSuccess
          invitator={invitator}
          setStage={setStage}
        />
      )
    else if (stage === Stage.PHONE_VERIFICATION)
      return (
        <PhoneVerification
          setStage={setStage}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )
    else if (stage === Stage.SMS_CODE_VERIFICATION)
      return (
        <SmsCodeVerification
          setStage={setStage}
          phoneNumber={phoneNumber}
          verifyPhoneNumber={verifyPhoneNumber}
        />
      )
    else if (stage === Stage.CREATE_PROFILE) return <CreateProfile setStage={setStage} />
  }, [stage, accessCode, invitator, phoneNumber])

  const handleConnect = async (address: address) => {
    const result = await loaded(
      async () => await contracts.onChainRef?.write.addressIDAddConfirm([address]),
      status => setIsConnecting({ status, address })
    )

    if (!result) {
      return
    }

    toast({
      status: 'success',
      title: 'Connection succeeded',
    })
  }

  /** Check if wallet disconnects */
  useEffect(() => {
    if (wallet.isConnecting) {
      return
    }

    if (!wallet.account) {
      !toast.isActive('connection_request') &&
        toast({ title: 'Please, connect your wallet first', id: 'connection_request', duration: null })
    } else {
      toast.close('connection_request')
    }

    return () => toast.close('connection_request')
  }, [wallet.account, wallet.isConnecting])

  /** Check for AddressID connection requests */
  useEffect(() => {
    if (accountProfile.isLoading) {
      return
    }

    if (accountProfile.pendingConnection?.length) {
      accountConnectionModalControl.onOpen()
      toast.close('error_not_registered')

      return
    }
  }, [accountProfile.isLoading])

  return (
    <Flex
      align="center"
      justify="center"
      overflow="hidden"
      width="100%"
      height={{ base: 'auto', md: 'calc(100vh - 74px)' }}
      position={{ base: 'unset', md: 'absolute' }}
      bottom="0"
      left="0"
      bg="backgroundSecondary"
      zIndex="1"
    >
      <Flex
        borderRadius="16px"
        border="1px solid"
        flexDirection={{ base: 'column-reverse', md: 'row' }}
        borderColor="borderPrimary"
      >
        <Flex
          p="32px"
          maxWidth="360px"
          w="360px"
        >
          {stageContent}
        </Flex>
        <Flex
          bgColor="borderPrimary"
          borderRadius={{ base: '12px 12px 0 0', md: '0 12px 12px 0' }}
        >
          <Image
            src={
              breakpoint === 'base' || breakpoint === 'xs' || breakpoint === 'sm'
                ? '/assets/icons/onboarding-right-mobile.svg'
                : '/assets/icons/onboarding-right.svg'
            }
            borderRadius={{ base: '16px 16px 0 0', md: '0 16px 16px 0' }}
          />
        </Flex>
      </Flex>

      <Modal
        onClose={accountConnectionModalControl.onClose}
        isOpen={accountConnectionModalControl.isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          sx={{ pointerEvents: 'all' }}
          containerProps={{ pointerEvents: 'none' }}
        >
          <ModalHeader>Connection request</ModalHeader>
          <ModalBody>
            <Text>
              Hit{' '}
              <Text
                as="span"
                fontStyle="italic"
              >
                Accept
              </Text>{' '}
              to set your address as sub-address of:
            </Text>
            {accountProfile?.pendingConnection?.reverse()?.map(({ address }) => {
              return (
                <Flex
                  key={address}
                  align="center"
                  justify="space-between"
                  p="8px 16px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="borderPrimary"
                  mt="16px"
                >
                  <Flex align="center">
                    <Image
                      src="/assets/icons/wallet-icon.svg"
                      boxSize="24px"
                      mr="8px"
                    />
                    <Text>{trimAddress(address, 10)}</Text>
                  </Flex>
                  <Button
                    variant="dark"
                    onClick={() => handleConnect(address)}
                    isLoading={RegexUtils.insensitive(address).test(isConnecting?.address!) && isConnecting?.status}
                  >
                    Accept
                  </Button>
                </Flex>
              )
            })}
            <Flex sx={{ mt: '12px', alignItems: 'start', justifyContent: 'center', gap: '6px' }}>
              <Image
                src="/assets/icons/info.svg"
                sx={{ boxSize: '24px', display: 'inline-block' }}
              />
              Accept only if you remember being the one who requested this connection.
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="destructive"
              onClick={accountConnectionModalControl.onClose}
            >
              Ignore
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
