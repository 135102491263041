import { Flex, Text, Box, Image, Tooltip, Button } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import useAsyncEffect from '../../../hooks/effects/async'
import { IGroup } from '../../../hooks/useGroup'
import { fetchApi } from '../../../utils/fetcher'
import { loaded } from '../../../utils/process'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { buildIpfsGateway } from '../../../utils/parser'
import sample from 'lodash/sample'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import useColor from '../../../hooks/chakra/useColor'

export const GroupPopover = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const wallet = useEthereumProvider()
  const [groups, setGroups] = useState<IGroup[]>([])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [areGroupsLoading, setAreGroupsLoading] = useState(false)

  const borderPrimaryColor = useColor('borderPrimary')

  const handleSearchLink = () => navigate('/dashboard')

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        if (wallet.account) {
          // TODO: This should be returned with profile data (via AccountProfileProvider)
          const data = await fetchApi(`profile/${wallet.account}/groups`)
          setGroups(data)
        }
      }, setAreGroupsLoading),
    [wallet.account]
  )

  if (!wallet.account || groups.length === 0) {
    return <></>
  }

  return (
    <Flex
      position="fixed"
      bottom="0"
      left="50px"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      zIndex="10"
      display={{ base: 'none', sm: 'flex' }}
    >
      <Tooltip label={isExpanded ? '' : 'See more Groups'}>
        <Box
          width="24px"
          height="24px"
          background="rgba(0, 0, 0, 0.32)"
          borderRadius="full"
          display="grid"
          cursor="pointer"
          placeItems="center"
          mb="8px"
          transform={isExpanded ? '' : 'rotate(180deg)'}
          onClick={() => setIsExpanded(p => !p)}
        >
          <Image
            src="/assets/icons/chevron-down-white.svg"
            width="16px"
            height="16px"
          />
        </Box>
      </Tooltip>

      <Flex
        boxShadow={`0px 12px 40px 0px ${borderPrimaryColor}`}
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        gap="10px"
        padding="16px"
        background="backgroundMain"
        borderRadius="16px 16px 0px 0px"
        cursor={!isExpanded ? 'pointer' : ''}
        onClick={() => (!isExpanded ? setIsExpanded(true) : null)}
        minW="72px"
      >
        {isExpanded && (
          <Button
            sx={{
              boxSize: '40px',
            }}
            background="backgroundMain"
            isDisabled
            variant="slave"
            borderRadius="8px"
            display="grid"
            placeItems="center"
            border="1px solid"
            borderColor="borderPrimary"
            onClick={handleSearchLink}
          >
            <Image
              src="/assets/icons/search.svg"
              width="16px"
              height="16px"
            />
          </Button>
        )}
        {groups
          .slice(0, 8)
          .map(group => (
            <Link
              key={group.address}
              to={`/group/${group.address}`}
            >
              <GroupIcon
                name={group.metadata.name}
                image={group.metadata.image}
              />
            </Link>
          ))
          .slice(0, isExpanded ? groups.length : 1)}
        {isExpanded && pathname !== '/groups' && (
          <Tooltip label="Create a Group">
            <Button
              sx={{
                boxSize: '40px',
              }}
              variant="slave"
              background="backgroundAccentLight"
              borderRadius="8px"
              display="grid"
              cursor="pointer"
              onClick={() => navigate('/groups', { state: { openCreator: true } })}
              placeItems="center"
            >
              <Image
                src="/assets/icons/circle-plus-outline.svg"
                width="16px"
                height="16px"
              />
            </Button>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}

type GroupIconProps = {
  name: string | undefined
  image: string | undefined
}
export const GroupIcon = ({ name, image }: GroupIconProps) => {
  const randomColor = sample([
    { bg: '#F8E3DA', font: '#85462B' },
    { bg: '#FBDDF3', font: '#8E3374' },
    { bg: '#D3F5F7', font: '#0F5156' },
    { bg: 'bluePlaceholder', font: '#2952CC' },
    { bg: '#E7E4F9', font: '#524988' },
    { bg: '#DCF2EA', font: '#317159' },
  ])
  const imageFallback = useMemo(
    () => (
      <Box
        sx={{
          boxSize: '40px',
        }}
        borderRadius="8px"
        background={randomColor.bg}
        display="grid"
        placeItems="center"
      >
        <Text
          color={randomColor.font}
          display="block"
        >
          {name ? name.slice(0, 2).toUpperCase() : 'GR'}
        </Text>
      </Box>
    ),
    [image]
  )

  return image ? (
    <Image
      src={buildIpfsGateway(image)}
      sx={{
        boxSize: '40px',
      }}
      borderRadius="8px"
      objectFit="cover"
      fallback={imageFallback}
    />
  ) : (
    imageFallback
  )
}
