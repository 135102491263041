import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { copyToClipboard } from '../../../../../utils/os'
import { PaymentResult, Stage } from '../UserOnboarding'
import { trimAddress } from '../../../../../utils/parser'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import SpinnerText from '../../../../shared/tags/SpinnerText'
import { fetcher } from '../../../../../utils/fetcher'
import { useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'

interface IResult {
  paymentResult: PaymentResult
  address: string
  close: () => void
  setStage: Dispatch<SetStateAction<Stage>>
}

export default function Result({ paymentResult, address, close, setStage }: IResult) {
  const { totalEsimtatedGas } = useUserOnboardingProvider()

  switch (paymentResult) {
    case PaymentResult.SUCCESS:
      return (
        <Success
          close={close}
          address={address}
        />
      )
    case PaymentResult.SENDING:
      return <Sending amount={totalEsimtatedGas} />
    case PaymentResult.FAILURE:
      return <Failure setStage={setStage} />
  }
}

function Failure({ setStage }: any) {
  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        py="48px"
        width="100%"
      >
        <Box
          w="56px"
          h="56px"
          borderRadius="16px"
          background="#F9DADA"
          display="grid"
          placeItems="center"
        >
          <Image src="/assets/icons/close-red.svg" />
        </Box>
        <Text
          mt="32px"
          color="textQuaternary"
          fontSize="16px"
          fontWeight="600"
        >
          Oops
        </Text>
        <Text
          fontSize="15px"
          color="textSecondary"
        >
          Something went wrong, please go back and try again.
        </Text>
        <Button
          marginTop="32px"
          background="#3366FF"
          border="none"
          color="#FAFBFF"
          onClick={() => setStage(Stage.PAYMENT)}
        >
          Go back
        </Button>
      </Flex>
    </>
  )
}

function Success({ address, close }: { address: string; close: () => void }) {
  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        py="48px"
      width="100%"
      >
        <Image
          src="/assets/icons/progress.svg"
          width="53px"
          height="53px"
        />
        <Text
          fontWeight="600"
          marginTop="32px"
          fontSize="16px"
          color="textQuaternary"
        >
          Congratulations
        </Text>
        <Text
          fontSize="15px"
          color="textSecondary"
        >
          Your account has been activated successfully.
        </Text>
        <Box
          onClick={async () => await copyToClipboard(address)}
          cursor="pointer"
          mt="32px"
          borderWidth="1px"
          borderColor="borderPrimary"
          borderRadius="8px"
          background="backgroundTertiary"
          px="12px"
          py="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          gap="8px"
        >
          <Text
            color="textQuaternary"
            fontSize="14px"
          >
            {trimAddress(address)}
          </Text>
          <Image src="/assets/icons/link-icon.svg" />
        </Box>
        <Button
          mt="32px"
          onClick={close}
        >
          Done
        </Button>
      </Flex>
    </>
  )
}

function Sending({ amount }: { amount: string }) {
  const [price, setPrice] = useState<number>()

  useEffect(() => {
    fetcher('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT').then((data: { price: string }) => {
      setPrice(Number(data.price))
    })
  }, [])

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        py="48px"
        overflow="hidden"
      >
        <Flex
          color="#3366FF"
          fontSize="152px"
          lineHeight="16px"
          transform="translateY(calc(-100% - 32px))"
        >
          <SpinnerText />
        </Flex>
        <Box
          mt="32px"
          textAlign="center"
        >
          <Text
            fontWeight="600"
            fontSize="16px"
            color="backgroundAccent"
          >
            Sending
          </Text>
          <Text
            color="#3366FF"
            fontWeight="600"
            fontSize="26px"
          >
            {price ? `$${(price * Number(amount)).toFixed(2)}` : <SpinnerText />}
          </Text>
          <Text
            fontSize="15px"
            color="#454F5B"
          >
            {amount} BNB
          </Text>
        </Box>
      </Flex>
    </>
  )
}
