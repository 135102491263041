import { Box, Flex, Grid, Image, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spinner } from '@chakra-ui/react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { Avatar } from '../../../shared/avatars/Avatar'
import { InputModule } from './InputModule/inputModule'
import { buildIpfsGateway, time_ago, trimAddress } from '../../../../utils/parser'
import { useNavigate } from 'react-router'
import { Message, Thread, emptyThread } from '../../../../provider/Chat/types'
import { CopyAddress } from './CopyAddress/copyAddress'
import { AddressZero } from '../../../../utils/ethereum'
import { MessagesList } from './MessagesList/messagesList'
import { ArrowLeft } from '../../../icons/ArrowLeft'

export const ChatWindow = () => {
  const { openThread, detailsOpened, toggleDetails, archiveThread, archivedThreads, selectedThreadId, getThreadById } =
    useChatProvider()
  const wallet = useEthereumProvider()

  const thread: Thread | null = useMemo(() => {
    return selectedThreadId ? getThreadById(selectedThreadId) : null
  }, [selectedThreadId])

  const navigate = useNavigate()

  const handleBackButton = () => {
    navigate('/chat')
  }

  return (
    <Grid
      templateRows="auto 1fr"
      border="1px solid"
      borderColor="rgba(230, 232, 240, 1)"
      maxH="80vh"
      pos="relative"
      borderRadius="16px"
      bg="backgroundMain"
    >
      <Flex
        align="center"
        justify="space-between"
        px="16px"
        h="56px"
        borderBottom="1px solid rgba(230, 232, 240, 1)"
      >
        <Flex
          align="center"
          gap="8px"
        >
          <Flex
            boxSize="30px"
            align="center"
            justifyContent="center"
            onClick={handleBackButton}
            _hover={{ bg: 'rgba(0,0,0,0.05)' }}
            cursor="pointer"
            padding="7px"
            borderRadius="50%"
          >
            <ArrowLeft />
          </Flex>
          <Grid
            templateColumns="40px auto"
            gap="12px"
            alignItems="center"
          >
            {thread ? (
              <Avatar
                bgColor="#eee"
                size="40px"
                image={thread ? thread.users[0].avatarUrl ?? undefined : undefined}
                name={thread ? thread.users[0].name ?? thread.users[0].mainAddress : 'X'}
                textSize={{ short: '14px', long: '14px' }}
              />
            ) : (
              <Flex
                align="center"
                bgColor="#eee"
                boxSize="40px"
                justify="center"
              >
                <Spinner />
              </Flex>
            )}
            <Flex
              flexDir="column"
              gap="4px"
            >
              <Box
                lineHeight="100%"
                fontSize="12px"
                color="textQuaternary"
                fontWeight="500"
              >
                {thread ? thread.users[0].nickname || trimAddress(thread.users[0].mainAddress) : 'Loading'}
              </Box>
              <Box
                lineHeight="100%"
                fontSize="12px"
                color="#8F95B2"
                fontWeight="400"
              >
                Active {(thread && thread.users[0] && time_ago(thread.users[0].lastActive)) || 'Never'}
              </Box>
            </Flex>
          </Grid>
        </Flex>
        <Flex
          align="center"
          gap="2px"
        >
          <CopyAddress address={thread ? thread.users[0].mainAddress : AddressZero} />
          <Image
            ml="10px"
            cursor="pointer"
            onClick={toggleDetails}
            _hover={{ bg: 'rgba(0,0,0,0.05)' }}
            padding="7px"
            bg={detailsOpened ? 'rgba(235, 240, 255, 1)' : 'none'}
            borderRadius="50%"
            src={detailsOpened ? '/assets/icons/info-circle-active.svg' : '/assets/icons/info-circle.svg'}
          />

          <Menu>
            <MenuButton
              _hover={{ bg: 'rgba(0,0,0,0.05)' }}
              borderRadius="50%"
              transition="0s"
            >
              <Image
                cursor="pointer"
                onClick={() => openThread(null)}
                padding="7px"
                src="/assets/icons/dots-vertical.svg"
              />
            </MenuButton>
            <MenuList
              color="rgba(71, 77, 102, 1)"
              fontWeight="400"
              fontSize="14px"
            >
              {/* <MenuItem icon={<Image src="/assets/icons/search.svg" />}>Search message history</MenuItem> */}
              {/* <MenuItem icon={<Image src="/assets/icons/paperclip.svg" />}> Show attachements</MenuItem>
              <MenuDivider /> */}
              {/* <MenuItem icon={<Image src="/assets/icons/message-icon.svg" />}>Mark as unread</MenuItem> */}
              {/* <MenuItem icon={<Image src="/assets/icons/bell.svg" />}>Disable notifications </MenuItem> */}
              {/* <MenuDivider /> */}
              <MenuItem
                onClick={() => {
                  if (!archivedThreads.includes(selectedThreadId)) openThread(null)
                  archiveThread(selectedThreadId)
                }}
                icon={<Image src="/assets/icons/archive.svg" />}
              >
                {archivedThreads.includes(selectedThreadId) ? 'Unarchive' : 'Archive'}
              </MenuItem>
              {/* <MenuItem icon={<Image src="/assets/icons/trash.svg" />}>Clear message history</MenuItem>
              <MenuItem icon={<Image src="/assets/icons/block2.svg" />}>Block messages</MenuItem> */}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <MessagesList
        thread={thread ?? emptyThread}
        address={wallet ? wallet.account ?? '' : AddressZero}
      />
      <InputModule threadId={selectedThreadId} />
    </Grid>
  )
}
