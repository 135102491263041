import { Button, Flex, Image, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

interface IAccountItem {
  name: string
  link: string
  imageUrl?: string
  isConnected?: boolean
}

export const AccountItem: FC<IAccountItem> = ({ name, link, imageUrl, isConnected }) => {
  return (
    <Flex
      align="center"
      justify="space-between"
    >
      <Flex
        gap="16px"
        align="center"
      >
        <Image
          src={imageUrl ? imageUrl : ''}
          h="48px"
          w="48px"
        />
        <Flex flexDir="column">
          <Text fontSize="14px">{name}</Text>
          <Text
            fontSize="12px"
            color="textSecondary"
          >
            {link}
          </Text>
        </Flex>
      </Flex>
      {isConnected ? (
        <Button
          variant="unstyled"
          bg="#D14343"
          color="backgroundMain"
          p="8px 16px"
        >
          Disconnect
        </Button>
      ) : (
        <Button
          variant="light"
          borderColor="#D8DAE5"
          p="8px 16px"
        >
          Connect
        </Button>
      )}
    </Flex>
  )
}
