import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react'
import React, { ReactNode, useEffect, useState } from 'react'
import { IPostContent } from '../actions/QuickPost/usePost'
import { IPostSchema } from '../../pages/Home/MiddlePanel/deafult/Default'
import SpinnerText from '../tags/SpinnerText'
import { formatUsd } from '../../../utils/parser'
import { useGroupProvider } from '../../../provider/Group/groupProvider'
import { InviteAsModal } from '../../pages/Home/MiddlePanel/roles/tabs/discussions/Post/Modals/InviteAsModal'

enum EPostPower {
  POSITIVE,
  NEGATIVE,
  NEUTRAL,
}

export const Power = ({
  usd,
  isCompact,
  isSymbolHidden,
  isTotal,
}: {
  usd?: string /* IPostSchema['power']['usd'] */
  isCompact?: boolean
  isSymbolHidden?: boolean
  isTotal?: boolean
}) => {
  const [postPowerState, setPostPowerState] = useState<EPostPower>(EPostPower.NEUTRAL)

  const groupProvider = useGroupProvider()
  const customSymbol = groupProvider.symbol
  const shouldHideSymbol = !!customSymbol || isSymbolHidden

  /** Set post power state */
  useEffect(() => {
    if (!usd) {
      setPostPowerState(EPostPower.NEUTRAL)
    } else if (usd.startsWith('-')) {
      setPostPowerState(EPostPower.NEGATIVE)
    } else if (Number(usd)) {
      setPostPowerState(EPostPower.POSITIVE)
    } else {
      setPostPowerState(EPostPower.NEUTRAL)
    }
  }, [usd])

  return (
    <Flex
      fontSize="12px"
      color="textSecondary"
    >
      <Text
        color={
          isTotal
            ? 'textQuaternary'
            : postPowerState === EPostPower.NEGATIVE
            ? '#D14343'
            : postPowerState === EPostPower.POSITIVE
            ? '#52BD94'
            : 'unset'
        }
      >
        {postPowerState === EPostPower.NEGATIVE
          ? `-${customSymbol ? '' : ' '}`
          : postPowerState === EPostPower.POSITIVE
          ? `+${customSymbol ? '' : ' '}`
          : ''}
        {!shouldHideSymbol && '$'}
        {!isCompact && !shouldHideSymbol && <>&nbsp;</>}
      </Text>
      <Text
        color={
          isTotal
            ? 'textQuaternary'
            : postPowerState === EPostPower.NEGATIVE
            ? '#D14343'
            : postPowerState === EPostPower.POSITIVE
            ? '#52BD94'
            : 'unset'
        }
      >
        {formatUsd(usd?.replace('-', '') || '0')}
      </Text>
      {!!shouldHideSymbol && (
        <Text
          color={
            isTotal
              ? 'textQuaternary'
              : postPowerState === EPostPower.NEGATIVE
              ? '#D14343'
              : postPowerState === EPostPower.POSITIVE
              ? '#52BD94'
              : 'unset'
          }
        >
          <>&nbsp;</>
          {customSymbol}
        </Text>
      )}
    </Flex>
  )
}
