import { Colors } from '../../theme/colors'
import { useColorMode } from '@chakra-ui/react'

/**
 * Converts a chakra color string to a hex color string defined in app's consts
 * @param color Chakra color string
 */
const useColor = (color?: keyof typeof Colors | string) => {
  const { colorMode } = useColorMode()

  /* If color is not defined in the app's consts, return the literal string color */
  if (!Colors[color!]) {
    return color
  }

  return Colors[color!]?.[colorMode === 'dark' ? '_dark' : 'default']
}

export default useColor
