import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination,
} from '@ajna/pagination'
import { Box, Image } from '@chakra-ui/react'
import React from 'react'

export const UserWallPagination = ({
  totalPages,
  handlePageChange,
  currentPage,
}: {
  totalPages: number
  handlePageChange: (p: number) => void
  currentPage: number
}) => {
  const { pages, pagesCount, offset, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } = usePagination(
    {
      total: totalPages * 10,
      limits: {
        outer: 2,
        inner: 2,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    }
  )

  return (
    <Box
      w="full"
      display="flex"
      alignItems="center"
      justifyContent="space-around"
    >
      <Pagination
        pagesCount={totalPages}
        currentPage={currentPage}
        onPageChange={(p: number) => handlePageChange(p)}
      >
        <PaginationContainer
          align="center"
          justify="space-between"
          w="auto"
          p={4}
          gap="4px"
        >
          <PaginationPrevious
            border="none"
            maxH="32px"
            maxW="32px"
            minH="32px"
            minW="32px"
            p="0"
          >
            <Image
              src="/assets/icons/chevron-right.svg"
              transform="rotate(180deg)"
            />
          </PaginationPrevious>
          <PaginationPageGroup
            align="center"
            separator={
              <PaginationSeparator
                isDisabled
                bg="backgroundAccentLight"
                border="none"
                fontSize="sm"
                w="32px"
                h="32px"
                jumpSize={11}
              />
            }
          >
            {pages.map((page: number) => (
              <PaginationPage
                w="32px"
                h="32px"
                border="none"
                bg="none"
                key={`pagination_page_${page}`}
                page={page}
                fontSize="sm"
                _hover={{
                  bg: 'backgroundAccentLight',
                }}
                _current={{
                  bg: 'backgroundAccentLight',
                  fontSize: 'sm',
                  color: '#3366FF',
                }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext
            border="none"
            maxH="32px"
            maxW="32px"
            minH="32px"
            minW="32px"
            p="0"
          >
            <Image src="/assets/icons/chevron-right.svg" />
          </PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Box>
  )
}
