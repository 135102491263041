import React, { useState } from 'react'
import { Text, useInterval } from '@chakra-ui/react'

interface Props {
  loader?: string
}

export default function SpinnerText({ loader = '...' }: Props) {
  const [transparentIndex, setTransparentIndex] = useState(0)

  useInterval(() => setTransparentIndex(prev => (prev + 1) % (loader.length + 1)), 200)

  return (
    <Text as="span">
      {loader.split('').map((element, index) => (
        <Text
          whiteSpace="nowrap"
          as="span"
          display="inline-block"
          key={`${element}_${index}`}
          transition="all .2s ease-out"
          opacity={transparentIndex === index ? '0' : 'unset'}
          transform={transparentIndex === index ? 'translateY(-.125em)' : 'unset'}
        >
          {element}
        </Text>
      ))}
    </Text>
  )
}
