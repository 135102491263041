import { ChakraProvider, Link, createStandaloneToast } from '@chakra-ui/react'
import { theme } from './theme/theme'
import '@fontsource/inter'
import { Wrapper } from './components/global/wrapper'
import { AccountProfileProvider } from './provider/AccountProfile/context'
import { ContractsProvider } from './provider/Contracts/contractsProvider'

import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { bscTestnet, bsc } from 'wagmi/chains'

import { publicProvider } from 'wagmi/providers/public'

import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { EthereumProvider } from './provider/Ethereum/ethereumProvider'
import { ChatProvider } from './provider/Chat/chatProvider'
import { IconsProvider } from './provider/Icons/iconsProvider'
import SpinnerText from './components/shared/tags/SpinnerText'
import { AuthWall } from './components/global/auth-wall'

import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { isProduction } from './utils/isProduction'

const queryClient = new QueryClient()

export const App = () => {
  const { ToastContainer } = createStandaloneToast()

  const environment = process.env.REACT_APP_ENV;

  const networks = environment === 'prod' ? bsc : bscTestnet;
  const wagmiChains = configureChains([networks], [publicProvider()])
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains: wagmiChains.chains }),
      new WalletConnectConnector({
        chains: wagmiChains.chains,
        options: {
          projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || 'e50500fc3f09c767dd272302407473f1',
          metadata: {
            name: 'SocialXplorer',
            description: 'SocialXplorer protocol app',
            url: window.location.hostname,
            icons: ['/logo-small.svg'],
          },
          showQrModal: true,
          qrModalOptions: {
            mobileWallets: [
              {
                id: 'candaoWallet',
                name: 'Candao Wallet',
                links: {
                  native: 'candaowallet://',
                  universal: 'https://candao.io',
                },
              },
            ],
            desktopWallets: [
              {
                id: 'candaoWallet',
                name: 'Candao Wallet',
                links: {
                  native: 'candaowallet://',
                  universal: 'https://candao.io',
                },
              },
            ],
            walletImages: {
              candaoWallet: 'https://live.staticflickr.com/65535/53428397921_6744780647.jpg',
            },
            themeVariables: {
              '--wcm-font-family': "'-apple-system', 'Inter'",
              '--wcm-background-color': '#101840',
              '--wcm-accent-color': '#101840',
              /* @ts-ignore */
              '--wcm-color-bg-1': '#FAFBFF',
              /* @ts-ignore */
              '--wcm-color-bg-2': '#FAFBFF',
              /* @ts-ignore */
              '--wcm-color-bg-3': '#FAFBFF',
              /* @ts-ignore */
              '--wcm-color-fg-1': '#101828',
              /* @ts-ignore */
              '--wcm-color-fg-2': '#98A2B3',
              /* @ts-ignore */
              '--wcm-color-fg-3': '#101828',
              /* @ts-ignore */
              '--wcm-z-index': 1400 /* ChakraUI Modal */,
              '--wcm-overlay-backdrop-filter': 'blur(10px)',
              /* @ts-ignore */
              '--wcm-overlay-background-color': '#0000000f',
              '--wcm-background-border-radius': '16px',
            },
          },
        },
      }),
      new InjectedConnector({
        chains: wagmiChains.chains,
        options: {
          name: 'Injected',
          shimDisconnect: true,
        },
      }),
    ],
    publicClient: wagmiChains.publicClient,
    webSocketPublicClient: wagmiChains.webSocketPublicClient,
  })

  return (
    <WagmiConfig config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <EthereumProvider>
          <AccountProfileProvider>
            <ContractsProvider>
              <ChatProvider>
                <IconsProvider>
                  <ChakraProvider theme={theme}>
                    <Wrapper/> 
                    <ToastContainer />
                  </ChakraProvider>
                </IconsProvider>
              </ChatProvider>
            </ContractsProvider>
          </AccountProfileProvider>
        </EthereumProvider>
      </QueryClientProvider>
    </WagmiConfig>
  )
}
