import { Colors } from './colors'
import { extendTheme } from '@chakra-ui/react'
import { ButtonStyle } from './components/buttonStyle'
import { Checkbox } from './components/checkboxStyle'
import { globalStyles } from './global'
import { SelectStyle } from './components/selectStyle'
import { _log } from '../logger'

export const theme = extendTheme({
  colors: {},
  semanticTokens: {
    colors: Colors,
  },
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: '16px',
          bg: 'backgroundMain',
        },
      },
    },
    Popover: {
      baseStyle: {
        content: {
          bg: 'backgroundTertiary',
        },
      },
    },
    Spinner: {
      baseStyle: {
        color: 'backgroundAccent',
      },
    },
    Button: ButtonStyle,
    Link: {
      baseStyle: {
        color: 'textBlue',
      },
    },
    Checkbox,
    Select: SelectStyle,
    Alert: {
      variants: {
        solid: {
          container: {
            bg: 'backgroundAccent',
            boxShadow: `2px 8px 32px 0px backgroundAccent`,
          },
        },
        error: {
          container: {
            bg: 'redPrimary',
            boxShadow: `2px 8px 32px 0px redPrimary`,
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: '8px',
      },
    },
  },
  config: {
    useSystemColorMode: true,
  },
  fonts: {
    body: `Inter`,
  },
  styles: {
    ...globalStyles,
  },
  breakpoints: {
    xs: '24em', // 384px
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '62em', // 992px
    candao: '1180px',
    xl: '80em', // 1280px
    '2xl': '96em', // 1536px
  },
})
