import { Box, Button, Flex, Image, Tab, TabIndicator, TabList, Tabs, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Tab as TabType } from '../../../User/UserEditModal/UserEditModal'
import { About } from './tabs/about/About'
import { Groups } from './tabs/discussions/Groups'
import { Members } from './tabs/members/Members'
import { Roles } from './tabs/roles/Roles'

export const RolesPanel = () => {
  const tabs = [
    {
      name: 'Discussion',
      component: <Groups />,
    },
    {
      name: 'Members',
      component: <Members />,
    },
    {
      name: 'Roles',
      component: <Roles />,
    },
    {
      name: 'About',
      component: <About />,
    },
  ]

  const [activeTab, setActiveTab] = useState<React.ReactNode>(tabs[0].component)

  return (
    <Flex
      flexDir="column"
      gap="24px"
      w="100%"
    >
      <Flex
        borderRadius="16px"
        border="1px solid"
        borderColor="borderPrimary"
        w="100%"
        flexDirection="column"
      >
        <Flex
          w="100%"
          gridGap={{ base: '8px', md: '24px' }}
          justifyContent="space-between"
          p="16px"
          alignItems={{ base: 'flex-start', md: 'center' }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex
            align="center"
            gap="8px"
          >
            <Flex
              bgColor="backgroundAccentLight"
              align="center"
              justify="center"
              boxSize="48px"
              borderRadius="8px"
            >
              <Text
                color="textBlue"
                fontSize="14px"
              >
                GR
              </Text>
            </Flex>
            <Flex
              flexDir="column"
              gap="4px"
            >
              <Text
                fontSize="16px"
                fontWeight="600"
                color="backgroundAccent"
              >
                Group name
              </Text>
              <Text
                fontSize="12px"
                color="textSecondary"
              >
                1.24K members
              </Text>
            </Flex>
          </Flex>
          <Button
            maxH="32px"
            maxW="32px"
            minH="32px"
            minW="32px"
            p="0"
          >
            <Image src="/assets/icons/dots.svg" />
          </Button>
        </Flex>
        <Box
          display="flex"
          w="100%"
          bgColor="borderPrimary"
          h="1px"
        />
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          p="16px"
          pb="0"
          gap="14px"
          flexWrap={{ base: 'wrap', xs: 'unset' }}
        >
          <Tabs
            isFitted
            variant="unstyled"
            w="60%"
            mb="0"
          >
            <TabList>
              {tabs.map((tab: TabType) => (
                <Tab
                  key={tab.name}
                  _selected={{ color: '#3366FF' }}
                  fontSize="14px"
                  p="14px"
                  onClick={() => setActiveTab(tab.component)}
                >
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="#3366FF"
            />
          </Tabs>
        </Flex>
      </Flex>
      {activeTab}
    </Flex>
  )
}
