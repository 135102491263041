import React, { useRef } from 'react'
import { Flex, Image, Input } from '@chakra-ui/react'
import TopPeople from './TopPeople/topPeople'
import { useNavigate } from 'react-router-dom'

export const RightPanel = () => {
  const input = useRef<HTMLInputElement>(null!)
  const navigate = useNavigate()

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value.length >= 2) {
      navigate('/dashboard', { state: { search: value } })
    }
  }

  return (
    <Flex
      flexDirection="column"
      gridGap="16px"
    >
      <Flex
        w={{ base: '100%', lg: '300px' }}
        bgColor="backgroundMain"
        borderRadius={{ base: '0', md: '8px' }}
        border={{ base: 'none', md: '1px solid' }}
        borderColor="borderPrimary"
        borderY="1px solid"
        borderTopColor="borderPrimary !important"
        borderBottomColor="borderPrimary !important"
        borderX="1px solid"
        borderLeftColor="borderPrimary !important"
        borderRightColor="borderPrimary !important"
        alignItems="center"
        gridGap="10px"
        px="12px"
        py="10px"
        onClick={() => input.current.focus()}
        cursor="text"
      >
        <Image src="/assets/icons/zoom.svg" />
        <Input
          ref={input}
          onChange={handleSearch}
          color="textSecondary"
          fontSize="14px"
          placeholder="Search"
          p="0px"
          h="20px"
          _placeholder={{ color: 'textTertiary' }}
          border="none"
          _focus={{ boxShadow: 'none', border: 'none !important', outline: 'none !important' }}
        />
      </Flex>
      <TopPeople />
    </Flex>
  )
}

export default RightPanel
