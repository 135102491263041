import { ComponentStyleConfig } from '@chakra-ui/react'

export const ButtonStyle: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '8px',
    height: 'auto',
    backgroundColor: 'backgroundMain',
    userSelect: 'none',
    _hover: {
      boxShadow: '3.4px 6.7px 6.7px #10182826',
      _disabled: {
        bg: 'auto',
      },
    },
    _active: {
      boxShadow: '1.0px 2.1px 2.1px #10182826',
      transitionDuration: '.05s',
    },
  },
  sizes: {
    sm: {
      h: '32px',
      fontSize: '14px',
      fontWeight: 'normal',
      padding: '0px 16px',
    },
    md: {
      h: '40px',
      padding: '0px 16px',
      fontWeight: 'normal',
      fontSize: '14px',
    },
  },
  variants: {
    'ghost-disabled': {
      bg: 'transparent',
      color: 'gray.400',
      border: '1px solid',
      borderColor: 'gray.500',
    },
    ghost: {
      bg: 'transparent',
      color: 'backgroundAccent',
      border: '1px solid',
      borderColor: 'brand.500',
    },
    inverted: {
      bg: 'backgroundMain',
      border: '1px solid',
      borderColor: 'borderPrimary',
    },
    dark: {
      color: 'backgroundMain',
      background: 'backgroundAccent',
      _hover: {
        borderColor: 'backgroundAccent',
      },
    },
    light: {
      color: 'textSecondary',
      background: 'backgroundMainundMain',
      border: '1px solid #D8DAE5',
      _hover: {
        borderColor: 'backgroundMain',
      },
    },
    destructive: {
      background: 'redPrimary',
      color: 'backgroundMain',
      fontWeight: 'bold',
      _hover: {
        background: 'darkred',
      },
      _focusVisible: {
        outline: '3px solid black !important',
      },
    },
    lightOnDark: {
      color: 'textSecondary',
      background: 'backgroundMain',
      _hover: {
        borderColor: 'backgroundMain',
      },
    },
    slave: {
      backgroundColor: 'auto',
      padding: 0,
      boxSize: 'auto',
      minWidth: 'unset',
    },
    slave_shadow: {
      backgroundColor: 'auto',
      _hover: {
        background: 'borderPrimary',
      },
      shadow: 'none !important',
      boxShadow: 'none !important',
      textShadow: 'none !important',
      dropShadow: 'none !important',
    },
  },
  defaultProps: {
    variant: 'light',
  },
}
