import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import LeftPanel from '../components/pages/Home/LeftPanel/leftPanel'
import { Default } from '../components/pages/Home/MiddlePanel/deafult/Default'
import { RolesPanel } from '../components/pages/Home/MiddlePanel/roles/RolesPanel'
import RightPanel from '../components/pages/Home/RightPanel/rightPanel'
import { useLocation } from 'react-router-dom'
import { ProfileProvider } from '../provider/Profile/profileProvider'
import { useProfile } from '../hooks/useProfile'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'

export enum Views {
  DEFAULT,
  ROLES,
}

const middlePanel: any = {
  [Views.DEFAULT]: <Default />,
  [Views.ROLES]: <RolesPanel />,
}

export const Home = () => {
  const [activeView, setActiveView] = useState<Views>(Views.DEFAULT)

  const { account } = useEthereumProvider()
  const profile = useProfile({ address: account as address })

  const location = useLocation()

  /** Go home upon `/` navigation */
  useEffect(() => setActiveView(Views.DEFAULT), [location])

  return (
    <Flex
      gridGap="24px"
      justify="space-between"
      direction={{ base: 'column', lg: 'row' }}
      w="100%"
    >
      <LeftPanel setView={setActiveView} />
      {middlePanel[activeView]}
      <RightPanel />
    </Flex>
  )
}
