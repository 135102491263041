import { Box, Flex, Grid, Text, Image } from '@chakra-ui/react'
import moment from 'moment'
import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { isAddress } from 'viem'
import LastProfile from '../components/pages/Dashboard/LatestProfiles/lastProfile'
import { emptyCounts, emptyResults, SearchBar } from '../components/pages/Dashboard/SearchBar/searchBar'
import { ResultCategory } from '../components/pages/Dashboard/SearchBar/searchResults/ResultCategory'
import SpinnerText from '../components/shared/tags/SpinnerText'
import { UserTag } from '../components/shared/tags/UserTag'
import useAsyncEffect from '../hooks/effects/async'
import { useFocus } from '../hooks/utils/useFocus'
import { emptyProfile } from '../provider/Profile/profileProvider'
import { fetchApi } from '../utils/fetcher'
import { buildIpfsGateway, getInitials, trimAddress } from '../utils/parser'
import { loaded } from '../utils/process'
import { IGroup } from '../hooks/useGroup'
import { ShortText } from '../components/shared/typography/ShortText'

export const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState(emptyResults)
  const [totalCounts, setTotalCounts] = useState(emptyCounts)
  const [isLoading, setIsLoading] = useState(true)
  const [focused, setFocused] = useFocus()
  const [resultType, setResultType] = useState<'none' | 'profile' | 'tx'>('none')

  useAsyncEffect(async () => {
    void loaded(async () => {
      for (const entry of searchParams.entries()) {
        const [param, value] = entry
        const data = await fetchApi(`search/${param}`)
        /** Display account if not fetched and pasted in */
        if (
          isAddress(param) &&
          !data.profile.flatMap((element: any) => element.address_?.[0]).includes(param.toLowerCase())
        ) {
          data.profile.push({
            ...emptyProfile,
            address_: [param.toLowerCase()],
            address: param.toLowerCase(),
            name: 'No name',
            _created_at: new Date(),
            _updated_at: new Date(),
            artificial: true,
          })
        }

        setData(data)
        setTotalCounts(data.totalCount)
      }
    }, setIsLoading)
  }, [searchParams])

  return (
    <Flex
      justify="center"
      w="100%"
      direction="column"
      bg="backgroundSecondary"
      gap="56px"
    >
      <Flex
        w="100%"
        h="180px"
        borderRadius="12px"
        bgColor="backgroundDark"
        justifyContent="center"
        alignItems="center"
      >
        <SearchBar
          searchRoute
          setParentData={setData}
          setIsParentLoading={setIsLoading}
        />
      </Flex>
      <Flex
        flexDir="column"
        gap="16px"
      >
        <Flex
          as="span"
          color="backgroundAccent"
          fontSize="18px"
          fontWeight="600"
        >
          Results found:&nbsp;
          <Text
            as="span"
            color="textSecondary"
          >
            {/* TODO: Change to state variable */}
            {isLoading ? <SpinnerText /> : data.profile.length + data.tx.length + data.group.length}
          </Text>
        </Flex>
        {data.profile.length > 0 && (
          <ResultCategory categoryText="Profiles">
            {!!data.profile.length && (
              <Box pt="10px">
                <LastProfile data={data.profile as any} />
              </Box>
            )}
          </ResultCategory>
        )}
        {data.tx.length > 0 && (
          <ResultCategory categoryText="Transactions">
            {data.tx.map((tx, index) => {
              return (
                <React.Fragment key={tx.hash}>
                  <Grid
                    templateColumns={{ base: '1fr 1fr', md: '1fr 1fr 1fr' }}
                    alignItems="center"
                    p="8px 0"
                    sx={{
                      '&:not(:last-of-type)': {
                        borderBottom: '1px solid borderPrimary',
                      },
                    }}
                  >
                    <Link
                      to={`/tx/${tx.hash}`}
                      ref={index === 0 && !data.profile.length ? focused : undefined}
                    >
                      <Flex
                        align="center"
                        gap="8px"
                      >
                        <Flex
                          height="40px"
                          width="40px"
                          borderRadius="8px"
                          bgColor="#EDEFF5"
                          fontSize="16px"
                          color="#C1C4D6"
                          align="center"
                          justify="center"
                        >
                          O
                        </Flex>
                        <Flex flexDir="column">
                          <Text
                            fontSize="14px"
                            color="#3366FF"
                          >
                            {trimAddress(tx.hash)}
                          </Text>
                          <Flex
                            align="center"
                            gap="4px"
                            fontSize="12px"
                            color="textSecondary"
                          >
                            {moment(tx._created_at).fromNow()}
                          </Flex>
                        </Flex>
                      </Flex>
                    </Link>
                    <Flex
                      flexDir="column"
                      fontSize="14px"
                      color="textSecondary"
                      align={{ base: 'end', md: 'start' }}
                    >
                      <Text>
                        From&nbsp;
                        <Link
                          to={`/profile/${tx.sender}`}
                          color="#3366FF"
                        >
                          {trimAddress(tx.sender)}
                        </Link>
                      </Text>
                      <Text>
                        To&nbsp;
                        <Link
                          to={`/profile/${tx.target}`}
                          color="#3366FF"
                        >
                          {trimAddress(tx.target)}
                        </Link>
                      </Text>
                    </Flex>
                    <Flex
                      align="end"
                      flexDir="column"
                      gap="4px"
                      display={{ md: 'flex', base: 'none' }}
                    >
                      <Text
                        fontSize="12px"
                        color="textSecondary"
                      >
                        Time
                      </Text>
                      <UserTag text="Label" />
                    </Flex>
                  </Grid>
                </React.Fragment>
              )
            })}
          </ResultCategory>
        )}
        {data.group.length > 0 && (
          <ResultCategory categoryText="Groups">
            {data.group.map((group: IGroup) => (
              <Link
                to={`/group/${group.address}`}
                key={`${group.address}-${group.symbol}`}
              >
                <Flex
                  my="8px"
                  alignItems="center"
                  justifyContent="flex-start"
                  gap="12px"
                >
                  <Image
                    src={buildIpfsGateway(group.metadata.image)}
                    fallback={
                      <Image
                        src="/assets/icons/bnbIcon.svg"
                        border="1px solid"
                        borderColor="borderPrimary"
                        borderRadius="13px"
                        sx={{ boxSize: '48px', p: '16px' }}
                      />
                    }
                    sx={{ borderRadius: '13px', boxSize: '48px' }}
                  />

                  <Flex
                    ml="12px"
                    flexDir="column"
                  >
                    <ShortText>
                      <Text
                        color={group.metadata.name ? 'textPrimary' : 'textTertiary'}
                        fontSize="14px"
                        fontWeight="500"
                      >
                        {group.metadata.name || 'No name'}
                      </Text>
                    </ShortText>
                    <Text
                      whiteSpace="nowrap"
                      fontSize="12px"
                      color="textSecondary"
                      pt="3px"
                    >
                      {group.symbol}
                    </Text>
                  </Flex>
                  <Flex
                    ml="24px"
                    gap="8px"
                  >
                    <Text color="textBlue">{trimAddress(group.owner)}</Text>
                  </Flex>
                </Flex>
              </Link>
            ))}
          </ResultCategory>
        )}
        {/* TODO: Add contract section */}
      </Flex>
    </Flex>
  )
}
