import { Flex, Grid, Image, Input, InputGroup, InputLeftElement, Text, Button, Spinner } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Type, Warning } from '../select/Select'
import { Category, Item } from '../select/utils'
import { CategoryItem } from './CategoryItem'
import { RoleInterestItem } from './RoleInterestItem'

interface IRolesInterestsPicker {
  items: string[]
  setItems: Dispatch<SetStateAction<string[]>>
  fetch: () => Promise<Category[]>
  type: Type

  showIcon?: boolean
  showWarning?: boolean
}

export const RolesInterestsPicker: FC<IRolesInterestsPicker> = ({
  items,
  setItems,
  fetch,
  type,
  showIcon,
  showWarning,
}) => {
  const [activeCategory, setActiveCategory] = useState<Category | null>(null)
  const [showAllCategories, setShowAllCategories] = useState(false)
  const [showAllItems, setShowAllItems] = useState(false)
  const [search, setSearch] = useState('')
  const [exampleCategories, setExampleCategories] = useState<Category[]>()
  const [initialCategories, setInitialCategories] = useState<Category[]>()
  const [searchResults, setSearchResults] = useState<Item[]>([])
  const [selectedItems, setSelectedItems] = useState<Item[]>([])

  useEffect(() => {
    fetch().then(res => {
      setExampleCategories(res)
      setInitialCategories(res)
    })
  }, [])

  const handleSearch = (e: any) => {
    const inputValue = e.target.value
    setSearch(inputValue)

    const results: Item[] = []
    const filteredCategories: Category[] = []

    initialCategories?.forEach((category: Category) => {
      const filteredItems = category.items.filter((item: Item) => {
        return item.name.toLowerCase().includes(inputValue.toLowerCase())
      })
      if (filteredItems?.length > 0) filteredCategories?.push(category)
      results.push(...filteredItems)
    })

    setExampleCategories(filteredCategories)

    //setExampleCategories(exampleCategories)

    setSearchResults(results)
  }

  const removeItem = (name: string) => {
    setItems((prevItems: string[]) => prevItems.filter((item: string) => item !== name))
  }

  return (
    <Flex
      flexDir="column"
      gap="19px"
    >
      <InputGroup>
        <InputLeftElement>
          <Image src="/assets/icons/search.svg" />
        </InputLeftElement>
        <Input
          placeholder={`Search your ${type === Type.INTERESTS ? 'interest' : 'role'}`}
          value={search}
          onChange={handleSearch}
        />
      </InputGroup>
      {activeCategory === null ? (
        <Flex
          flexDir="column"
          gap="10px"
        >
          {exampleCategories?.length ? (
            <Grid
              templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(4, 1fr)' }}
              gridGap="4px"
            >
              {exampleCategories
                ?.slice(0, showAllCategories ? exampleCategories?.length : 12)
                ?.map((category: Category, index) => (
                  <CategoryItem
                    key={category?.name}
                    name={category?.name}
                    imageUrl={`/assets/images/roles/${
                      category?.name === 'Information Technology (IT) Roles'
                        ? 'it'
                        : category?.name === 'Researcher/Analyst Roles'
                        ? 'analyst roles'
                        : category?.name?.toLowerCase()
                    }.webp`}
                    onClick={() => {
                      setActiveCategory(category)
                    }}
                  />
                ))}
            </Grid>
          ) : (
            <Flex sx={{ flexGrow: 1, justifyContent: 'center', pt: '8px' }}>
              <Spinner size="lg" />
            </Flex>
          )}
          {exampleCategories && exampleCategories?.length > 12 && (
            <Button
              variant="dark"
              onClick={() => setShowAllCategories(prev => !prev)}
            >
              {exampleCategories &&
                (showAllCategories
                  ? 'Collapse'
                  : `Show more categories ${
                      exampleCategories?.length - 12 > 0 ? `+${exampleCategories?.length - 12}` : ''
                    }`)}
            </Button>
          )}
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          gap="19px"
          cursor="pointer"
        >
          <Flex
            align="center"
            gap="12px"
            onClick={() => {
              setActiveCategory(null)
              setShowAllCategories(false)
              setShowAllItems(false)
            }}
          >
            <Image src="/assets/icons/arrow-left.svg" />
            <Text
              fontSize="14px"
              fontWeight="500"
              color="backgroundAccent"
            >
              Back to categories
            </Text>
          </Flex>
          <Flex
            flexDir="column"
            gap="10px"
          >
            <Flex
              flexWrap="wrap"
              gap="8px"
            >
              {activeCategory?.items
                ?.filter((item: Item) => searchResults?.length === 0 || searchResults?.includes(item))
                ?.slice(0, showAllItems ? activeCategory?.items?.length : 8)
                ?.map((item: Item, index) => (
                  <RoleInterestItem
                    key={index}
                    item={item}
                    onClick={() => {
                      if (selectedItems?.includes(item) && items?.includes(item?.name)) return
                      setItems(prev => [...prev, item?.name])
                      setSelectedItems(prev => [...prev, item])
                    }}
                  />
                ))}
            </Flex>
            <Button onClick={() => setShowAllItems(prev => !prev)}>
              {activeCategory?.items && (showAllItems ? 'Collapse' : `Show more +${activeCategory?.items?.length - 8}`)}
            </Button>
          </Flex>
        </Flex>
      )}
      {selectedItems?.length > 0 && (
        <Text
          fontSize="16px"
          fontWeight="600"
          color="backgroundAccent"
        >
          Choosen {type === Type.INTERESTS ? 'interests' : 'roles'}
        </Text>
      )}
      <Flex
        flexWrap="wrap"
        gap="8px"
      >
        {selectedItems?.map((item: Item, index) => (
          <RoleInterestItem
            key={index}
            item={item}
            onClick={() => {
              setItems(prev => [...prev.slice(0, index), ...prev.slice(index + 1)])
              setSelectedItems(prev => [...prev.slice(0, index), ...prev.slice(index + 1)])
            }}
            selected
          />
        ))}
      </Flex>
      {showWarning && items?.length <= 2 && <Warning type={type} />}
    </Flex>
  )
}
