import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import React from 'react'
import { AppealModal } from './AppealModal'

export const PostWarningModal = ({ disclosure, id }: { disclosure: UseDisclosureReturn; id: string }) => {
  const appealDisclosure = useDisclosure()

  return (
    <>
      <AppealModal
        disclosure={appealDisclosure}
        parentDisclosure={disclosure}
        postId={id}
      />
      <Modal
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        size="xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.15)" />
        <ModalContent
          bgColor="backgroundMain"
          boxShadow="0px 12px 40px rgba(16, 24, 64, 0.24)"
          borderRadius="16px"
          p="0"
          mx="10px"
          marginBottom={{ base: '10px', md: '64px' }}
        >
          <ModalHeader>
            <Text
              color="textQuaternary"
              fontSize="14px"
              fontWeight="500"
            >
              Details
            </Text>
          </ModalHeader>
          <ModalCloseButton mt="4px" />
          <Divider orientation="horizontal" />
          <ModalBody
            py="24px"
            display="flex"
            flexDir="column"
            gap="20px"
            color="textQuaternary"
            alignItems="start"
          >
            <Text fontSize="14px">Your post has been limited, check the reason below.</Text>
            <Flex
              bg="#FFFAF1"
              border="1px solid"
              borderColor="#FFB020"
              borderRadius="8px"
              w="100%"
              p="12px 16px"
            >
              Pellentesque accumsan enim eget odio ornare hendrerit. Donec gravida, magna in egestas auctor, eros velit
              bibendum nibh, vel condimentum purus augue euismod purus. Mauris tempor justo vitae pellentesque lacinia.
              Ut et ipsum sit amet diam cursus pellentesque quis non quam. Nulla nec aliquam nunc
            </Flex>
            <Text
              color="textQuaternary"
              fontSize="12px"
              fontWeight="500"
            >
              Compose an appeal to request that moderators review the post again.
            </Text>
            <Button
              bg="textBlue"
              color="backgroundMain"
              onClick={() => {
                disclosure.onClose()
                appealDisclosure.onOpen()
              }}
            >
              Write an appeal
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
