import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Colors } from '../../../../../theme/colors'
import ReactMarkdown from 'react-markdown'
import '../../../../../styles/markdown.css'

const markdownContent = `
Welcome to SocialXplorer, a new social media dimension!

A key component of the Candao protocol, SocialXplorer enables users to efficiently connect, monetize, and develop their communities.

The role system, in combination with a targeting mechanism, is one of the best features.

Why does that matter?

Users can target their posts to a certain audience whenever they want, just like with Facebook Ads, but it’s free on Candao.

All transactions are signed with a non-custodial wallet, which means that the followers exist on-chain, and all activities are conducted on the blockchain.

Nice, huh? Check out the product!
`

export const Announcement = () => {
  const [date, setDate] = useState<string>('1st July 2023')
  const [text, setText] = useState<string>('Welcome to SocialXplorer Beta!')

  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Flex
      flexWrap="wrap"
      bg="borderPrimary"
      w="100%"
      alignItems="center"
      justifyContent="space-between"
      gap="16px"
      border="1px solid"
      borderColor="borderPrimary"
      padding="8px 16px"
      borderRadius="8px"
      fontSize="12px"
    >
      <Flex
        flexWrap="wrap"
        align="center"
        gap="8px"
      >
        <Image src="/assets/icons/announcement.svg" />
        <Box color="textPrimary">{text}</Box>
        <Box color="textSecondary">{date}</Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        align="center"
        justify="flex-end"
        onClick={onOpen}
        cursor="pointer"
      >
        <Box color="textSecondary">More&nbsp;</Box>
        <Image src="/assets/icons/arrow-right.svg" />
        <Modal
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Welcome to SocialXplorer – the new dimension of social media!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ReactMarkdown className="markdown">{markdownContent}</ReactMarkdown>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Flex>
  )
}
