import { Flex } from '@chakra-ui/react'
import { General } from './General'

export const Feed = ({ groupName }: { groupName: string }) => {
  //const insertPost = (post: IPostSchema) => setPosts(prev => [post, ...prev])

  return (
    <Flex
      gap="24px"
      direction={{ base: 'column', xl: 'row' }}
      w="100%"
    >
      <Flex sx={{ flexDir: 'column', gap: '16px', w: '100%' }}>
        <Flex
          gap="24px"
          direction={{ base: 'column', lg: 'row' }}
          minW="100%"
        >
          <General />
        </Flex>
      </Flex>
    </Flex>
  )
}
