import React from 'react'
import Markdown from 'react-markdown'

export const Licenses = () => {
  const markdownText = `***Open-source licenses***     
   [@ajna/pagination](https://www.npmjs.com/package/@ajna/pagination)  
   [@chakra-ui/react](https://www.npmjs.com/package/@chakra-ui/react)    
   [@fontsource/inter](https://www.npmjs.com/package/@fontsource/inter)  
   [apexcharts](https://www.npmjs.com/package/apexcharts)  
   [axios](https://www.npmjs.com/package/axios)  
   [chakra-ui-autocomplete](https://www.npmjs.com/package/chakra-ui-autocomplete)  
   [emoji-picker-react](https://www.npmjs.com/package/emoji-picker-react)  
   [framer-motion](https://www.npmjs.com/package/framer-motion)  
   [media-chrome](https://www.npmjs.com/package/media-chrome)  
   [moment](https://www.npmjs.com/package/moment)
   [next-auth](https://www.npmjs.com/package/next-auth)  
   [react-apexcharts](https://www.npmjs.com/package/react-apexcharts)  
   [react-beautiful-dnd](https://www.npmjs.com/package/react-beautiful-dnd)  
   [react-bottom-scroll-listener](https://www.npmjs.com/package/react-bottom-scroll-listener)  
   [react-device-detect](https://www.npmjs.com/package/react-device-detect)  
   [react-dropzone](https://www.npmjs.com/package/react-dropzone)  
   [react-easy-crop](https://www.npmjs.com/package/react-easy-crop)  
   [react-fast-marquee](https://www.npmjs.com/package/react-fast-marquee)  
   [react-icons](https://www.npmjs.com/package/react-icons)  
   [react-image-crop](https://www.npmjs.com/package/react-image-crop)  
   [react-markdown](https://www.npmjs.com/package/react-markdown)  
   [react-paginate](https://www.npmjs.com/package/react-paginate)  
   [react-qr-code](https://www.npmjs.com/package/react-qr-code)  
   [react-query](https://www.npmjs.com/package/react-query)  
   [react-select](https://www.npmjs.com/package/react-select)  
   [react-share](https://www.npmjs.com/package/react-share)  
   [socket.io-client](https://www.npmjs.com/package/socket.io-client)  
   [use-window-focus](https://www.npmjs.com/package/use-window-focus)  
   [viem](https://www.npmjs.com/package/viem)  
   [wagmi](https://www.npmjs.com/package/wagmi)   
   `

  return <Markdown>{markdownText}</Markdown>
}
