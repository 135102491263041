import { Box, Flex, Image } from '@chakra-ui/react'
import { TokenInfoItem } from './tokenInfoItem/tokenInfoItem'
import SpinnerText from '../../../shared/tags/SpinnerText'
import { useCandaoPrice } from '../../../../hooks/candao/useCandaoPrice'
import { useState } from 'react'
import useAsyncEffect from '../../../../hooks/effects/async'
import { fetchApi } from '../../../../utils/fetcher'
import { loaded } from '../../../../utils/process'
import { formatUsd } from '../../../../utils/parser'

export const CandaoTokenInfo = () => {
  const { price, percentage, oldPrice } = useCandaoPrice()
  const [cdoHolders, setCdoHolders] = useState<number>()

  useAsyncEffect(async () => {
    loaded(async () => {
      const cdoHolders = await fetchApi(`ico/holders`)
      setCdoHolders(cdoHolders?.length)
    })
  }, [])

  return (
    <Flex
      flexWrap="wrap"
      minW={{ sm: '100%', lg: '275px', xl: '380px' }}
      bg="backgroundMain"
      flexDir="column"
      height="min"
      borderRadius="16px"
      border="1px solid"
      borderColor="borderPrimary"
    >
      <Flex
        flexWrap="wrap"
        gap="8px"
        w="100%"
        justifyContent="space-between"
        padding="24px 24px 8px 24px"
        bgPos="bottom"
        bgRepeat="no-repeat"
        bgImage="/assets/elements/gradientbg.png"
        bgSize="100%"
      >
        <Flex gridGap="8px">
          <Flex
            align="center"
            justify="center"
            boxSize="48px"
            bg="borderPrimary"
            borderRadius="50%"
          >
            <Image
              boxSize="32px"
              src="/assets/icons/candao-logo.svg"
            />
          </Flex>
          <Flex
            flexWrap="wrap"
            flexDir="column"
            justifyContent="center"
          >
            <Box
              fontSize="14px"
              color="textPrimary"
              fontWeight="bold"
            >
              Candao
            </Box>
          </Flex>
        </Flex>
        <Flex
          flexWrap="wrap"
          justifyContent="flex-end"
          align="center"
        >
          <Flex
            flexWrap="wrap"
            flexDir="column"
            align="flex-end"
            gap="6px"
          >
            <Box
              fontSize="12px"
              color="textSecondary"
            >
              Latest Price
            </Box>
            <Flex
              flexWrap="wrap"
              gap="4px"
              align="center"
            >
              <Box
                fontSize="12px"
                color="textPrimary"
              >
                ${formatUsd(price) ?? <SpinnerText />}
              </Box>{' '}
              <Box
                color="textSecondary"
                fontSize="10px"
              >
                CDO
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        flexWrap="wrap"
        flexDir="column"
        padding="8px 24px 24px"
        gap="16px"
      >
        <TokenInfoItem
          label="Initial supply"
          icon="/assets/icons/marketcap.svg"
          value="405.333.600"
        />
        <TokenInfoItem
          label="Total supply"
          icon="/assets/icons/volume.svg"
          top="Holders"
          value="7.343.000.000 CDO"
          bottom={cdoHolders}
        />

        {/*
        HIDE TEMPORARY
        <TokenInfoItem
          label="Total CDO Staked"
          icon="/assets/icons/stake.svg"
          top="Staking rate"
          bottom={
            <Text>
              <SpinnerText loader="__" /> %
            </Text>
          }
        /> */}
      </Flex>
    </Flex>
  )
}
