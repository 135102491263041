import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Tooltip,
  UseDisclosureReturn,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { Alert } from './Alert/Alert'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useGroupProvider } from '../../../../provider/Group/groupProvider'
import { _log } from '../../../../logger'

export enum SendStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  NONE = 'none',
}

export const EmailView = ({
  disclosure,
  parentDisclosure,
}: {
  disclosure: UseDisclosureReturn
  parentDisclosure: UseDisclosureReturn
}) => {
  const [emailNumber, setEmailNumber] = useState(0)
  const [input, setInput] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [sendStatus, setSendStatus] = useState<SendStatus>(SendStatus.NONE)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const wallet = useEthereumProvider()

  const group = useGroupProvider()

  const alertDisclosure = useDisclosure({ defaultIsOpen: true })

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const handleInput = (e: any) => {
    setInput(e.target.value)
    const emails = e.target.value
      .trim()
      .split(/[\s,]+/)
      .filter(email => email !== '' && email.trim().match(emailRegex))
      .map(email => email.trim())
    setEmails(emails)
    //setEmailNumber(emails.length)
  }

  const handleInvite = async () => {
    await loaded(
      async () => {
        if (emails.length === 0) {
          throw new Error('Emails must be > 0')
        }
        const res = await fetchApi('group/sendEmailInvite', {
          emails,
          groupAddress: group.address,
        })

        setSendStatus(SendStatus.SUCCESS)
      },
      setIsButtonLoading,
      e => {
        _log(e)
        setSendStatus(SendStatus.ERROR)
      }
    )
  }

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={() => {
        parentDisclosure.onOpen()
        disclosure.onClose()
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader
          color="backgroundAccent"
          fontSize="18px"
          fontWeight="600"
        >
          Invite via email
        </ModalHeader>
        <Divider />
        <ModalCloseButton mt="8px" />
        <ModalBody
          display="flex"
          flexDir="column"
          p="24px"
          gap="24px"
        >
          <Text
            fontSize="14px"
            color="textSecondary"
          >
            Add people by writing their email address, you can add as much as you want email address to invite others.
          </Text>
          <Textarea
            placeholder="Enter an email address"
            _placeholder={{ fontSize: '14px' }}
            onChange={handleInput}
            value={input}
            fontSize="14px"
          />
          <Alert
            title={sendStatus === SendStatus.SUCCESS ? 'Invitations sent successfully' : 'Something went wrong.'}
            description={
              sendStatus === SendStatus.SUCCESS
                ? 'We have successfully sent the invitation email to all the attached emails. Write new emails to invite more if you want.'
                : 'There seems to be a problem sending email invitations, please try again.'
            }
            status={sendStatus}
            disclosure={alertDisclosure}
          />
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Flex
            align="center"
            justify="space-between"
            w="100%"
          >
            <Flex
              as="span"
              display="inline-block"
              fontSize="12px"
              color="backgroundAccent"
            >
              <Text
                fontWeight="500"
                as="span"
                display="inline-block"
              >
                {emails.length} people&nbsp;
              </Text>
              <Text
                as="span"
                display="inline-block"
              >
                will be invited
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              gap="8px"
            >
              <Button
                onClick={() => {
                  parentDisclosure.onOpen()
                  disclosure.onClose()
                }}
                h="32px"
                fontSize="12px"
              >
                Cancel
              </Button>
              <Tooltip label="Soon!">
                <Button
                  onClick={handleInvite}
                  isDisabled={!(emails.length > 0) || !wallet.isLoggedIn}
                  h="32px"
                  fontSize="12px"
                  bg="textBlue"
                  color="backgroundMain"
                  isLoading={isButtonLoading}
                >
                  Done
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
