import { Button, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { contentSwitcher, Switcher } from './userContentSwitcher'

export const UserContentSwitcherMobile = ({ setValue }: { setValue: any }) => {
  const [isActive, setIsActive] = useState(Switcher.Posts)

  const handlerActive = (value: Switcher) => {
    setIsActive(value)
    setValue(value)
  }
  return (
    <Flex
      maxW="100vw"
      overflow="auto"
      align="center"
      p="15px 18px"
      gap="8px"
      display={{ base: 'flex', lg: 'none' }}
      // ml={{ base: '-16px', md: '0' }}
      bg="backgroundMain"
      borderRadius={{ base: '0', md: '16px' }}
      border="1px solid"
      borderColor="borderPrimary"
      borderX={{ base: 'none', md: '1px solid' }}
      borderLeftColor="borderPrimary !important"
      borderRightColor="borderPrimary !important"
      scrollMarginY="10px"
      sx={{
        '::-webkit-scrollbar': {
          height: '6px',
          marginY: '30px',
          border: 'none',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#36F',
          borderRadius: '8px',
        },
        '::-webkit-scrollbar-track': {
          //background: '#EDEFF5',
        },
      }}
    >
      {contentSwitcher.map(tab => {
        return (
          <Button
            isDisabled={[Switcher.Domains].includes(tab.id)}
            display="flex"
            __css={{}}
            _disabled={{ filter: 'grayscale(1)', opacity: '.5', cursor: 'not-allowed' }}
            key={tab.label}
            _hover={{
              background: 'backgroundAccentLight',
            }}
            onClick={() => handlerActive(tab.id)}
            bgColor={isActive === tab.id ? 'backgroundAccentLight' : 'transparent'}
            cursor="pointer"
            borderRadius="8px"
            p="6px 8px"
            bg={isActive ? '#F3F6FF' : 'backgroundTertiary'}
            border="1px solid"
            borderColor={isActive ? 'bluePlaceholder' : '#EDEFF5'}
            color={isActive === tab.id ? 'textSecondary' : 'textSecondary'}
          >
            {tab.label}
          </Button>
        )
      })}
    </Flex>
  )
}
