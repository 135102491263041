import React from 'react'
import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import { useIconProvider } from '../../../../../../provider/Icons/iconsProvider'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

export const UserInterestComponent = ({ interest }: { interest: string }) => {
  const { interests } = useIconProvider()

  const navigate = useNavigate()

  return (
    <Flex
      flexDirection="column"
      sx={{
        '&:not(:last-of-type)': {
          borderBottom: '1px solid borderPrimary',
        },
      }}
    >
      <Flex
        p="12px 16px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex gridGap="16px">
          <Box
            display="flex"
            borderRadius="8px"
            w="40px"
            h="40px"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src={interests.get(interest)}
              fallback={<QuestionOutlineIcon />}
            />
          </Box>
          <Flex direction="column">
            <Text color="textQuaternary">{interest}</Text>
            <Text
              color="textSecondary"
              fontSize="12px"
            >
              <SpinnerText loader="__" /> Interested
            </Text>
          </Flex>
        </Flex>
        <Box
          display="flex"
          w="32px"
          h="32px"
          border="1px solid"
          borderColor="#D8DAE5"
          borderRadius="8px"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
          onClick={() => navigate('/dashboard', { state: { search: interest } })}
        >
          <Image
            src="/assets/icons/link-icon.svg"
            boxSize="16px"
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default UserInterestComponent
