import React from 'react'
import { Icon, useColorModeValue } from '@chakra-ui/react'

export const Twitter = () => {
  const color = useColorModeValue('#101840', '#fff')

  return (
    <Icon
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2.25C11.55 2.475 11.1 2.55 10.575 2.625C11.1 2.325 11.475 1.875 11.625 1.275C11.175 1.575 10.65 1.725 10.05 1.875C9.6 1.425 8.925 1.125 8.25 1.125C6.975 1.125 5.85 2.25 5.85 3.6C5.85 3.825 5.85 3.975 5.925 4.125C3.9 4.05 2.025 3.075 0.825 1.575C0.6 1.95 0.525 2.325 0.525 2.85C0.525 3.675 0.975 4.425 1.65 4.875C1.275 4.875 0.9 4.725 0.525 4.575C0.525 5.775 1.35 6.75 2.475 6.975C2.25 7.05 2.025 7.05 1.8 7.05C1.65 7.05 1.5 7.05 1.35 6.975C1.65 7.95 2.55 8.7 3.675 8.7C2.85 9.375 1.8 9.75 0.6 9.75C0.375 9.75 0.225 9.75 0 9.75C1.125 10.425 2.4 10.875 3.75 10.875C8.25 10.875 10.725 7.125 10.725 3.9C10.725 3.825 10.725 3.675 10.725 3.6C11.25 3.225 11.7 2.775 12 2.25Z"
        fill={color}
      />
    </Icon>
  )
}
