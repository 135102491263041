import { _log } from '../logger'

type hexColor = `#${string}`

type IColor = {
  [key in
    | 'backgroundMain'
    | 'backgroundSecondary'
    | 'backgroundAccent'
    | 'backgroundAccentLight'
    | 'backgroundTertiary'
    | 'backgroundQuaternary'
    | 'borderPrimary'
    | 'backgroundDark'
    | 'textWhite'
    | 'textPrimary'
    | 'textSecondary'
    | 'textTertiary'
    | 'textBlue'
    | 'textQuaternary'
    | 'greenPrimary'
    | 'greenSecondary'
    | 'redPrimary'
    | 'redSecondary'
    | 'bluePrimary'
    | 'goldPrimary'
    | 'blueSecondary'
    | 'bluePlaceholder'
    | 'grayPrimary'
    | 'graySecondary']: hexColor
}

const light: IColor = {
  backgroundMain: '#FFFFFF',
  backgroundSecondary: '#FAFBFF',
  backgroundTertiary: '#F3F6FF',
  backgroundQuaternary: '#F9FAFC',
  backgroundAccent: '#101840',
  backgroundAccentLight: '#EBF0FF',
  borderPrimary: '#E6E8F0',

  backgroundDark: '#101840',
  textWhite: '#FFFFFF',

  textPrimary: '#101828',
  textSecondary: '#696F8C',
  textTertiary: '#98A2B3',
  textBlue: '#3366FF',
  textQuaternary: '#474D66',

  greenPrimary: '#12B76A',
  greenSecondary: '#ECFDF3',
  redPrimary: '#F04438',
  redSecondary: '#FEF3F2',
  bluePrimary: '#2E90FA',
  blueSecondary: '#EFF8FF',
  bluePlaceholder: '#D6E0FF',
  goldPrimary: '#996A13',

  grayPrimary: '#F2F4F7',
  graySecondary: '#F9FAFB',
}

const dark: IColor = {
  backgroundMain: '#2e3138',
  backgroundSecondary: '#1b1f27',
  backgroundTertiary: '#1b1f27',
  backgroundQuaternary: '#1b1f27',
  backgroundAccent: '#FAFBFF',
  backgroundAccentLight: '#212940',
  borderPrimary: '#474D66',

  backgroundDark: '#101828',
  textWhite: '#FFFFFF',

  textPrimary: '#FAFBFF',
  textSecondary: '#E6E8F0',
  textTertiary: '#E6E8F0',
  textBlue: '#3366FF',
  textQuaternary: '#E6E8F0',

  greenPrimary: '#12B76A',
  greenSecondary: '#73ab8a',
  redPrimary: '#F04438',
  redSecondary: '#b88d89',
  bluePrimary: '#2E90FA',
  blueSecondary: '#7ea8c8',
  bluePlaceholder: '#101828',
  goldPrimary: '#c38718',

  grayPrimary: '#c0c6ce',
  graySecondary: '#afb8c0',
}

export const Colors: { [key in keyof typeof light]: { default: string; _dark: string } } = Object.fromEntries(
  Object.entries(light).map(([key, value]) => [
    key,
    {
      default: value,
      _dark: dark[key],
    },
  ])
) as any
