import { Flex, Image, Link, Text, Tooltip } from '@chakra-ui/react'
import { INft } from './UserNfts'
import { buildIpfsGateway, formatUsd, trimAddress } from '../../../../utils/parser'
import SpinnerText from '../../../shared/tags/SpinnerText'
import { redirect } from '../../../../utils/redirector'

export const UserNft = ({ nft }: { nft: INft }) => {
  return (
    <Tooltip
      label={
        <Flex
          color="textPrimary"
          gap="4px"
          py="6px"
          borderRadius="10px !important"
        >
          <Image
            src={buildIpfsGateway(nft.metadata?.image) ?? '/assets/images/bnbIcon.svg'}
            fallback={
              <Image
                src="/assets/icons/bnbIcon.svg"
                padding="8px"
                border="1px solid"
                borderColor="borderPrimary"
                borderRadius="4px"
              />
            }
            fallbackSrc=""
            w="32px"
            h="32px"
            maxW="32px"
            maxH="32px"
            borderRadius="4px"
          />
          <Flex
            flexDir="column"
            gap="2px"
          >
            <Text
              fontSize="12px"
              fontWeight="500"
            >
              {nft.name}
            </Text>
            <Text
              color="#8F95B2"
              fontSize="8px"
            >
              {trimAddress(nft.address) || 'Unknown'}
            </Text>
          </Flex>
        </Flex>
      }
      placement="bottom"
      offset={[20, -80]}
      hasArrow
      bg="backgroundMain"
    >
      <Link
        flexDir="column"
        gap="8px"
        display="flex"
        maxW="88px"
        href={`https://opensea.io/assets/${nft.chain.toLowerCase()}/${nft.address}/${nft.id}`}
        target="_blank"
      >
        <Flex sx={{ w: '88px', h: '88px', alignItems: 'center', justifyContent: 'center' }}>
          <Image
            src={buildIpfsGateway(nft.metadata?.image) ?? '/assets/images/bnbIcon.svg'}
            fallback={
              <Image
                src="/assets/icons/bnbIcon.svg"
                border="1px solid"
                borderColor="borderPrimary"
                borderRadius="13px"
                sx={{ w: '88px', h: '88px', p: '16px' }}
              />
            }
            bg="#F4F4F4"
            w="88px"
            h="88px"
            maxW="88px"
            maxH="88px"
            borderRadius="13px"
            objectFit="cover"
            objectPosition="center"
          />
        </Flex>
        <Flex
          flexDir="column"
          gap="2px"
        >
          <Text
            color="#8F95B2"
            fontSize="8px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {nft.metadata?.name || `#${nft.id}`}
          </Text>
          <Flex
            as="span"
            color="#36F"
            fontSize="12px"
          >
            $
            <Text
              as="span"
              color="textQuaternary"
              mr="2px"
            >
              {nft.price !== '0' ? formatUsd(nft.price) : <SpinnerText />}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Tooltip>
  )
}
