export const createMessageFromData = (m: any) => {
  return {
    uuid: m.uuid,
    text: m.content ?? '',
    assets: m.assets.map((asset: any) => {
      return {
        path: asset.path,
        id: asset._id,
        type: asset.type,
      }
    }),
    date: m._created_at,
    sender: m.sender ?? m.author,
    thread: m.thread,
  }
}
