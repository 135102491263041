import { fetchApi, fetcher } from '../../../utils/fetcher'
import { loaded } from '../../../utils/process'

export type ParentCategory = {
  name: string
  id: number
}

export interface Item {
  category: ParentCategory
  icon: string
  id: number
  name: string
}

export interface Role extends Item {
  description: string
}

export interface Interest extends Item {
  icon_colored: string
}

export interface Category {
  name: string
  id: number
  items: Array<Item>
}

export const fetchRoles = async (): Promise<Category[]> =>
  (await loaded(async () => {
    const roles = (await fetchApi('role/dump')).roles

    const categories = Object.keys(roles)

    const res: Category[] = []
    for (const category of categories) {
      res.push({
        name: category,
        items: roles[category],
        id: -1,
      })
    }
    return res
  })) || []

export const fetchInterests = async (): Promise<Category[]> =>
  (await loaded(async () => {
    const { interests } = await fetchApi(`interest/dump`)

    const categories = Object.keys(interests)

    const res: Category[] = []
    for (const category of categories) {
      res.push({
        name: category,
        items: interests[category],
        id: -1,
      })
    }

    return res
  })) || []
