import { Icon } from '@chakra-ui/react'

interface IProps {
  color?: string
}

export const ArrowLeft = ({ color = '#8F95B2' }: IProps) => (
  <Icon
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1104_23333)">
      <path
        d="M3.33325 8H12.6666"
        stroke="#8F95B2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 8L7.33325 12"
        stroke="#8F95B2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33325 8L7.33325 4"
        stroke="#8F95B2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1104_23333">
        <rect
          width="16"
          height="16"
          fill="backgroundMain"
        />
      </clipPath>
    </defs>
  </Icon>
)
