import { Button, Divider, Flex, Image, Popover, PopoverContent, PopoverTrigger, Text, Tooltip } from '@chakra-ui/react'
import { useEthereumProvider } from '../../../../../../../../provider/Ethereum/ethereumProvider'
import { IPostSchema, PostType } from '../../../../deafult/Default'
import { trimAddress } from '../../../../../../../../utils/parser'
import { useMemo } from 'react'
import { RegexUtils } from '../../../../../../../../utils/regex'

interface IProps {
  post: IPostSchema
  handlers: {
    report: () => void
    delete: () => void
    copy: () => void
    details: () => void
    boost: () => void
    addOffer: () => void
    createGroup: () => Promise<void>
    navigate: () => void
  }
}

export default function PostOptions({ post, handlers }: IProps) {
  const { account } = useEthereumProvider()

  const isSelf = useMemo(() => post?.author?.toLowerCase() === account?.toLowerCase(), [post, account])

  const isComment = useMemo(() => post.thread?.length !== 0, [post])

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button
          border="none"
          minH="24px"
          maxH="24px"
          minW="24px"
          maxW="24px"
          p="0"
          bg="none"
        >
          <Image src="/assets/icons/dots.svg" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        width="auto"
        cursor="auto"
      >
        <Flex
          width="auto"
          flexDir="column"
          justifyContent="start"
          alignItems="start"
          gap="4px"
          color="backgroundAccent"
          p="8px 8px"
          zIndex="100"
        >
          {!isComment && isSelf && (
            <ListOption
              icon="/assets/icons/block2.svg"
              text="Boost post"
              // onClick={handlers.boost}
              onClick={() => null}
              isDisabled
            />
          )}
          {!isComment && isSelf && (
            <ListOption
              icon="/assets/icons/block2.svg"
              text="Show details"
              onClick={handlers.details}
            />
          )}
          {!isComment && isSelf && post.type === PostType.Post && (
            <ListOption
              icon="/assets/icons/block2.svg"
              text="Add offer"
              onClick={handlers.addOffer}
            />
          )}
          {!isComment && isSelf && post.type !== PostType.Post && (
            <ListOption
              icon="/assets/icons/block2.svg"
              text="Create group"
              onClick={handlers.createGroup}
            />
          )}
          {!isComment && isSelf && (
            <Divider
              marginTop="4px"
              marginBottom="4px"
            />
          )}
          <ListOption
            icon="/assets/icons/copy.svg"
            text="Copy link"
            onClick={handlers.copy}
          />
          <ListOption
            icon="/assets/icons/block2.svg"
            text="Go to post"
            onClick={handlers.navigate}
          />
          <Divider
            marginTop="4px"
            marginBottom="4px"
          />
          <ListOption
            isDisabled={!account || isSelf}
            icon="/assets/icons/block2.svg"
            text={`Report/Block ${trimAddress(post?.author)}`}
            onClick={() => null}
          />
          <ListOption
            isDisabled={!account || isSelf}
            icon="/assets/icons/block2.svg"
            text="Report this post"
            onClick={handlers.report}
          />
          {isSelf && (
            <>
              <Divider
                marginTop="4px"
                marginBottom="4px"
              />
              <ListOption
                isDisabled={!account}
                icon="/assets/icons/trash.svg"
                text="Delete post"
                onClick={handlers.delete}
              />
            </>
          )}
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

interface IListOption {
  icon: string
  text: string
  onClick: () => void
  isDisabled?: boolean
}

export function ListOption({ icon, text, onClick, isDisabled }: IListOption) {
  // Tylko tak działa, takze musialem tak zrobic
  if (RegexUtils.insensitive(text).test('boost post')) {
    return (
      <Tooltip label="Coming soon!">
        <Button
          variant="slave_shadow"
          border="none"
          justifyContent="flex-start"
          width="100%"
          onClick={onClick}
          p="6px 6px"
          isDisabled={isDisabled}
        >
          <Image
            src={icon}
            p="0px 0px "
          />
          <Text
            p="0px 10px"
            fontSize="14px"
            fontWeight="400"
            color="textQuaternary"
          >
            {text}
          </Text>
        </Button>
      </Tooltip>
    )
  }
  return (
    <Button
      variant="slave_shadow"
      border="none"
      justifyContent="flex-start"
      width="100%"
      onClick={onClick}
      p="6px 6px"
      isDisabled={isDisabled}
    >
      <Image
        src={icon}
        p="0px 0px "
      />
      <Text
        p="0px 10px"
        fontSize="14px"
        fontWeight="400"
        color="textQuaternary"
      >
        {text}
      </Text>
    </Button>
  )
}
