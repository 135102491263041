import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { AccountItem } from './AccountItem'
import { DisabledContainer } from '../../../../shared/containers/DisabledContainer'

export const AccountsTab = () => {
  return (
    <DisabledContainer>
      <Flex
        p="32px 24px"
        flexDir="column"
      >
        <Flex
          flexDir="column"
          gap="2px"
        >
          <Text
            fontWeight="500"
            color="backgroundAccent"
            fontSize="14px"
          >
            Your accounts
          </Text>
        </Flex>
        <Flex
          mt="24px"
          gap="16px"
          flexDir="column"
        >
          <AccountItem
            name="Facebook"
            link="https://facebook.com"
            imageUrl="/assets/icons/fb.svg"
          />
          <AccountItem
            name="Tiktok"
            link="https://tiktok.com"
            imageUrl="/assets/icons/tiktok.svg"
            isConnected
          />
          <AccountItem
            name="Twitter"
            link="https://twitter.com"
            imageUrl="/assets/icons/tt.svg"
          />
          <AccountItem
            name="Snapchat"
            link="https://snapchat.com"
            imageUrl="/assets/icons/snapchat.svg"
          />
          <AccountItem
            name="Instagram"
            link="https://instagram.com"
            imageUrl="/assets/icons/ig.svg"
          />
        </Flex>
      </Flex>
    </DisabledContainer>
  )
}
