enum EError {
  PERMISSION = -1,
}

const copyToClipboard = async (content: string) => {
  try {
    await navigator.clipboard.writeText(content)
  } catch {
    return EError.PERMISSION
  }
}

const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time))

export { copyToClipboard, delay }
export type { EError }
