import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { useProfileProvider } from '../../../../../../provider/Profile/profileProvider'
import SpinnerText from '../../../../../shared/tags/SpinnerText'
import UserInterestComponent from './userInterestComponent'
import { UserWallLayout } from '../UserWallLayout'

export const UserInterests = () => {
  const profile = useProfileProvider()

  return (
    <UserWallLayout
      heading="Interests"
      headingDetails={`${profile.isLoading ? <SpinnerText /> : profile.interests?.length || 0} interests`}
    >
      {profile.interests?.map(interest => (
        <React.Fragment key={interest}>
          <UserInterestComponent interest={interest} />
        </React.Fragment>
      ))}
    </UserWallLayout>
  )
}

export default UserInterests
