import { FC } from 'react'
import { SendStatus } from '../EmailView'
import { UseDisclosureReturn, Alert as ChakraAlert, CloseButton, Text, Flex, AlertIcon } from '@chakra-ui/react'

type AlertProps = {
  status: SendStatus
  disclosure: UseDisclosureReturn
  title: string
  description: string
}

export const Alert: FC<AlertProps> = ({ status, disclosure, title, description }) => {
  if (status === SendStatus.NONE || !disclosure.isOpen) {
    return <></>
  }

  return (
    <ChakraAlert
      sx={{
        border: `1px solid`,
        borderRadius: '8px',
        borderColor: `${status === SendStatus.SUCCESS ? '#52BD94' : '#D14343'}`,
        bg: status === SendStatus.SUCCESS ? '#F5FBF8' : '#FDF4F4',
        gap: '16px',
      }}
      status={status}
    >
      <AlertIcon
        sx={{
          width: '16px',
          height: '16px',
          alignSelf: 'flex-start',
          px: '1px !important',
          pt: '4px',
          m: '0px',
          gap: '0px !important',
        }}
      />
      <Flex sx={{ flexDirection: 'column', gap: '4px', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Text
          sx={{
            color: status === SendStatus.SUCCESS ? '#317159' : '#A73636',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '20px',
          }}
        >
          {title}
        </Text>
        <Text sx={{ color: 'textSecondary', fontSize: '14px', lineHeight: '20px' }}>{description}</Text>
      </Flex>
      <CloseButton
        sx={{ p: '0px', pt: '4px', m: '0px', width: '16px', height: '16px', alignSelf: 'flex-start' }}
        onClick={disclosure.onClose}
      />
    </ChakraAlert>
  )
}
