import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

export const Error = () => {
  const history = useNavigate()

  const navigate = () => {
    history('/')
  }

  return (
    <Flex
      flexWrap="wrap"
      pt="32px"
      px="auto"
      flexDirection="column"
      alignItems="center"
      bg="backgroundSecondary"
      py={{ base: '160px' }}
      w="100%"
    >
      <Text
        fontWeight="bold"
        fontSize="24px"
      >
        404
      </Text>
      <Text>You've reached the dead end!</Text>
      <Button
        variant="dark"
        width="320px"
        mt="32px"
        onClick={navigate}
      >
        Go home
      </Button>
    </Flex>
  )
}

export default Error
