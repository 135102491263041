import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseDisclosureReturn,
  Flex,
  Text,
  Input,
  useDisclosure,
} from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import { IGroup } from '../../../../hooks/useGroup'
import { ImportTokenModal } from './ImportToken'
import { GroupNameModal } from './GroupName'
import { _log } from '../../../../logger'
import { CreateTokenModal } from './CreateToken'
import { GroupCreatorStep, useGroupCreatorProvider } from '../../../../provider/GroupCreator/groupCreatorProvider'

type CreateGroupProps = {
  handleCreate: (groupCreator: Partial<IGroup>) => Promise<void>
}

export const CreateGroup: FC<CreateGroupProps> = ({ handleCreate }) => {
  const groupCreator = useGroupCreatorProvider()

  const currentComponent = useMemo(() => {
    switch (groupCreator.step) {
      case GroupCreatorStep.Welcome:
        return <GroupNameModal />
      case GroupCreatorStep.CreateToken:
        return <CreateTokenModal />
      case GroupCreatorStep.ImportToken:
        return <ImportTokenModal handleCreate={handleCreate} />
      default:
        return <GroupNameModal />
    }
  }, [groupCreator.step])

  return (
    <Modal
      isOpen={groupCreator.isOpen}
      onClose={groupCreator.onClose}
    >
      <ModalOverlay />
      {currentComponent}
    </Modal>
  )
}
