import { Button, Flex, Tooltip } from '@chakra-ui/react'
import { FC } from 'react'
import { EGroupTab, useTabProvider } from '../../../provider/Group/tabProvider'

type TGroupTabPickerProps = {
  handleJoinAs: (tab: EGroupTab) => Promise<void>
}

export const GroupTabPicker: FC<TGroupTabPickerProps> = ({ handleJoinAs }) => {
  const joinableTabs = [EGroupTab.Channels, EGroupTab.Voting, EGroupTab.About]

  const { currentTab, setCurrentTab } = useTabProvider()

  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      sx={{
        w: { base: '100%', sm: '168px' },
        flexDirection: 'column',
        gridGap: '2px',
        bgColor: 'backgroundMain',
        p: '16px',
        alignSelf: 'flex-start',
        borderRadius: '16px',
        border: '1px solid',
        borderColor: 'borderPrimary',
      }}
    >
      {(Object.keys(EGroupTab) as (keyof typeof EGroupTab)[]).map(tab => {
        return (
          <Tooltip key={tab}>
            <Button
              display="flex"
              __css={{}}
              _disabled={{ filter: 'grayscale(1)', opacity: '.5', cursor: 'not-allowed' }}
              key={tab}
              _hover={{
                background: 'backgroundAccentLight',
              }}
              bgColor={currentTab === tab ? 'backgroundAccentLight' : 'transparent'}
              color={currentTab === tab ? 'textBlue' : 'textSecondary'}
              sx={{
                fontSize: '14px',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: '6px 8px',
                borderRadius: '4px',
                cursor: 'pointer',
                w: { xl: '140px', sm: 'auto' },
                gap: '16px',
              }}
              onClick={() =>
                joinableTabs.includes(tab as EGroupTab)
                  ? handleJoinAs(tab as EGroupTab)
                  : setCurrentTab(tab as EGroupTab)
              }
            >
              {tab}
              {joinableTabs.includes(tab as EGroupTab) && (
                <Button
                  sx={{
                    bg: 'textBlue',
                    padding: '0px 5px',
                    h: 'min',
                    borderRadius: '4px',
                    color: 'textWhite',
                    fontSize: '10px',
                    lineHeight: '20px',
                    border: 'none',
                  }}
                  as="span"
                >
                  JoinAS
                </Button>
              )}
            </Button>
          </Tooltip>
        )
      })}
    </Flex>
  )
}
