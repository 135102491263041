import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { CheckCircleIcon, CopyIcon } from '@chakra-ui/icons'

export const CopyAddress = ({ address }: { address: string }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) setCopied(false)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [copied])

  return (
    <Flex
      border="1px solid"
      display={{ base: 'none', md: 'flex' }}
      gap="12px"
      align="center"
      borderColor="borderPrimary"
      borderRadius="4px"
      padding="1px 6px"
      fontSize="12px"
      color="textSecondary"
    >
      <Box>{address.slice(0, 6) + '...' + address.slice(-5)}</Box>
      {copied ? (
        <CheckCircleIcon />
      ) : (
        <CopyIcon
          cursor="pointer"
          onClick={() => {
            setCopied(true)

            window.navigator.clipboard.writeText(address)
          }}
        />
      )}
    </Flex>
  )
}
