import React, { useState } from 'react'
import {
  ButtonProps,
  Flex,
  Image,
  Link as ChakraLink,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { SearchBar } from '../components/pages/Dashboard/SearchBar/searchBar'
import { Colors } from '../theme/colors'
import { CardContainer } from '../components/shared/containers/CardContainer'
import useAsyncEffect from '../hooks/effects/async'
import { loaded } from '../utils/process'
import { fetchApi } from '../utils/fetcher'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import { trimAddress } from '../utils/parser'
import { Outcome } from '../components/shared/actions/Outcome'
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
  PaginationSeparator,
} from '@ajna/pagination'
import { useProfileProvider } from '../provider/Profile/profileProvider'

export const Transactions = () => {
  const [txs, setTxs] = useState<any[]>()
  const [numOfPages, setNumOfPages] = useState(1)
  const outerLimit = 2
  const innerLimit = 2
  const { pages, pagesCount, offset, currentPage, setCurrentPage, setIsDisabled, isDisabled, pageSize, setPageSize } =
    usePagination({
      total: numOfPages * 10,
      limits: {
        outer: outerLimit,
        inner: innerLimit,
      },
      initialState: {
        pageSize: 10,
        isDisabled: false,
        currentPage: 1,
      },
    })
  const profile = useProfileProvider()

  const baseStyles: ButtonProps = {
    w: '32px',
    h: '32px',
    fontSize: 'sm',
    border: 'none',
  }

  const normalStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: 'backgroundAccentLight',
    },
  }

  const activeStyles: ButtonProps = {
    ...baseStyles,
    _hover: {
      bg: 'backgroundAccentLight',
    },
    bg: 'backgroundAccentLight',
    color: '#3366FF',
  }

  useAsyncEffect(async () => {
    void loaded(async () => {
      try {
        const data = await fetchApi('tx/page/1', { user: profile.address?.toLowerCase() })

        setTxs(data.transactions)
        setNumOfPages(data.totalPages)
      } catch {
        setTxs([])
      }
    })
  }, [])

  const handlePageChange = async (page: number) => {
    setCurrentPage(page)
    try {
      const data = await fetchApi(`tx/page/${page}`, { user: profile.address?.toLowerCase() })
      setTxs(data.transactions)
      setNumOfPages(data.totalPages)
    } catch {
      setTxs([])
    }
  }

  return (
    <Flex
      // bg="backgroundMain"
      bg="backgroundSecondary"
      justify="center"
      w="100%"
      direction="column"
    >
      <Flex
        w="100%"
        h="180px"
        borderRadius="12px"
        bgColor="backgroundDark"
        justifyContent="center"
        alignItems="center"
      >
        <SearchBar />
      </Flex>
      <Text
        pt="32px"
        pb="24px"
        mx={{base: '16px', candao: 'unset'}}
        fontSize="24px"
        color="textPrimary"
        fontWeight="600"
      >
        Transactions
      </Text>
      <CardContainer width="100%">
        <TableContainer w="100%">
          {!txs && (
            <Flex
              height="200px"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Spinner size="xl" />
            </Flex>
          )}
          {txs && (
            <Table variant="simple">
              <Thead
                borderRadius="20px"
              >
                <Tr
                  bgColor="backgroundMain"
                  borderRadius="8px"
                >
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    Hash
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    Block
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    Age
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    Type
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    User
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    From
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    To
                  </Th>
                  <Th
                    border="0px"
                    fontSize="10px"
                    color="textSecondary"
                  >
                    Result
                  </Th>
                </Tr>
              </Thead>
              <Tbody width="100%">
                {txs.map(tx => (
                  <Tr
                    key={tx.hash}
                    fontSize="14px"
                  >
                    <Td
                      color="#3366FF"
                      border="0px"
                      display="flex"
                      gridGap="18px"
                      cursor="pointer"
                    >
                      <Image src="/assets/icons/eye-icon.svg" />
                      <Link
                        style={{ color: 'textBlue' }}
                        to={`/tx/${tx.hash}`}
                      >
                        {trimAddress(tx.hash)}
                      </Link>
                    </Td>
                    <Td
                      color="#3366FF"
                      border="0px"
                    >
                      <ChakraLink href={`https://bscscan.com/block/${tx.blockNumber}`}>
                        {tx.blockNumber}
                      </ChakraLink>
                    </Td>
                    <Td
                      color="#8F95B2"
                      border="0px"
                    >
                      {moment(tx._created_at).fromNow()}
                    </Td>
                    <Td
                      color="#8F95B2"
                      border="0px"
                      fontStyle="italic"
                      fontWeight="bold"
                    >
                      {tx.action}
                    </Td>
                    <Td
                      color="#3366FF"
                      border="0px"
                    >
                      <Link
                        style={{ color: 'textBlue' }}
                        to={`/profile/${tx.sender}`}
                      >
                        {trimAddress(tx.sender)}
                      </Link>
                    </Td>
                    <Td
                      color="#3366FF"
                      border="0px"
                    >
                      <Link
                        style={{ color: 'textBlue' }}
                        to={`/profile/${tx.sender}`}
                      >
                        {trimAddress(tx.sender)}
                      </Link>
                    </Td>
                    <Td
                      color="#3366FF"
                      border="0px"
                    >
                      <Link
                        style={{ color: 'textBlue' }}
                        to={`/profile/${tx.target}`}
                      >
                        {trimAddress(tx.target)}
                      </Link>
                    </Td>
                    <Td
                      color="#8F95B2"
                      border="0px"
                    >
                      <Outcome
                        isSuccess={tx.confirmed}
                        isLoading={moment.duration(moment().diff(tx._created_at)).asSeconds() <= 18 * 3 + 10}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>

        {txs && (
          <Flex
            w="100%"
            justify="center"
            gap="8px"
          >
            {/* @ts-ignore */}
            <Pagination
              pagesCount={numOfPages}
              currentPage={currentPage}
              // normalStyles={normalStyles}
              // activeStyles={activeStyles}
              // separatorStyles={baseStyles}
              onPageChange={(p: number) => handlePageChange(p)}
            >
              <PaginationContainer
                align="center"
                justify="space-between"
                w="auto"
                p={4}
                gap="4px"
              >
                <PaginationPrevious
                  border="none"
                  maxH="32px"
                  maxW="32px"
                  minH="32px"
                  minW="32px"
                  p="0"
                >
                  <Image
                    src="/assets/icons/chevron-right.svg"
                    transform="rotate(180deg)"
                  />
                </PaginationPrevious>
                <PaginationPageGroup
                  align="center"
                  separator={
                    <PaginationSeparator
                      isDisabled
                      bg="backgroundAccentLight"
                      border="none"
                      fontSize="sm"
                      w="32px"
                      h="32px"
                      jumpSize={11}
                    />
                  }
                >
                  {pages.map((page: number) => (
                    <PaginationPage
                      w="32px"
                      h="32px"
                      border="none"
                      bg="none"
                      key={`pagination_page_${page}`}
                      page={page}
                      fontSize="sm"
                      _hover={{
                        bg: 'backgroundAccentLight',
                      }}
                      _current={{
                        bg: 'backgroundAccentLight',
                        fontSize: 'sm',
                        color: '#3366FF',
                      }}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext
                  border="none"
                  maxH="32px"
                  maxW="32px"
                  minH="32px"
                  minW="32px"
                  p="0"
                >
                  <Image src="/assets/icons/chevron-right.svg" />
                </PaginationNext>
              </PaginationContainer>
            </Pagination>
          </Flex>
        )}
      </CardContainer>
    </Flex>
  )
}

export default Transactions
