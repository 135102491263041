import { Flex, Image, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

interface IViewSwitcherProps {
  onClick: () => void
  imageUri: string
  title: string
  text: string
}

export const ViewSwitcher: FC<IViewSwitcherProps> = ({ onClick, imageUri, title, text }) => {
  return (
    <Flex
      align="center"
      gap="16px"
      onClick={onClick}
      cursor="pointer"
    >
      <Image src={imageUri} />
      <Flex
        flexDir="column"
        gap="4px"
      >
        <Text
          fontSize="16px"
          color="backgroundAccent"
          fontWeight="600"
        >
          {title}
        </Text>
        <Text
          fontSize="14px"
          color="textSecondary"
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  )
}
