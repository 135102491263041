import { Flex, Grid, Text, Box } from '@chakra-ui/react'
import { ChatWindow } from '../components/pages/Chat/ChatWindow/chatWindow'
import { DetailsTab } from '../components/pages/Chat/ChatWindow/DetailsTab/detailsTab'
import { RightFilter } from '../components/pages/Chat/RightFilter/rightFilter'
import { ThreadsList } from '../components/pages/Chat/ThreadsList/threadsList'
import LeftPanel from '../components/pages/Home/LeftPanel/leftPanel'
import { useChatProvider } from '../provider/Chat/chatProvider'
import { useAccount } from 'wagmi'
import { useLocation } from 'react-router'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'

export const Chat = () => {
  const { selectedThreadId, detailsOpened, openThread } = useChatProvider()
  const { isConnected } = useAccount()

  const wallet = useEthereumProvider()

  const location = useLocation()

  return (
    <Grid
      p={{ base: '0px 16px', md: '0', xl: '0' }}
      gridGap="24px"
      templateColumns={{
        base: '1fr',
        md: selectedThreadId && location.pathname == '/chat/messages' && !detailsOpened ? 'auto 1fr' : 'auto 1fr auto',
      }}
      w="100%"
    >
      <LeftPanel setView={() => {}} />
      {selectedThreadId && isConnected && location.pathname == '/chat/messages' ? (
        <>
          <Box display={{ base: detailsOpened ? 'none' : 'block', md: 'block' }}>
            <ChatWindow />
          </Box>
          {detailsOpened && <DetailsTab />}
        </>
      ) : (
        <>
          {wallet.isAuthed ? (
            <ThreadsList />
          ) : (
            <Flex
              align="center"
              justify="center"
              padding="0px 60px"
              flexDir="column"
            >
              Log in to use chat
            </Flex>
          )}
          <RightFilter />
        </>
      )}
    </Grid>
  )
}
