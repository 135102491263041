import React from 'react'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { useIconProvider } from '../../../../../../provider/Icons/iconsProvider'
import { QuestionOutlineIcon } from '@chakra-ui/icons'

export const UserRolesComponent = ({ role }: { role: string }) => {
  const { roles } = useIconProvider()
  return (
    <Flex
      justifyContent="space-between"
      flexDirection="column"
      sx={{
        '&:not(:last-of-type)': {
          borderBottom: '1px solid borderPrimary',
        },
      }}
    >
      <Flex
        p="12px 16px"
        justify="space-between"
        align="center"
        w="100%"
      >
        <Flex
          gridGap="16px"
          alignItems="center"
        >
          <Box
            alignItems="center"
            justifyContent="center"
            w="40px"
            h="40px"
            display="flex"
            borderRadius="8px"
          >
            <Image src={roles.get(role)} fallback={<QuestionOutlineIcon/>} />
          </Box>
          <Flex alignItems="center">
            <Text color="textQuaternary">{role}</Text>
          </Flex>
        </Flex>
        <Button
          minH="32px"
          minW="32px"
          maxH="32px"
          maxW="32px"
          p="0"
        >
          <Image src="/assets/icons/link-icon.svg" />
        </Button>
      </Flex>
    </Flex>
  )
}

export default UserRolesComponent
