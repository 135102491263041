import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navbar } from '../navbar/navbar'
import { UserPage } from '../../../pages/User'
import { Footer } from '../footer/footer'
import Error from '../../../pages/Error'
import { ScrollToTop } from '../../shared/containers/SctollToTop/sctollToTop'
import { Flex, useColorMode, useColorModePreference, useToast } from '@chakra-ui/react'
import { Dashboard } from '../../../pages/Dashboard'
import Transactions from '../../../pages/Transactions'
import Profiles from '../../../pages/Profiles'
import Transaction from '../../../pages/Transaction'
import { Onboarding } from '../../pages/Onboarding/Onboarding'
import * as React from 'react'
import { useEffect } from 'react'
import { useScrollPosition } from '../../../hooks/utils/useScrollPosition'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { Home } from '../../../pages/Home'
import { Chat } from '../../../pages/Chat'
import { ChatProvider } from '../../../provider/Chat/chatProvider'
import { OnboardingProvider, useOnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import PostPage from '../../pages/PostPage'
import { getNetwork } from '@wagmi/core'
import CryptoTopbar from '../crypto-topbar/CryptoTopbar'
import { RouteWrapper } from './RouteWrapper'
import { useAccount } from 'wagmi'
import MaxWidthWrapper from './maxWidthWrapper'
import { ChatOverlay } from '../chat-overlay/chatOverlay'
import { Search } from '../../../pages/Search'
import { Admin } from '../../../pages/Admin'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { Group } from '../../../pages/Group'
import { Groups } from '../../../pages/Groups'
import { fetchApi } from '../../../utils/fetcher'
import { _log } from '../../../logger'
import { loaded } from '../../../utils/process'
import { GroupPopover } from '../group-popover'
import { ContractsProvider } from '../../../provider/Contracts/contractsProvider'
import LinkForwarder from '../../../pages/LinkForwarder'
import { Licenses } from '../../../pages/Licenses'

export const Wrapper = () => {
  const { setColorMode } = useColorMode()
  const wallet = useEthereumProvider()
  const accountProfile = useAccountProfileProvider()
  const systemColorMode = useColorModePreference()
  const { account: ethereumAccount } = useEthereumProvider()
  const { chain } = getNetwork()
  const account = useAccount()

  const privatePages = [
    {
      path: '/admin',
      element: <Admin />,
    },
  ]

  const pages = [
    {
      path: '/profile/:id',
      element: <UserPage />,
    },
    {
      path: '/group/:id?',
      element: <Group />,
    },
    {
      path: '/groups',
      element: (
        <ContractsProvider>
          <Groups />
        </ContractsProvider>
      ),
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/txs',
      element: <Transactions />,
    },
    {
      path: `/tx/:id`,
      element: <Transaction />,
    },
    {
      path: '/profiles',
      element: <Profiles />,
    },
    {
      path: '/chat/*',
      element: <Chat />,
    },
    {
      path: '/search',
      element: <Search />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/onboarding',
      element: <Onboarding />,
    },
    {
      path: '/post/:id',
      element: <PostPage />,
    },
    {
      path: '/l/:id',
      element: <LinkForwarder />,
    },
    {
      path: '/docs/licenses',
      element: <Licenses />,
    },
    ...(accountProfile.isAdmin ? privatePages : []),
  ].map((element, index) => ({ ...element, id: `${element.path}_${index}` }))

  /** Sync color mode with system */
  useEffect(() => {
    if (!systemColorMode) {
      return
    }

    setColorMode(systemColorMode)
  }, [systemColorMode])

  /** Render network hint */
  useEffect(() => {
    // TODO: Fix
    // if (
    //   !account.connector ||
    //   !account.isConnected ||
    //   wallet.isConnecting ||
    //   (chain && !chain.unsupported && Object.values(ENetwork).includes(chain.id))
    // ) {
    //   toast.close('error_network')
    //   return
    // }
    // if (!toast.isActive('error_network')) {
    //   toast({ duration: null, render: () => <NetworkAlert />, id: 'error_network' })
    // }
  }, [chain?.id, account.isConnected, wallet.isConnecting, account.connector])

  return (
    <Flex
      flexWrap="wrap"
      minH="100vh"
      direction="column"
      justifyContent="flex-start"
    >
      <BrowserRouter>
        <OnboardingProvider>
          <CryptoTopbar />
          <Navbar />
          {wallet.isAuthed && <ChatOverlay />}

          <ScrollToTop>
            <MaxWidthWrapper
              disabledPages={['/onboarding']}
              maxW="1180px"
            >
              <RouteWrapper>
                <Routes>
                  {pages.map(({ path, element, id }) => (
                    <Route
                      path={path}
                      element={element}
                      key={id}
                    />
                  ))}
                  <Route
                    path="*"
                    element={<Error />}
                    key="*"
                  />
                </Routes>
              </RouteWrapper>
            </MaxWidthWrapper>
          </ScrollToTop>

          <Footer />
          <GroupPopover />
        </OnboardingProvider>
      </BrowserRouter>
    </Flex>
  )
}
