import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Image,
  Link as ChakraLink,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { OverviewChart } from '../../../../../../User/UserOverview/OverviewChart'
import { Colors } from '../../../../../../../../theme/colors'
import { trimAddress } from '../../../../../../../../utils/parser'
import useAsyncEffect from '../../../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../../../utils/process'
import { fetchApi } from '../../../../../../../../utils/fetcher'
import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { IProfile } from '../../../../../../../../provider/Profile/profileProvider'
import { useClipboard } from '@chakra-ui/react'
import { UserTag } from '../../../../../../../shared/tags/UserTag'
import { useIconProvider } from '../../../../../../../../provider/Icons/iconsProvider'
import { PostType } from '../../../../deafult/Default'
import { usePostProvider } from '../../../../../../../../provider/PostProvider/postProvider'
import SpinnerText from '../../../../../../../shared/tags/SpinnerText'
import { StatDetail } from './StatDetail'
import { Address } from '../../../../../../../../contracts/address'

export enum EventType {
  RewardClaimed = 'RewardClaimed',
  WinnerAnnounced = 'WinnerAnnounced',
  Join = 'Join',
  Invite = 'Invite',
  Power = 'Power',
  Reply = 'Reply',
}

type Activity = {
  event: EventType
  value: string
  from: string
  to: string
  date: string
}

type PostStats = {
  replies: number
  likes: number
  shares: number
}

interface IPostDetails {
  isOpen: boolean
  onClose: () => void
  author: {
    name: IProfile['name']
    address: IProfile['address']
  }
}

export default function PostDetails({ isOpen, onClose, author }: IPostDetails) {
  const { type, ipfs, target } = usePostProvider()

  const [selectedFilter, setSelectedFilter] = useState<'All' | EventType>('All')
  const [availableFilters, setAvailableFilters] = useState<any[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [profiles, setProfiles] = useState<Map<IProfile['address'], IProfile['name']>>(new Map())
  const [rolesVisible, setRolesVisible] = useState(false)
  const [interestsVisible, setInterestsVisible] = useState(false)
  const toast = useToast()

  const [stats, setStats] = useState<PostStats>({ likes: 0, replies: 0, shares: 0 })
  const { onCopy } = useClipboard(ipfs)
  useAsyncEffect(async () => {
    await loaded(async () => {
      const res = await fetchApi(`post/activity`, { ipfs })
      if (res.activity !== undefined) {
        const addresses: string[] = []
        res.activity.forEach((ac: Activity) => {
          if (!addresses.includes(ac.from)) {
            addresses.push(ac.from)
          }
          if (!addresses.includes(ac.to)) {
            addresses.push(ac.to)
          }
        })

        const profiles: IProfile[] = await fetchApi('profile', { addresses })
        const profilesMap = new Map<IProfile['address'], IProfile['name']>()

        profiles.forEach((p: IProfile) => {
          profilesMap.set(p.address_[0], p.name)
        })
        setProfiles(profilesMap)
        setActivities(res.activity.reverse())
      }
    })
  }, [ipfs])

  useAsyncEffect(async () => {
    await loaded(async () => {
      const res: PostStats = await fetchApi('post/stats', { ipfs })
      setStats(res)
    })
  }, [ipfs])

  useEffect(() => {
    const filters = activities.map(ac => ac.event)
    setAvailableFilters(['All', ...new Set(filters)])
  }, [activities])

  //const { roles, interests } = useIconProvider()

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            fontWeight="600"
            fontSize="16px"
          >
            Post details
          </Text>
        </ModalHeader>
        <ModalCloseButton marginTop="6px">
          <Image
            src="/assets/icons/close-dark.svg"
            boxSize="16px"
            onClick={onClose}
          />
        </ModalCloseButton>
        <Divider orientation="horizontal" />
        <Flex
          p="24px"
          direction="column"
          gap="24px"
          width="auto"
        >
          <Flex
            flexDir="column"
            gap="8px"
          >
            <Flex
              width="full"
              borderWidth="1px"
              borderRadius="8px"
              borderColor="borderPrimary"
              backgroundColor="backgroundTertiary"
              p="16px"
              justify="space-between"
            >
              <Flex direction="column">
                <Box
                  display="flex"
                  alignItems="center"
                  gap="4px"
                >
                  <Text
                    color="textSecondary"
                    fontWeight="400"
                    fontSize="12"
                  >
                    Creator
                  </Text>
                  <ChakraLink
                    href={`/profile/${author.address}`}
                    color="#3366FF"
                    fontWeight="500"
                    fontSize="12"
                    textDecoration="none"
                  >
                    {author.name}
                  </ChakraLink>
                  {author.name && <Image src="/assets/icons/verified.svg" />}
                </Box>
                <Text
                  mt="16px"
                  fontSize="18px"
                  fontWeight="600"
                  cursor="pointer"
                  color="backgroundAccent"
                  onClick={() => {
                    onCopy()
                    toast({ title: 'Copied successfully', status: 'info' })
                  }}
                >
                  #{ipfs.slice(7, 17)}...
                </Text>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="4px"
                >
                  <Text
                    color="textSecondary"
                    fontWeight="400"
                    fontSize="12"
                  >
                    Owned by
                  </Text>
                  <ChakraLink
                    href={`/profile/${author.address}`}
                    color="#3366FF"
                    fontWeight="500"
                    fontSize="12"
                  >
                    {author.name}
                  </ChakraLink>
                </Box>
              </Flex>
              {type !== PostType.Post && (
                <Flex sx={{ flexDir: 'column', gap: '8px', alignItems: 'end' }}>
                  <Flex
                    as="span"
                    sx={{ gap: '4px', alignItems: 'center' }}
                  >
                    <Text sx={{ fontSize: '12px', color: '#696F8C' }}>Post type:</Text>
                    <Text sx={{ fontSize: '12px', color: '#101840', fontWeight: '500' }}>
                      {type === PostType.Task
                        ? 'Task'
                        : type === PostType.Quest
                        ? 'Quest'
                        : type === PostType.Transfer
                        ? 'Transfer'
                        : type === PostType.Job
                        ? 'Job'
                        : ''}
                    </Text>
                  </Flex>
                  <Flex
                    as="span"
                    sx={{ gap: '4px', alignItems: 'center' }}
                  >
                    <Text sx={{ fontSize: '12px', color: '#696F8C' }}>Contract:</Text>
                    {/* TODO: Get address from somewhere */}
                    <Text sx={{ fontSize: '12px', color: '#36F', fontWeight: '500' }}>
                      {trimAddress(Address.QUEST)}
                    </Text>
                    <Link
                      href="https://bscscan.com/address/0x82bE056c76970e36bB7a4CE7dAA2692cB1538959"
                      target="_blank"
                    >
                      <Image src="/assets/icons/share.svg" />
                    </Link>
                  </Flex>
                </Flex>
              )}
            </Flex>
            {/* {type === PostType.Post && (
              <>
                <Flex
                  border="1px solid"
                  borderColor="borderPrimary"
                  borderRadius="8px"
                  bg="backgroundTertiary"
                  flexDir="column"
                  p="16px"
                  gap="8px"
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="space-between"
                  >
                    <Flex
                      as="span"
                      color="textSecondary"
                      fontSize="12px"
                    >
                      Role targeting:&nbsp;
                      <Text
                        as="span"
                        color="textPrimary"
                      >
                        {target?.roles?.length}
                      </Text>
                    </Flex>
                    <Image
                      src="/assets/icons/chevron-down-small.svg"
                      cursor="pointer"
                      onClick={() => setRolesVisible(prev => !prev)}
                      transform={rolesVisible ? 'rotate(180deg)' : ''}
                    />
                  </Flex>
                  {rolesVisible && (
                    <Flex
                      flexWrap="wrap"
                      gap="4px"
                    >
                      {target?.roles?.map(role => (
                        <UserTag
                          text={role}
                          imageUri={roles.get(role)}
                        />
                      ))}
                    </Flex>
                  )}
                </Flex>
                <Flex
                  border="1px solid"
                  borderColor="borderPrimary"
                  borderRadius="8px"
                  bg="backgroundTertiary"
                  flexDir="column"
                  p="16px"
                  gap="8px"
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="space-between"
                  >
                    <Flex
                      as="span"
                      color="textSecondary"
                      fontSize="12px"
                    >
                      Interest targeting:&nbsp;
                      <Text
                        as="span"
                        color="textPrimary"
                      >
                        {target?.interests?.length}
                      </Text>
                    </Flex>
                    <Image
                      src="/assets/icons/chevron-down-small.svg"
                      cursor="pointer"
                      onClick={() => setInterestsVisible(prev => !prev)}
                      transform={interestsVisible ? 'rotate(180deg)' : ''}
                    />
                  </Flex>
                  {interestsVisible && (
                    <Flex
                      flexWrap="wrap"
                      gap="4px"
                    >
                      {target?.interests?.map(interest => (
                        <UserTag
                          text={interest}
                          imageUri={interests.get(interest)}
                        />
                      ))}
                    </Flex>
                  )}
                </Flex>
              </>
            )} */}
          </Flex>
          <Flex
            width="full"
            borderWidth="1px"
            borderRadius="8px"
            borderColor="borderPrimary"
            p="24px"
            sx={{ flexDirection: 'column', gap: '16px' }}
          >
            <Flex sx={{ w: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
              <Flex sx={{ flexDirection: 'column' }}>
                {/* TODO: if post type is quest/task etc. -> display stats for joined and invited instead of shares and replies */}
                <Text sx={{ color: '#101840', fontWeight: '600', fontSize: '24px' }}>
                  {stats.likes + stats.replies + stats.shares}
                </Text>
                <Text sx={{ color: '#696F8C', fontSize: '12px' }}>
                  {type === PostType.Post ? 'Replies, Shares' : 'Replies, Joined, Invited'}
                </Text>
              </Flex>
              <Flex sx={{ flexDirection: 'column' }}>
                <Flex
                  as="span"
                  sx={{ color: '#101840', fontWeight: '600', fontSize: '24px' }}
                >
                  {/* TODO: Get power from somewhere */}
                  <SpinnerText />
                </Flex>
                <Text sx={{ color: '#696F8C', fontSize: '12px' }}>Power</Text>
              </Flex>
            </Flex>
            {type === PostType.Post ? (
              <Grid
                templateColumns="1fr 1fr 1fr"
                sx={{ gap: '8px' }}
              >
                <StatDetail
                  label="Replies"
                  data={stats?.replies}
                />
                <StatDetail
                  label="Shares"
                  data={stats?.shares}
                />
              </Grid>
            ) : (
              <Grid
                templateColumns="1fr 1fr 1fr"
                sx={{ gap: '8px' }}
              >
                <StatDetail
                  label="Replies"
                  data={stats.replies}
                />
                <StatDetail label="Joined" />
                <StatDetail label="Invited" />
              </Grid>
            )}
          </Flex>
          <Box
            display="flex"
            flexDirection="column"
            width="auto"
            borderWidth="1px"
            borderRadius="8px"
            borderColor="borderPrimary"
          >
            <Container p="12px">
              <Text
                fontWeight="600"
                fontSize="16px"
              >
                Post Activity
              </Text>
            </Container>
            <Divider orientation="horizontal" />
            <Flex
              alignItems="center"
              py="12px"
              px="14px"
              gap="8px"
              justifyContent="flex-start"
            >
              {availableFilters.map((av, idx) => (
                <Box
                  key={`${idx}-${av}`}
                  px="8px"
                  py="2px"
                  background={selectedFilter === av ? 'bluePlaceholder' : 'backgroundTertiary'}
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="#EDEFF5"
                  cursor="pointer"
                  onClick={() => setSelectedFilter(av)}
                >
                  <Text
                    color="textQuaternary"
                    fontSize="14px"
                  >
                    {av}: {av === 'All' ? activities.length : activities.filter(ac => ac.event === av).length}
                  </Text>
                </Box>
              ))}
            </Flex>
            <Divider orientation="horizontal" />
            <Flex
              alignItems="center"
              justifyContent="space-around"
              gap="10px"
              bgColor="borderPrimary"
              borderRadius="8px"
              fontSize="10px"
              color="textSecondary"
              textAlign="left"
              pl="20px"
              borderTop="1px solid borderPrimary"
            >
              <Box
                border="0px"
                fontSize="10px"
                color="textSecondary"
                fontWeight="600"
                py="14px"
                width="100%"
              >
                EVENT
              </Box>
              <Box
                border="0px"
                fontSize="10px"
                color="textSecondary"
                fontWeight="600"
                width="100%"
              >
                VALUE
              </Box>
              <Box
                border="0px"
                fontSize="10px"
                color="textSecondary"
                fontWeight="600"
                width="100%"
              >
                FROM
              </Box>
              <Box
                border="0px"
                fontSize="10px"
                color="textSecondary"
                fontWeight="600"
                width="100%"
              >
                TO
              </Box>
              <Box
                border="0px"
                fontSize="10px"
                color="textSecondary"
                fontWeight="600"
                width="100%"
              >
                DATE
              </Box>
            </Flex>
            <Flex
              direction="column"
              maxHeight="220px"
              overflowY="scroll"
            >
              {(selectedFilter === 'All'
                ? activities
                : activities.filter(activity => activity.event === selectedFilter)
              ).map((ac: Activity, idx) => (
                <Row
                  profiles={profiles}
                  activity={ac}
                  key={idx + ac.date}
                  borderBottom={idx === activities?.length - 1}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

const Row: FC<{
  activity: Activity
  borderBottom: boolean
  profiles: Map<IProfile['address'], IProfile['name']>
}> = ({ activity, borderBottom, profiles }) => {
  return (
    <Flex
      // borderBottom={borderBottom ? '0px' : '1px'}
      // borderColor="borderPrimary"
      alignItems="center"
      justifyContent="space-around"
      gap="10px"
      fontSize="10px"
      color="textSecondary"
      textAlign="left"
      pl="20px"
      borderTop="1px solid borderPrimary"
    >
      <Box
        py="16px"
        color="textQuaternary"
        fontSize="12px"
        fontWeight="400"
        border="0px"
        width="100%"
      >
        {/* {(activity.event as string)[0].toUpperCase() + (activity.event as string).slice(1, activity.event.length)} */}
        {activity.event}
      </Box>
      <Box
        py="16px"
        color={
          activity.event === EventType.Power
            ? Number(activity.value) > 0
              ? '#429777'
              : Number(activity.value) < 0
              ? '#D14343'
              : 'textQuaternary'
            : 'textQuaternary'
        }
        fontSize="12px"
        fontWeight="400"
        border="0px"
        width="100%"
        //
      >
        {activity.event === EventType.Power && (+activity.value > 0 ? '+' : '')}
        {activity.event === EventType.Power ? Number((+activity.value / 10 ** 114).toFixed(4)) * 1 : activity.value}
      </Box>
      <Box
        py="16px"
        color="#3366FF"
        fontSize="12px"
        fontWeight="400"
        border="0px"
        width="100%"
      >
        <ChakraLink
          style={{ color: 'textBlue' }}
          href={`/profile/${activity.from}`}
          target="_blank"
        >
          {profiles.get(activity.from as address)}
        </ChakraLink>
      </Box>
      <Box
        py="16px"
        color="#3366FF"
        fontSize="12px"
        fontWeight="400"
        border="0px"
        width="100%"
      >
        {activity.to !== '-' ? (
          <ChakraLink
            style={{ color: 'textBlue' }}
            href={`/profile/${activity.to}`}
            target="_blank"
          >
            {profiles.get(activity.to as address)}
          </ChakraLink>
        ) : (
          <Text>{activity.to}</Text>
        )}
      </Box>
      <Box
        py="16px"
        color="textQuaternary"
        fontSize="12px"
        fontWeight="400"
        width="100%"
        border="0px"
      >
        {moment.unix(Number(activity.date)).fromNow()}
      </Box>
    </Flex>
  )
}
