import { Icon, useColorMode } from '@chakra-ui/react'
import useColor from '../../hooks/chakra/useColor'

interface IProps {
  color?: string
}

export const DotsGridIcon = ({ color }: IProps) => {
  const color_ = useColor(color)

  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color_}
    >
      <g clipPath="url(#clip0_266_9839)">
        <path
          d="M2.66666 3.33341C2.66666 3.51023 2.73689 3.67979 2.86192 3.80482C2.98694 3.92984 3.15651 4.00008 3.33332 4.00008C3.51013 4.00008 3.6797 3.92984 3.80473 3.80482C3.92975 3.67979 3.99999 3.51023 3.99999 3.33341C3.99999 3.1566 3.92975 2.98703 3.80473 2.86201C3.6797 2.73699 3.51013 2.66675 3.33332 2.66675C3.15651 2.66675 2.98694 2.73699 2.86192 2.86201C2.73689 2.98703 2.66666 3.1566 2.66666 3.33341Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33334 3.33341C7.33334 3.51023 7.40358 3.67979 7.52861 3.80482C7.65363 3.92984 7.8232 4.00008 8.00001 4.00008C8.17682 4.00008 8.34639 3.92984 8.47141 3.80482C8.59644 3.67979 8.66668 3.51023 8.66668 3.33341C8.66668 3.1566 8.59644 2.98703 8.47141 2.86201C8.34639 2.73699 8.17682 2.66675 8.00001 2.66675C7.8232 2.66675 7.65363 2.73699 7.52861 2.86201C7.40358 2.98703 7.33334 3.1566 7.33334 3.33341Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 3.33341C12 3.51023 12.0702 3.67979 12.1953 3.80482C12.3203 3.92984 12.4899 4.00008 12.6667 4.00008C12.8435 4.00008 13.013 3.92984 13.1381 3.80482C13.2631 3.67979 13.3333 3.51023 13.3333 3.33341C13.3333 3.1566 13.2631 2.98703 13.1381 2.86201C13.013 2.73699 12.8435 2.66675 12.6667 2.66675C12.4899 2.66675 12.3203 2.73699 12.1953 2.86201C12.0702 2.98703 12 3.1566 12 3.33341Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66666 7.99992C2.66666 8.17673 2.73689 8.3463 2.86192 8.47132C2.98694 8.59635 3.15651 8.66659 3.33332 8.66659C3.51013 8.66659 3.6797 8.59635 3.80473 8.47132C3.92975 8.3463 3.99999 8.17673 3.99999 7.99992C3.99999 7.82311 3.92975 7.65354 3.80473 7.52851C3.6797 7.40349 3.51013 7.33325 3.33332 7.33325C3.15651 7.33325 2.98694 7.40349 2.86192 7.52851C2.73689 7.65354 2.66666 7.82311 2.66666 7.99992Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33334 7.99992C7.33334 8.17673 7.40358 8.3463 7.52861 8.47132C7.65363 8.59635 7.8232 8.66659 8.00001 8.66659C8.17682 8.66659 8.34639 8.59635 8.47141 8.47132C8.59644 8.3463 8.66668 8.17673 8.66668 7.99992C8.66668 7.82311 8.59644 7.65354 8.47141 7.52851C8.34639 7.40349 8.17682 7.33325 8.00001 7.33325C7.8232 7.33325 7.65363 7.40349 7.52861 7.52851C7.40358 7.65354 7.33334 7.82311 7.33334 7.99992Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7.99992C12 8.17673 12.0702 8.3463 12.1953 8.47132C12.3203 8.59635 12.4899 8.66659 12.6667 8.66659C12.8435 8.66659 13.013 8.59635 13.1381 8.47132C13.2631 8.3463 13.3333 8.17673 13.3333 7.99992C13.3333 7.82311 13.2631 7.65354 13.1381 7.52851C13.013 7.40349 12.8435 7.33325 12.6667 7.33325C12.4899 7.33325 12.3203 7.40349 12.1953 7.52851C12.0702 7.65354 12 7.82311 12 7.99992Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66666 12.6667C2.66666 12.8435 2.73689 13.013 2.86192 13.1381C2.98694 13.2631 3.15651 13.3333 3.33332 13.3333C3.51013 13.3333 3.6797 13.2631 3.80473 13.1381C3.92975 13.013 3.99999 12.8435 3.99999 12.6667C3.99999 12.4899 3.92975 12.3203 3.80473 12.1953C3.6797 12.0702 3.51013 12 3.33332 12C3.15651 12 2.98694 12.0702 2.86192 12.1953C2.73689 12.3203 2.66666 12.4899 2.66666 12.6667Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.33334 12.6667C7.33334 12.8435 7.40358 13.013 7.52861 13.1381C7.65363 13.2631 7.8232 13.3333 8.00001 13.3333C8.17682 13.3333 8.34639 13.2631 8.47141 13.1381C8.59644 13.013 8.66668 12.8435 8.66668 12.6667C8.66668 12.4899 8.59644 12.3203 8.47141 12.1953C8.34639 12.0702 8.17682 12 8.00001 12C7.8232 12 7.65363 12.0702 7.52861 12.1953C7.40358 12.3203 7.33334 12.4899 7.33334 12.6667Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12.6667C12 12.8435 12.0702 13.013 12.1953 13.1381C12.3203 13.2631 12.4899 13.3333 12.6667 13.3333C12.8435 13.3333 13.013 13.2631 13.1381 13.1381C13.2631 13.013 13.3333 12.8435 13.3333 12.6667C13.3333 12.4899 13.2631 12.3203 13.1381 12.1953C13.013 12.0702 12.8435 12 12.6667 12C12.4899 12 12.3203 12.0702 12.1953 12.1953C12.0702 12.3203 12 12.4899 12 12.6667Z"
          stroke={color_}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_266_9839">
          <rect
            width="16"
            height="16"
            fill="white"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
