import { Flex, Image, Text, useToast } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import React from 'react'
import { copyToClipboard } from '../../../../utils/os'
import { trimAddress } from '../../../../utils/parser'

interface IProps {
  address: address
  hideImage?: boolean
  isDisabled?: boolean
}

export const AddressEntry = ({ address, hideImage, isDisabled }: IProps) => {
  const toast = useToast()

  const handleCopyAddress = async () => {
    await copyToClipboard(address)
    toast({ title: `Copied!` })
  }

  return (
    <Link
      style={{
        width: 'auto',
        padding: '8px',
        borderRadius: '8px',
        border: '1px solid',
        borderColor: '#DBDAE5',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '8px',
        fontSize: '12px',
        display: 'flex',
        ...(isDisabled ? { cursor: 'not-allowed', opacity: '.5' } : {}),
      }}
      to={isDisabled ? '' : `/profile/${address}`}
    >
      <Flex
        align="center"
        gap="8px"
      >
        {!hideImage && (
          <Image
            src="/assets/icons/bnbIcon-gold.svg"
            boxSize="24px"
          />
        )}
        <Image src="/assets/icons/wallet-icon.svg" />
        <Text color="textPrimary">{trimAddress(address, 10)}</Text>
      </Flex>
      <Flex
        align="center"
        justify="center"
        boxSize="16px"
        p="0"
        bgColor="borderPrimary"
        borderRadius="4px"
      >
        <Image
          src="/assets/icons/link-icon.svg"
          cursor="pointer"
          onClick={handleCopyAddress}
        />
      </Flex>
    </Link>
  )
}
