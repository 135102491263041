import { Button, Flex, Text } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { UserAvatar } from '../../../shared/avatars/UserAvatar'
import { IInvitator, Stage } from '../Onboarding'
import { Avatar } from '../../../shared/avatars/Avatar'

interface ICreateProfileProps {
  setStage: Dispatch<SetStateAction<Stage>>
  invitator: IInvitator
}

export const InvitationSuccess: FC<ICreateProfileProps> = ({ setStage, invitator }) => {
  return (
    <Flex
      align="center"
      flexDir="column"
      gap="32px"
      w="100%"
      justify="center"
    >
      <Avatar
        name={invitator.name}
        bgColor="bluePlaceholder"
        image={invitator.avatar}
        size="64px"
        textSize={{ short: '32px', long: '24px' }}
      />
      <Flex
        flexDir="column"
        align="center"
      >
        <Text
          fontSize="14px"
          color="textQuaternary"
        >
          You have been invited by
        </Text>
        <Text
          fontSize="16px"
          fontWeight="600"
          color="backgroundAccent"
        >
          {invitator.name}
        </Text>
      </Flex>
      <Button
        variant="dark"
        onClick={() => setStage(Stage.PHONE_VERIFICATION)}
        bgColor="textBlue"
        fontSize="12px"
        color="backgroundMain"
      >
        Continue
      </Button>
    </Flex>
  )
}
