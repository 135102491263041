import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import { Badge, Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import { UserTag } from '../../../../shared/tags/UserTag'
import { useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'
import { useIconProvider } from '../../../../../provider/Icons/iconsProvider'
import { buildIpfsGateway } from '../../../../../utils/parser'
import intersection from 'lodash/intersection'

interface ISummary {
  setStage: Dispatch<SetStateAction<Stage>>
}

export default function Summary({ setStage }: ISummary) {
  const { username, description, roles, interests, images, totalEsimtatedGas } = useUserOnboardingProvider()
  const [files, setFiles] = useState<any>([])
  const { name } = useUserOnboardingProvider()
  const { getRootProps, getInputProps } = useDropzone({
    // @ts-ignore
    accept: { image: '*' },
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))
      const allFiles = [...files, ...newFiles]
      setFiles(allFiles)
    },
  })

  const { interests: interestsContext, roles: rolesContext } = useIconProvider()

  const dropzoneRef = useRef(null!)

  const handlePicker = () => {
    if (dropzoneRef.current) {
      // @ts-ignore
      dropzoneRef.current.click()
    }
  }
  return (
    <>
      <Heading
        step={6}
        heading="Summary"
        subheading="Short summary of your costs (gas on blockchain)."
      />
      <Flex
        direction="column"
        px="24px"
        gap="16px"
      >
        <Box>
          <Text
            fontSize="12px"
            fontWeight="500"
            color="textSecondary"
          >
            Profile Name
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="textQuaternary"
          >
            {name}
          </Text>
        </Box>
        <Box>
          <Text
            fontSize="12px"
            fontWeight="500"
            color="textSecondary"
          >
            Profile nickname
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="textQuaternary"
          >
            {username}
          </Text>
        </Box>
        <Box>
          <Text
            fontSize="12px"
            fontWeight="500"
            color="textSecondary"
          >
            Description
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="textQuaternary"
          >
            {description}
          </Text>
        </Box>
        <Box>
          <Text
            fontSize="12px"
            fontWeight="500"
            color="textSecondary"
          >
            Interests
          </Text>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="4px"
            mt="2px"
            flexWrap="wrap"
          >
            {interests.slice(0, 4).map((interest: string, idx: number) => {
              return (
                <UserTag
                  text={interest}
                  key={idx}
                  imageUri={interestsContext.get(interest)}
                  colorBg="#E7E4F9"
                  colorFg="#524988"
                />
              )
            })}
            <Text
              fontSize="12px"
              color="textQuaternary"
            >
              {interests.length > 4 && `+${interests.length - 4} interests`}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text
            fontSize="12px"
            fontWeight="500"
            color="textSecondary"
          >
            Roles
          </Text>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="4px"
            mt="2px"
            flexWrap="wrap"
          >
            {roles.slice(0, 4).map((role: string, idx: number) => {
              return (
                <UserTag
                  text={role}
                  key={idx}
                  imageUri={rolesContext.get(role)}
                  colorBg="#E7E4F9"
                  colorFg="#524988"
                />
              )
            })}
            <Text
              fontSize="12px"
              color="textQuaternary"
            >
              {roles.length > 4 && `+${roles.length - 4} roles`}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text
            fontSize="12px"
            fontWeight="500"
            color="textSecondary"
          >
            Photos
          </Text>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap="16px"
          >
            {images.map((file: any, idx: number) => {
              return (
                <Flex
                  bgColor="borderPrimary"
                  borderRadius="8px"
                  key={file}
                >
                  <Image
                    width="114px"
                    height="114px"
                    src={buildIpfsGateway(file)}
                    objectFit="contain"
                    borderRadius="8px"
                  />
                </Flex>
              )
            })}
            <Box
              width="114px"
              height="114px"
              borderStyle="dashed"
              borderColor="#D8DAE5"
              borderWidth="1px"
              borderRadius="8px"
              display="grid"
              placeItems="center"
              cursor="pointer"
              onClick={handlePicker}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Badge
                background="backgroundAccentLight"
                color="#3366FF"
                fontWeight="500"
                px="8px"
                py="4px"
                textTransform="none"
              >
                Add picture
              </Badge>
            </Box>
          </Grid>
        </Box>
      </Flex>
      <Footer
        action={() => setStage(Stage.PAYMENT)}
        goBack={() => setStage(Stage.INTERESTS)}
        estimatedCost={`${totalEsimtatedGas} BNB`}
      />
    </>
  )
}
