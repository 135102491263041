import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import { Box, FormControl, FormLabel, Input, Text, Textarea } from '@chakra-ui/react'
import { useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'

interface IInfo {
  setStage: Dispatch<SetStateAction<Stage>>
  estimateGas: () => Promise<string>
}

export default function Info({ setStage, estimateGas }: IInfo) {
  const { username, setUsername, description, setDescription, totalEsimtatedGas, setTotalEstimatedGas } =
    useUserOnboardingProvider()

  const [textAreaSize, setTextAreaSize] = useState<number>(description?.length ?? 0)
  const { name, setName } = useUserOnboardingProvider()
  const [isEstimationLoading, setIsEstimationLoading] = useState<boolean>(false)

  const [error, setError] = useState('')

  useEffect(() => {
    setIsEstimationLoading(true)
    estimateGas().then((res: string) => {
      setTotalEstimatedGas(res)
      setIsEstimationLoading(false)
    })
  }, [name, username, description])

  return (
    <>
      <Heading
        step={3}
        heading="Your personal info"
        subheading="Choose your onChain username, profile name and write something about yourself."
      />
      <FormControl px="24px">
        <FormLabel>
          <Text
            fontSize="14px"
            color="backgroundAccent"
            fontWeight="500"
          >
            Profile name
          </Text>
        </FormLabel>
        <Input
          type="text"
          value={name}
          fontSize="14px"
          color="textQuaternary"
          onChange={e => setName(e.target.value)}
        />
        <FormLabel mt="24px">
          <Text
            fontSize="14px"
            color="backgroundAccent"
            fontWeight="500"
          >
            OnChain username
          </Text>
        </FormLabel>
        <Input
          type="text"
          defaultValue={username}
          fontSize="14px"
          color="textQuaternary"
          onChange={e => setUsername(e.target.value)}
        />
      </FormControl>
      <Box
        mt="24px"
        px="24px"
      >
        <Text
          fontSize="14px"
          color="backgroundAccent"
          fontWeight="500"
        >
          Description
        </Text>
        <Text
          fontSize="14px"
          color="textSecondary"
          fontWeight="400"
        >
          Write at least 30 letter description
        </Text>
        <Box
          mt="8px"
          position="relative"
        >
          <Textarea
            placeholder="Here is a sample placeholder"
            defaultValue={description}
            size="sm"
            maxLength={100}
            onChange={e => {
              setTextAreaSize(e.target.value.length)
              setDescription(e.target.value)
            }}
          />
          <Box
            w="full"
            position="absolute"
            bottom="0"
            left="0"
            borderTopColor="#D8DAE5"
            borderTopWidth="1px"
          >
            <Text
              fontSize="12px"
              color="#8F95B2"
              px="11px"
              py="4px"
            >
              {formatNumber(textAreaSize)}/100
            </Text>
          </Box>
        </Box>
      </Box>
      {error?.length > 0 && (
        <Text
          color="redPrimary"
          fontSize="12px"
          mt="24px"
          px="24px"
        >
          {error}
        </Text>
      )}
      <Footer
        estimatedCost={`${totalEsimtatedGas} BNB`}
        isLoading={isEstimationLoading}
        action={() => {
          if (description?.length >= 30) {
            if (name?.length > 0) {
              setError('')
              setStage(Stage.ROLES)
            } else setError('Your name cannot be empty.')
          } else setError('Your description should be at least 30 characters long.')
        }}
        goBack={() => setStage(Stage.PHOTOS)}
      />
    </>
  )
}

function formatNumber(num: number): string {
  if (num == 0) {
    return num.toString()
  }

  if (num < 10) {
    return `00${num}`
  } else if (num < 100) {
    return `0${num}`
  } else {
    return `${num}`
  }
}
