import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import { AccountsTab } from './tabs/AccountsTab'
import { GeneralTab } from './tabs/GeneralTab'
import { InterestsTab } from './tabs/InterestsTab'
import { RolesTab } from './tabs/RolesTab'
import { useContractsProvider } from '../../../../provider/Contracts/contractsProvider'
import { loaded } from '../../../../utils/process'
import { ContractAlert } from '../../../global/wrapper/alerts/ContractAlert'
import { trimAddress } from '../../../../utils/parser'
import { useNavigate } from 'react-router-dom'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { fetchApi } from '../../../../utils/fetcher'
import { waitForTransaction } from '@wagmi/core'

interface IUserEditModal {
  isOpen: boolean
  onClose: () => void
}

export type Tab = {
  name: string
  component: React.ReactNode
}

export const UserEditModal: FC<IUserEditModal> = ({ isOpen, onClose }) => {
  const data = useAccountProfileProvider()

  const [name, setName] = useState<string>(data.name)
  const [username, setUsername] = useState<string>(data.nickname)
  const [description, setDescription] = useState<string>(data.description)
  const [location, setLocation] = useState<string>(data.location)
  const [avatar, setAvatar] = useState<string>(data.avatar)
  const [background, setBackground] = useState<string>(data.background)
  const [roles, setRoles] = useState<string[]>(data.roles ?? [])
  const [interests, setInterests] = useState<string[]>(data.interests ?? [])

  const [error, setError] = useState('')

  const [isProcessing, setIsProcessing] = useState(false)
  const [txHash, setTxHash] = useState<txHash>()
  const [ipfsId, setIpfsId] = useState<string>()

  const { address_ } = useAccountProfileProvider()
  const contracts = useContractsProvider()
  const toast = useToast()
  const history = useNavigate()
  const wallet = useEthereumProvider()

  const handleSubmit = async () => {
    if (name?.length > 0) {
      if (description?.length >= 30) {
        setError('')
        const _data = [
          address_,
          name ?? '',
          '' /** @deprecated */,
          username ?? '',
          '' /** @deprecated */,
          [] /** @deprecated */,
          description ?? '',
          location ?? '',
          avatar ?? '',
          null /** TODO image array */ ?? [],
          background /** TODO bg image */ ?? '',
          null /** TODO sex constant */ ?? 0,
        ]
        const _dataExternal = [roles ?? [], interests ?? []]
        const _mask = [
          true,
          name !== data.name,
          false,
          username !== data.nickname,
          false,
          false,
          description !== data.description,
          location !== data.location,
          avatar !== data.avatar,
          false,
          background !== data.background,
          false,
          roles !== data.roles,
          interests !== data.interests,
        ]

        /** Blockchain path */
        await loaded(
          async () => {
            const tx = await contracts.id?.write.setId([_data, _dataExternal, _mask])

            /** IPFS path */
            void (async () =>
              setIpfsId(
                (
                  await fetchApi('id/upload', {
                    name,
                    nickname: username,
                    description,
                    location,
                    avatar,
                    background,
                  })
                ).path
              ))()

            toast({
              render: () => (
                <ContractAlert
                  content={trimAddress(address_?.[0])!}
                  class_="CandaoId"
                  action={{
                    call: () => history(`/tx/${tx}`),
                    description: 'See transaction',
                  }}
                />
              ),
            })
          },
          setIsProcessing,
          () =>
            toast({
              title: `[CandaoId]: Please, check your wallet connection`,
              status: 'error',
            })
        )
      } else {
        setError('Your description should be at least 30 characters long.')
      }
    } else {
      setError('Your name cannot be empty.')
    }
  }

  const tabs = [
    {
      name: 'General',
      component: (
        <GeneralTab
          setName={setName}
          setUsername={setUsername}
          setDescription={setDescription}
          setLocation={setLocation}
          setBackground={setBackground}
          setAvatar={setAvatar}
        />
      ),
    },
    {
      name: 'Roles',
      component: (
        <RolesTab
          roles={roles}
          setRoles={setRoles}
        />
      ),
    },
    {
      name: 'Interests',
      component: (
        <InterestsTab
          interests={interests}
          setInterests={setInterests}
        />
      ),
    },
    { name: 'Accounts', component: <AccountsTab /> },
  ]

  useEffect(() => {
    setName(data.name)
    setUsername(data.nickname)
    setDescription(data.description)
    setLocation(data.location)
    setAvatar(data.avatar)
    setBackground(data.background)
    setRoles(data.roles)
    setInterests(data.interests)
  }, [isOpen])

  /** Yield IPFS resource; TODO: this is to be uploaded on OnChainRef */
  useEffect(() => {
    if (!ipfsId) {
      return
    }

    console.info(ipfsId)
  }, [ipfsId])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setError('')
        onClose()
      }}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent
        mt={{ base: '72px', sm: '7vh' }}
        position="relative"
        bgColor="backgroundMain"
        color="backgroundAccent"
        mx="10px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        {isProcessing && (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            background="rgba(0, 0, 0, 0.4)"
            display="grid"
            placeItems="center"
            zIndex="100"
          >
            <Spinner size="xl" />
          </Box>
        )}
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text>Edit profile</Text>
          <Flex
            gap="8px"
            align="center"
          >
            <Button
              variant="light"
              borderColor="#D8DAE5"
              onClick={() => {
                setError('')
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              variant="dark"
              bgColor="backgroundAccent"
              color="backgroundMain"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
        </ModalHeader>
        <Flex justify="end">
          {error?.length > 0 && (
            <Text
              fontSize="12px"
              color="redPrimary"
              mx="24px"
              my="8px"
            >
              {error}
            </Text>
          )}
        </Flex>
        <Divider />
        <ModalBody p="0px">
          <Tabs
            isFitted
            variant="unstyled"
            w="100%"
          >
            <TabList>
              {tabs.map((tab: Tab) => (
                <Tab
                  key={tab.name}
                  _selected={{ color: '#3366FF' }}
                  fontSize="14px"
                  p="14px"
                >
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="#3366FF"
            />
            <TabPanels overflow="visible">
              {tabs.map((tab: Tab) => (
                <TabPanel
                  key={tab.name}
                  p="0"
                >
                  {tab.component}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
