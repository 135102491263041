import useAsyncEffect from './async'
import { loaded } from '../../utils/process'

const useLoadedEffect = (
  callback: () => Promise<void>,
  dependencies: React.DependencyList,
  setIsLoading: (_: boolean) => void = () => null,
  onError?: (error: any) => void
): void =>
  useAsyncEffect(async () => {
    await loaded(async () => await callback(), setIsLoading, onError)
  }, dependencies)

export default useLoadedEffect
