import { UseDisclosureReturn, useColorMode, useToast, Box, Button, ModalFooter, Flex, Spinner } from '@chakra-ui/react'
import { FC, useEffect, useRef } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { usePostProvider } from '../../../provider/PostProvider/postProvider'
import QRCodeStyling from 'qr-code-styling'
import { Colors } from '../../../theme/colors'
import { copyToClipboard } from '../../../utils/os'
import SpinnerText from '../tags/SpinnerText'

type TQrModalProps = {
  disclosure: UseDisclosureReturn
}

export const QrModal: FC<TQrModalProps> = ({ disclosure }) => {
  const { isOpen, onClose } = disclosure

  const { shortLink, ipfs } = usePostProvider()
  const { colorMode } = useColorMode()
  const toast = useToast()

  const ref = useRef<HTMLDivElement>(null)

  const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    type: 'svg',
    data: shortLink ? `${window.location.origin}/l/${shortLink}` : `${window.location.origin}/post/${ipfs}`,
    margin: 0,
    qrOptions: { typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'L' },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: 'classy', color: Colors.textQuaternary[colorMode === 'dark' ? '_dark' : 'default'] },
    backgroundOptions: { color: Colors.backgroundMain[colorMode === 'dark' ? '_dark' : 'default'] },
    cornersSquareOptions: { color: Colors.textQuaternary[colorMode === 'dark' ? '_dark' : 'default'] },
    cornersDotOptions: { color: Colors.bluePrimary[colorMode === 'dark' ? '_dark' : 'default'] },
  })

  useEffect(() => {
    setTimeout(() => {
      qrCode.append(ref!.current as any)
    }, 100)
  }, [disclosure.isOpen])

  const handleCopyCode = async () => {
    await copyToClipboard(
      shortLink ? `${window.location.origin}/l/${shortLink}` : `${window.location.origin}/post/${ipfs}`
    )
    toast({ status: 'info', title: 'Copied' })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share this post</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDir="column"
          alignItems="center"
          p="24px"
          gap="24px"
        >
          <Flex
            sx={{
              position: 'absolute',
              boxSize: '300px',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '186px',
              color: 'textSecondary',
              zIndex: '-1',
            }}
          >
            <SpinnerText />
          </Flex>
          <Box
            boxSize="300px"
            ref={ref}
          />
        </ModalBody>
        <ModalFooter
          display="flex"
          alignItems="center"
          gap="8px"
        >
          <Button
            onClick={handleCopyCode}
            h="32px"
            fontSize="12px"
          >
            Copy code
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
