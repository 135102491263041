import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'
import React, { useRef } from 'react'

export const PostActionModal = ({
  disclosure,
  handleAction,
  title,
  description,
  actionText,
}: {
  disclosure: any
  handleAction: () => void
  title: string
  description: string
  actionText: string
}) => {
  const ref = useRef(null!)

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      leastDestructiveRef={ref}
      onClose={disclosure.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx="10px">
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
          >
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={ref}
              onClick={disclosure.onClose}
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleAction}
              ml={3}
            >
              {actionText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
