import React from 'react'
import { Flex, Text } from '@chakra-ui/react'
import SpinnerText from '../../shared/tags/SpinnerText'

export const ProfilesInfo = ({
  title,
  numbers,
  percent,
  subTitle,
  subInfo,
}: {
  title: string
  numbers?: string
  percent?: number
  subTitle: string
  subInfo: string
}) => {
  return (
    <Flex
      bgColor="backgroundMain"
      direction="column"
      p="24px"
      border="1px solid"
      borderColor="borderPrimary"
      borderRadius="16px"
      w={{ base: '100%', lg: '33%' }}
      gridGap="8px"
    >
      <Text
        fontSize="12px"
        color="backgroundAccent"
        fontWeight="500"
      >
        {title}
      </Text>
      <Flex direction="column">
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Text
            color="backgroundAccent"
            fontSize="24px"
            fontWeight="600"
          >
            {numbers || <SpinnerText />}
          </Text>
          <Text
            fontSize="16px"
            color={(percent ?? 1) < 0 ? 'redPrimary' : '#52BD94'}
          >
            {percent ?? <SpinnerText />}%
          </Text>
        </Flex>
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            color="textSecondary"
            fontSize="12px"
            fontWeight="400"
          >
            {subTitle}
          </Text>
          <Text
            color="#8F95B2"
            fontSize="12px"
          >
            {subInfo}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProfilesInfo
