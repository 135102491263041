import { Flex, Image, Spinner } from '@chakra-ui/react'
import React from 'react'

interface IProps {
  isSuccess: boolean
  isLoading?: boolean
}

export const Outcome = ({ isSuccess, isLoading }: IProps) => (
  <Flex
    justifyContent="center"
    alignItems="center"
  >
    {isLoading && <Spinner boxSize="16px" />}
    {!isLoading && <Image src={`/assets/icons/${isSuccess ? 'success' : 'failure'}-result-icon.svg`} />}
  </Flex>
)
