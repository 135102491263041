import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Tabs,
  Link as ChakraLink,
  TabPanels,
  TabPanel,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Avatar } from '../../../../../../../shared/avatars/Avatar'
import merge from 'lodash/merge'
import compact from 'lodash/compact'
import moment from 'moment'
import { FC, useEffect, useMemo, useState } from 'react'
import useAsyncEffect from '../../../../../../../../hooks/effects/async'
import { useEthereumProvider } from '../../../../../../../../provider/Ethereum/ethereumProvider'
import { IId, emptyProfile } from '../../../../../../../../provider/Profile/profileProvider'
import { AddressZero } from '../../../../../../../../utils/ethereum'
import { fetchApi } from '../../../../../../../../utils/fetcher'
import {
  buildIpfsGateway,
  formatUsd,
  getInitials,
  trimAddress,
  trimStringInbetween,
} from '../../../../../../../../utils/parser'
import { loaded } from '../../../../../../../../utils/process'
import { usePost } from '../../../../../../../shared/actions/QuickPost/usePost'
import { IPostSchema, PostType } from '../../../../deafult/Default'
import PostOptions, { ListOption } from '../PostOptions'
import { CommentsSection } from './CommentsSection/CommentsSection'
import { TargetingModal } from './Modals/TargetingModal'
import { PowerDetailsModal } from './Modals/PowerDetailsModal'
import { SharedModal } from './Modals/SharedModal'
import { QuickPostModal } from '../../../../../../../shared/actions/QuickPost/modal/QuickPostModal'
import { UsersModal } from './Modals/UsersModal'
import { UserTag } from '../../../../../../../shared/tags/UserTag'
import { PostActionModal } from './Modals/PostActionModal'
import { useIconProvider } from '../../../../../../../../provider/Icons/iconsProvider'
import { JoinAsModal } from './Modals/JoinAsModal'
import { useContractsProvider } from '../../../../../../../../provider/Contracts/contractsProvider'
import { InviteAsModal } from './Modals/InviteAsModal'
import { Address } from '../../../../../../../../contracts/address'
import { ReactNode } from 'react'
import PostDetails from '../PostDetails/PostDetails'
import SpinnerText from '../../../../../../../shared/tags/SpinnerText'
import { Power } from '../../../../../../../shared/typography/Power'
import { waitForTransaction } from '@wagmi/core'
import { BoostModal } from './Modals/BoostModal'
import { PostWarningModal } from './Modals/PostWarningModal'
import { PostProvider } from '../../../../../../../../provider/PostProvider/postProvider'
import QRCode from 'react-qr-code'
import { useGroupProvider } from '../../../../../../../../provider/Group/groupProvider'
import { zeroAddress } from 'viem'
import { includesInsensitive } from '../../../../../../../../utils/ts'
import { useCandaoPrice } from '../../../../../../../../hooks/candao/useCandaoPrice'
import { readContract } from '@wagmi/core'
import { Abi } from '../../../../../../../../contracts/abi'
import { copyToClipboard } from '../../../../../../../../utils/os'
import { VoteIcon } from '../../../../../../../icons/VoteIcon'
import { QrModal } from '../../../../../../../shared/qr/QrModal'
import { QuestModal } from '../../../../../../../shared/actions/QuickPost/modal/QuestModal/QuestModal'
import { useAccountProfileProvider } from '../../../../../../../../provider/AccountProfile/context'
import { useWalletClient } from 'wagmi'
import { _log } from '../../../../../../../../logger'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { getColor, getTheme } from '../../../../../../../../theme/global'

interface IPostProps {
  data: IPostSchema
  isNested?: boolean
  customContent?: ReactNode
  isModal?: boolean
  customQuestAction?: (action: 'join' | 'invite') => void
}

enum EVoteAction {
  DOWN = -1,
  UP = 1,
}

export type Quest = {
  name: string
  description: string
  tokens: Token[]
  roles: any[]
  type: PostType
  winners: number
}

type Token = { name: string; address: string; amount: number; usd: number }

export const Post: FC<IPostProps> = ({ data, isNested = false, customContent, isModal = false, customQuestAction }) => {
  const whitelistedTokens = [Address.BUSD, Address.USDC, Address.USDT, Address.W_CDO_PROD]
  //TODO: Fetch warning info along with user info data (user settings/prefs/misc data)
  const [showWarning, setShowWarning] = useState(data?.reports ? data?.reports.length > 0 : false)
  const [isContentLoading, setIsContentLoading] = useState(false)
  const [user, setUser] = useState(emptyProfile)
  const [isUserLoading, setIsUserLoading] = useState(true)
  const [isReporting, setIsReporting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isVoting, setIsVoting] = useState(false)
  const [isMoreTextVisible, setIsMoreTextVisible] = useState(!!customContent)
  const [isJoiningQuest, setIsJoiningQuest] = useState(false)
  const [shitWarningVisible, setShitWarningVisible] = useState(true)
  const [isClaimingLoading, setIsClaimingLoading] = useState(false)
  const [commentsVisible, setCommentsVisible] = useState<boolean>(false)
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [tokens, setTokens] = useState<Token[]>([])

  const accountProfile = useAccountProfileProvider()

  const [sharingPostData, setSharingPostData] = useState<IPostSchema>()
  const [viewCount, setViewCount] = useState<number>(0)
  const [sharedUsers, setSharedUsers] = useState<IId[]>([])
  const [tvl, setTvl] = useState(0)
  const [totalTokens, setTotalTokens] = useState(0)
  const [commentsAmount, setCommentsAmount] = useState<number>()
  const [imageModal, setImageModal] = useState<{
    isOpen: boolean
    currentImage: string
    index: number
  }>({
    isOpen: false,
    currentImage: '',
    index: 0,
  })

  const [potentialQuest, setPotentialQuest] = useState<Quest>()

  const isQuest = useMemo(() => [PostType.Quest, PostType.Task, PostType.Job].includes(data.type), [data.type])
  const imageModalControl: UseDisclosureReturn = useDisclosure()
  const { isOpen: isOpenShare, onOpen: onOpenShare, onClose: onCloseShare } = useDisclosure()
  const deletionModal = useDisclosure()
  const reportModal = useDisclosure()
  const reportsModal = useDisclosure()
  const targetingModal = useDisclosure()
  const powerDetailsModal = useDisclosure()
  const shareModal = useDisclosure()
  const sharedModal = useDisclosure()
  const votedModal = useDisclosure()
  const joinAsModal = useDisclosure()
  const inviteAsModal = useDisclosure()
  const postDetailsModal = useDisclosure()
  const boostModal = useDisclosure()
  const warningModal = useDisclosure()
  const qrModalDisclosure = useDisclosure()
  const questModalDisclosure = useDisclosure()

  const params = useParams()
  const sharedPost = usePost()
  // Providers
  const { account } = useEthereumProvider()
  const toast = useToast()
  const navigate = useNavigate()
  const { roles, interests } = useIconProvider()
  const location = useLocation()
  const wallet = useEthereumProvider()
  const contracts = useContractsProvider()
  const group = useGroupProvider()

  const { price } = useCandaoPrice()
  const { upgrade } = usePost()

  const walletClient_ = useWalletClient()
  const walletClient = walletClient_.data!

  const getPostUri = (): string => {
    if (data.ipfs === 'root') {
      return `/post/${data.ipfs}`
    }

    try {
      const firstPart = data?.ipfs.match(/(?:ipfs\/|ipfs:\/\/)(\w.*?)(?=\/post)/)?.[1]
      const secondPart = data?.ipfs.match(/\/post-(\w.*?)\.json/)?.[1]

      const targetLocation = `/post/${firstPart}-${secondPart}`

      return targetLocation
    } catch {}

    return ''
  }

  const getTokenName = async (address: address): Promise<string> => {
    try {
      return (await readContract({
        abi: Abi.ERC20,
        address: address,
        functionName: 'name',
        args: [],
      })) as string
    } catch (e) {}
    return ''
  }

  useEffect(() => {
    if (isModal) {
      return
    }
    try {
      const ipfs = location.state.ipfs
      const action = location.state.action

      if (ipfs === data.ipfs) {
        if (isQuest) {
          if (action === 'join') {
            if (data.author !== account && !data.isClosed) {
              handleJoinQuest()
            }
          } else if (action === 'invite') {
            if (!data.isClosed) {
              inviteAsModal.onOpen()
            }
          }
        }
      }
    } catch (e) {}
  }, [location.state])

  const getPostReferralUri = () => `${getPostUri()}?referral=${user.address}`

  const showQr = () => {
    qrModalDisclosure.onOpen()
  }

  const getShortLink = () => `${window.location.origin}/l/${data.shortLink}`

  // TODO: [/ proposal]: are you sure you want to upvote? you can do this only once.
  const handleVote = async (action: EVoteAction) => {
    // 3. update gui / show loading
    if (isVoting) {
      return
    }

    await loaded(
      async () => {
        toast({ title: 'Voting…', id: 'status_voting', duration: null })

        const result = await fetchApi('post/vote', {
          uuid: data?.ipfs,
          action,
        })

        toast({ title: `${action === EVoteAction.UP ? 'Up' : 'Down'}voted!` })
        merge(data, { power: result })
      },
      setIsVoting,
      error => {
        if (toast.isActive('error_vote')) {
          return
        }

        if ([500].includes(error?.response?.data?.statusCode)) {
          toast({ title: '[Error]: Moralis Web3 provider is down', id: 'error_vote' })
        } else if ([401, 403].includes(error?.response?.data?.statusCode)) {
          toast({ title: 'Please log in first', id: 'error_vote' })
        } else {
          if (error?.response?.data?.message === 'Already voted') {
            toast({
              title: `You have already ${action === EVoteAction.UP ? 'up' : 'down'}voted this post`,
              id: 'error_vote',
            })
            return
          }

          toast({ title: error?.response?.data?.message })
        }
      }
    )

    toast.close('status_voting')
  }

  const handleReport = async () => {
    if (isReporting) {
      return
    }

    await loaded(
      async () => {
        toast({ title: 'Reporting…', id: 'status_reporting', duration: null })

        await fetchApi('post/report', {
          uuid: data?.ipfs,
        })

        reportModal.onClose()
        toast({ title: 'Post reported!' })
      },
      setIsReporting,
      () => {
        if (toast.isActive('error_already_reported')) {
          return
        }

        reportModal.onClose()
        toast({ title: 'You have already reported this post', id: 'error_already_reported' })
      }
    )

    toast.close('status_reporting')
  }

  const handleIgnoreReporting = async () => {
    await loaded(
      async () => {
        toast({ title: 'Ignoring reporting...', id: 'status_ignoring_reporting', duration: null })

        if (data?.reports !== undefined && data.reports?.length > 0) {
          await fetchApi('post/report', {
            uuid: data?.ipfs,
            clearReport: true,
          })
        } else {
          toast({ title: "Can't clear reports" })
          reportModal.onClose()
          return
        }

        reportModal.onClose()
        toast({ title: 'Cleared reports' })
      },
      setIsReporting,
      () => {
        if (toast.isActive('error_already_ignoring_reports')) {
          return
        }

        reportModal.onClose()
        toast({ title: 'Already ignoring reports', id: 'error_already_ignoring_reports' })
      }
    )

    toast.close('status_ignoring_reporting')
  }

  const handleDelete = async () => {
    if (isDeleting) {
      return
    }

    await loaded(
      async () => {
        toast({ title: 'Deleting…', id: 'status_deleting', duration: null })
        await fetchApi('post/delete', {
          uuid: data?.ipfs,
        })

        deletionModal.onClose()
        setIsDeleted(true)
        toast({ title: 'Post removed!' })
      },
      setIsDeleting,
      () => {
        if (toast.isActive('error_already_deleted')) {
          return
        }

        toast({ title: 'Already deleted', id: 'error_already_deleted' })
      }
    )

    toast.close('status_deleting')
  }

  const handleNavigate = () => {
    const target = getPostUri()
    if (location.pathname === target && !isNested) {
      return
    }

    navigate(target)
  }

  const handleCopyLink = async () => {
    const target = getPostReferralUri()
    const result = await copyToClipboard(
      `${window.location.origin}${target}${!account ? '' : `&ghostReferral=${account}`}`
    )

    toast({ title: 'Copied!' })
    return result
  }

  const handleShare = async () => {
    shareModal.onOpen()
  }

  const handleJoinQuest = () => {
    joinAsModal.onOpen()
  }

  const handleDetails = () => {
    postDetailsModal.onOpen()
  }

  const handleAddOffer = async (quest: Quest) => {
    await upgrade(data.ipfs, quest)
  }

  const handleSelectWinner = async (addr: string) => {
    await loaded(
      async () => {
        await contracts.quest?.write.giveReward([addr, Number(data.quest?.id)])

        const res = await fetchApi('post/announceWinner', {
          ipfs: data?.ipfs,
          winner: addr,
        })

        toast({ title: res.message, status: 'success' })
      },
      () => null,
      err => {
        let errMsg = err?.response?.data?.message
        if (err?.response?.data?.message === undefined) {
          errMsg = 'Transaction cancelled'
        }
        toast({ title: errMsg, status: 'error' })
      }
    )
  }

  const handleClaimReward = async () => {
    await loaded(
      async () => {
        const tx = await contracts.quest.write.claimRewards([data.quest?.id])
        await waitForTransaction({ hash: tx })
        await fetchApi('post/claimReward', {
          ipfs: data?.ipfs,
          address: account,
        })

        toast({ title: 'Reward claimed successfully', status: 'success' })
      },
      setIsClaimingLoading,
      err => {
        let errMsg = err?.response?.data?.message
        if (err?.response?.data?.message === undefined) {
          errMsg = 'Metamask transaction error'
        }
        toast({ title: errMsg, status: 'error' })
      }
    )
  }

  const handleJoin = async (name: string) => {
    joinAsModal.onClose()
    await loaded(
      async () => {
        const ipfs = data?.ipfs
        const res = await fetchApi('post/participate', { ipfs, address: account, asRole: name })
        toast({
          title: 'Joined!',
          status: 'success',
        })
      },
      setIsJoiningQuest,
      err => {
        let errMsg = err?.response?.data?.message
        if (err?.response?.data?.message === undefined) {
          errMsg = 'Metamask transaction error'
        }
        toast({ title: 'Error while joining group', status: 'error' })
      }
    )
  }

  useEffect(() => {
    sharedPost.setContent({ ...sharedPost.content, sharing: data?.ipfs })
  }, [params.id])

  /** Handle warning sync */
  useEffect(() => setShowWarning(data?.reports ? data?.reports?.length > 0 : false), [data.reports?.length])

  useEffect(() => {
    if (!location.pathname.startsWith('/post/')) {
      return
    }
    if (!isNested) {
      setCommentsVisible(true)
    }
    // setCommentsVisible(true)
  }, [location.pathname])

  /* Fetch sharers profile data */
  useAsyncEffect(async () => {
    if (!data.sharers?.length) {
      return
    }

    await loaded(async () =>
      setSharedUsers(await fetchApi(`id`, { addresses: data.sharers?.map(sharer => sharer.author) }))
    )
  }, [data.sharers])

  useAsyncEffect(async () => {
    if (!data?.sharing) {
      return
    }

    await loaded(async () => {
      const res = await fetchApi('post', [data.sharing])
      setSharingPostData(res?.[0])
    })
  }, [data])

  useAsyncEffect(async () => {
    if (!data.author) {
      return
    }

    if (data.author === AddressZero) {
      return
    }

    await loaded(async () => {
      setUser(await fetchApi(`profile/${data.author}`))
    }, setIsUserLoading)
  }, [data.author])

  useAsyncEffect(
    async () =>
      await loaded(async () => setCommentsAmount(await fetchApi('post/counts/replies', { thread: data?.ipfs }))),
    []
  )

  useEffect(() => {
    let newTVL = 0
    let tokensAmount = 0

    data.reward?.tokens.forEach(token => {
      if (includesInsensitive(whitelistedTokens, token.address)) {
        if (token.address.toLowerCase() === Address.W_CDO_PROD.toLowerCase()) {
          newTVL += Number(token.amount) * Number(price)
          token.usd = Number(token.amount) * Number(price)
        } else {
          newTVL += Number(token.amount)
          token.usd = Number(token.amount)
        }
      }
      tokensAmount += Number(token.amount)
    })

    setTotalTokens(tokensAmount)
    setTvl(newTVL)
  }, [data.reward, price])

  useAsyncEffect(async () => {
    if (data.reward?.tokens) {
      const tokens: Token[] = await Promise.all(
        data.reward.tokens.map(async (token: Token) => {
          return {
            ...token,
            name: await getTokenName(token.address as address),
          }
        })
      )
      setTokens(tokens)
    }
  }, [data.reward?.tokens])

  const handleCreateGroup = async () => {
    const promise = new Promise<void>(async (resolve, reject) => {
      if (wallet.account) {
        const calldata = [data.quest?.name || '', data.quest?.name ? getInitials(data.quest?.name) : '']
        await loaded(
          async () => {
            await await contracts.groupsDeployer?.write.deploy(calldata)
          },
          undefined,
          _ => {
            toast({ status: 'error', title: 'This address has already been imported”' })
          }
        )
        const hash = await contracts.groupsDeployer?.write.deploy(calldata)
        const receipt = await waitForTransaction({ hash })

        console.log({ receipt })

        try {
          console.log('fetching')
          const res = await fetchApi('quest/upgrade', {
            post: data.ipfs,
            tx: receipt.transactionHash,
          })
          console.log({ res })
        } catch (e) {
          _log({ e })
        }

        toast({ title: 'Group created!' })
        resolve()
      } else {
        toast({ status: 'error', title: 'You must be logged in' })
        reject()
      }
    })
    toast.promise(promise, {
      success: { title: 'Group created successfully' },
      error: { title: 'Error while creating group' },
      loading: { title: 'Group creation pending' },
    })
  }

  return (
    <PostProvider post={data}>
      <QrModal disclosure={qrModalDisclosure} />
      <BoostModal
        isOpen={boostModal.isOpen}
        onClose={boostModal.onClose}
      />
      <QuestModal
        isOpen={questModalDisclosure.isOpen}
        onClose={questModalDisclosure.onClose}
        setQuests={setPotentialQuest}
        onParentOpen={() => null}
        post={null}
        submit={handleAddOffer}
      />
      <PostDetails
        isOpen={postDetailsModal.isOpen}
        onClose={postDetailsModal.onClose}
        author={{
          address: user.address_?.[0] as address,
          name: user.name,
        }}
      />
      <JoinAsModal
        handleJoin={handleJoin}
        isOpen={joinAsModal.isOpen}
        onClose={joinAsModal.onClose}
      />
      <InviteAsModal
        isOpen={inviteAsModal.isOpen}
        onClose={inviteAsModal.onClose}
        link={`https://socialxplorer.io/post/${data?.ipfs.match(/(?:ipfs\/|ipfs:\/\/)(\w.*?)(?=\/post)/)?.[1]}-${
          data?.ipfs.match(/\/post-(\w.*?)\.json/)?.[1]
        }`}
      />
      <Modal
        isOpen={isOpenShare}
        onClose={onCloseShare}
      >
        <ModalOverlay />
        <ModalContent
          mx="10px"
          marginBottom={{ base: '10px', md: '64px' }}
        >
          <ModalBody
            p="12px"
            sx={{ display: 'flex', gap: '12px', flexDirection: 'column' }}
          >
            <Button
              w="100%"
              variant="ghost"
              onClick={() => {
                onCloseShare()
                handleShare()
              }}
            >
              <Text>Share via SocialXplorer</Text>
            </Button>
            <Button
              w="100%"
              variant="ghost"
              onClick={() => {
                onCloseShare()
                handleCopyLink()
              }}
            >
              <Text>Copy link</Text>
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>

      {shareModal.isOpen && (
        <QuickPostModal
          isOpen={shareModal.isOpen}
          onClose={shareModal.onClose}
          onOpen={shareModal.onOpen}
          post={sharedPost}
          sharedPost={data}
        />
      )}

      <UsersModal
        data={data?.reports ?? []}
        title="Reports"
        isOpen={reportsModal.isOpen}
        onClose={reportsModal.onClose}
      />

      {imageModal.isOpen && (
        <Modal
          isOpen={imageModalControl.isOpen}
          onClose={imageModalControl.onClose}
        >
          <ModalOverlay />
          <ModalContent
            maxW="800px"
            marginBottom={{ base: '10px', md: '64px' }}
            mx="10px"
          >
            <ModalBody py="24px">
              <Image
                src={buildIpfsGateway(imageModal.currentImage)}
                borderRadius="6px"
                w="100%"
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {targetingModal.isOpen && !data?.quest?.id && (
        <TargetingModal
          target={data.target ?? { roles: [], interests: [] }}
          isOpen={targetingModal.isOpen}
          onClose={targetingModal.onClose}
        />
      )}

      {powerDetailsModal.isOpen && (
        <PowerDetailsModal
          chains={[]}
          isOpen={powerDetailsModal.isOpen}
          onClose={powerDetailsModal.onClose}
        />
      )}

      {isContentLoading ? (
        <Flex
          flexGrow="1"
          justifyContent="center"
          alignItems="center"
          height={isNested ? '121px' : '50vh'}
          borderRadius="16px"
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex
          id={data.ipfs}
          borderRadius={isNested ? '0px' : { base: '0', md: '16px' }}
          border={isNested ? '0px' : '1px solid'}
          bg={isNested ? 'backgroundMain' : showWarning ? '#FFFAF1' : 'backgroundMain'}
          overflow="hidden"
          borderX={{
            base: 'none',
            md: isNested ? 'none' : showWarning ? '1px solid' : '1px solid',
          }}
          borderColor={{ base: 'borderPrimary', md: isNested ? 'none' : showWarning ? '#FFB020' : 'borderPrimary' }}
          borderLeftColor={{
            base: 'none',
            md: isNested ? 'none' : showWarning ? '#FFB020 !important' : 'borderPrimary !important',
          }}
          borderRightColor={{
            base: 'none',
            md: isNested ? 'none' : showWarning ? '#FFB020 !important' : 'borderPrimary !important',
          }}
          w="100%"
          onClick={(e: any) => {
            if (isModal) {
              e.stopPropagation()
              return
            }
            if (e.target !== e.currentTarget) {
              e.stopPropagation()

              if (customContent) {
                return
              }
              handleNavigate()
            }
          }}
          cursor={customContent ? 'auto' : 'pointer'}
          flexDirection="column"
          {...(isDeleted && {
            opacity: '.33',
            pointerEvents: 'none',
            userSelect: 'none',
            filter: 'grayscale(1)',
          })}
        >
          {!isNested && !!data.sharers?.length && (
            <>
              <SharedModal
                data={data.sharers}
                isOpen={sharedModal.isOpen}
                onClose={sharedModal.onClose}
              />
              <Flex
                p="12px 16px"
                borderBottom="1px solid borderPrimary"
                align="center"
                fontSize="12px"
                gap="8px"
                cursor="pointer"
                onClick={(e: any) => {
                  sharedModal.onOpen()

                  e.stopPropagation()
                }}
              >
                <Image src="/assets/icons/shared.svg" />
                <Text
                  color="#8F95B2"
                  sx={{ lineHeight: '16px' }}
                >
                  {data.sharers.map(
                    (sharer, index) =>
                      `${sharedUsers[index]?.nickname ?? `${sharer.author?.slice(0, 12)}…`}${
                        index === data.sharers!.length - 1 ? '' : ', '
                      }`
                  )}
                </Text>
                {data.sharers.length > 3 && <Text color="textTertiary">+{data.sharers.length - 3}</Text>}
              </Flex>
            </>
          )}
          {showWarning && (
            <>
              <PostWarningModal
                disclosure={warningModal}
                id={(data as any)._id}
              />
              <Flex
                borderBottom="1px solid #FFB020"
                align="center"
                justify="space-between"
                p="12px 16px"
                cursor="pointer"
              >
                <Flex
                  align="center"
                  gap="16px"
                >
                  <Image src="/assets/icons/alert.svg" />
                  <Text
                    color="textQuaternary"
                    fontSize="14px"
                    fontWeight="500"
                  >
                    Visibility for this posts has been limited.
                  </Text>
                </Flex>
                <Text
                  textDecoration="underline"
                  cursor="pointer"
                  color="#996A13"
                  fontSize="14px"
                  onClick={(e: any) => {
                    warningModal.onOpen()

                    e.stopPropagation()
                  }}
                >
                  React now
                </Text>
              </Flex>
            </>
          )}
          {isQuest && !isModal && (
            <Flex
              borderBottom="1px solid borderPrimary"
              align="center"
              justify="space-between"
              p="12px 16px"
              onClick={(e: any) => {
                targetingModal.onOpen()

                e.stopPropagation()
              }}
              cursor="pointer"
            >
              <Flex sx={{ gap: '8px' }}>
                <Flex
                  sx={{ alignItems: 'center', gap: '8px' }}
                  onClick={showQr}
                >
                  <QRCode
                    value={getShortLink()}
                    fgColor="#474D66"
                    bgColor="#FFFFFF"
                    size={24}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                  <Text sx={{ fontSize: '12px', color: '#8F95B2' }}>Scan the QR code to see the offer</Text>
                </Flex>
                <Tooltip label="Copy link">
                  <Button
                    variant="slave"
                    onClick={event => {
                      handleCopyLink()
                      event.stopPropagation()
                    }}
                  >
                    <Image src="/assets/icons/copy.svg" />
                  </Button>
                </Tooltip>
              </Flex>
              <Flex
                align="center"
                gap="10px"
                fontSize="12px"
                fontWeight="500"
                color="#8F95B2"
              >
                <Flex
                  as="span"
                  gap="4px"
                  align="center"
                >
                  <Image src="/assets/icons/joined-icon.svg" />
                  <Text
                    as="span"
                    color="textPrimary"
                  >
                    {data.participants?.length}
                  </Text>
                </Flex>
                <Box
                  h="10px"
                  w="1px"
                  bg="#D2D2D2"
                />
                {/* <Flex as="span">
                  Total value:&nbsp;
                  <Text
                    as="span"
                    color="textPrimary"
                  >
                    ${tvl * 1}
                  </Text>
                </Flex> */}
                <Flex as="span">
                  Slots:&nbsp;
                  <Text
                    as="span"
                    color="textPrimary"
                  >
                    {data.quest?.slots ?? <SpinnerText />}
                  </Text>
                </Flex>
                <Box
                  h="10px"
                  w="1px"
                  bg="#D2D2D2"
                />
                <Flex
                  align="center"
                  gap="8px"
                >
                  <Box
                    bg={data.isClosed ? 'redPrimary' : '#52BD94'}
                    borderRadius="50%"
                    w="12px"
                    h="12px"
                  ></Box>
                  <Text
                    fontSize="12px"
                    fontWeight="500"
                  >
                    {data.type?.toUpperCase()}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )}

          <Flex
            borderBottom="1px solid borderPrimary"
            align="center"
            justify="space-between"
            p="12px 16px"
            cursor="auto"
            onClick={event => event.stopPropagation()}
          >
            <Flex
              flexDir="column"
              gap="5.5px"
            >
              {data.group && group.address === zeroAddress && (
                <Link
                  to={`/group/${data.group}`}
                  style={{
                    fontSize: '14px',
                    color: 'textPrimary',
                    fontWeight: '600',
                  }}
                >
                  Group | {trimAddress(data.group)}
                </Link>
              )}
              {!isModal && !isNested && !isQuest && (
                <Flex
                  align="center"
                  gap="8px"
                  minH="24px"
                >
                  <Flex
                    onClick={showQr}
                    align="center"
                    gap="8px"
                    cursor="pointer"
                  >
                    <Box sx={{ mr: '3px', boxSize: '24px' }}>
                      {!user.accessCode ? (
                        <Spinner />
                      ) : (
                        <QRCode
                          value={getShortLink()}
                          fgColor="#474D66"
                          bgColor="#FFFFFF"
                          size={24}
                        />
                      )}
                    </Box>
                    <Text
                      fontSize="12px"
                      color="#8F95B2"
                    >
                      Scan the QR code to see the post
                    </Text>
                  </Flex>
                  <Tooltip label="Copy link">
                    <Button
                      variant="slave"
                      onClick={event => {
                        handleCopyLink()
                        event.stopPropagation()
                      }}
                    >
                      <Image src="/assets/icons/copy.svg" />
                    </Button>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          </Flex>

          {!isNested && !isModal && (!!data.target?.roles?.length || !!data.target?.interests?.length) && (
            <Flex
              borderBottom="1px solid borderPrimary"
              align="center"
              justify="space-between"
              padding="12px 16px"
              onClick={(e: any) => {
                targetingModal.onOpen()

                e.stopPropagation()
              }}
              cursor="pointer"
            >
              {!!data.target?.roles?.length && (
                <Flex alignItems="center">
                  <Text
                    color="#8f95b2"
                    fontSize="12px"
                    whiteSpace="nowrap"
                    mt="2px"
                  >
                    For roles&nbsp;
                  </Text>
                  <Flex
                    alignItems="center"
                    ml="4px"
                    minW="18px"
                    gap="8px"
                  >
                    {data.target.roles?.slice(0, 2).map(role => (
                      <Tooltip
                        key={role}
                        label={role}
                      >
                        <Flex
                          ml="-4px"
                          position="relative"
                        >
                          <UserTag
                            compact
                            text={role}
                            key={role}
                            imageUri={roles.get(role)}
                            colorBg="#EDEFF5"
                            colorFg="textQuaternary"
                          />
                        </Flex>
                      </Tooltip>
                    ))}
                    {data.target.roles?.length > 2 && (
                      <Text
                        fontSize="12px"
                        color="textSecondary"
                      >
                        +{data.target.roles?.length - 2}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              )}
              {!!data.target?.interests?.length && (
                <Flex gap="4px">
                  <Flex
                    alignItems="center"
                    ml="4px"
                    minW="18px"
                  >
                    {data.target.interests?.slice(0, 2).map(interest => (
                      <Tooltip
                        key={interest}
                        label={interest}
                      >
                        <Flex
                          ml="-4px"
                          position="relative"
                        >
                          <Image
                            maxH="16px"
                            maxW="16px"
                            minH="16px"
                            minW="16px"
                            src={interests.get(interest)}
                          />
                        </Flex>
                      </Tooltip>
                    ))}
                    {data.target.interests?.length > 2 && (
                      <Text
                        fontSize="12px"
                        color="textSecondary"
                      >
                        +{data.target.interests?.length - 2}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              )}
            </Flex>
          )}
          {!isNested ?? (
            <Box
              display="flex"
              w="100%"
              bgColor="borderPrimary"
              h="1px"
            />
          )}
          <Flex
            p={isModal ? '0px 16px' : '8px 16px 12px 16px'}
            flexDir="column"
            gap={isNested ? '8px' : '16px'}
            borderRadius="16px"
          >
            {!isModal && (
              <Flex
                align="center"
                justify="space-between"
              >
                <Link
                  to={`/profile/${user.address_?.[0] || data.author}`}
                  onClick={(e: any) => e.stopPropagation()}
                >
                  <Flex
                    gap="8px"
                    align="center"
                  >
                    <Avatar
                      name={user.name}
                      bgColor="bluePlaceholder"
                      image={data.ipfs === 'root' ? 'https://i.ibb.co/WknrcXc/wcdo.png' : user.avatar}
                      isLoading={isUserLoading}
                      size={isNested ? '32px' : '50px'}
                      textSize={{ short: '12px', long: '12px' }}
                    />
                    <Flex
                      flexDir={isNested ? 'row' : 'column'}
                      align={isNested ? 'baseline' : 'normal'}
                      gap={isNested ? '8px' : '0'}
                    >
                      <Text
                        fontSize="14px"
                        lineHeight="20px"
                        color="backgroundAccent"
                        mb="2px"
                      >
                        {data.ipfs === 'root' ? 'Candao Protocol' : user.name || 'No name'}
                      </Text>
                      {!compact && (
                        <Text
                          color="textSecondary"
                          fontSize="12"
                          lineHeight="16px"
                          wordBreak="break-all"
                          mb="2px"
                          overflow="hidden"
                          sx={{
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                          }}
                        >
                          {user.description}
                        </Text>
                      )}
                      <Text
                        fontSize="12px"
                        color="textQuaternary"
                      >
                        <Text
                          as="span"
                          sx={{ color: 'textBlue' }}
                        >
                          {trimAddress(data.author)}
                        </Text>{' '}
                        | {moment(data?._created_at).fromNow()}
                      </Text>
                    </Flex>
                  </Flex>
                </Link>

                <Flex
                  gap="16px"
                  align="center"
                  onClick={(e: any) => e.stopPropagation()}
                >
                  <PostOptions
                    post={data}
                    handlers={{
                      navigate: handleNavigate,
                      boost: boostModal.onOpen,
                      report: reportModal.onOpen,
                      delete: deletionModal.onOpen,
                      copy: handleCopyLink,
                      details: handleDetails,
                      addOffer: questModalDisclosure.onOpen,
                      createGroup: handleCreateGroup,
                    }}
                  />
                  <PostActionModal
                    title={`Delete Post
                        ${trimStringInbetween(
                          8,
                          12,
                          data?.ipfs.match(
                            /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/
                          )?.[1] ?? ''
                        )}
                        published ${moment(data?._created_at).fromNow()}?`}
                    description="You cannot revert this action afterwards."
                    handleAction={handleDelete}
                    disclosure={deletionModal}
                    actionText="Delete"
                  />
                  <PostActionModal
                    title="Report"
                    description="Do you really want to report this?"
                    handleAction={handleReport}
                    disclosure={reportModal}
                    actionText="Report"
                  />
                </Flex>
              </Flex>
            )}
            {isQuest && !isModal && (
              <Text
                color="backgroundAccent"
                fontSize="18px"
                fontWeight="600"
              >
                {data?.quest?.name}
              </Text>
            )}
            {data.ipfs === 'root' && (
              <Text
                color="backgroundAccent"
                fontSize="18px"
                fontWeight="600"
                cursor="pointer"
                onClick={handleNavigate}
              >
                {data?.text?.split(/[\r\n]+/)?.[0]}
              </Text>
            )}

            <Flex
              fontSize="14px"
              as="span"
              ml={isNested ? '40px' : '0px'}
              wordBreak="break-word"
              maxHeight={isMoreTextVisible ? 'auto' : '200px'}
              overflow="hidden"
              cursor={customContent ? 'auto' : 'pointer'}
              display="inline-block"
              whiteSpace="pre-wrap"
            >
              {isMoreTextVisible || (data.text?.length as number) <= 200 ? (
                <Box
                  as="span"
                  display="inline-block"
                >
                  {data?.text?.split(/[\r\n]+/).length === 1
                    ? data?.text
                    : data?.text
                        ?.replace(data?.text?.split(/[\r\n]+/)?.[0], '')
                        ?.trim()
                        ?.split(' ')
                        ?.map((word: string) =>
                          word.startsWith('#') ? (
                            <Text
                              color="textBlue"
                              as="span"
                              //whiteSpace="pre-wrap"
                              display="inline-block"
                            >
                              {word}{' '}
                            </Text>
                          ) : (
                            word + ' '
                          )
                        )}
                  {!!customContent ||
                    ((data.text?.length as number) > 200 && (
                      <Box
                        color="gray"
                        as="span"
                        display="inline-block"
                        ml="4px"
                        onClick={(e: any) => {
                          e.stopPropagation()
                          setIsMoreTextVisible(prevState => !prevState)
                        }}
                        sx={{ cursor: 'pointer' }}
                      >
                        Show less
                      </Box>
                    ))}
                </Box>
              ) : (
                <Box
                  as="span"
                  display="inline-block"
                >
                  {data?.text
                    ?.slice(0, 200)
                    ?.split(' ')
                    ?.map((word: string) =>
                      word.startsWith('#') ? (
                        <Text
                          color="textBlue"
                          as="span"
                          //whiteSpace="pre-wrap"
                          display="inline-block"
                        >
                          {word}{' '}
                        </Text>
                      ) : (
                        word + ' '
                      )
                    )}
                  …
                  <Box
                    color="gray"
                    as="span"
                    display="inline-block"
                    ml="4px"
                    onClick={(e: any) => {
                      e.stopPropagation()
                      setIsMoreTextVisible(prevState => !prevState)
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    Show more
                  </Box>
                </Box>
              )}
            </Flex>

            {data.type === PostType.Transfer && (
              <Flex
                sx={{
                  flexDirection: 'column',
                  border: '1px solid',
                  borderColor: 'textSecondary',
                  borderRadius: '8px',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '600' }}>Transfer</Text>
                <Flex sx={{ fontSize: '14px', flexDirection: 'column', pb: '8px' }}>
                  <Text sx={{ fontWeight: '500' }}>value&nbsp;</Text>
                  <Text>{data.transfer?.amount}</Text>
                  <Text>{data.transfer?.token}</Text>
                </Flex>
                <Flex sx={{ fontSize: '14px', flexDirection: 'column' }}>
                  <Text sx={{ fontWeight: '500' }}>recipient&nbsp;</Text>
                  <Text>{data.transfer?.recipient}</Text>
                </Flex>
              </Flex>
            )}
            <Box
              onClick={e => e.stopPropagation()}
              cursor="auto"
            >
              {customContent}
            </Box>
            {!isNested && !!data.sharing && (
              <Box
                mx="auto"
                border="1px solid"
                borderColor="borderPrimary"
                borderRadius="16px"
                width="100%"
                overflow="hidden"
              >
                {!sharingPostData && (
                  <Flex
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: '#0000000a',
                      color: 'textTertiary',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '350px',
                      fontSize: '13px',
                      fontWeight: '500',
                    }}
                  >
                    This shared post was removed
                  </Flex>
                )}
                {sharingPostData !== undefined && (
                  <Post
                    isNested={true}
                    data={sharingPostData as IPostSchema}
                  />
                )}
              </Box>
            )}

            <Grid
              templateColumns={data.images?.length! > 1 ? '1fr 1fr' : '1fr'}
              gridGap="8px"
              ml={isNested ? '40px' : '0px'}
            >
              {data.images?.map((image, index: number) => (
                <Flex
                  key={`${image}_${index}`}
                  bgColor="borderPrimary"
                  h={(data.images?.length || 0) > 1 ? '250px' : '350px'}
                  borderRadius="8px"
                  position="relative"
                  bgImage={buildIpfsGateway(image)}
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center center"
                  align="center"
                  justify="center"
                  cursor="pointer"
                  onClick={(e: any) => {
                    if (!image) {
                      return
                    }

                    imageModalControl.onOpen()
                    setImageModal({
                      isOpen: true,
                      currentImage: image,
                      index: index,
                    })

                    e.stopPropagation()
                  }}
                />
              ))}
            </Grid>

            {isQuest && (
              <Flex
                bg="backgroundQuaternary"
                border="1px solid"
                borderColor="borderPrimary"
                borderX="none"
                borderBottom="none"
                w="calc(100% + 32px)"
                ml="-16px"
                flexDir="column"
                onClick={(e: any) => e.stopPropagation()}
              >
                <Tabs>
                  {/* <TabList w="100%">
                    <Tab
                      w="50%"
                      p="14px"
                      fontSize="14px"
                      _selected={{ color: 'textBlue' }}
                    >
                      Details
                    </Tab>
                    <Tab
                      w="50%"
                      p="14px"
                      fontSize="14px"
                      _selected={{ color: 'textBlue' }}
                    >
                      Joined
                    </Tab>
                  </TabList> */}
                  {/* <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg="textBlue"
                    borderRadius="1px"
                  /> */}
                  <TabPanels>
                    <TabPanel w="100%">
                      <Flex
                        flexDir="column"
                        w="100%"
                        gap="12px"
                      >
                        <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                          <Link
                            to={`/profile/${user.address_?.[0] || data.author}`}
                            onClick={(e: any) => e.stopPropagation()}
                          >
                            <Flex
                              gap="8px"
                              align="center"
                            >
                              <Avatar
                                name={user.name}
                                bgColor="bluePlaceholder"
                                image={data.ipfs === 'root' ? 'https://i.ibb.co/WknrcXc/wcdo.png' : user.avatar}
                                isLoading={isUserLoading}
                                size={isNested ? '32px' : '32px'}
                                textSize={{ short: '12px', long: '12px' }}
                              />
                              <Flex
                                flexDir={isNested ? 'row' : 'column'}
                                align={isNested ? 'baseline' : 'normal'}
                                gap={isNested ? '8px' : '0'}
                              >
                                <Text
                                  fontSize="16px"
                                  fontWeight="600"
                                  lineHeight="20px"
                                  color="backgroundAccent"
                                  mb="2px"
                                >
                                  {data.ipfs === 'root' ? 'Candao Protocol' : user.name || 'No name'}
                                </Text>
                              </Flex>
                            </Flex>
                          </Link>
                          <Box
                            h="15px"
                            w="1px"
                            bg="#D2D2D2"
                          />
                          <Flex
                            align="center"
                            gap="8px"
                          >
                            <Flex
                              borderRadius="50%"
                              minH="28px"
                              maxH="28px"
                              minW="28px"
                              maxW="28px"
                              align="center"
                              justify="center"
                              border="1px solid"
                              borderColor="#EDEFF5"
                              bg="backgroundMain"
                            >
                              {data?.quest?.roles[0] !== undefined && (
                                <Image
                                  maxH="16px"
                                  maxW="16px"
                                  minH="16px"
                                  minW="16px"
                                  src={roles.get(data?.quest?.roles[0])}
                                  fallback={<QuestionOutlineIcon />}
                                />
                              )}
                            </Flex>
                            <Text
                              color="backgroundAccent"
                              fontSize="16px"
                              fontWeight="600"
                            >
                              {data?.quest?.roles[0]}
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          bg="backgroundMain"
                          p="6px 10px"
                          borderRadius="8px"
                          border="1px solid"
                          borderColor="borderPrimary"
                          fontSize="12px"
                          flexDir="column"
                        >
                          <Flex
                            align="center"
                            justify="space-between"
                          >
                            <Flex
                              gap="13px"
                              align="center"
                            >
                              <Flex as="span">
                                Total value locked:&nbsp;
                                <Flex
                                  as="span"
                                  color="textPrimary"
                                  fontWeight="500"
                                >
                                  {tvl > 0 ? (
                                    <>
                                      {' '}
                                      <Text color="#36F">$</Text>
                                      {formatUsd(tvl)}
                                    </>
                                  ) : (
                                    `${totalTokens} Tokens`
                                  )}
                                </Flex>
                              </Flex>
                              <Box
                                h="15px"
                                w="1px"
                                bg="#D2D2D2"
                              />
                              <Flex as="span">
                                Assets:&nbsp;
                                <Text
                                  as="span"
                                  color="textPrimary"
                                  fontWeight="500"
                                >
                                  {data.reward?.tokens.length}
                                </Text>
                              </Flex>
                              <Box
                                h="15px"
                                w="1px"
                                bg="#D2D2D2"
                              />
                            </Flex>
                            <Flex
                              align="center"
                              gap="4px"
                              onClick={() => setDetailsVisible(prev => !prev)}
                              userSelect="none"
                            >
                              <Text>Details</Text>
                              <Image
                                src="/assets/icons/chevron-down-small.svg"
                                sx={{ transform: detailsVisible && 'rotate(180deg)' }}
                              />
                            </Flex>
                          </Flex>
                          {detailsVisible && (
                            <Flex
                              flexDir="column"
                              gap="16px"
                              my="16px"
                            >
                              <ChakraLink
                                href="https://bscscan.com/address/0x78689c1821E283689D5A1B102F6dA9bd102F082D"
                                isExternal
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                }}
                              >
                                <Flex
                                  align="center"
                                  gap="12px"
                                >
                                  <Image src="/assets/icons/sheet-icon.svg" />
                                  <Flex
                                    as="span"
                                    fontSize="12px"
                                    color="textSecondary"
                                  >
                                    Contract details:&nbsp;
                                    <Flex>
                                      <Text
                                        color="#36F"
                                        textDecoration="underline"
                                      >
                                        {/* TODO: get it from somewhere */}
                                        {trimAddress(Address.QUEST)}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                                <Image src="/assets/icons/share-icon.svg" />
                              </ChakraLink>
                              <Flex
                                p="8px"
                                bg="borderPrimary"
                                fontSize="10px"
                                fontWeight="600"
                                color="textSecondary"
                                borderRadius="4px"
                              >
                                Assets: {data?.reward?.tokens?.length}
                              </Flex>
                              {shitWarningVisible && (
                                <Flex
                                  w="100%"
                                  p="12px 16px"
                                  borderRadius="8px"
                                  border="1px solid"
                                  borderColor="#FFB020"
                                  align="center"
                                  bg={getColor('goldPrimary', 0.1)}
                                  justify="space-between"
                                >
                                  <Flex
                                    align="center"
                                    gap="16px"
                                    fontSize="12px"
                                    fontWeight="500"
                                    color="goldPrimary"
                                  >
                                    <Image src="/assets/icons/alert.svg" />
                                    <Text>Always ensure that the tokens you accept are legitimate.</Text>
                                  </Flex>
                                  <Image
                                    src="/assets/icons/close-dark.svg"
                                    onClick={() => setShitWarningVisible(false)}
                                  />
                                </Flex>
                              )}
                              {tokens.map(token => (
                                <Flex
                                  key={`${token?.address}-${token.amount}`}
                                  p="8px 14px"
                                  borderRadius="8px"
                                  border="1px solid"
                                  borderColor="#EDEFF5"
                                  align="center"
                                  justify="space-between"
                                >
                                  <Flex
                                    align="center"
                                    gap="4px"
                                  >
                                    <Text sx={{ color: 'textQuaternary' }}>{token.name}</Text>
                                    {trimAddress(token.address)}
                                  </Flex>
                                  <Flex
                                    gap="48px"
                                    align="center"
                                  >
                                    <Flex
                                      flexDir="column"
                                      gap="2px"
                                      textAlign="end"
                                    >
                                      <Text
                                        fontSize="12px"
                                        color="#8F95B2"
                                      >
                                        Amount
                                      </Text>
                                      <Text
                                        color="textQuaternary"
                                        fontSize="12px"
                                      >
                                        {formatUsd(token?.amount)}
                                      </Text>
                                    </Flex>
                                    <Flex
                                      flexDir="column"
                                      gap="2px"
                                      textAlign="end"
                                    >
                                      <Text
                                        fontSize="12px"
                                        color="#8F95B2"
                                      >
                                        Value USD
                                      </Text>
                                      <Text
                                        color="textQuaternary"
                                        fontSize="12px"
                                      >
                                        {includesInsensitive(whitelistedTokens, token.address)
                                          ? `$${formatUsd(token?.usd)}`
                                          : 'unknown'}
                                      </Text>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          )}
                        </Flex>
                        <Flex
                          w="100%"
                          align="center"
                          gap="8px"
                          mt="16px"
                        >
                          {data.participants?.map(p => p.address).includes(account as address) ? (
                            <Button
                              bg="textBlue"
                              color="backgroundMain"
                              display="flex"
                              flexDir="column"
                              w="50%"
                              p="25px"
                              isDisabled={!(data.winner?.address === account) || data.winner?.isClaimed}
                              onClick={handleClaimReward}
                              isLoading={isClaimingLoading}
                            >
                              <Text
                                fontSize="14px"
                                fontWeight="500"
                              >
                                {data.winner?.isClaimed ? 'Already claimed' : 'Claim'}
                              </Text>
                            </Button>
                          ) : (
                            <Button
                              bg="none"
                              display="flex"
                              //flexDir="column"
                              lineHeight="142%"
                              w="50%"
                              borderRadius="8px"
                              p="0"
                              h="54px"
                              variant="dark"
                              isDisabled={data.author === account || data.isClosed}
                              onClick={
                                customQuestAction !== undefined ? () => customQuestAction('join') : handleJoinQuest
                              }
                              isLoading={isJoiningQuest}
                            >
                              <Flex
                                fontSize="14px"
                                fontWeight="500"
                                bg="textBlue"
                                color="white"
                                w="70%"
                                h="100%"
                                borderRadius="8px 0 0 8px"
                                border="none"
                                align="center"
                                justify="center"
                              >
                                Join AS
                              </Flex>
                              <Flex
                                fontSize="12px"
                                bg="white"
                                color="textBlue"
                                h="100%"
                                borderRadius="0 8px 8px 0"
                                border="1px solid"
                                borderColor={getTheme() === 'dark' ? 'white' : 'borderPrimary'}
                                align="center"
                                justify="center"
                                flexDir="column"
                                w="30%"
                                borderLeft="none"
                                px="8px"
                              >
                                <Text sx={{ fontWeight: '500' }}>
                                  {tvl > 0 ? `$${formatUsd(tvl * 1)}` : formatUsd(totalTokens)}
                                </Text>
                                <Text sx={{ color: 'gray' }}>{tvl > 0 ? `USDT` : 'Tokens'}</Text>
                              </Flex>
                            </Button>
                          )}
                          <Button
                            color="backgroundMain"
                            display="flex"
                            //flexDir="column"
                            lineHeight="142%"
                            bg="none"
                            w="50%"
                            variant="dark"
                            p="0"
                            h="54px"
                            borderRadius="8px"
                            isDisabled={data.isClosed}
                            onClick={
                              customQuestAction !== undefined ? () => customQuestAction('invite') : inviteAsModal.onOpen
                            }
                          >
                            <Flex
                              fontSize="14px"
                              fontWeight="500"
                              bg="backgroundAccent"
                              w="70%"
                              h="100%"
                              borderRadius="8px 0 0 8px"
                              align="center"
                              border="none"
                              justify="center"
                            >
                              Invite AS
                            </Flex>
                            <Flex
                              fontSize="12px"
                              bg="white"
                              color="textBlue"
                              h="100%"
                              borderRadius="0 8px 8px 0"
                              align="center"
                              border="1px solid"
                              borderColor={getTheme() === 'dark' ? 'white' : 'borderPrimary'}
                              justify="center"
                              flexDirection="column"
                              w="30%"
                              borderLeft="none"
                              px="8px"
                            >
                              <Text sx={{ fontWeight: '500' }}>
                                {tvl > 0 ? `$${formatUsd(tvl * 0.2)}` : formatUsd(totalTokens * 0.2)}
                              </Text>
                              <Text sx={{ color: 'gray' }}>{tvl > 0 ? `USDT` : 'Tokens'}</Text>
                            </Flex>
                          </Button>
                        </Flex>
                      </Flex>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            )}
            {!isModal && (
              <Flex
                align="center"
                justify="space-between"
                flexDirection="column"
                width="calc(100% + 32px)"
                minH="24px"
                mx="-16px"
                mb="-12px"
              >
                <Flex
                  align="center"
                  borderTop={`${!isNested ? '1px solid' : 'none'}`}
                  borderColor="borderPrimary"
                  justifyContent="space-between"
                  width="100%"
                  gap="12px"
                  p="8px 16px"
                  onClick={(e: any) => e.stopPropagation()}
                >
                  <Flex
                    align="center"
                    mr="18px"
                  >
                    <Tooltip label={wallet.isLoggedIn ? 'Upvote' : 'Please, connect wallet first'}>
                      <Button
                        variant="slave"
                        onClick={() => handleVote(EVoteAction.UP)}
                        isDisabled={!wallet.account}
                        sx={{ pr: '4px' }}
                      >
                        {/* <Image src="/assets/icons/upvote.svg" /> */}
                        <VoteIcon
                          color={
                            Number(data?.power?.valueUp)
                              ? '#52BD94'
                              : data?.power?.valueUp?.startsWith('-')
                              ? '#D14343'
                              : '#8F95B2'
                          }
                        />
                        <Power
                          isCompact
                          usd={data?.power?.valueUp}
                          isSymbolHidden={data.group && group.address === zeroAddress}
                        />
                      </Button>
                    </Tooltip>
                    <Tooltip label={wallet.isLoggedIn ? 'Downvote' : 'Please, connect wallet first'}>
                      <Button
                        variant="slave"
                        onClick={() => handleVote(EVoteAction.DOWN)}
                        isDisabled={!wallet.account}
                        sx={{ pr: '4px' }}
                      >
                        <VoteIcon
                          color={data?.power?.valueDown ? '#D14343' : '#8F95B2'}
                          down
                        />
                        <Power
                          isCompact
                          usd={`${data?.power?.valueDown ? '-' + data?.power?.valueDown : data?.power?.valueDown}`}
                          isSymbolHidden={data.group && group.address === zeroAddress}
                        />
                      </Button>
                    </Tooltip>

                    {isNested && wallet.isLoggedIn && (
                      <Button
                        variant="slave"
                        onClick={() => setCommentsVisible(prevState => !prevState)}
                        ml="8px"
                        borderRadius="3px"
                        px="4px"
                        gap="4px"
                      >
                        <Image src="/assets/icons/icon-reply.svg" />
                        <Text
                          color="textSecondary"
                          fontSize="12px"
                        >
                          {commentsAmount ? `${commentsAmount} replies` : 'Reply'}
                        </Text>
                      </Button>
                    )}
                  </Flex>
                  <Tooltip
                    label="Post power"
                    hasArrow
                  >
                    <Flex onClick={votedModal.onOpen}>
                      <UsersModal
                        title="Upvote & downvote"
                        data={data?.power?.voters}
                        token={data.group && data.group}
                        isOpen={votedModal.isOpen}
                        onClose={votedModal.onClose}
                      />
                      <Box
                        sx={{ ml: '8px' }}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="6px"
                        cursor="pointer"
                      >
                        <Text
                          fontSize="12px"
                          color="textSecondary"
                        >
                          Total:{' '}
                        </Text>
                        <Power
                          isTotal
                          usd={data?.power?.value}
                          isSymbolHidden={data.group && group.address === zeroAddress}
                        />
                      </Box>
                    </Flex>
                  </Tooltip>
                </Flex>
                {isNested ? (
                  <Flex
                    align="center"
                    justify="space-between"
                    direction="row"
                  >
                    {!!viewCount && (
                      <>
                        <Image src="/assets/icons/chart.svg" />
                        <Text
                          color="textSecondary"
                          fontSize="12px"
                        >
                          24.5k
                        </Text>
                      </>
                    )}
                  </Flex>
                ) : (
                  <Flex
                    onClick={(e: any) => e.stopPropagation()}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    p="8px 16px"
                    borderTop={`${!isNested ? '1px solid' : 'none'}`}
                    borderColor="borderPrimary"
                  >
                    <Flex>
                      <Tooltip label={wallet.isLoggedIn ? 'Comment' : 'Please, connect wallet first'}>
                        <Button
                          variant="slave"
                          alignItems="center"
                          pr="6px"
                          onClick={() => setCommentsVisible(prevState => !prevState)}
                        >
                          {/* navigate(data.ipfs) */}
                          <Image src="/assets/icons/comment.svg" />
                          <Text
                            fontSize="12px"
                            color="textSecondary"
                          >
                            {commentsAmount}
                          </Text>
                        </Button>
                      </Tooltip>
                      <Popover>
                        <PopoverTrigger>
                          <Tooltip label={wallet.isLoggedIn ? 'Share' : 'Please, connect wallet first'}>
                            <Button
                              variant="slave"
                              alignItems="center"
                              px="6px"
                              onClick={handleShare}
                            >
                              <Image src="/assets/icons/share.svg" />
                              <Text
                                fontSize="12px"
                                color="textSecondary"
                              >
                                0
                              </Text>
                            </Button>
                          </Tooltip>
                        </PopoverTrigger>
                        <PopoverContent
                          width="auto"
                          cursor="auto"
                        >
                          <Flex
                            width="auto"
                            flexDir="column"
                            justifyContent="start"
                            alignItems="start"
                            gap="4px"
                            color="backgroundAccent"
                            p="8px 8px"
                            zIndex="100"
                          >
                            <ListOption
                              isDisabled={false}
                              icon="/assets/icons/share3.svg"
                              text="Share via social explorer"
                              onClick={() => handleShare()}
                            />
                            <ListOption
                              isDisabled={true}
                              icon="/assets/icons/twitter-new.svg"
                              text="Share on twitter"
                              onClick={() => null}
                            />
                            <ListOption
                              isDisabled={false}
                              icon="/assets/icons/link-icon.svg"
                              text="Copy link"
                              onClick={() => handleCopyLink()}
                            />
                            <ListOption
                              isDisabled={true}
                              icon="/assets/icons/messenger.svg"
                              text="Send in a private message"
                              onClick={() => null}
                            />
                          </Flex>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                    <Tooltip label="Details">
                      <Button
                        variant="slave"
                        ml="8px"
                        onClick={postDetailsModal.onOpen}
                      >
                        <Image src="/assets/icons/report.svg" />
                      </Button>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
          {commentsVisible && !isModal && (
            <CommentsSection
              thread={data}
              isNested={isNested}
            />
          )}
          {location.pathname.startsWith('/admin') && (
            <Box
              borderTop="1px solid"
              borderColor="borderPrimary"
              p="16px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text
                color="#8F95B2"
                fontSize="12px"
              >
                Total reported times:{' '}
                <Text
                  display="inline"
                  color="#36F"
                  decoration="underline"
                  onClick={e => {
                    e.stopPropagation()
                    reportsModal.onOpen()
                  }}
                  as="span"
                  cursor="pointer"
                >
                  {data?.reports?.length}
                </Text>
              </Text>
              <Flex
                gap="6px"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Button
                  background="backgroundAccent"
                  border="none"
                  color="#FAFBFF"
                  onClick={async e => {
                    e.stopPropagation()
                    await handleIgnoreReporting()
                  }}
                >
                  Ignore reporting
                </Button>
                <Button
                  background="#3366FF"
                  border="none"
                  color="#FAFBFF"
                  onClick={async e => {
                    e.stopPropagation()
                    await handleDelete()
                  }}
                >
                  Delete post
                </Button>
              </Flex>
            </Box>
          )}
        </Flex>
      )}
    </PostProvider>
  )
}
