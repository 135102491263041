import { Box } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

export const ShortText = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      maxWidth="16ch"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {children}
    </Box>
  )
}
