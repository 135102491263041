import React from 'react'
import { Flex, Image, Text } from '@chakra-ui/react'

export const LeftPanelSingleTransaction = ({ label }: { label: string }) => {
  return (
    <Flex
      gridGap="10px"
      alignItems="center"
      w="240px"
    >
      <Image
        src="/assets/icons/info-circle.svg"
        boxSize="16px"
      />
      <Text
        color="textQuaternary"
        fontSize="14px"
      >
        {label}
      </Text>
    </Flex>
  )
}

export default LeftPanelSingleTransaction
