import { Flex, Image, Text } from '@chakra-ui/react'
import Marquee from 'react-fast-marquee'
import useAsyncEffect from '../../../hooks/effects/async'
import { loaded } from '../../../utils/process'
import { useEffect, useState } from 'react'
import { fetchApi } from '../../../utils/fetcher'
import { useOnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import { useCandaoPrice } from '../../../hooks/candao/useCandaoPrice'

type Coin = {
  name: string
  price: number
  iconUrl: string
  change: string
}

export default function CryptoTopbar() {
  const [data, setData] = useState([
    {
      name: '',
      price: 0,
      iconUrl: '',
      change: '',
    },
  ])

  const onboarding = useOnboardingProvider()
  const cdo = useCandaoPrice()

  useEffect(() => {
    if (data.map(d => d.name).includes('CDO')) {
      return
    }

    if (cdo && cdo.price) {
      setData(prev => [
        {
          name: 'CDO',
          price: Number(cdo.price) || 0,
          iconUrl: '/assets/icons/candao-logo.svg',
          change: cdo.percentage || '0%',
        },
        ...prev,
      ])
    }
  }, [cdo])

  useAsyncEffect(async () => {
    await loaded(async () => {
      const data = await fetchApi(`tokens-prices`)

      if (data.tokenPrices) {
        setData(data.tokenPrices)
      }
    })
  }, [])

  return (
    <Flex
      h="32px"
      bg="backgroundMain"
      maxWidth="100vw"
      borderBottom="1px solid borderPrimary"
      zIndex={onboarding.shouldElevateTopBar ? 'popover' : 'sticky'}
    >
      <Marquee speed={20}>
        {data.map((item: Coin, index) => (
          <Flex
            alignItems="center"
            gap="0.25rem"
            padding="0 16px"
            key={index}
            _after={{ content: '""', width: '1px', height: '12px', bg: 'borderPrimary', transform: 'translateX(14px)' }}
          >
            <Image
              src={item.iconUrl}
              alt={item.name}
              h="14px"
            />
            <Text
              fontSize="12px"
              color="textSecondary"
            >
              {item.name}
            </Text>
            <Text fontSize="12px">{Number(item.price).toFixed(2)}$</Text>
            <Text
              color={item.change.includes('-') ? 'redPrimary' : 'greenPrimary'}
              fontSize="10px"
              fontWeight="600"
            >
              {item.change}%
            </Text>
          </Flex>
        ))}
      </Marquee>
    </Flex>
  )
}
