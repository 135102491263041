import React from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'

export const UserDomains = () => {
  return (
    <Flex
      flexDirection="column"
      w={{ base: '100%', lg: 'auto' }}
      gridGap="2px"
      border="1px solid"
      borderColor="borderPrimary"
      borderRadius="16px"
    >
      <Flex
        p="16px"
        justifyContent="space-between"
      >
        <Text
          fontSize="16px"
          fontWeight="600"
          color="backgroundAccent"
        >
          Domains
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          color="textSecondary"
        >
          3 domains
        </Text>
      </Flex>
      <Flex
        p="12px 16px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="column">
          <Text color="textQuaternary">.domain</Text>
          <Text
            fontSize="12px"
            color="textSecondary"
          >
            Purchased at 00 M, 0000
          </Text>
        </Flex>
        <Flex gridGap="8px">
          <Box
            borderRadius="8px"
            gridGap="8px"
            border="1px solid"
            borderColor="#D8DAE5"
            display="flex"
            p="6px 8px"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontSize="14px"
              color="#3366FF"
            >
              0xCcW8···b90b11
            </Text>
            <Box
              borderRadius="4px"
              display="flex"
              w="20px"
              h="20px"
              bgColor="borderPrimary"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src="/assets/icons/link-icon.svg"
                boxSize="14px"
              />
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserDomains
