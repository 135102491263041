import { Box, Flex, Image, Link, PopoverContent, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { Link as LinkR, useLocation } from 'react-router-dom'
import { adminRoutes, routes } from '../routes'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'

export const WalletsPopup = () => {
  const history = useLocation()
  const accountProfile = useAccountProfileProvider()

  const links = [
    // {
    //   label: 'SocialXplorer',
    //   href: '',
    //   icon: '/assets/icons/xplorer-logo-small.svg',
    //   description: 'SocialXplorer platform',
    // },
    {
      label: 'Candao',
      href: 'https://candao.io',
      icon: '/assets/icons/candao.svg',
      description: 'Candao Protocol',
    },
    {
      label: 'Candao Heroes',
      href: 'https://heroes.candao.io',
      icon: '/assets/icons/candaoHeroes.svg',
      description: 'Candao Heroes platform',
    },
  ]

  return (
    <PopoverContent
      _focusVisible={{
        outline: 'none !important',
      }}
      position="absolute"
      right="-16px"
      top="0"
      width="auto"
      bgColor="backgroundMain"
      boxShadow="0px 4px 8px rgba(16, 24, 64, 0.16)"
      borderRadius="8px"
      cursor="auto"
    >
      <Flex
        borderRadius="8px"
        sx={{ flexDirection: { base: 'column-reverse', lg: 'unset' } }}
      >
        <Flex
          flexDir="column"
          justifyContent="space-around"
          p="16px"
          gap="8px"
          display={{ base: 'flex', lg: 'none' }}
          borderRadius="8px"
        >
          {[...routes, ...(accountProfile.isAdmin ? adminRoutes : [])].map(item => {
            return (
              <Tooltip
                key={item.href}
                hasArrow
                label={item.href === '/domains' && 'Implementation preview.'}
              >
                <Box
                  borderRadius="8px"
                  key={item.href}
                  _hover={{
                    borderRadius: '8px',
                    background: 'backgroundAccentLight',
                  }}
                >
                  <LinkR
                    to={item.href}
                    style={{
                      padding: '6px 24px',
                      borderRadius: '8px',
                      background: history.pathname === item.href ? 'backgroundAccentLight' : 'transparent',
                      fontSize: '14px',
                      color: `${history.pathname === item.href ? '#3366FF' : 'textSecondary'}`,
                      display: 'block',
                    }}
                  >
                    {item.label}
                  </LinkR>
                </Box>
              </Tooltip>
            )
          })}
        </Flex>
        <Flex
          borderRadius="8px"
          gap="12px"
          alignItems="flex-start"
          flexDir="column"
          bg="backgroundTertiary"
          py="16px"
        >
          {links.map(link => (
            <Link
              key={link.href}
              display="flex"
              borderRadius="8px"
              flexDir="row"
              gap="8px"
              mx="20px"
              width="220px"
              href={link.href}
              cursor="pointer"
              _hover={{ bgColor: 'borderPrimary', textDecoration: 'underline' }}
            >
              <Image src={link.icon} />
              <Flex
                flexDir="column"
                justifyContent="space-between"
                flexGrow={1}
                py="4px"
              >
                <Flex
                  gap="8px"
                  alignItems="center"
                >
                  <Text
                    fontSize="14px"
                    fontWeight={700}
                    color="textQuaternary"
                  >
                    {link.label}
                  </Text>
                  {link.href === '' && (
                    <Image
                      src="/assets/icons/betaBadge.svg"
                      height="16px"
                    />
                  )}
                </Flex>
                <Text
                  fontSize="12px"
                  color="#8F95B2"
                >
                  {link.description}
                </Text>
              </Flex>
            </Link>
          ))}
        </Flex>
      </Flex>
    </PopoverContent>
  )
}
