import { Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import Chart from 'react-apexcharts'

interface IAreaGradientChartProps {
  color: string
  data: {
    values?: number[]
    keys?: number[] | Date[]
  }
}

export const AreaGradientChart: FC<IAreaGradientChartProps> = ({ color, data }) => {
  // const options = {
  //   chart: {
  //     id: { color },
  //     toolbar: {
  //       show: false,
  //       autoSelected: 'pan',
  //     },
  //     width: '100%',
  //     height: '100px',
  //     zoom: {
  //       autoScaleYaxis: true,
  //     },
  //   },
  //   tooltip: {
  //     enabled: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   colors: [color],
  //   fill: {
  //     gradient: {
  //       shadeIntensity: 0,
  //       inverseColors: false,
  //       opacityFrom: 0.55,
  //       opacityTo: 0.05,
  //     },
  //   },
  //   grid: {
  //     show: false,
  //     padding: {
  //       left: 0,
  //       right: 0,
  //       top: 0,
  //       bottom: 0,
  //     },
  //   },
  //   stroke: {
  //     width: 2,
  //     curve: 'straight',
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   xaxis: {
  //     show: true,
  //     categories: data.keys?.length && data.keys.length > 1 ? data.keys : [1, 2],
  //     labels: {
  //       show: false,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     axisBorder: {
  //       show: false,
  //     },
  //   },
  //   yaxis: {
  //     show: true,
  //     tickAmount: 1,
  //     labels: {
  //       show: true,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //     axisBorder: {
  //       show: false,
  //     },
  //   },
  // };

  const options = {
    chart: {
      type: 'area',
      stacked: false,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
        autoSelected: 'pan',
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0,
        inverseColors: false,
        opacityFrom: 0.55,
        opacityTo: 0.05,
      },
    },
    stroke: {
      width: 2,
      curve: 'straight',
    },
    yaxis: {
      tickAmount: 1,
      labels: {
        show: true,
        style: {
          colors: ['#8F95B2'],
          fontSize: '10px',
        },
        formatter: (value: number) => {
          return value.toFixed(0)
        },
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    xaxis: {
      type: 'datetime',
      categories: data.keys?.length && data.keys.length > 1 ? data.keys : [1, 2],
      ticksAmount: 3,
      labels: {
        show: true,
        style: {
          colors: '#8F95B2',
          fontSize: '10px',
          fontWeight: '400',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false,
      padding: {
        left: 10,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function (val: any) {
          return (val / 1000000).toFixed(0)
        },
      },
    },
  }

  const series = [
    {
      name: 'Dataset',
      data: data.values?.length && data.values.length > 1 ? data.values : data.values?.length ? [2, 1] : [0, 0],
    },
  ]

  return (
    <Flex
      w="100%"
      pointerEvents="none"
    >
      <Box
        w="100%"
        ml="-10px"
        mb="-20px"
      >
        <Chart
          options={options as any}
          series={series}
          type="area"
          height="170px"
        />
      </Box>
    </Flex>
  )
}
