import { Item } from '../select/utils'
import { Box, Flex, Image, Spinner, Text } from '@chakra-ui/react'

export const RoleInterestItem = ({
  item,
  selected,
  onClick,
}: {
  item: Item
  selected?: boolean
  onClick: () => void
}) => {
  return (
    <Flex
      align="center"
      gap="12px"
      border="1px solid"
      borderColor="#D8DAE5"
      borderRadius="8px"
      p="10px 12px"
      bg={selected ? '#F3F6FF' : 'backgroundMain'}
      cursor="pointer"
      onClick={onClick}
    >
      <Image
        src={item?.icon}
        fallback={
          <Box boxSize="28px">
            <Spinner />
          </Box>
        }
        borderRadius="50%"
        boxSize="28px"
      />
      <Text
        fontSize="14px"
        color="textQuaternary"
      >
        {item?.name}
      </Text>
      {selected ? <Image src="/assets/icons/trash.svg" /> : <Image src="/assets/icons/circle-plus-outline-dark.svg" />}
    </Flex>
  )
}
