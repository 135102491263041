import React, { useState } from 'react'
import { Flex } from '@chakra-ui/react'
import UserContentSwitcher, { Switcher } from './userContentSwitcher'
import UserWall from './UserWall/userWall'
import { UserContentSwitcherMobile } from './userContentSwitcherMobile'
import TopPeople from '../../Home/RightPanel/TopPeople/topPeople'

export const UserContent = () => {
  const [page, setPage] = useState<Switcher>(Switcher.Posts)

  return (
    <Flex
      gap="24px"
      direction={{ base: 'column', xl: 'row' }}
    >
      <Flex
        gap="24px"
        direction={{ base: 'column', lg: 'row' }}
        minW="100%"
      >
        <UserContentSwitcher
          value={page}
          setValue={setPage}
        />
        <UserContentSwitcherMobile setValue={setPage} />
        <UserWall value={page} />
        <Flex minW="286px">
          <TopPeople />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserContent
