import { useRef } from 'react'

export const useFocus = () => {
  const htmlElement = useRef<HTMLElement | any>(null)
  const setFocus = () => {
    htmlElement.current && htmlElement.current.focus()
  }

  return [htmlElement, setFocus as any]
}
