import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { Avatar } from '../../../../shared/avatars/Avatar'
import { INotification } from './NotificationModal'
import { trimAddress } from '../../../../../utils/parser'
import moment from 'moment'
import { useState } from 'react'
import useAsyncEffect from '../../../../../hooks/effects/async'
import { emptyProfile } from '../../../../../provider/Profile/profileProvider'
import { fetchApi } from '../../../../../utils/fetcher'

export const NotificationItem = ({
  confirmable = true,
  data,
  handlers,
}: {
  confirmable?: boolean
  data: INotification
  handlers: {
    closeModal: () => void
  }
}) => {
  const [sender, setSender] = useState(emptyProfile)
  useAsyncEffect(async () => {
    if (data.sender) {
      const profile = await fetchApi(`profile/${data.sender}`)
      setSender(profile)
    }
  }, [data])
  return (
    <Flex
      align="center"
      justify="space-between"
    >
      <Flex
        align="center"
        gap="16px"
      >
        <Box boxSize="50px">
          <Avatar
            image={sender.avatar}
            size="50px"
            bgColor="bluePlaceholder"
            textSize={{ long: '17px', short: '17px' }}
          />
        </Box>
        <Flex
          flexDir="column"
          gap="2px"
        >
          <Flex
            fontSize="14px"
            color="backgroundAccent"
            align="center"
            gap="8px"
          >
            <Text>{trimAddress(data.address)}</Text>
            <Image src="/assets/icons/user-check.svg" />
          </Flex>
          <Text
            fontSize="12px"
            color="textSecondary"
          >
            {data.data}
          </Text>
          <Text
            fontSize="12px"
            color="textBlue"
            fontWeight="500"
          >
            {moment(data.date).fromNow()}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
