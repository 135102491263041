import {
  Center,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Image,
  Text,
  Button,
  Link,
  Tooltip,
  Spinner,
  UseDisclosureReturn,
} from '@chakra-ui/react'
import { useAccount } from 'wagmi'
import { _log } from '../../../logger'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { HelloIcon } from '../../icons/Hello'

interface IParams {
  controller: UseDisclosureReturn
  walletOptionsModalController: UseDisclosureReturn
}

export const SignInModal = ({ controller, walletOptionsModalController }: IParams) => {
  const wallet = useEthereumProvider()
  const wagmiAccount = useAccount()
  const navigate = useNavigate()
  const location = useLocation()

  const handleOnboard = () => {
    controller.onClose()
    navigate('/onboarding')
  }

  /* Handle close when using a wallet to log in */
  useEffect(() => {
    if (wallet.isAuthing || wagmiAccount.isConnecting) {
      controller.onClose()
    }
  }, [wallet.isAuthing, wagmiAccount.isConnecting])

  /* Handle close upon log-in success */
  useEffect(() => {
    if (!wallet.account || wallet.isAuthing || wagmiAccount.isConnecting || wallet.isNetworkSwitching) {
      return
    }

    controller.onClose()
  }, [wallet.isAuthing, wagmiAccount.isConnecting, wallet.isNetworkSwitching, wallet.account])

  return (
    <Modal
      isOpen={controller.isOpen}
      onClose={controller.onClose}
      isCentered
      size="3xl"
    >
      <ModalOverlay sx={{ backdropFilter: 'blur(10px)', background: '#0000000f' }} />
      <ModalContent
        borderRadius="16px"
        bg="backgroundMain"
        p="0"
        overflow="hidden"
        mt="30vh"
      >
        <Flex w="100%">
          <Flex
            p="40px 32px"
            borderRadius="16px 0 0 16px"
            flexDir="column"
            justify="space-between"
            minW="440px"
            gap="48px"
          >
            {wallet.isAuthing || wagmiAccount.isConnecting || wallet.isNetworkSwitching ? (
              <Flex sx={{ p: '8px' }}>
                <Spinner
                  boxSize="36px"
                  thickness="4px"
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="space-between"
                minH="52px"
              >
                <HelloIcon color="backgroundTertiary" />
                {location.pathname !== '/onboarding' && (
                  <Flex
                    align="center"
                    gap="8px"
                  >
                    <Text
                      fontSize="14px"
                      color="textQuaternary"
                    >
                      Don't have an account?
                    </Text>
                    <Button
                      p="8px 16px"
                      bg="backgroundTertiary"
                      border="none"
                      fontSize="12px"
                      fontWeight="500"
                      color="textQuaternary"
                      onClick={handleOnboard}
                    >
                      Sign up
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}
            <Flex
              flexDir="column"
              gap="24px"
            >
              <Flex
                flexDir="column"
                gap="8px"
                fontSize="20px"
                fontWeight="600"
                align="center"
              >
                <Flex>
                  Sign in&nbsp;
                  <Text
                    bgGradient="linear(91deg, backgroundAccent 44.37%, #36F 65.01%)"
                    bgClip="text"
                  >
                    to SocialXplorer
                  </Text>
                </Flex>
                <Text
                  fontSize="14px"
                  color="textQuaternary"
                  fontWeight="400"
                >
                  Candao Protocol
                </Text>
              </Flex>
              <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
                <Button
                  w="100%"
                  alignItems="center"
                  gap="8px"
                  onClick={walletOptionsModalController.onOpen}
                >
                  <Image src="/assets/icons/walletConnect-logo.svg" />
                  <Text
                    fontSize="14px"
                    color="textSecondary"
                  >
                    Log in with Wallet
                  </Text>
                </Button>
                <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                  <Flex sx={{ flexGrow: 1, height: '1px', bg: '#D9D9D9', ml: '8px' }} />
                  <Text
                    fontSize="14px"
                    color="textQuaternary"
                    fontWeight="400"
                    width="10ch"
                    textAlign="center"
                  >
                    or
                  </Text>
                  <Flex sx={{ flexGrow: 1, height: '1px', bg: '#D9D9D9', mr: '8px' }} />
                </Flex>
                <Tooltip label="Coming soon">
                  <Button
                    w="100%"
                    alignItems="center"
                    gap="8px"
                    isDisabled
                  >
                    <Image src="/assets/icons/google.svg" />
                    <Text
                      fontSize="14px"
                      color="textSecondary"
                    >
                      Continue with Google
                    </Text>
                  </Button>
                </Tooltip>
              </Flex>
              <Text
                as="span"
                fontSize="12px"
                color="textQuaternary"
              >
                By clicking{' '}
                <Text
                  as="span"
                  fontStyle="italic"
                >
                  Log in with Wallet
                </Text>{' '}
                or{' '}
                <Text
                  as="span"
                  fontStyle="italic"
                >
                  Continue with Google
                </Text>
                , you agree to SocialXplorer&nbsp;
                <Link
                  color="textBlue"
                  as="span"
                >
                  Privacy Policy
                </Link>
                .
              </Text>
            </Flex>
          </Flex>
          <Flex
            position="relative"
            w="100%"
            borderRadius="0 16px 16px 0"
            justify="end"
            bg="borderPrimary"
          >
            <Flex
              bg="url(/assets/icons/onboarding-right.svg)"
              borderRadius="0 16px 16px 0"
              boxSize="100%"
            />
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
