import { Dispatch, SetStateAction, createContext, useContext, useReducer, useState } from 'react'
import { IGroup, emptyGroup } from '../../hooks/useGroup'
import { makeReducer } from '../../utils/react'

export enum GroupCreatorStep {
  Welcome = 'Welcome',
  CreateToken = 'CreateToken',
  ImportToken = 'ImportToken',
}

interface GroupCreationContext {
  onClose: () => void
  step: GroupCreatorStep
  setStep: Dispatch<SetStateAction<GroupCreatorStep>>
  data: Partial<IGroup>
  dispatch: Dispatch<Partial<Partial<IGroup>>>
  isOpen: boolean
}

const emptyGroupCreationContext: GroupCreationContext = {
  onClose: () => null,
  step: GroupCreatorStep.Welcome,
  setStep: () => null,
  data: {},
  dispatch: () => null,
  isOpen: false
}

const GroupCreatorContext = createContext<GroupCreationContext>(emptyGroupCreationContext)

export const GroupCreatorProvider = ({ children, onClose, isOpen }: { children: React.ReactNode; onClose: () => void, isOpen: boolean }) => {
  const [data, dispatch] = useReducer(...makeReducer<Partial<IGroup>>({}))
  const [step, setStep] = useState(GroupCreatorStep.Welcome)
  return (
    <GroupCreatorContext.Provider
      value={{
        onClose,
        step,
        setStep,
        data,
        dispatch,
        isOpen
      }}
    >
      {children}
    </GroupCreatorContext.Provider>
  )
}

export const useGroupCreatorProvider = () => {
  const context = useContext(GroupCreatorContext)
  if (!context) {
    throw new Error('Cannot use GroupCreatorContext outside Provider')
  }

  return context
}
