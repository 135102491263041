import { Box, Flex, Image } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const CardHeader = ({
  title,
  addon = '',
  addonLink = '',
}: {
  title: string
  addon?: string
  addonLink?: string
}) => {
  return (
    <Flex
      flexWrap="wrap"
      justifyContent="space-between"
      align="center"
      mb="16px"
      px="16px"
    >
      <Box
        fontSize="18px"
        color="textPrimary"
        fontWeight="bold"
      >
        {title}
      </Box>
      <Link to={addonLink}>
        <Flex
          flexWrap="wrap"
          fontSize="14px"
          align="center"
          gap="8px"
        >
          {addon}
          {addon && <Image src="/assets/icons/arrow-right.svg" />}
        </Flex>{' '}
      </Link>
    </Flex>
  )
}
