import React from 'react'
import { IUseProfile } from '../../hooks/useProfile'
import { INft } from '../../components/pages/User/UserNfts/UserNfts'

export interface IId extends IIdHelper {
  address_: /** root address ([0]) followed by sub-addresses ([1..]) */ address[]
  name: string
  nickname: string
  description: string
  location: string
  avatar: string
  image: string[]
  background: string
  sex: number
}

export interface IIdHelper {
  address?: address /** CandaoId root address */
}

export interface IFollow {
  address_: address[]
  followed: address[]
  followers: address[]
}

export interface IFriend {
  friends: address[]
  friended: address[]
  frienders: address[]
}

export interface IRole {
  roles: string[]
}

export interface IInterest {
  interests: string[]
}

export interface IProfile extends IId, IFollow, IFriend, IRole, IInterest {
  _created_at: string | number | Date
  _updated_at: string | number | Date
  isOnboarded: boolean
  accessCode?: string
  pendingConnection?: {
    address: address
  }[]
  blockedUsers: address[]
  isAdmin: boolean
  nfts: INft[]
}

export interface IProfileBatch extends Array<IProfile> {}

export const emptyProfile: IProfile = {
  address: undefined,
  address_: [],
  description: '',
  followed: [],
  followers: [],
  friended: [],
  frienders: [],
  friends: [],
  interests: [],
  location: '',
  name: '',
  nickname: '',
  roles: [],
  avatar: '',
  background: '',
  image: [],
  sex: 0,
  _created_at: '',
  _updated_at: '',
  isOnboarded: true,
  blockedUsers: [],
  isAdmin: false,
  nfts: [],
  accessCode: '',
}

export const ProfileProvider = ({ children, value }: { children: React.ReactNode; value: IUseProfile }) => {
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}

const ProfileContext = React.createContext<IUseProfile>({
  ...emptyProfile,
  isLoading: false,
  setProfile: () => null,
})

export const useProfileProvider = () => {
  return React.useContext(ProfileContext)
}
