import React from 'react'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { ButtonFollow } from '../../../../../shared/actions/ButtonFollow'
import { getInitials } from '../../../../../../utils/parser'
import { ShortText } from '../../../../../shared/typography/ShortText'
import { RolesWithIndicator } from '../../../../../shared/roles/RolesWithIndicator'

export const UserFollowersComponent = ({
  address,
  name,
  nickname,
  roles,
  description,
}: {
  address: address
  name: string
  nickname: string
  roles: string[]
  description: string
}) => {
  return (
    <Flex
      flexDirection="column"
      sx={{
        '&:not(:last-of-type)': {
          borderBottom: '1px solid borderPrimary',
        },
      }}
    >
      <Flex
        p="12px 16px"
        justifyContent="space-between"
        w="100%"
      >
        <Link to={`/profile/${address}`}>
          <Flex gridGap="8px">
            <Box
              alignItems="center"
              justifyContent="center"
              w="40px"
              h="40px"
              display="flex"
              borderRadius="50%"
              bgColor="bluePlaceholder"
              color="#2952CC"
              textTransform="uppercase"
            >
              {getInitials(name)}
            </Box>
            <Flex direction="column">
              <ShortText>
                <Text
                  fontSize="14px"
                  color="backgroundAccent"
                >
                  {name}
                </Text>
              </ShortText>
              <ShortText>
                <Text
                  fontSize="12px"
                  color="textSecondary"
                >
                  @{nickname}
                </Text>
              </ShortText>
            </Flex>
          </Flex>
        </Link>
        <Flex
          gridGap="8px"
          height="32px"
        >
          {/* <Button
            borderRadius="8px"
            border="1px solid"
            borderColor="#D8DAE5"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="32px"
          >
            <Image
              src="/assets/icons/dots.svg"
              boxSize="16px"
            />
          </Button> */}
          <Button
            borderRadius="8px"
            border="1px solid"
            borderColor="#D8DAE5"
            display="flex"
            alignItems="center"
            height="32px"
            justifyContent="center"
          >
            <Image
              src="/assets/icons/message-icon.svg"
              boxSize="16px"
            />
          </Button>
          <ButtonFollow target={address} />
        </Flex>
      </Flex>
      <Flex
        p="8px 64px"
        flexDirection="column"
      >
        <Flex
          gridGap="4px"
          flexWrap="wrap"
        >
          <RolesWithIndicator roles={roles} />
        </Flex>
        <Text
          pt="10px"
          fontSize="14px"
          color="textQuaternary"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}

export default UserFollowersComponent
