import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { RoleFeed } from './RoleFeed'
import { useGroupProvider } from '../../../../../../provider/Group/groupProvider'

export const RolesFeed = () => {
  const group = useGroupProvider()
  // const roles = useMemo(
  //   () => Array.from(new Set(group.members?.reduce((acc, current) => [...acc, ...current.roles], [] as string[]))),
  //   [group.members?.length]
  // )

  const roles = useMemo(() => {
    const rolesMap = new Map<string, number>()
    group.members?.forEach(member => {
      member.roles.forEach(role => {
        const v = rolesMap.get(role) ?? 0
        rolesMap.set(role, v + 1)
      })
    })
    return rolesMap
  }, [group.members])

  return (
    <Flex
      p="16px"
      bg="backgroundMain"
      border="1px solid"
      borderColor="borderPrimary"
      borderRadius="16px"
      flexDir="column"
      gap="16px"
    >
      <Flex
        w="100%"
        align="center"
        justify="space-between"
      >
        <Text
          fontWeight="600"
          color="backgroundAccent"
          fontSize="16px"
        >
          Roles
        </Text>
        <Button
          variant="slave"
          color="textBlue"
          isDisabled
        >
          More details
        </Button>
      </Flex>
      <Flex
        flexDir="column"
        gap="6px"
        w="100%"
      >
        {Array.from(roles.keys()).length > 0 ? (
          Array.from(roles.keys()).map(role => (
            <RoleFeed
              key={role}
              roleCategory={role}
              people={roles.get(role) ?? 0}
              rolesNumber={1}
            />
          ))
        ) : (
          <Flex
            flexDir="column"
            gap="8px"
            align="center"
            textAlign="center"
          >
            <Image src="/assets/icons/no-content.svg" />
            <Text
              color="textQuaternary"
              fontSize="16px"
              fontWeight="600"
            >
              No roles yet
            </Text>
            <Text
              fontSize="12px"
              color="textSecondary"
            >
              There aren't any roles yet.
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
