type EToken =
  | 'WETH'
  | 'ETH'
  | 'MATIC'
  | 'SHIB'
  | 'WBTC'
  | 'BNB'
  | 'USDC'
  | 'USDT'
  | 'WBNB'
  | 'BUSD'
  | 'DOGE'
  | 'ADA'
  | 'CDO'

export const TokenIcon: { [key in EToken]: string } = {
  CDO: 'https://bscscan.com/token/images/wcdo_32.png',

  BNB: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg',
  ETH: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg',
  MATIC: 'https://cryptologos.cc/logos/polygon-matic-logo.svg',

  WETH: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg',
  WBNB: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg',
  WBTC: 'https://cryptologos.cc/logos/wrapped-bitcoin-wbtc-logo.svg',

  USDC: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg',
  USDT: 'https://cryptologos.cc/logos/tether-usdt-logo.svg',
  BUSD: 'https://cryptologos.cc/logos/binance-usd-busd-logo.svg',

  DOGE: 'https://cryptologos.cc/logos/dogecoin-doge-logo.svg',
  ADA: 'https://cryptologos.cc/logos/cardano-ada-logo.svg',
  SHIB: 'https://cryptologos.cc/logos/shiba-inu-shib-logo.svg',
}
