const includesInsensitive = (values: string[], value: string) =>
  values.some(element => element?.toLowerCase() === value?.toLowerCase())

const splitByIndex = (data?: string, index: number = 0): string => {
  if (!data || Math.abs(index || 0) > data.length) {
    return ''
  }

  const _index = index < 0 ? data.length + index : index

  return data.substring(0, _index) + '.' + data.substring(_index)
}

export { includesInsensitive, splitByIndex }
