import { Flex, Box, Text } from '@chakra-ui/react'
import { IProfile } from '../../../../provider/Profile/profileProvider'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import useAsyncEffect from '../../../../hooks/effects/async'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import { Link, useNavigate } from 'react-router-dom'
import { Avatar } from '../../avatars/Avatar'

interface IHolders {
  setHoldersAmount: Dispatch<SetStateAction<number>>
}

export const Holders: FC<IHolders> = ({ setHoldersAmount }) => {
  const [holders, setHolders] = useState<IProfile[]>([])

  const navigate = useNavigate()

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        if (holders.length) {
          return
        }

        const data: string[] = await fetchApi('ico/holders')

        setHoldersAmount(data.length)

        if (!data.length) {
          return
        }

        const profiles = await fetchApi('profile', { addresses: data })
        setHolders(profiles)
      }),
    []
  )

  return (
    <Flex direction="column">
      {holders.map(holder => (
        <Flex
          key={`${holder.address_[0]}`}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Link
            to={`/profile/${holder.address_[0]}`}
            style={{
              gap: '8px',
              display: 'flex',
            }}
          >
            <Box position="relative">
              <Avatar
                size="40px"
                bgColor="bluePlaceholder"
                name={holder.name}
                image={holder.avatar}
                textSize={{ short: '12px', long: '12px' }}
              />
            </Box>
            <Flex
              alignItems="start"
              justifyContent="flex-start"
              direction="column"
            >
              <Text
                color="backgroundAccent"
                fontSize="12px"
              >
                {holder.name}
              </Text>
              <Text
                color="textSecondary"
                fontSize="12px"
              >
                @{holder.nickname?.length > 15 ? `${holder.nickname?.slice(0, 15)}…` : holder.nickname}
              </Text>
            </Flex>
          </Link>
        </Flex>
      ))}
    </Flex>
  )
}
