import { Button, Tooltip, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import { useContractsProvider } from '../../../provider/Contracts/contractsProvider'
import { loaded } from '../../../utils/process'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import useAsyncEffect from '../../../hooks/effects/async'
import { trimAddress } from '../../../utils/parser'
import { ContractAlert } from '../../global/wrapper/alerts/ContractAlert'
import { useNavigate } from 'react-router-dom'
import { waitForTransaction } from '@wagmi/core'
import { ENetwork } from '../../../enum/network.enum'
import { getNetwork } from '@wagmi/core'
import { getTheme } from '../../../theme/global'

interface IProps {
  target?: address
  description?: { placement: string }
  width?: string
}

export const ButtonFollow = ({ target, description = { placement: 'right' }, width }: IProps) => {
  const [isAccountFollowing, setIsAccountFollowing] = useState<boolean>()
  const [isProcessing, setIsProcessing] = useState(false)
  const [txHash, setTxHash] = useState<txHash>()

  const wallet = useEthereumProvider()
  const user = useAccountProfileProvider()
  const contracts = useContractsProvider()
  const toast = useToast({})
  const history = useNavigate()
  const { chain } = getNetwork()

  const isWrongNetwork = ENetwork.FALLBACK !== chain?.id
  const isSelf = target === wallet.account?.toLowerCase() && !!wallet.account
  const isDisabled = isSelf || !wallet.isLoggedIn || isWrongNetwork
  const canFollow = !(isSelf || isAccountFollowing)

  const handleFollow = () =>
    loaded(async () => {
      if (wallet.account?.toLowerCase() === target) {
        return
      }

      if (!wallet.account) {
        await wallet.connect()
      }

      const tx = await (isAccountFollowing
        ? contracts.follow?.write.unfollow([target])
        : contracts.follow?.write.follow([target]))
      setTxHash(tx)
    }, setIsProcessing)

  /** State init */
  useEffect(() => setIsAccountFollowing(target && user.followed?.includes(target)), [user.followed, target])

  /** Loading state sync */
  useAsyncEffect(async () => {
    if (!txHash) {
      return
    }

    setIsAccountFollowing(prevState => !prevState)
    toast({
      render: () => (
        <ContractAlert
          content={trimAddress(target)!}
          class_="CandaoFollow"
          action={{ call: () => history(`/tx/${txHash}`), description: 'See transaction' }}
        />
      ),
    })

    // await loaded(
    //   async () => {
    //     const result = await waitForTransaction({ hash: txHash })

    //     /** Handle success
    //      *  - flip the state
    //      *  - show a toast
    //      */
    //     setIsAccountFollowing(prevState => !prevState)
    //     toast({
    //       render: () => (
    //         <ContractAlert
    //           content={trimAddress(target)!}
    //           class_="CandaoFollow"
    //           action={{ call: () => history(`/tx/${result?.transactionHash}`), description: 'See transaction' }}
    //         />
    //       ),
    //     })
    //   },
    //   setIsProcessing,
    //   () => toast({ title: `[CandaoFollow]: Please, check your wallet connection`, status: 'error' })
    // )
  }, [txHash])

  return (
    <Tooltip
      label={canFollow ? null : 'Unfollow'}
      placement={description?.placement as any}
    >
      <Button
        width={width}
        fontSize="12px"
        borderRadius="8px"
        border="1px solid"
        borderColor="#D8DAE5"
        display="flex"
        height="auto"
        py="10px"
        isDisabled={isDisabled}
        isLoading={isProcessing}
        onClick={handleFollow}
        textTransform="capitalize"
        variant={canFollow ? 'dark' : 'light'}
      >
        {canFollow ? 'follow' : 'following'}
      </Button>
    </Tooltip>
  )
}
