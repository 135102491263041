import { Flex, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

interface IUserWallLayout {
  heading?: string
  headingDetails?: React.ReactNode
  children?: React.ReactNode
}

export const UserWallLayout: FC<IUserWallLayout> = ({ heading, headingDetails, children }) => {
  return (
    <Flex
      flexDirection="column"
      w={{ base: '100vw', sm: '100%', lg: 'auto' }}
      borderRadius={{ base: '0', sm: '16px' }}
      gridGap="2px"
      border="1px solid"
      borderColor="borderPrimary"
      bgColor="backgroundMain"
    >
      <Flex
        p="16px"
        justifyContent="space-between"
      >
        <Text
          fontSize="16px"
          fontWeight="600"
          color="backgroundAccent"
        >
          {heading}
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          color="textSecondary"
        >
          {headingDetails}
        </Text>
      </Flex>
      {children}
    </Flex>
  )
}
