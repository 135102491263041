import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Flex,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
} from '@chakra-ui/react'
import { CardContainer } from '../../shared/containers/CardContainer'
import { Colors } from '../../../theme/colors'
import { IPostSchema } from '../Home/MiddlePanel/deafult/Default'
import useAsyncEffect from '../../../hooks/effects/async'
import { loadavg } from 'os'
import { loaded } from '../../../utils/process'
import { fetchApi } from '../../../utils/fetcher'
import { Post } from '../Home/MiddlePanel/roles/tabs/discussions/Post/Post'
import SpinnerText from '../../shared/tags/SpinnerText'

export const Reported = () => {
  const [posts, setPosts] = useState<IPostSchema[]>([])
  const [isMoreLoading, setIsMoreLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isEnd, setIsEnd] = useState(false)
  const [resultType, setResultType] = useState<'none' | 'posts' | 'profiles'>('none')

  useAsyncEffect(
    async () =>
      await loaded(async () => {
        const posts = await fetchApi(`admin/getPosts/${page}`)
        if (posts.length > 0) {
          setPosts(prev => prev.concat(posts))
        } else {
          setIsEnd(true)
        }
      }, setIsMoreLoading),
    [page]
  )

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      if (isEnd || isMoreLoading) {
        return
      }

      setPage(prev => prev + 1)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Flex
      direction="column"
      gap="12px"
      align="center"
    >
      <Flex
        flexDirection="column"
        w={{ base: 'auto', lg: '648px' }}
        borderRadius={{ base: '0', sm: '16px' }}
        gridGap="2px"
        border="1px solid"
        borderColor="borderPrimary"
        borderX={{ base: 'none', sm: '1px solid' }}
        borderLeftColor="borderPrimary !important"
        borderRightColor="borderPrimary !important"
        bgColor="backgroundMain"
        mx={{ sm: '16px', md: '0' }}
        pb="16px"
      >
        <Flex
          w="100%"
          px="16px"
          pt="16px"
          justifyContent="space-between"
        >
          <Text
            as="span"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
            color="backgroundAccent"
          >
            Reported
          </Text>
        </Flex>
        <Flex
          gap="6px"
          marginTop="6px"
          px="16px"
        >
          <Button
            // isDisabled={totalCounts.txs == 0 || !search}
            height="32px"
            color="textQuaternary"
            bg={resultType == 'posts' ? 'backgroundAccentLight' : 'backgroundMain'}
            onClick={() => setResultType(resultType == 'posts' ? 'none' : 'posts')}
            border="1px solid"
            borderColor={resultType == 'posts' ? 'bluePlaceholder' : '#D8DAE5'}
          >
            {/* Transactions: {search ? totalCounts.txs : 0} */}
            Posts: {posts ? posts.length : <SpinnerText />}
          </Button>
          <Button
            // isDisabled={totalCounts.profile == 0 || !search}
            height="32px"
            color="textQuaternary"
            onClick={() => setResultType(resultType == 'profiles' ? 'none' : 'profiles')}
            bg={resultType == 'profiles' ? 'backgroundAccentLight' : 'backgroundMain'}
            border="1px solid"
            borderColor={resultType == 'profiles' ? 'bluePlaceholder' : '#D8DAE5'}
          >
            {/* Profiles: {search ? totalCounts.profile : 0} */}
            Profiles: 10
          </Button>
        </Flex>
      </Flex>
      {resultType == 'posts' ? (
        posts.map((post, index) => (
          <Post
            data={post}
            key={`${post.ipfs}-${index}`}
          />
        ))
      ) : (
        <Text>Reported profiles</Text>
      )}
      <Flex sx={{ boxSize: '24px', pt: '16px' }}>{isMoreLoading && <Spinner />}</Flex>
    </Flex>
  )
}
