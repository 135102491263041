import { Box, Flex, Image } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export const BackToTop = () => {
  const [isScrollable, setisScrollable] = useState(false)

  const { id } = useParams()

  useEffect(() => setisScrollable(document.documentElement.scrollHeight > window.innerHeight), [id])

  return isScrollable ? (
    <Flex
      flexWrap="wrap"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      align="center"
      gap="8px"
      cursor="pointer"
      color="backgroundAccent"
      opacity=".5"
    >
      <Image src="/assets/icons/back-to-top.svg" />
      <Box userSelect="none">Back to top</Box>
    </Flex>
  ) : (
    <></>
  )
}
