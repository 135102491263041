import React from 'react'
import { IPostSchema, PostType, emptyPostSchema } from '../../components/pages/Home/MiddlePanel/deafult/Default'

const emptyPost: IPostSchema = {
  ipfs: '',
  _created_at: '',
  type: 'Post' as PostType,
}

export const PostProvider = ({ children, post }: { children: React.ReactNode; post: IPostSchema }) => {
  return <PostContext.Provider value={post}>{children}</PostContext.Provider>
}

const PostContext = React.createContext(emptyPost)

export const usePostProvider = () => {
  const context = React.useContext(PostContext)

  if (!context) {
    throw new Error('`usePostProvider` cannot be used outside of a `PostProvider`!')
  }

  return context
}
