import { Button, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { useGroupProvider } from '../../../../../../provider/Group/groupProvider'
import { IProfile } from '../../../../../../provider/Profile/profileProvider'
import useAsyncEffect from '../../../../../../hooks/effects/async'
import { loaded } from '../../../../../../utils/process'
import { fetchApi } from '../../../../../../utils/fetcher'
import { Member } from './Member'
import { _log } from '../../../../../../logger'

export const TopMembers = () => {
  const [profiles, setProfiles] = React.useState<IProfile[]>([])
  const [areProfilesLoading, setAreProfilesLoading] = React.useState(true)

  const group = useGroupProvider()

  useAsyncEffect(async () => {
    if (!group.members?.length) {
      setAreProfilesLoading(false)
      return
    }

    await loaded(async () => {
      const res = await fetchApi('profile', { addresses: group.members?.map(member => member.address.toLowerCase()) })
      setProfiles(res)
    }, setAreProfilesLoading)
  }, [group.members?.length])

  return (
    <Flex
      p="16px"
      bg="backgroundMain"
      border="1px solid"
      borderColor="borderPrimary"
      borderRadius="16px"
      flexDir="column"
      gap="16px"
    >
      <Flex
        w="100%"
        align="center"
        justify="space-between"
      >
        <Text
          fontWeight="600"
          color="backgroundAccent"
          fontSize="16px"
        >
          Top members
        </Text>
        <Button
          variant="slave"
          isDisabled
        >
          <Image src="/assets/icons/userAddBlue.svg" />
        </Button>
      </Flex>
      {areProfilesLoading ? (
        <Center sx={{ py: '20px' }}>
          <Spinner />
        </Center>
      ) : profiles.length > 0 ? (
        profiles.map(profile => (
          <Member
            key={`${profile.address_[0]}`}
            data={profile}
          />
        ))
      ) : (
        <Flex
          flexDir="column"
          gap="8px"
          align="center"
          textAlign="center"
        >
          <Image src="/assets/icons/members.svg" />
          <Text
            fontSize="16px"
            color="textQuaternary"
            fontWeight="600"
          >
            No members yet
          </Text>
          <Text
            fontSize="12px"
            color="textSecondary"
          >
            There aren't any members yet.
          </Text>
          <Button
            variant="slave"
            isDisabled
            color="textBlue"
          >
            Invite members
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
