import { Flex, Text } from '@chakra-ui/react'
import { IProfile } from '../../../../../../provider/Profile/profileProvider'
import { Avatar } from '../../../../../shared/avatars/Avatar'
import { Link } from 'react-router-dom'
import SpinnerText from '../../../../../shared/tags/SpinnerText'

interface IProps {
  data: IProfile
}

export const Member = ({ data }: IProps) => {
  return (
    <Link
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      to={`/profile/${data.address_[0]}`}
    >
      <Flex sx={{ alignItems: 'center', gap: '8px' }}>
        <Avatar
          name={data.name}
          bgColor="bluePlaceholder"
          image={data.avatar}
          size="40px"
          textSize={{ short: '12px', long: '12px' }}
        />
        <Flex
          flexDir="column"
          align="normal"
          gap="0"
        >
          <Text
            fontSize="14px"
            lineHeight="20px"
            color="backgroundAccent"
            mb="2px"
          >
            {data.name || 'No name'}
          </Text>
          <Text
            color="textSecondary"
            fontSize="12"
            lineHeight="16px"
            wordBreak="break-all"
            mb="2px"
            overflow="hidden"
            sx={{
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
            }}
          >
            @{data.nickname}
          </Text>
        </Flex>
      </Flex>
      <Flex sx={{ flexDirection: 'column', alignItems: 'end' }}>
        <SpinnerText />
        <Text sx={{ fontSize: '12px', color: 'textSecondary' }}>Market cap</Text>
      </Flex>
    </Link>
  )
}
