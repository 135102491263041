import { Image, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import React from 'react'

export const GroupContentSearch = ({
  placeholder,
  searchAction,
  isDisabled,
}: {
  placeholder: string
  searchAction: (e: any) => void
  isDisabled?: boolean
}) => {
  return (
    <InputGroup>
      <InputLeftElement>
        <Image src="/assets/icons/search.svg" />
      </InputLeftElement>
      <Input
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={searchAction}
        _focus={{ border: '1px solid !important', borderColor: 'borderPrimary !important', outline: 'none !important' }}
        _hover={{ border: '1px solid !important', borderColor: 'borderPrimary !important' }}
      />
    </InputGroup>
  )
}
