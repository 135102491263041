import { useState } from 'react'
import { Button, Flex, Tooltip } from '@chakra-ui/react'

export enum Switcher {
  Posts,
  Roles,
  Following,
  Followers,
  Friends,
  Transactions,
  Interests,
  Domains,
  Groups,
}

export const contentSwitcher = [
  { id: Switcher.Posts, label: 'Posts' },
  { id: Switcher.Roles, label: 'Roles' },
  { id: Switcher.Following, label: 'Following' },
  { id: Switcher.Followers, label: 'Followers' },
  { id: Switcher.Friends, label: 'Friends' },
  { id: Switcher.Transactions, label: 'Transactions' },
  { id: Switcher.Interests, label: 'Interests' },
  { id: Switcher.Domains, label: 'Domains' },
  { id: Switcher.Groups, label: 'Groups' },
]

export const UserContentSwitcher = ({ value, setValue }: { value: any; setValue: any }) => {
  const [isActive, setIsActive] = useState(Switcher.Posts)

  const handlerActive = (value: Switcher) => {
    setIsActive(value)
    setValue(value)
  }

  return (
    <Flex
      display={{ base: 'none', lg: 'flex' }}
      w={{ base: '100%', sm: 'auto' }}
      flexDirection="column"
      gridGap="2px"
      border="1px solid"
      borderColor="borderPrimary"
      borderX={{ base: 'none', lg: '1px solid' }}
      borderLeftColor="borderPrimary !important"
      borderRightColor="borderPrimary !important"
      bgColor="backgroundMain"
      borderRadius="16px"
      p="16px"
      alignSelf="flex-start"
    >
      {contentSwitcher.map(tab => {
        return (
          <Tooltip
            key={tab.id}
            label={[Switcher.Domains].includes(tab.id) && 'Implementation preview.'}
          >
            <Button
              isDisabled={[Switcher.Domains].includes(tab.id)}
              display="flex"
              __css={{}}
              _disabled={{ filter: 'grayscale(1)', opacity: '.5', cursor: 'not-allowed' }}
              key={tab.label}
              _hover={{
                background: 'backgroundAccentLight',
              }}
              onClick={() => handlerActive(tab.id)}
              bgColor={isActive === tab.id ? 'backgroundAccentLight' : 'transparent'}
              cursor="pointer"
              borderRadius="4px"
              p="6px 16px"
              color={isActive === tab.id ? 'textBlue' : 'textSecondary'}
            >
              {tab.label}
            </Button>
          </Tooltip>
        )
      })}
    </Flex>
  )
}

export default UserContentSwitcher
