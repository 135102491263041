import { Flex } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

export default function MaxWidthWrapper({
  children,
  disabledPages,
  maxW,
}: {
  children: React.ReactNode
  disabledPages: string[]
  maxW: string
}) {
  const location = useLocation()
  const isDisabled = disabledPages.includes(location.pathname)

  const isPaddingDisabled = ["/profile/*"];

  if (isDisabled) {
    return <>{children}</>
  } else {
    return (
      <Flex
        maxW={maxW}
        mx="auto"
        w="100%"
        px={{base: isPaddingDisabled.includes(location.pathname) ? "16px" : "0", md: "16px"}}
      >
        {children}
      </Flex>
    )
  }
}
