import { Flex, Image, Spinner } from '@chakra-ui/react'
import { Message, Thread } from '../../../../../provider/Chat/types'
import { buildIpfsGateway } from '../../../../../utils/parser'
import { useEffect, useRef } from 'react'
import { useChatProvider } from '../../../../../provider/Chat/chatProvider'

export const MessagesList = ({ thread, address }: { thread: Thread; address: string }) => {
  const { loadThread } = useChatProvider()

  const bottomRef = useRef(null)

  useEffect(() => {
    ;(bottomRef as any).current?.scroll({ top: 9991500000 })
  }, [thread?.scrollTrigger])

  const observerTarget = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          if (thread.id) {
            loadThread(thread.id)
          }
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [observerTarget, thread.id])

  return (
    <Flex
      flexDir="column"
      px="20px"
      mb="110px"
      pos="relative"
      ref={bottomRef}
      overflowX="scroll"
      h="60vh"
      gap="5px"
    >
      <Flex ref={observerTarget}></Flex>
      <Flex
        w="100%"
        justify="center"
        align="center"
      >
        {thread?.loading && thread.messages.length != 0 && <Spinner boxSize="20px" />}
      </Flex>

      {thread.messages.length == 0 ? (
        <Flex margin="auto">
          <Spinner />
        </Flex>
      ) : (
        thread.messages.map((m: Message, i: number) => {
          if (i == 0) return
          return (
            <Flex
              fontSize="14px"
              color={address == m.sender ? 'backgroundMain' : 'textQuaternary'}
              padding="6px 8px"
              flexDir="column"
              maxW="50%"
              mb={i == thread.messages.length - 1 ? '10px' : '0px'}
              mt={i == 1 ? '10px' : '0px'}
              borderRadius="8px"
              bg={address == m.sender ? '#3366FF' : '#EDEFF5'}
              alignSelf={address == m.sender ? 'flex-end' : 'flex-start'}
            >
              <Flex>{m.text}</Flex>
              <Flex>
                {m.assets &&
                  m.assets.map((asset: any) => {
                    if (asset.type == 'image') {
                      return (
                        <Image
                          key={asset.path}
                          src={buildIpfsGateway(asset.path)}
                        />
                      )
                    }
                  })}
              </Flex>
            </Flex>
          )
        })
      )}
    </Flex>
  )
}
