import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import Welcome from './stages/Welcome'
import Comparison from './stages/Comparison'
import Photos from './stages/Photos'
import Info from './stages/Info'
import Roles from './stages/Roles'
import Interests from './stages/Interests'
import Summary from './stages/Summary'
import Payment from './stages/Payment'
import Result from './stages/Result'

import { useProfileProvider } from '../../../../provider/Profile/profileProvider'
import { loaded } from '../../../../utils/process'
import { fetchApi } from '../../../../utils/fetcher'
import { ContractAlert } from '../../../global/wrapper/alerts/ContractAlert'
import { trimAddress } from '../../../../utils/parser'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { useContractsProvider } from '../../../../provider/Contracts/contractsProvider'
import { useNavigate } from 'react-router-dom'
import { useAccountProfileProvider } from '../../../../provider/AccountProfile/context'
import SpinnerText from '../../../shared/tags/SpinnerText'
import { IInterest, useUserOnboardingProvider } from '../../../../provider/UserOnboarding/userOnboardingProvider'
import { waitForTransaction } from '@wagmi/core'
import { formatUnits } from 'viem'
import useWindowDimensions from '../../../../hooks/utils/useWindowDimensions'

export enum Stage {
  WELCOME,
  COMPARISON,
  PHOTOS,
  INFO,
  ROLES,
  INTERESTS,
  SUMMARY,
  PAYMENT,
  RESULT,
}

export enum PaymentResult {
  SENDING,
  SUCCESS,
  FAILURE,
}

interface IUserOnboarding {
  close: () => void
}

export const parseInterests = (interests: string[]): IInterest[] => {
  if (!interests) return []
  return interests.map((interest: string): IInterest => {
    return {
      icon: '',
      value: interest,
      label: interest,
      color: '',
      interestedAmount: '0',
      remove: (name: string) => null,
    }
  })
}

export const interestsToStringArray = (interests: IInterest[]): string[] => {
  return interests.map((interest: IInterest) => interest.value)
}

export default function UserOnboarding({ close }: IUserOnboarding) {
  const [stage, setStage] = useState<Stage>(Stage.WELCOME)
  const profile = useProfileProvider()

  const {
    name,
    username,
    description,
    images,
    roles,
    interests,
    setName,
    setUsername,
    setDescription,
    setRoles,
    setInterests,
    setImages,
  } = useUserOnboardingProvider()

  const [paymentResult, setPaymentResult] = useState<PaymentResult>(PaymentResult.SENDING)

  const ethereum = useEthereumProvider()
  const contracts = useContractsProvider()
  const toast = useToast()
  const history = useNavigate()

  const { address_ } = useAccountProfileProvider()

  const [resultTx, setResultTx] = useState<string | undefined>()

  const estimateGas = async (): Promise<string> => {
    const _data = [
      address_,
      name ?? '',
      '' /** @deprecated */,
      username ?? '',
      '' /** @deprecated */,
      [] /** @deprecated */,
      description ?? '',
      '',
      '',
      images /** TODO image array */ ?? [],
      null /** TODO bg image */ ?? '',
      null /** TODO sex constant */ ?? 0,
    ]
    const _dataExternal = [roles ?? [], interests ?? []]
    const _mask = [
      true,
      name !== profile.name,
      false,
      username !== profile.nickname,
      false,
      false,
      description !== profile.description,
      false,
      false,
      images !== profile.image,
      false,
      false,
      roles !== profile.roles,
      interests !== profile.interests,
    ]

    const estimatedGas = (await contracts.id?.estimateGas.setId([_data, _dataExternal, _mask])) ?? 0
    return formatUnits(BigInt(estimatedGas), 8).toString()
  }

  const handleSubmit = async () => {
    setPaymentResult(PaymentResult.SENDING)
    setStage(Stage.RESULT)
    const _data = [
      address_,
      name ?? '',
      '' /** @deprecated */,
      username ?? '',
      '' /** @deprecated */,
      [] /** @deprecated */,
      description ?? '',
      '',
      '',
      images /** TODO image array */ ?? [],
      null /** TODO bg image */ ?? '',
      null /** TODO sex constant */ ?? 0,
    ]
    const _dataExternal = [roles ?? [], interests ?? []]
    const _mask = [
      true,
      name !== profile.name,
      false,
      username !== profile.nickname,
      false,
      false,
      description !== profile.description,
      false,
      false,
      images !== profile.image,
      false,
      false,
      roles !== profile.roles,
      interests !== profile.interests,
    ]

    /** Blockchain path */
    await loaded(
      async () => {
        const tx = await contracts.id?.write.setId([_data, _dataExternal, _mask])
        /** IPFS path */
        void (async () =>
          await fetchApi('id/upload', {
            name,
            nickname: username,
            description,
          }))()

        setPaymentResult(PaymentResult.SUCCESS)
        setResultTx(tx)

        close()
        toast({
          render: () => (
            <ContractAlert
              content={trimAddress(tx)!}
              class_="CandaoId"
              action={{
                call: () => history(`/tx/${tx}`),
                description: 'See transaction',
              }}
            />
          ),
        })
      },
      undefined,
      error => {
        setPaymentResult(PaymentResult.FAILURE)

        if (error.cause?.code === 4001) {
          return toast({
            title: `[CandaoId]: Cancelled`,
            status: 'info',
          })
        }

        return toast({
          title: `[CandaoId]: Please, check your wallet connection`,
          status: 'error',
        })
      }
    )
  }

  useEffect(() => {
    setName(profile.name ?? '')
    setUsername(profile.nickname ?? '')
    setDescription(profile.description ?? '')
    setRoles(profile.roles ?? [])
    setInterests(profile.interests ?? [])
    setImages(profile.image ?? [])
  }, [])

  const { width } = useWindowDimensions()

  useEffect(() => {
    if ((width ?? 0) >= 768) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  const stageContent = useMemo(() => {
    if (stage === Stage.WELCOME) return <Welcome setStage={setStage} />
    else if (stage === Stage.COMPARISON) return <Comparison setStage={setStage} />
    else if (stage === Stage.PHOTOS) return <Photos setStage={setStage} />
    else if (stage === Stage.INFO)
      return (
        <Info
          setStage={setStage}
          estimateGas={estimateGas}
        />
      )
    else if (stage === Stage.ROLES)
      return (
        <Roles
          estimateGas={estimateGas}
          setStage={setStage}
        />
      )
    else if (stage === Stage.INTERESTS)
      return (
        <Interests
          estimateGas={estimateGas}
          setStage={setStage}
        />
      )
    else if (stage === Stage.SUMMARY) return <Summary setStage={setStage} />
    else if (stage == Stage.PAYMENT)
      return (
        <Payment
          handleSubmit={handleSubmit}
          setStage={setStage}
        />
      )
    else if (stage == Stage.RESULT)
      return (
        <Result
          setStage={setStage}
          close={close}
          paymentResult={paymentResult}
          address={resultTx ?? ''}
        />
      )
  }, [stage, name, username, description, roles, interests, paymentResult, resultTx])

  if ((width ?? 0) >= 768) {
    return (
      <Center
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        transition="all 1s ease-in-out"
        zIndex="sticky"
        style={{
          backdropFilter: 'blur(6px) brightness(.95)',
          WebkitBackdropFilter: 'blur(6px) brightness(.95)',
          maxHeight: '100%',
          overflow: 'hidden',
        }}
      >
        <Wrapper>{stageContent}</Wrapper>
      </Center>
    )
  } else {
    return (
      <>
        <Box
          position="absolute"
          top="0"
          left="0"
          zIndex="sticky"
          style={{ backdropFilter: 'blur(6px) brightness(.95)' }}
          w="100%"
        />
        <MobileWrapper>{stageContent}</MobileWrapper>
      </>
    )
  }
}

function Wrapper({ children }: any) {
  return (
    <Modal
      isOpen={true}
      onClose={() => null}
      size="xl"
    >
      <ModalContent
        marginBottom={{ base: '10px', md: '64px' }}
        mt={72 + 16 + 64 + 'px'}
        borderRadius="16px"
        zIndex="modal"
        position="absolute"
        mx="10px"
        width="auto"
        bg="transparent"
        boxShadow="none !important"
        pb="48px"
      >
        <Flex
          display="flex"
          direction="column"
          background="backgroundMain"
          borderRadius="16px"
          boxShadow="var(--modal-shadow)"
          style={{ boxShadow: '0px 12px 40px rgba(16, 24, 64, 0.24)' }}
          zIndex="modal"
          width="550px"
          overflow="visible"
        >
          {children}
        </Flex>
      </ModalContent>
    </Modal>
  )
}

function MobileWrapper({ children }: { children: ReactNode }) {
  return (
    <Flex
      width="100%"
      zIndex="modal"
      position="absolute"
      left="0"
      top="104px"
      height="max-content"
    >
      <Flex
        display="flex"
        direction="column"
        background="backgroundMain"
        borderRadius="16px"
        borderTopRadius={{ base: '0', md: '16px' }}
        style={{ boxShadow: '0px 12px 40px rgba(16, 24, 64, 0.24)' }}
        zIndex="modal"
        width="100%"
        overflow="scroll"
      >
        {children}
      </Flex>
    </Flex>
  )
}

export function Footer({
  estimatedCost,
  action,
  goBack,
  isLoading = false,
}: {
  estimatedCost?: string
  action: () => void
  goBack: () => void
  isLoading?: boolean
}) {
  return (
    <>
      <Divider
        orientation="horizontal"
        mt="24px"
      />
      <Flex
        py="16px"
        gap="8px"
        justifyContent="space-between"
        alignItems="center"
        mx="24px"
      >
        {estimatedCost ? (
          <Box
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            fontSize="12px"
            gap="8px"
          >
            <Text color="textSecondary">Estimated cost</Text>
            {isLoading ? <SpinnerText /> : <Text color="textQuaternary">{estimatedCost}</Text>}
          </Box>
        ) : (
          <></>
        )}
        <Box>
          <Button onClick={goBack}>
            <Text>Back</Text>
          </Button>
          <Button
            background="backgroundAccent"
            onClick={action}
            ml="8px"
            variant="dark"
          >
            <Text color="#FAFBFF">Continue</Text>
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export function CancelModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const navigate = useNavigate()
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent mt={{ base: '200px', md: '300px' }}>
          <ModalHeader>Cancel onboarding</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Are you sure you want to cancel onboarding?</p>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
            >
              No, go back
            </Button>
            <Button
              variant="destructive"
              onClick={() => navigate('/')}
            >
              Cancel onboarding
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export function Heading({ step, heading, subheading }: { step: number; heading: string; subheading: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding="16px 24px"
      >
        <Text
          color="backgroundAccent"
          fontWeight="600"
          fontSize="18px"
        >
          Complete your profile
        </Text>
        <Image
          cursor="pointer"
          src="/assets/icons/close-black.svg"
          onClick={onOpen}
        />
      </Flex>

      <CancelModal
        isOpen={isOpen}
        onClose={onClose}
      />

      <Divider orientation="horizontal" />
      <Stepper step={step} />
      <Divider orientation="horizontal" />
      <Box padding="24px">
        <Text
          color="backgroundAccent"
          fontWeight="600"
          fontSize="16px"
        >
          {heading}
        </Text>
        <Text
          fontSize="15px"
          color="#454F5B"
          lineHeight="15px"
          fontWeight="400"
        >
          {subheading}
        </Text>
      </Box>
    </>
  )
}

export function Stepper({ step }: { step: number }) {
  return (
    <Flex
      justifyContent="space-between"
      mx="24px"
      gap="16px"
      py="20px"
      overflowX={{ base: 'scroll', md: 'hidden' }}
      scrollMarginY="10px"
      pointerEvents="none"
      userSelect="none"
      sx={{
        '::-webkit-scrollbar': {
          height: '6px',
          marginY: '30px',
          border: 'none',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#36F',
          borderRadius: '8px',
        },
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Badge
          background={step === 1 ? 'bluePlaceholder' : step > 1 ? '#DCF2EA' : '#EDEFF5'}
          color={step == 1 ? '#2952CC' : 'textSecondary'}
          height="16px"
          width="16px"
          display="grid"
          placeContent="center"
        >
          {step > 1 ? <Image src="/assets/icons/check.svg" /> : 1}
        </Badge>
        <Text
          color={step == 1 ? '#3366FF' : 'textSecondary'}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Comparison
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Badge
          background={step === 2 ? 'bluePlaceholder' : step > 2 ? '#DCF2EA' : '#EDEFF5'}
          color={step == 2 ? '#2952CC' : 'textSecondary'}
          height="16px"
          width="16px"
          display="grid"
          placeContent="center"
        >
          {step > 2 ? <Image src="/assets/icons/check.svg" /> : 2}
        </Badge>
        <Text
          color={step == 2 ? '#3366FF' : 'textSecondary'}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Photos
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Badge
          background={step === 3 ? 'bluePlaceholder' : step > 3 ? '#DCF2EA' : '#EDEFF5'}
          color={step == 3 ? '#2952CC' : 'textSecondary'}
          height="16px"
          width="16px"
          display="grid"
          placeContent="center"
        >
          {step > 3 ? <Image src="/assets/icons/check.svg" /> : 3}
        </Badge>
        <Text
          color={step == 3 ? '#3366FF' : 'textSecondary'}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Info
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Badge
          background={step === 4 ? 'bluePlaceholder' : step > 4 ? '#DCF2EA' : '#EDEFF5'}
          color={step == 14 ? '#2952CC' : 'textSecondary'}
          height="16px"
          width="16px"
          display="grid"
          placeContent="center"
        >
          {step > 4 ? <Image src="/assets/icons/check.svg" /> : 4}
        </Badge>
        <Text
          color={step == 4 ? '#3366FF' : 'textSecondary'}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Roles
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Badge
          background={step === 5 ? 'bluePlaceholder' : step > 5 ? '#DCF2EA' : '#EDEFF5'}
          color={step == 5 ? '#2952CC' : 'textSecondary'}
          height="16px"
          width="16px"
          display="grid"
          placeContent="center"
        >
          {step > 5 ? <Image src="/assets/icons/check.svg" /> : 5}
        </Badge>
        <Text
          color={step == 5 ? '#3366FF' : 'textSecondary'}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Interests
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="8px"
      >
        <Badge
          background={step === 6 ? 'bluePlaceholder' : step > 6 ? '#DCF2EA' : '#EDEFF5'}
          color={step == 6 ? '#2952CC' : 'textSecondary'}
          height="16px"
          width="16px"
          display="grid"
          placeContent="center"
        >
          {step > 6 ? <Image src="/assets/icons/check.svg" /> : 6}
        </Badge>
        <Text
          color={step == 6 ? '#3366FF' : 'textSecondary'}
          fontSize="12px"
          fontWeight="400"
          lineHeight="16px"
        >
          Summary
        </Text>
      </Flex>
    </Flex>
  )
}
