import React, { FC } from 'react'
import { useProfileProvider } from '../../../provider/Profile/profileProvider'
import { Avatar } from './Avatar'

interface IUserAvatarProps {
  bgColor: string
  size: string | number
  children?: React.ReactNode
  image?: string
  borderRadius?: string
}

export const UserAvatar: FC<IUserAvatarProps> = ({ bgColor, image, size, children }) => {
  const profile = useProfileProvider()

  return <Avatar {...{ bgColor, image, size, name: profile.name, isLoading: profile.isLoading, children }} />
}
