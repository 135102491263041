import { Flex, Image, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

interface IListItemProps {
  icon?: string
  text: string
  action?: () => void
}

export const ListItem: FC<IListItemProps> = ({ icon, text, action }) => {
  return (
    <Flex
      borderRadius="4px"
      onClick={action}
      align="center"
      w="100%"
      gap="8px"
      fontSize="14px"
      color="textQuaternary"
      p="6px 8px"
      _hover={{ bgColor: 'borderPrimary' }}
      cursor="pointer"
    >
      {icon && (
        <Image
          src={icon}
          boxSize="16px"
        />
      )}
      <Text>{text}</Text>
    </Flex>
  )
}
