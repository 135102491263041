import { Alert, AlertIcon, AlertTitle, Box, Button, chakra } from '@chakra-ui/react'
import React, { useState } from 'react'

interface IProps {
  class_?: string
  content: string
  action?: {
    call: () => void
    description: string
  }
}

export const ContractAlert = ({ class_, content, action }: IProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const id = `${class_}_pending`

  return (
    <Alert
      addRole={false}
      status="info"
      variant="solid"
      {...{ id }}
      alignItems="center"
      borderRadius="md"
      boxShadow="lg"
      paddingEnd={8}
      textAlign="start"
      width="auto"
    >
      <AlertIcon />
      <chakra.div
        pl="8px"
        flex="1"
        gap="8px"
        alignItems="center"
      >
        <AlertTitle id="toast-error_network-title">{`[${class_}]: ${content}`}</AlertTitle>
        <Box>
          <Button
            mt="12px"
            width="100%"
            variant="lightOnDark"
            fontWeight="bold"
            color="textPrimary"
            autoFocus={true}
            isLoading={isLoading}
            onClick={action?.call}
          >
            {action?.description}
          </Button>
        </Box>
      </chakra.div>
    </Alert>
  )
}
