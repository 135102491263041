import { Divider, PopoverContent } from '@chakra-ui/react'
import { ListItem } from './ListItem'
import { redirect } from '../../../../../utils/redirector'
import { useEthereumProvider } from '../../../../../provider/Ethereum/ethereumProvider'
import { useNavigate } from 'react-router-dom'
import { AddressZero } from '../../../../../utils/ethereum'
import { isMobile } from 'react-device-detect'

interface IProps {
  controller: { onClose: () => void }
}

export const ProfilePopup = ({ controller }: IProps) => {
  const wallet = useEthereumProvider()
  const navigate = useNavigate()

  const handleDisconnect = async () => {
    /** Set logout flag */
    localStorage.setItem('shouldReconnect', '')

    wallet.disconnect(wallet.account as address)
  }

  return (
    <PopoverContent
      onClick={controller.onClose}
      position="absolute"
      left="-80px"
      width="auto"
      boxShadow="0px 4px 8px rgba(16, 24, 64, 0.16)"
      borderRadius="8px"
      whiteSpace="nowrap"
      zIndex="1000"
      p="8px"
      minW="160px"
      border="none"
      flexDirection="column"
      gap="4px"
    >
      <ListItem
        text="My profile"
        icon="/assets/icons/user-icon.svg"
        action={() => navigate(`/profile/${wallet.account ?? AddressZero}`)}
      />
      <Divider
        bgColor="borderPrimary"
        color="borderPrimary"
      />
      <ListItem
        text="Edit profile"
        icon="/assets/icons/settings-icon.svg"
        action={() => navigate(`/profile/${wallet.account ?? AddressZero}`, { state: { edit: true } })}
      />
      <Divider
        bgColor="borderPrimary"
        color="borderPrimary"
      />
      <ListItem
        text="Send feedback"
        icon="/assets/icons/envelope.svg"
        action={() =>
          redirect(
            `mailto:support@candao.io?subject=<SocialXplorer.io: bug report | user ${wallet.account ?? AddressZero}>`
          )
        }
      />
      <Divider
        bgColor="borderPrimary"
        color="borderPrimary"
      />
      {isMobile && (
        <>
          <ListItem
            text="Messages"
            icon="/assets/icons/envelope.svg"
            action={() => navigate('/chat')}
          />
          <Divider
            bgColor="borderPrimary"
            color="borderPrimary"
          />
        </>
      )}
      <ListItem
        text="Log out"
        action={handleDisconnect}
      />
    </PopoverContent>
  )
}
