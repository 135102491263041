import { Button, Flex, Image, Input, InputGroup, InputLeftAddon, Text, useToast } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Stage } from '../Onboarding'
import { loaded } from '../../../../utils/process'
import { fetcher } from '../../../../utils/fetcher'
import { _log } from '../../../../logger'
import { error } from 'console'

interface ICreateProfileProps {
  setStage: Dispatch<SetStateAction<Stage>>
  setPhoneNumber: Dispatch<SetStateAction<string>>
  phoneNumber: string
}

const candaoApi = process.env.REACT_APP_CANDAO_API;

export const PhoneVerification: FC<ICreateProfileProps> = ({ setStage, setPhoneNumber, phoneNumber }) => {
  const [isPhoneCorrect, setIsPhoneCorrect] = useState<boolean | undefined>(undefined)
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [phonePrefix, setPhonePrefix] = useState<string>('+48')
  const toast = useToast()

  const handleCodeInput = (e: any) => {
    setIsPhoneCorrect(undefined)
    setIsContinueButtonDisabled(e.target.value === '')
    setPhoneNumber(phonePrefix.concat(e.target.value))
  }

  const handleContinue = async () =>
    await loaded(
      async () => {
        /* FIXME: TODO: api.candao.io deprecation */
        const res = await fetcher(`${candaoApi}/api/auth/send-phone-code`, {
          phone_number: phoneNumber,
        })
        _log('response:', res)

        if (res.message !== 'Code sent') {
          toast({ title: res.message, status: 'error', isClosable: false })
        } else {
          toast({ title: res.message, status: 'info', isClosable: false })
          setStage(Stage.SMS_CODE_VERIFICATION)
        }
      },
      setIsSubmitting,
      error => toast({ title: error.response.data.message, status: 'error' })
    )

  return (
    <Flex
      flexDir="column"
      gap="32px"
      align="start"
      w="100%"
    >
      <Flex
        bgColor="#FFEFD2"
        borderRadius="16px"
        align="center"
        justify="center"
        p="16px"
      >
        <Image src="/assets/icons/phone-add.svg" />
      </Flex>
      <Flex flexDir="column">
        <Text
          color="backgroundAccent"
          fontSize="20px"
          fontWeight="600"
        >
          Phone verification
        </Text>
        <Text
          color="textQuaternary"
          fontSize="14px"
        >
          Please, enter your mobile number below
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        gap="8px"
        w="100%"
      >
        <Text
          color="backgroundAccent"
          fontSize="14px"
          fontWeight="500"
        >
          Your mobile number
        </Text>
        <InputGroup>
          <InputLeftAddon
            bg="none"
            borderColor="#D8DAE5"
            borderLeftWidth="0px"
            _hover={{ borderColor: '#D8DAE5' }}
            width="60px"
            ml="0px"
            px="0px"
            borderRightWidth="0px"
          >
            <Input
              px="8px"
              width="60px"
              style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
              value={phonePrefix}
              onChange={e => setPhonePrefix(e.target.value)}
            />
          </InputLeftAddon>
          <Input
            placeholder="Enter the number"
            color="textQuaternary"
            onChange={handleCodeInput}
            borderColor="#D8DAE5"
            _hover={{ borderColor: '#D8DAE5' }}
          />
        </InputGroup>
        <Text
          fontSize="14px"
          color="#D14343"
          opacity={isPhoneCorrect === false ? '1' : '0'}
        >
          Please, enter a valid mobile number
        </Text>
      </Flex>
      <Flex
        w="100%"
        align="center"
        justify="flex-end"
        gap="8px"
      >
        <Button
          variant="light"
          borderColor="#D8DAE5"
          fontSize="12px"
          onClick={() => {
            setIsPhoneCorrect(false)
            setIsContinueButtonDisabled(true)
            setStage(Stage.INVITATION_CODE)
          }}
        >
          Back
        </Button>
        <Button
          bgColor="textBlue"
          isDisabled={isContinueButtonDisabled}
          onClick={handleContinue}
          isLoading={isSubmitting}
          fontSize="12px"
          color="backgroundMain"
          variant="dark"
        >
          Send verification code
        </Button>
      </Flex>
    </Flex>
  )
}
