import { Box, Flex } from '@chakra-ui/react'
import { useChatProvider } from '../../../../../provider/Chat/chatProvider'
import { CHAT_FILTER_TYPE } from '../../../../../provider/Chat/reducer'
import { useMemo } from 'react'

const MobileFilterItem = ({ active, label, onClick }: { active: boolean; label: string; onClick: () => any }) => {
  return (
    <Flex
      cursor="pointer"
      _hover={{ bg: 'rgba(235, 240, 255, 1)' }}
      marginY="14px"
      borderRadius="8px"
      whiteSpace="nowrap"
      onClick={onClick}
      border="1px solid"
      borderColor="rgba(237, 239, 245, 1)"
      padding="6px 40px"
      color={active ? 'rgba(51, 102, 255, 1)' : 'textSecondary'}
      bg={active ? 'rgba(235, 240, 255, 1)' : 'none'}
    >
      {label}
    </Flex>
  )
}

export const MobileFilters = () => {
  const { filter, setFilter, threads, archivedThreads } = useChatProvider()

  const archivedThreadsLength = useMemo(() => {
    return threads.filter(thread => archivedThreads.includes(thread.id)).length
  }, [archivedThreads, threads])

  return (
    <Flex
      overflowX="scroll"
      maxW="calc(100vw - 32px)"
      display={{ base: 'flex', md: 'none' }}
      align="center"
      borderBottom="1px solid rgba(230, 232, 240, 1)"
      gap="4px"
    >
      <Box w="60px"></Box>
      <MobileFilterItem
        label={'All messages: ' + (threads.length - archivedThreadsLength)}
        active={filter == CHAT_FILTER_TYPE.All}
        onClick={() => setFilter(CHAT_FILTER_TYPE.All)}
      />
      <MobileFilterItem
        label={'Archived: ' + archivedThreadsLength}
        active={filter == CHAT_FILTER_TYPE.Archived}
        onClick={() => setFilter(CHAT_FILTER_TYPE.Archived)}
      />
      <Box w="15px"></Box>
    </Flex>
  )
}
