import { LinkGroup } from './LinkGroup/linkGroup'

export const FooterLinks = () => {
  return (
    <>
      <LinkGroup
        title="Company"
        items={[
          {
            label: 'About us',
            href: 'https://gitbook.candao.io/whitepaper/2.-introduction',
          },
          {
            label: 'Contact us',
            href: 'mailto:support@candao.io',
          },
          // {
          //   label: 'Careers',
          //   href: 'careers',
          // },
          {
            label: 'Terms of service',
            href: 'https://docs.google.com/document/d/1c5BWHGeWQN5mMtro_-CcG99tykGRIGE2/edit#heading=h.gjdgxs',
            target: '_blank',
          },
          {
            label: 'Candao Heroes',
            href: 'https://heroes.candao.io/',
            target: '_blank',
          },
        ]}
      />
      <LinkGroup
        title="Community"
        items={[
          // {
          //   label: 'API documentation',
          //   href: 'docs',
          // },
          // {
          //   label: 'Knowledge base',
          //   href: 'knowledge',
          // },
          // {
          //   label: 'Network status',
          //   href: 'status',
          // },
          // {
          //   label: 'Newsletters',
          //   href: 'newsletter',
          // },
          {
            label: 'Community',
            href: 'https://t.me/candao_protocol',
            target: '_blank',
          },
          {
            label: 'Whitepaper',
            href: 'https://gitbook.candao.io/whitepaper/2.-introduction',
            target: '_blank',
          },
        ]}
      />
      <LinkGroup
        title="Docs"
        items={[
          {
            label: 'Open-source licenses',
            href: '/docs/licenses',
          },
        ]}
      />
      {/*<LinkGroup*/}
      {/*  title={'Products & Services'}*/}
      {/*  items={[*/}
      {/*    {*/}
      {/*      label: 'Advertise',*/}
      {/*      href: 'advertise',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: 'Priority support',*/}
      {/*      href: 'support',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: 'Blockscan',*/}
      {/*      href: 'blockscan',*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: 'Blockscan chat',*/}
      {/*      href: 'chat',*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*/>*/}
    </>
  )
}
