import useWindowFocus from 'use-window-focus'
import { switchNetwork } from '@wagmi/core'
import { useEffect, useRef } from 'react'

const useSwitchNetworkSafe = () => {
  const isWindowFocused = useWindowFocus()
  const hasFocusedSinceRef = useRef<any>()

  const switch_ = async (id: number) => {
    // TODO: Fix
    // if (!isWindowFocused) {
    //   await new Promise(resolve => {
    //     hasFocusedSinceRef.current = resolve;
    //   });
    // } else if (hasFocusedSinceRef.current) {
    //   hasFocusedSinceRef.current();
    //   hasFocusedSinceRef.current = null;
    // }

    return await switchNetwork({
      chainId: id,
    })
  }

  useEffect(() => {
    return () => {
      hasFocusedSinceRef.current = null
    }
  }, [])

  return { switchNetworkSafe: switch_ }
}

export { useSwitchNetworkSafe }
