import { Box, Flex, useToast } from '@chakra-ui/react'
import { UserHeader } from '../components/pages/User/UserHeader/userHeader'
import UserContent from '../components/pages/User/UserContent/UserContent'
import { ProfileProvider } from '../provider/Profile/profileProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { useProfile } from '../hooks/useProfile'
import { useEffect, useState } from 'react'
import { useEthereumProvider } from '../provider/Ethereum/ethereumProvider'
import { fetchApi } from '../utils/fetcher'
import { getAuthHeaders } from '../utils/auth'
import { AddressZero } from '../utils/ethereum'
import { UserOnboardingProvider } from '../provider/UserOnboarding/userOnboardingProvider'
import { ContentSwitcher, Switcher } from '../components/pages/Admin/ContentSwitcher'
import React from 'react'
import { Reported } from '../components/pages/Admin/Reported'

export const pages = [
  { name: 'Activity', component: <></>, id: Switcher.Activity },
  { name: 'Reported', component: <Reported />, id: Switcher.Reported },
  { name: 'Suspended', component: <></>, id: Switcher.Suspended },
  { name: 'To be approved', component: <></>, id: Switcher.ToBeApproved },
  { name: 'Chat', component: <></>, id: Switcher.Chat },
  { name: 'Admin settings', component: <></>, id: Switcher.Settings },
]

export const Admin = () => {
  const [page, setPage] = useState(Switcher.Reported)

  return (
    <Flex
      bg="backgroundSecondary"
      justify="center"
      w="100%"
      overflow="hidden"
    >
      <Flex
        flexWrap="wrap"
        direction="row"
        gap="24px"
        w="100%"
      >
        <ContentSwitcher
          initialValue={page}
          setValue={setPage}
        />
        <Flex
          flexDirection="column"
          gridGap="16px"
          w={{ base: '100%', lg: '648px' }}
        >
          {pages.map(item => {
            return <React.Fragment key={item.id}> {item.id === page && <>{item.component}</>}</React.Fragment>
          })}
        </Flex>
        <Flex sx={{ bg: 'lightgray', flexGrow: '1', height: '100%' }} />
      </Flex>
    </Flex>
  )
}
