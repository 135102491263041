import { Flex, Image, Link } from '@chakra-ui/react'
import { Telegram } from '../../../icons/Telegram'
import { Twitter } from '../../../icons/Twitter'

const links = [
  {
    icon: <Telegram />,
    href: 'https://t.me/candao_protocol',
  },
  {
    icon: <Twitter />,
    href: 'https://twitter.com/Candao_io',
  },
  // {
  //   icon: '/assets/icons/instagram.svg',
  //   href: 'https://twitter.com/pawel_barylski?lang=en',
  // },
  // {
  //   icon: '/assets/icons/tiktok-small.svg',
  //   href: 'https://www.tiktok.com/@candaometaverse',
  // },
]

export const FooterSocials = () => {
  return (
    <Flex
      flexWrap="wrap"
      gap="8px"
    >
      {links.map(link => {
        return (
          <Link
            key={link.href}
            href={link.href}
            target="_blank"
            borderRadius="full"
          >
            <Flex
              flexWrap="wrap"
              border="1px solid"
              borderRadius="full"
              alignItems="center"
              justify="center"
              borderColor="graySecondary"
              boxSize="28px"
              _hover={{ opacity: '0,7' }}
            >
              {link.icon}
            </Flex>
          </Link>
        )
      })}
    </Flex>
  )
}
