import sample from 'lodash/sample'
import { ipfsGateway } from './config'

const trimAddress = (address?: string, minCount?: number, isLonger?: boolean) => {
  if (!address) {
    return
  }

  if (minCount) {
    return `${address.slice(0, 6)}···${address.slice((38 - (minCount ?? 4)) % 38, 38)}`
  }

  return `${address.slice(0, 6)}···${address.slice(-5)}`
}

const trimTransactionId = (id: string) => `${id.slice(0, 6)}···${id.slice(62, 67)}`

const trimStringInbetween = (beginning: number, ending: number, content: string) =>
  `${content.slice(0, beginning)}···${content.slice(-ending)}`

const trimString = (ifLongerThan: number, target: string) => {
  return target.length > ifLongerThan ? `${target.substring(0, ifLongerThan)}···` : target
}

const formatFixedDecimals = (amount: string | number, decimals: number) => Number(amount).toFixed(decimals)

const convertSnakeToCamelCase = (token: string) =>
  token.toLowerCase().replace(/(_\w)/g, group => group.toUpperCase().replace('_', ''))

const isLinkExternal = (uri: string) => uri.startsWith('http')

const capitalizeFirstLetter = (content: string) => `${content.charAt(0).toUpperCase()}${content.slice(1)}`

/**
 * value                decimal places
 * [0, 0.0009]          full
 * [0.001, 0.999]       2
 * [1.00, 999.99]       2
 * [1000.0, 99999.9]    1
 * [100000, +inf]       0
 * @param balance input string
 */
const trimBalance = (balance: string) => {
  const numericApproximation = parseFloat(balance)

  const decimalSeparatorIndex = balance.indexOf('.')

  if (numericApproximation >= 0 && numericApproximation < 0.001) {
    return balance
  }
  if (numericApproximation >= 0.001 && numericApproximation < 1) {
    return balance.substring(0, decimalSeparatorIndex + 3)
  }
  if (numericApproximation >= 1 && numericApproximation < 1000) {
    return balance.substring(0, decimalSeparatorIndex + 3)
  }
  if (numericApproximation >= 1000 && numericApproximation < 100000) {
    return balance.substring(0, decimalSeparatorIndex + 2)
  }
  if (numericApproximation >= 100000) {
    return balance.substring(0, decimalSeparatorIndex)
  }

  return balance
}

const squashArray = <T>(data: Array<T>): T[] => data.filter(datum => !!datum)

const removeUndefinedFields = (object: object | any) => {
  Object.keys(object).forEach(key => object[key] === undefined && delete object[key])
  return object
}

function adjustColor(color: string, amount: number) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  )
}

function getInitials(name?: string) {
  if (!name) {
    return 'NN'
  }

  const namesArray = name.trim().split(' ')
  if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
  else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`
}

const getRandomColor = () => sample(['#897AE3', '#ED55C2', '#52BD94', '#25CBD6', '#D14343', '#66460D'])

const buildIpfsGateway = <T = any>(ipfs: T | ipfs | string) =>
  typeof ipfs === 'string' ? (ipfs.match(/^ipfs:\/\//gm) ? `https://${ipfsGateway}/ipfs/${ipfs.slice(7)}` : ipfs) : ipfs

const formatUsd = (number?: string | number) => {
  if (!number || number === 'undefined') {
    return '0.00'
  }

  try {
    number = number.toString()

    const [integerPart, decimalPart = ''] = number.split('.')

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const formattedDecimal = decimalPart.slice(0, 2).padEnd(2, '0')

    const parsed = formattedDecimal ? `${formattedInteger}.${formattedDecimal}` : formattedInteger

    const parsedNormalized = parsed.replaceAll(',', '')
    return Number(parsedNormalized) < 10_000 ? parsed : nFormatter(parsedNormalized)
  } catch {
    return '0.00'
  }
}

export const shortText = (text: string, maxLen: number, postFix: string = '...') =>
  text.length > maxLen ? text.slice(0, maxLen).toString() + postFix : text

const nFormatter = (number: number | string, digits: number = 0) => {
  const _number = Number(number)

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return _number >= item.value
    })
  return item ? (_number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

export {
  formatUsd,
  buildIpfsGateway,
  getRandomColor,
  squashArray,
  convertSnakeToCamelCase,
  capitalizeFirstLetter,
  isLinkExternal,
  trimBalance,
  trimString,
  trimTransactionId,
  trimAddress,
  removeUndefinedFields,
  adjustColor,
  getInitials,
  trimStringInbetween,
  nFormatter,
}

export const time_ago = (time: any) => {
  switch (typeof time) {
    case 'number':
      break
    case 'string':
      time = +new Date(time)
      break
    case 'object':
      if (time.constructor === Date) time = time.getTime()
      break
    default:
      time = +new Date()
  }
  var time_formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]
  var seconds = (+new Date() - time) / 1000,
    token = 'ago',
    list_choice = 1

  if (seconds == 0) {
    return 'Just now'
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = 'from now'
    list_choice = 2
  }
  var i = 0,
    format
  while ((format = time_formats[i++]))
    if ((seconds as any) < format[0]) {
      if (typeof format[2] == 'string') return format[list_choice]
      else return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token
    }
  return time
}
