import {
  Box,
  Divider,
  Flex,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { formatUnits } from 'viem'
import SpinnerText from '../../../../../../../../shared/tags/SpinnerText'
import { formatUsd } from '../../../../../../../../../utils/parser'
import { _log } from '../../../../../../../../../logger'
import { TokenIcon } from '../../../../../../../../../utils/tokenIcon'

export interface IToken {
  name: string
  value: string
  decimals: number
  chain: string
}

interface IPowerDetailsModalProps {
  onClose: () => void
  isOpen: boolean
  power?: Pick<IToken, 'value' | 'decimals'>
  chains: (
    | {
        name: string
        tokens: IToken[]
      }
    | undefined
  )[]
}

export const PowerDetailsModal: FC<IPowerDetailsModalProps> = ({ onClose, isOpen, chains, power }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
    >
      <ModalOverlay
        bg="rgba(0, 0, 0, 0.15)"
        pt="200px"
      />
      <ModalContent
        bgColor="backgroundMain"
        boxShadow="0px 12px 40px rgba(16, 24, 64, 0.24)"
        borderRadius="16px"
        p="0"
        mx="10px"
        mt="200px"
        marginBottom={{ base: '10px', md: '64px' }}
      >
        <ModalHeader>
          <Text
            color="backgroundAccent"
            fontSize="18px"
            fontWeight="600"
            lineHeight="24px"
          >
            Voting power
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <Divider orientation="horizontal" />
        <ModalBody
          p="24px"
          display="flex"
          flexDirection="column"
          gap="8px"
        >
          <Flex
            fontSize="14px"
            gap="2px"
            mb="5px"
          >
            <Image src="/assets/icons/coin-dollar.svg" />
            <Text color="textSecondary">Power:&nbsp;</Text>
            <Text color="#3366FF">
              ${power ? formatUsd(formatUnits(BigInt(power.value), power.decimals)) : <SpinnerText />}
            </Text>
          </Flex>
          {chains?.length &&
            chains.map((chain, idx: number) => (
              <Chain
                key={`${idx}-${chain?.name || ''}`}
                name={chain?.name || ''}
                tokens={chain?.tokens || []}
              />
            ))}

          <Link
            px="16px"
            py="8px"
            borderRadius="8px"
            border="1px"
            borderColor="#D8DAE5"
            mt="12px"
            cursor="pointer"
          >
            <Text
              fontSize="12px"
              color="textSecondary"
              textAlign="center"
            >
              Learn more about voting power
            </Text>
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const Token: FC<IToken> = ({ name, value, decimals }) => {
  return (
    <Flex
      mt="8px"
      background="backgroundTertiary"
      px="19px"
      py="8px"
      borderRadius="8px"
      border="1px"
      borderColor="#EDEFF5"
      fontSize="12px"
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Flex
        alignItems="start"
        direction="column"
        sx={{ w: '100%' }}
      >
        <Text color="#8F95B2">Token</Text>
        <Flex sx={{ gap: '4px', alignItems: 'center' }}>
          <Image
            src={TokenIcon[name.toUpperCase()]}
            width="12px"
            height="12px"
            sx={{ borderRadius: '100%' }}
          />
          <Text color="textQuaternary">{name.toUpperCase()}</Text>
        </Flex>
      </Flex>
      <Flex
        sx={{ w: '100%' }}
        alignItems="start"
        direction="column"
      >
        <Text color="#8F95B2">Value</Text>
        <Text color="textQuaternary">${formatUsd(formatUnits(BigInt(value), decimals))}</Text>
      </Flex>
    </Flex>
  )
}

interface IChainProps {
  name: string
  tokens: IToken[]
}

const Chain: FC<IChainProps> = ({ name, tokens }) => {
  _log('tokens', tokens)
  const [doesShowMore, setDoesShowMore] = useState(false)

  if (!tokens.length) {
    return null
  }

  return (
    <Box>
      <Box
        p="8px"
        mt="8px"
        borderRadius="4px"
        background="#EDEFF5"
      >
        <Text
          color="textSecondary"
          fontSize="10px"
          textTransform="uppercase"
          fontWeight="bold"
        >
          {name} TOKENS ({tokens.length})
        </Text>
      </Box>
      {tokens &&
        tokens.slice(0, doesShowMore ? tokens.length : 2).map(token => (
          <Token
            key={token.name}
            {...token}
          />
        ))}
      {tokens.length > 2 && (
        <Text
          onClick={() => setDoesShowMore(prev => !prev)}
          mt="8px"
          fontSize="12px"
          textColor="#36F"
          cursor="pointer"
        >
          {doesShowMore ? `Show less` : `Show more: ${tokens.length - 2}`}
        </Text>
      )}
    </Box>
  )
}
