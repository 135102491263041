import { Box, Flex } from '@chakra-ui/react'
import { IRoute } from '../../../navbar/routes'
import { Link } from 'react-router-dom'

export const LinkGroup = ({ items, title }: { items: IRoute[]; title: string }) => {
  return (
    <Flex
      flexWrap="wrap"
      gap="16px"
      flexDir="column"
    >
      <Box
        fontSize="12px"
        color="textTertiary"
      >
        {title}
      </Box>
      <Flex
        flexWrap="wrap"
        flexDir="column"
        gap="8px"
      >
        {items.map(item => {
          return (
            <Link
              key={item.href}
              target={item.target}
              style={{ fontSize: '14px', color: 'textSecondary' }}
              to={item.href}
            >
              {item.label}
            </Link>
          )
        })}
      </Flex>
    </Flex>
  )
}
