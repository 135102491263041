import { Box } from '@chakra-ui/react'
import { IParent } from '../../../types/Component'

interface IProps {
  corner?: string
}

export const DisabledContainer = ({ children, corner }: IParent & IProps) => (
  <Box
    sx={{
      position: 'relative',
      background: 'borderPrimary repeating-linear-gradient(-45deg, transparent, transparent 1px, #fff 1px, #fff 6px)',
      outline: '1px borderPrimary dotted',
      borderRadius: corner ?? '4px',
      cursor: 'not-allowed',
    }}
  >
    <Box sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} />
    <Box
      sx={{ filter: 'grayscale(.33)', opacity: '.5' }}
      pointerEvents="none"
    >
      {children}
    </Box>
  </Box>
)
