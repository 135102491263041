import { Flex, Text } from '@chakra-ui/react'
import React, { FC, SetStateAction } from 'react'
import { fetcher } from '../../../../../utils/fetcher'
import { RolesInterestsPicker } from '../../../../shared/roles&interestsPicker/rolesInterestsPicker'
import { Select, Type } from '../../../../shared/select/Select'
import { fetchInterests } from '../../../../shared/select/utils'

interface IInterestTabProps {
  interests: string[]
  setInterests: React.Dispatch<SetStateAction<string[]>>
}

export const InterestsTab: FC<IInterestTabProps> = ({ interests, setInterests }) => {
  return (
    <Flex
      p="32px 24px"
      flexDir="column"
    >
      <Flex
        flexDir="column"
        gap="2px"
      >
        <Text
          fontWeight="500"
          color="backgroundAccent"
          fontSize="14px"
          mb="12px"
        >
          Edit your Interests
        </Text>
      </Flex>
      <RolesInterestsPicker
        type={Type.INTERESTS}
        items={interests}
        setItems={setInterests}
        fetch={fetchInterests}
      />
    </Flex>
  )
}
