import {
  Flex,
  Text,
  Spinner,
  Box,
  Button,
  Grid,
  useDisclosure,
  Breadcrumb,
  BreadcrumbItem,
  Image,
  BreadcrumbLink,
} from '@chakra-ui/react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import useAsyncEffect from '../hooks/effects/async'
import { loaded } from '../utils/process'
import { fetchApi } from '../utils/fetcher'
import { Abi } from '../contracts/abi'
import { readContract } from '@wagmi/core'
import { IGroup, emptyGroup } from '../hooks/useGroup'
import { GroupProvider } from '../provider/Group/groupProvider'
import { GroupDetails } from '../components/pages/Groups/tabs/feed/GroupDetails'
import { RolesFeed } from '../components/pages/Groups/tabs/feed/rolesFeed/RolesFeed'
import { TopMembers } from '../components/pages/Groups/tabs/feed/members/TopMembers'
import { GroupHeader } from '../components/pages/Groups/GroupHeader'
import { Feed } from '../components/pages/Groups/tabs/feed/Feed'
import { GroupTabPicker } from '../components/pages/Groups/GroupTabPicker'
import { EFeed, EGroupTab, TabsProvider } from '../provider/Group/tabProvider'
import { PostModal } from '../components/shared/post/PostModal'
import { IPostSchema } from '../components/pages/Home/MiddlePanel/deafult/Default'
import { GroupFeedPicker } from '../components/pages/Groups/GroupFeedPicker'
import { Members } from '../components/pages/Groups/tabs/members/Members'
import { NavigationBar } from '../components/pages/Groups/NavigationBar'
import { Offers } from '../components/pages/Groups/tabs/offers/Offers'

export enum EState {
  LOADING,
  ERROR,
  LOADED,
}

type GroupTab = {
  label: string
  content: React.ReactNode
}

/**
 * @dev Group's address will never require onboarding.
 *   The edit modal will be different (it will use CandaoGroups instead of CandaoId).
 *   Data will be fetched from a collection different than `Profiles`.
 */

export const Group = () => {
  const [data, setData] = useState<IGroup>(emptyGroup)
  const [state, setState] = useState(EState.LOADING)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [currentTab, setCurrentTab] = useState(EGroupTab.Feed)
  const [feed, setFeed] = useState(EFeed.General)
  const [postIpfs, setPostIpfs] = useState<IPostSchema['ipfs']>(
    'ipfs://QmaXfiNpHxQ8byb5ymg1nAR2mwwKJeB4Gx1LomCfeXFTwJ/post-a208a32f-3eae-455d-92ba-2f3271ae9d5a.json'
  )

  const location = useLocation()

  const postModalDisclosure = useDisclosure()

  useEffect(() => {
    try {
      setTimeout(() => {
        const ipfs = location?.state?.ipfs

        if (!ipfs) {
          return
        }

        const targetPost = document.getElementById(ipfs)
        if (targetPost) {
          targetPost.scrollIntoView({ behavior: 'smooth' })
        }
      }, 1000)
    } catch (e) {}
  }, [location])

  //const insertPost = (post: IPostSchema) => setPosts(prev => [post, ...prev])

  const groupTab = useMemo(() => {
    switch (currentTab) {
      case EGroupTab.Feed:
        return <Feed groupName={data?.metadata?.name || ''} />
      case EGroupTab.Offers:
        return <Offers />
      case EGroupTab.Members:
        return <Members />
    }
  }, [currentTab, data])

  const navigate = useNavigate()
  const { id } = useParams()

  /* Sync group data */
  useAsyncEffect(async () => {
    if (!id) {
      setData(emptyGroup)
      return
    }

    setState(EState.LOADING)

    await loaded(
      async () => setData(await fetchApi(`group/${id}`)),
      undefined,
      () => setState(EState.ERROR)
    )
    setState(EState.LOADED)
  }, [id])

  /* Sync group-emitted posts. */
  useAsyncEffect(async () => {
    if (!id) {
      setData(emptyGroup)
      return
    }

    setState(EState.LOADING)

    await loaded(
      async () => setData(await fetchApi(`group/${id}`)),
      undefined,
      () => setState(EState.ERROR)
    )
    setState(EState.LOADED)
  }, [id])

  /* Fetch symbol if not yet retrieved from backend (not yet indexed by backend in the db) */
  useAsyncEffect(async () => {
    if (!id || state !== EState.LOADED || data.symbol) {
      return
    }

    await loaded(async () => {
      const symbol = (await readContract({
        abi: Abi.ERC20,
        address: id as address,
        functionName: 'symbol',
      })) as string

      setData(prev => ({ ...prev, symbol }))
    })
  }, [id, state])

  if (!id) {
    navigate('/groups')
  }

  if (state === EState.LOADING) {
    return (
      <Flex sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', mt: '48px' }}>
        <Spinner
          size="lg"
          thickness="2.5px"
          speed=".8s"
        />
      </Flex>
    )
  }

  if (state === EState.ERROR) {
    return (
      <Box>
        <Text>Group not unregistered or not ERC20; register it yourself now</Text>
        <Button
          onClick={() => navigate('/groups')}
          variant="dark"
        >
          Register
        </Button>
      </Box>
    )
  }

  const handleJoinAs = async (tab: EGroupTab) => {
    postModalDisclosure.onOpen()
  }

  return (
    <GroupProvider group={data}>
      <TabsProvider {...{ currentTab, setCurrentTab, feed, setFeed }}>
        <PostModal
          ipfs={postIpfs}
          disclosure={postModalDisclosure}
        />
        <Flex
          bg="backgroundSecondary"
          justify="center"
          w="100%"
          overflow="visible"
        >
          <Grid
            flexWrap="wrap"
            gridGap="24px"
            w="100%"
            templateColumns={{ base: '1fr', lg: activeTabIndex === 0 ? '1fr 260px' : '1fr' }}
          >
            <Flex sx={{ w: '100%', flexDirection: 'column', gap: '20px' }}>
              <Flex sx={{ alignItems: 'flex-start', justifyContent: 'space-between', gap: '12px' }}>
                <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
                  <GroupTabPicker handleJoinAs={handleJoinAs} />
                  <GroupFeedPicker isVisible={currentTab === EGroupTab.Feed} />
                </Flex>

                <Flex sx={{ flexDir: 'column', gap: '16px', w: '100%' }}>
                  <NavigationBar
                    group={data}
                    activeTab={currentTab !== EGroupTab.Feed ? currentTab : undefined}
                    navigate={() => setCurrentTab(EGroupTab.Feed)}
                  />
                  {groupTab}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              minW="250px"
              flexDir="column"
              maxW="100%"
              gap="22px"
              display={activeTabIndex === 0 ? 'flex' : 'none'}
            >
              {/* <TopPeople /> */}
              <GroupHeader />
              <GroupDetails />
              <RolesFeed />
              <TopMembers />
            </Flex>
          </Grid>
        </Flex>
      </TabsProvider>
    </GroupProvider>
  )
}
