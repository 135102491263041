import {
  Box,
  Button,
  Flex,
  Image,
  Popover,
  PopoverTrigger,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { WalletsPopup } from './ConnectWallet/WalletsPopup'
import { ConnectWalletCustom } from './ConnectWallet/ConnectWalletCustom'
import { useScrollPosition } from '../../../hooks/utils/useScrollPosition'
import { useOnboardingProvider } from '../../../provider/Onboarding/onboardingProvider'
import { adminRoutes, routes } from './routes'
import { useAccountProfileProvider } from '../../../provider/AccountProfile/context'
import SpinnerText from '../../shared/tags/SpinnerText'
import { useState } from 'react'
import { formatUsd } from '../../../utils/parser'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { DotsGridIcon } from '../../icons/DotsGrid'
import { useCandaoPrice } from '../../../hooks/candao/useCandaoPrice'
import { BetaBadgeIcon } from '../../icons/BetaBadge'

export const Navbar = () => {
  // const [cdoPrice, setCdoPrice] = useState('')
  const { price } = useCandaoPrice()
  const [cdoPriceChange, setCdoPriceChange] = useState(0)

  const history = useLocation()
  const walletsPopup = useDisclosure()
  const logoResourceLight = useBreakpointValue({ base: '/logo-small.svg', sm: '/logo.svg' })
  const logoResourceDark = useBreakpointValue({ base: '/logo-small.svg', sm: '/logo-dark.svg' })
  const { scrollPosition } = useScrollPosition()
  const onboarding = useOnboardingProvider()
  const accountProfile = useAccountProfileProvider()
  const colorMode = useColorMode()

  const handleWalletMenu = () => {
    walletsPopup.onToggle()
  }

  // useAsyncEffect(
  //   async () =>
  //     await loaded(async () => {
  //       const res = await fetcher('https://api.dev.candao.io/api/token/cdo/price')
  //       const old_cdo_price = Number(res.old_cdo_price)
  //       const new_cdo_price = Number(res.new_cdo_price)
  //       const priceChange = (new_cdo_price / old_cdo_price) * 100

  //       setCdoPrice(new_cdo_price.toString())
  //       setCdoPriceChange(priceChange)
  //     }),
  //   []
  // )

  return (
    <Flex
      mb="2px"
      position="sticky"
      top="0"
      width="100%"
      background="backgroundSecondary"
      zIndex={onboarding.shouldElevateTopBar ? 'popover' : 'sticky'}
      transition="box-shadow .5s ease-in-out"
      {...(scrollPosition > 1 || onboarding.shouldElevateTopBar
        ? { boxShadow: !onboarding ? '3.4px 6.7px 6.7px hsl(0deg 0% 0% / 0.05)' : 'none' }
        : {})}
      justifyContent="center"
      py="20px"
    >
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        gap="20px"
        maxW="1180px"
        px="16px"
      >
        <Flex
          align="center"
          gap="8px"
          flexShrink="0"
        >
          <Link to="/">
            <Flex
              align="center"
              gap="8px"
              flexShrink="0"
            >
              <Image src={colorMode.colorMode === 'light' ? logoResourceLight : logoResourceDark} />
              <BetaBadgeIcon />
            </Flex>
          </Link>
        </Flex>

        <Flex
          gap="8px"
          align="center"
          justifyContent="space-between"
          display="flex"
          userSelect="none"
          ml="auto"
        >
          <Flex
            gap="4px"
            display={{ base: 'none', lg: 'flex' }}
          >
            {[...routes, ...(accountProfile.isAdmin ? adminRoutes : [])].map(item => {
              return (
                <Tooltip
                  key={item.href}
                  hasArrow
                  label={item.href === '/domains' && 'Coming soon!'}
                >
                  <Box
                    key={item.href}
                    _hover={{
                      borderRadius: '8px',
                      background: 'backgroundAccentLight',
                      color: 'textSecondary',
                    }}
                  >
                    <Link
                      to={item.href}
                      draggable={false}
                      style={{
                        pointerEvents: item.href === '/domains' ? 'none' : 'auto',
                        padding: '6px 14px',
                        lineHeight: '20px',
                        borderRadius: '8px',
                        background: history.pathname === item.href ? 'backgroundAccentLight' : 'transparent',
                        fontSize: '14px',
                        color: `${history.pathname === item.href ? '#3366FF' : 'inherit'}`,
                        display: 'block',
                      }}
                    >
                      {item.label}
                    </Link>
                  </Box>
                </Tooltip>
              )
            })}
          </Flex>

          <Flex
            display="flex"
            gridGap="8px"
          >
            <Flex
              px="12px"
              py="6px"
              gap="4px"
              fontSize="12px"
              border="1px solid"
              borderColor="borderPrimary"
              borderRadius="8px"
              background="backgroundMain"
              align="end"
              sx={{
                display: { base: 'none', candao: 'flex' },
              }}
            >
              <Text
                color="textSecondary"
                whiteSpace="nowrap"
              >
                CDO Price
              </Text>
              <Text color="textQuaternary">${Number(price) > 0 ? formatUsd(price) : <SpinnerText />}</Text>
              <Text
                color="#52BD94"
                fontWeight="600"
                fontSize="10.5px"
                sx={{
                  transform: 'translateY(-7%)',
                }}
              >
                +0%
              </Text>
            </Flex>
            <ConnectWalletCustom />
            <Popover>
              <PopoverTrigger>
                <Button
                  as="div"
                  tabIndex={0}
                  variant="slave"
                  display="flex"
                  bg="backgroundAccent"
                  h="32px"
                  w="32px"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={handleWalletMenu}
                  sx={{
                    transition: 'background .33s ease-in-out !important',
                  }}
                >
                  <DotsGridIcon color="backgroundMain" />
                  <WalletsPopup />
                </Button>
              </PopoverTrigger>
            </Popover>
            <Button
              as="div"
              tabIndex={0}
              variant="slave"
              display="flex"
              bg="backgroundAccent"
              h="32px"
              w="32px"
              borderRadius="8px"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={colorMode.toggleColorMode}
              sx={{
                transition: 'background .33s ease-in-out !important',
              }}
            >
              {colorMode.colorMode === 'light' ? (
                <MoonIcon color="backgroundMain" />
              ) : (
                <SunIcon color="backgroundMain" />
              )}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
