export interface IRoute {
  label: string
  href: string
  target?: string
}

const routes: IRoute[] = [
  { label: 'Home', href: '/' },
  { label: 'People', href: '/profiles' },
  { label: 'Groups', href: '/groups' },
  { label: 'Transactions', href: '/txs' },
  { label: 'Dashboard', href: '/dashboard' },
]

const adminRoutes: IRoute[] = [{ label: 'Admin', href: '/admin' }]

export { routes, adminRoutes }
