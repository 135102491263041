import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = ({ children }: any) => {
  const location = useLocation()

  const isUser = location.pathname.includes('/profile/')

  useEffect(() => {
    if (!!location?.state?.ipfs) {
      return
    }

    window.scrollTo({ top: 0, behavior: isUser ? 'auto' : 'smooth' })
  }, [location])
  return <>{children}</>
}
