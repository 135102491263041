import { ENetwork } from '../../enum/network.enum'
import { EError } from './useEthereum'
import { useEthereumProvider } from '../../provider/Ethereum/ethereumProvider'
import { loaded } from '../../utils/process'
import { useSwitchNetworkSafe } from './useSwitchNetworkSafe'

const useNetworks = () => {
  const ethereum = useEthereumProvider()
  const switcher = useSwitchNetworkSafe()

  /**
   *
   * @dev TODO: Multichain
   * @param network
   * @returns
   */
  const handleSwitchNetwork = async (network = ENetwork.FALLBACK) =>
    await loaded(
      async () => await switcher.switchNetworkSafe(network),
      undefined,
      (error: any) => {
        if (error.code === EError.USER_REQUEST_PENDING || error.code === EError.USER_REJECTION) {
          return
        }

        throw new Error('Open your wallet to take action')
      }
    )
  return { switchNetwork: handleSwitchNetwork }
}

export default useNetworks
