import React from 'react'
import { fetchApi, fetcher } from '../../utils/fetcher'
import { useQuery } from '@tanstack/react-query'

export interface Icons {
  interests: Map<string, string>
  roles: Map<string, string>
}

export const emptyIcons: Icons = {
  interests: new Map<string, string>(),
  roles: new Map<string, string>(),
}

export const IconsProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: roles } = useQuery({
    queryKey: ['getAllRoles'],
    queryFn: async (): Promise<Map<string, string>> => {
      const { roles } = await fetchApi('role/dump')

      const rolesMap = new Map<string, string>()

      Object.keys(roles).forEach((key: string) => {
        roles[key].forEach((role: any) => {
          rolesMap.set(role.name, role.icon)
        })
      })

      return rolesMap
    },
    initialData: new Map<string, string>(),
  })

  const { data: interests } = useQuery({
    queryKey: ['getAllInterests'],
    queryFn: async (): Promise<Map<string, string>> => {
      const { interests } = await fetchApi('interest/dump')

      const interestsMap = new Map<string, string>()

      Object.keys(interests).forEach((key: string) => {
        interests[key].forEach((interest: any) => {
          interestsMap.set(interest.name, interest.icon)
        })
      })

      return interestsMap
    },
    initialData: new Map<string, string>(),
  })

  return <IconContext.Provider value={{ interests, roles }}>{children}</IconContext.Provider>
}

const IconContext = React.createContext(emptyIcons)

export const useIconProvider = () => {
  const context = React.useContext(IconContext)

  if (!context) {
    throw new Error('`useIconProvider` cannot be used outside of a `IconProvider`!')
  }
  return context
}
