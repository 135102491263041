import { Box, Flex, Image } from '@chakra-ui/react'
import { BackToTop } from './BackToTop/backToTop'
import { FooterLinks } from './FooterLinks/footerLinks'
import { FooterSocials } from './FooterSocials/footerSocials'
import { useProfile } from '../../../hooks/useProfile'

export const Footer = () => {
  return (
    <Flex
      justifyContent="center"
    >
      <Flex
        flexWrap="wrap"
        bg="backgroundSecondary"
        pt="20px"
        sx={{
          a: {
            color: '#667085',
          },
        }}
        maxW="1180px"
        w="100%"
        px="16px"
      >
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          w="100%"
          padding="24px 0px"
        >
          <Box opacity=".5">
            <FooterSocials />
          </Box>
          <BackToTop />
        </Flex>
        <Flex
          flexWrap="wrap"
          h="1px"
          bg="#F2F4F7"
        />
        <Flex
          flexWrap="wrap"
          padding="24px 0px"
          gap="70px"
        >
          <Flex
            flexWrap="wrap"
            flexDir="column"
            gap="16px"
            justify="space-between"
          >
            <Flex
              flexWrap="wrap"
              flexDir="column"
              gap="16px"
            >
              <Flex
                flexWrap="wrap"
                color="textTertiary"
                align="center"
                fontSize="12px"
                gap="8px"
              >
                <Image
                  src="/logo-small.svg"
                  boxSize="24px"
                />{' '}
                SocialXplorer
              </Flex>
              <Box
                color="textSecondary"
                fontSize="14px"
                maxW="400px"
              >
                SocialXplorer is a Block Explorer and Analytics Platform for Candao, a decentralized social protocol.
              </Box>
            </Flex>

            <Box
              color="textSecondary"
              fontSize="12px"
            >
              SocialXplorer © 2023
            </Box>
          </Flex>
          <Flex gap={{ base: '36px', lg: '80px' }}>
            <FooterLinks />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
