import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Footer, Heading, Stage } from '../UserOnboarding'
import { Box } from '@chakra-ui/react'
import { fetcher } from '../../../../../utils/fetcher'
import { useUserOnboardingProvider } from '../../../../../provider/UserOnboarding/userOnboardingProvider'
import { Select, Type } from '../../../../shared/select/Select'
import { loaded } from '../../../../../utils/process'
import { fetchRoles } from '../../../../shared/select/utils'
import { RolesInterestsPicker } from '../../../../shared/roles&interestsPicker/rolesInterestsPicker'

interface IRoles {
  setStage: Dispatch<SetStateAction<Stage>>
  estimateGas: () => Promise<string>
}

export default function Roles({ setStage, estimateGas }: IRoles) {
  const { roles, setRoles, totalEsimtatedGas, setTotalEstimatedGas } = useUserOnboardingProvider()

  const [isEstimationLoading, setIsEstimationLoading] = useState<boolean>(false)

  useEffect(() => {
    setIsEstimationLoading(true)
    estimateGas().then((res: string) => {
      setTotalEstimatedGas(res)
      setIsEstimationLoading(false)
    })
  }, [roles])

  return (
    <>
      <Heading
        step={4}
        heading="Choose your roles"
        subheading="Each of us plays many roles in life. Share who you are and let everyone find you."
      />
      <Box
        px="24px"
        width="100%"
      >
        <RolesInterestsPicker
          items={roles}
          setItems={setRoles}
          fetch={fetchRoles}
          type={Type.ROLES}
          showWarning
        />
      </Box>
      <Footer
        estimatedCost={`${totalEsimtatedGas} BNB`}
        isLoading={isEstimationLoading}
        action={() => {
          setStage(Stage.INTERESTS)
        }}
        goBack={() => setStage(Stage.INFO)}
      />
    </>
  )
}
