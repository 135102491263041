import { createContext, ReactNode, useContext } from 'react'
import { useEthereumProvider } from '../Ethereum/ethereumProvider'
import { emptyProfile } from '../Profile/profileProvider'
import { IUseProfile, useProfile } from '../../hooks/useProfile'

export const AccountProfileProvider = ({ children }: { children: ReactNode }) => {
  const wallet = useEthereumProvider()
  const profile = useProfile({ address: wallet.account as address })

  return (
    <AccountProfileContext.Provider
      value={{
        ...profile,
        /** axiom-fallback */
        address_: [wallet.account?.toLowerCase() as address],
        /** axiom-fallback */
        address: wallet.account?.toLowerCase() as address,
      }}
    >
      {children}
    </AccountProfileContext.Provider>
  )
}

const AccountProfileContext = createContext<IUseProfile>({
  ...emptyProfile,
  isLoading: true,
  setProfile: () => null,
})

export const useAccountProfileProvider = () => {
  const context = useContext(AccountProfileContext)

  if (!context) {
    throw new Error('`useAccountProfileProvider` cannot be used outside of a `AccountProfileProvider`!')
  }
  return context
}
