import { Box, Flex, Grid } from '@chakra-ui/layout'
import { useChatProvider } from '../../../provider/Chat/chatProvider'
import { useNavigate } from 'react-router'
import { useEthereumProvider } from '../../../provider/Ethereum/ethereumProvider'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Avatar } from '../../shared/avatars/Avatar'
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { User, emptyUser } from '../../../provider/Chat/types'
import { ChatWindowItem } from './ChatWindowItem/chatWindowItem'
import { Spinner, Tooltip, useColorModeValue } from '@chakra-ui/react'

const OverlayThreadItem = ({ id, users }: { id: number; users: User[] }) => {
  const { openThread, threads, threadsFiltered, toggleOverlayChat, selectedOverlayThreadIds, openOverlayThread } =
    useChatProvider()
  const navigate = useNavigate()
  const wallet = useEthereumProvider()

  return (
    <Grid
      onClick={() => {
        openOverlayThread(id)
      }}
      _hover={{ opacity: 0.8 }}
      cursor="pointer"
      gap="12px"
    >
      <Avatar
        bgColor="#eee"
        size="50px"
        image={users[0] ? users[0].avatarUrl : ''}
        name={users[0] ? users[0].nickname ?? users[0].mainAddress : 'X'}
        textSize={{ short: '14px', long: '14px' }}
      />
    </Grid>
  )
}

export const ChatOverlay = () => {
  const {
    threadsFiltered,
    overlayChatOpened,
    selectedOverlayThreadIds,
    messages,
    loading,
    currentUser,
    toggleOverlayChat,
    threads,
    getThreadById,
    openOverlayThread,
  } = useChatProvider()
  const navigate = useNavigate()

  const [threadsOpen, setThreadsOpen] = useState<boolean>(false)

  const iconColor = useColorModeValue('#fff', '#fff')

  const activeThreads = useMemo(() => {
    return selectedOverlayThreadIds.map(threadId => getThreadById(threadId))
  }, [selectedOverlayThreadIds, threads])

  return (
    <Flex
      bottom="0px"
      right="40px"
      pos="fixed"
      align="flex-end"
      display={{ base: 'none', lg: 'flex' }}
      zIndex="100"
      gap="30px"
    >
      {activeThreads.map(thread => {
        return (
          <ChatWindowItem
            key={thread?.id}
            threadId={thread ? thread.id : ''}
            loading={thread ? thread.loading : false}
            users={thread ? thread.users : [emptyUser]}
            messages={thread ? thread.messages : []}
            scrollTrigger={thread ? thread.scrollTrigger : true}
          />
        )
      })}

      <Flex
        flexDir="column"
        w="56px"
        bg="backgroundMain"
        pt="4px"
        align="center"
        boxShadow="0px 4px 8px 0px backgroundAccent29"
        borderRadius="65px 65px 0px 0px"
        pb="20px"
        pos="relative"
      >
        <Tooltip label={threadsOpen ? '' : 'See more Chats'}>
          <Flex
            boxSize="24px"
            bg="#00000052"
            pos="absolute"
            borderRadius="50%"
            top="-32px"
            align="center"
            cursor="pointer"
            onClick={() => setThreadsOpen(open => !open)}
            justify="center"
          >
            <ChevronDownIcon
              transform={!threadsOpen ? 'rotate(180deg)' : 'none'}
              color={iconColor}
            />
          </Flex>
        </Tooltip>
        {threadsFiltered.length > 0 &&
          threadsOpen &&
          threadsFiltered.slice(0, 5).map(thread => {
            return (
              <OverlayThreadItem
                key={thread.id}
                users={thread.users}
                id={thread.id}
              />
            )
          })}
        {loading ? (
          <Spinner
            color="borderPrimary"
            boxSize="42px"
            mt="4px"
          />
        ) : (
          <Flex
            borderRadius="50%"
            boxSize="42px"
            bg="#C1C4D6"
            align="center"
            justify="center"
            cursor="pointer"
            _hover={{ opacity: '0.7' }}
            mt="4px"
            onClick={() => navigate('/chat')}
          >
            <Image src="/assets/icons/message-overlay.svg" />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
