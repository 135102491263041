import { Map } from 'vfile'
import { Thread, User } from './types'

export enum CHAT_FILTER_TYPE {
  All,
  Unread,
  Sent,
  Archived,
}

export interface IState {
  filter: CHAT_FILTER_TYPE
  loading: boolean
  threads: Thread[]
  selectedThreadId: string | null
  selectedOverlayThreadIds: string[]
  messages: any[]
  isConnected: boolean
  overlayChatOpened: boolean
  users: Record<string, User>
  detailsOpened: boolean

  threadsFiltered: any[]
  currentUser: any
  archivedThreads: any[]
}

export const emptyState: IState = {
  filter: CHAT_FILTER_TYPE.All,
  loading: true,
  threads: [],
  threadsFiltered: [],
  overlayChatOpened: false,
  selectedOverlayThreadIds: [],
  selectedThreadId: null,
  users: {},
  messages: [],
  isConnected: false,
  detailsOpened: false,
  currentUser: null,
  archivedThreads: [],
}

export const Reducer = (state: IState, updatedProperties: Partial<IState>) => ({
  ...state,
  ...updatedProperties,
})
