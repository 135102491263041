import { Box, Flex, Grid, Image, Spinner } from '@chakra-ui/react'
import { Message, User } from '../../../../provider/Chat/types'
import { InputModule } from '../../../pages/Chat/ChatWindow/InputModule/inputModule'
import { Avatar } from '../../../shared/avatars/Avatar'
import { useEffect, useRef } from 'react'
import { useChatProvider } from '../../../../provider/Chat/chatProvider'
import { useEthereumProvider } from '../../../../provider/Ethereum/ethereumProvider'
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons'
import { buildIpfsGateway } from '../../../../utils/parser'
import { Navigate, useNavigate } from 'react-router'

export const ChatWindowItem = ({
  threadId,
  messages,
  users,
  loading,
  scrollTrigger,
}: {
  threadId: string
  messages: Message[]
  users: User[]
  loading: boolean
  scrollTrigger: boolean
}) => {
  const observerTarget = useRef(null)

  const { openOverlayThread, loadThread, openThread } = useChatProvider()

  const bottomRef = useRef(null)

  const navigate = useNavigate()

  useEffect(() => {
    ;(bottomRef as any).current?.scroll({ top: 9991500000 })
  }, [scrollTrigger])

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          loadThread(threadId)
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current)
      }
    }
  }, [observerTarget])

  const wallet = useEthereumProvider()

  return (
    <Grid
      templateRows="45px auto 45px"
      border="1px solid"
      borderColor="borderPrimary"
      bg="backgroundMain"
      h="400px"
      pos="relative"
      borderTopRadius="8px"
      w="300px"
    >
      <Grid
        h="45px"
        px="6px"
        borderBottom="1px solid borderPrimary"
        templateColumns="40px 1fr auto"
        gap="6px"
        alignItems="center"
      >
        <Avatar
          bgColor="#eee"
          size="40px"
          image={users[0].avatarUrl ?? ''}
          name={users[0].nickname ?? users[0].mainAddress}
          textSize={{ short: '14px', long: '14px' }}
        />

        <Box
          color="backgroundAccent"
          fontSize="12px"
        >
          {users[0].nickname ?? users[0].mainAddress.slice(0, 5)}
        </Box>
        <Flex
          align="center"
          mr="8px"
          gap="8px"
        >
          <Image
            src="/assets/icons/share.svg"
            boxSize="18px"
            cursor="pointer"
            onClick={() => {
              openOverlayThread(threadId)
              navigate('/chat/messages')
              openThread(threadId)
            }}
          />
          <CloseIcon
            cursor="pointer"
            color="#8F95B2"
            w="10px"
            onClick={() => openOverlayThread(threadId)}
          />
        </Flex>
      </Grid>
      <>
        <Flex
          flexDir="column"
          px="20px"
          pos="relative"
          overflowX="scroll"
          ref={bottomRef}
          gap="5px"
        >
          <Flex ref={observerTarget}></Flex>
          {loading && (
            <Flex
              align="center"
              justify="center"
              padding="7px"
            >
              <Spinner />
            </Flex>
          )}
          {messages.length == 0 ? (
            <Flex
              justify="center"
              align="center"
              m="auto"
            >
              <Spinner />
            </Flex>
          ) : (
            messages.map((m: any, i: number) => {
              if (i == 0) return
              return (
                <Flex
                  key={i}
                  fontSize="14px"
                  color={wallet ? (wallet.account == m.sender ? 'backgroundMain' : 'textQuaternary') : ''}
                  padding="6px 8px"
                  flexDir="column"
                  maxW="70%"
                  borderRadius="8px"
                  bg={wallet ? (wallet.account == m.sender ? '#3366FF' : '#EDEFF5') : ''}
                  alignSelf={wallet ? (wallet.account == m.sender ? 'flex-end' : 'flex-start') : 'flex-start'}
                  mt={i == 1 ? '8px' : '0px'}
                  mb={i == messages.length - 1 ? '8px' : '0px'}
                >
                  <Flex>{m.text}</Flex>
                  <Flex>
                    {m.assets &&
                      m.assets.map((asset: any) => {
                        if (asset.type == 'image') {
                          return (
                            <Image
                              key={JSON.stringify(asset)}
                              src={buildIpfsGateway(asset.path)}
                            />
                          )
                        }
                      })}
                  </Flex>
                </Flex>
              )
            })
          )}
        </Flex>
      </>
      <InputModule
        threadId={threadId ?? null}
        small
      />
    </Grid>
  )
}
